import getQueryString from 'utils/getQueryString';
import client from './client';

export const ApprovalCaseStatus = {
  request: 'APPROVAL_IN_PROGRESS' as const,
  rejected: 'WRITING_IN_PROGRESS' as const,
  approved: 'APPROVED' as const,
};
export const ApprovalStatus = {
  request: 'APPROVAL_IN_PROGRESS' as const,
  rejected: 'REJECTED' as const,
  approved: 'APPROVED' as const,
};

export type ApprovalCaseStatusType =
  typeof ApprovalCaseStatus[keyof typeof ApprovalCaseStatus];
export type ApprovalStatusType =
  typeof ApprovalStatus[keyof typeof ApprovalStatus];

export const getApproveCases = ({
  companyId,
  approvalStatus,
  icsrStatus,
  query,
}: {
  companyId?: number;
  icsrStatus: ApprovalCaseStatusType;
  approvalStatus: ApprovalStatusType;
  query: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/icsr-summaries/approval-status?companyId=${companyId}&approvalStatus=${approvalStatus}&icsrStatus=${icsrStatus}&${queryString}`,
  );
};

export const getExpeditedReports = ({
  companyId,
  daysBeforeExpiration,
  isNextDaysInclude = false,
  query,
}: {
  companyId?: number;
  daysBeforeExpiration: number;
  query: any;
  isNextDaysInclude?: boolean;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/icsr-summaries/unreported-expedited-reports?companyId=${companyId}&daysBeforeExpiration=${daysBeforeExpiration}&isNextDaysInclude=${isNextDaysInclude}&${queryString}`,
  );
};
