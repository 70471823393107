import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { deleteSenders, getSenders } from 'api/senders';

import { companyState } from 'state';
import useList from 'hooks/useList';
import { Sender } from 'types/sender';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

const listHeads: ListHead[] = [
  {
    name: 'type',
    label: 'Sender Type',
    sort: true,
    filter: true,
    type: 'select',
    data: [
      { key: '1', value: 'Pharmaceutical Company' },
      { key: '2', value: 'Regulatory Authority' },
      { key: '3', value: 'Health Professional' },
      { key: '4', value: 'Regional Pharmacovigilance Centre' },
      {
        key: '5',
        value: 'WHO Collaborating Centres for International Drug Monitoring',
      },
      {
        key: '6',
        value:
          'Other (e.g. Distributor, Study Sponsor, Contract Research Organisation, or non-Commercial Organisation)',
      },
      { key: '7', value: 'Patient / Consumer' },
    ],
    valueField: 'key',
    textField: 'value',
    width: '20rem',
  },
  {
    name: 'organizationName',
    label: "Sender's Organization",
    sort: true,
  },
  {
    name: 'department',
    label: "Sender's Department",
    sort: true,
  },
  {
    name: 'givenName',
    label: "Sender's Given Name",
    sort: true,
  },
  {
    name: 'edit',
    style: { width: '5rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'organizationName', label: 'Organization Name' }],
    filter: true,
    width: '24rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function SenderList() {
  const location = useLocation();
  const [{ selected: company }] = useRecoilState(companyState);

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    selected,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    handleDelete,
  } = useList({
    queryKey: 'senders',
    queryFn: (q) => getSenders({ companyId: company?.id, query: q }),
    deleteFn: (s) => deleteSenders(s),
  });

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
        <div
          className="flex cursor-pointer items-center space-x-2 pl-4 [&_svg]:h-[1.875rem] [&_svg]:w-[1.875rem] [&_svg]:rounded-sm [&_svg]:bg-gray-100 
              [&_svg]:p-1.5 [&_svg]:text-slate-500 dark:[&_svg]:border-slate-600 dark:[&_svg]:text-slate-500
              [&_svg:hover]:bg-slate-400 [&_svg:hover]:text-slate-200 dark:[&_svg:hover]:bg-slate-600"
        >
          <button onClick={handleDelete} disabled={!selected.length}>
            <TbTrash title="delete" />
          </button>
          <NavLink to="./new" state={{ prevUrl: location.pathname }}>
            <Button color="blue" size="sm">
              Create
            </Button>
          </NavLink>
        </div>
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR
              type="head"
              selected={data?.length === selected.length}
              select={(checked) => (checked ? selectAll() : reset())}
            >
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
          >
            {data?.map(
              ({ id, type, organizationName, sendingOfficers }: Sender) => {
                return (
                  <LTR
                    key={id}
                    type="body"
                    selected={selected.findIndex((item) => item === id) !== -1}
                    select={(checked) => select(id, checked)}
                  >
                    <LTD>
                      {
                        listHeads
                          .find(({ name }) => name === 'type')
                          ?.data?.find(({ key }) => key === type)?.value
                      }
                    </LTD>
                    <LTD>{organizationName}</LTD>
                    <LTD>{sendingOfficers?.[0]?.department}</LTD>
                    <LTD>{sendingOfficers?.[0]?.givenName}</LTD>
                    <LTD>
                      <NavLink
                        to={`./${id}`}
                        state={{ prevUrl: location.pathname }}
                        className="flex items-center hover:opacity-60"
                      >
                        <TbEdit className="mr-1" /> Edit
                      </NavLink>
                    </LTD>
                  </LTR>
                );
              },
            )}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
    </>
  );
}

export default SenderList;
