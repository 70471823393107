export const UserType = {
  manager: 'SAFETY_MANAGER',
  user: 'MEMBER_USER',
} as const;

export interface User {
  id?: number;
  companyId?: number;
  companyName?: string;
  userType: string;
  userName: string;
  email: string;
  phone: string;
  startDate: string;
  endDate: string;
  registDate: string;
  editableEdc: boolean;
  managerFlag: number;
  deleted: boolean;
}
