import { atom } from 'recoil';

import { Company } from 'types/company';

const companyState = atom<{ selected?: Company; list: Company[] }>({
  key: 'company',
  default: {
    list: [],
  },
});

export default companyState;
