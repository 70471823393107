import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Stomp from 'stompjs';

import { logout } from 'api/auth';

import tokenTimer from 'utils/tokenTimer';
import modal from 'utils/modal';

import { userState } from 'state';

const client: Stomp.Client = Stomp.client(
  `${process.env.REACT_APP_WEB_SOCKET_URL}`,
);
client.debug = () => {};
client.connect(
  {},
  (msg: any) => {
    console.info('WS', msg.command);
  },
  (error) => {
    console.error('WS', error);
  },
);

function WS() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user] = useRecoilState(userState);

  const [subscription, setSubscription] = useState<Stomp.Subscription>();

  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  const subscribe = (target: string) => {
    if (!client.connected) return;
    const newSubscription = client.subscribe(
      `/topic/users/${target}`,
      (data) => {
        try {
          const msg = JSON.parse(data.body);
          if (msg.id === 'DUPLICATED_LOGIN') {
            newSubscription.unsubscribe();
            modal.alert(
              t('modal:alert:title'),
              t('modal:alert:duplicateLogin'),
            );
            handleLogout();
          }
        } catch (error: any) {
          console.error('WS subscribe', error);
        }
      },
    );
    setSubscription(newSubscription);
  };

  useEffect(() => {
    if (subscription) subscription.unsubscribe();
    if (user) subscribe(user.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
}

export default WS;
