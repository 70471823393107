import { TFunction } from 'i18next';

const identification = (codeList: any, t: TFunction) => ({
  'C.1.1': {
    elementNumber: 'C.1.1',
    label: t('ICSR:elementName:C.1.1'),
    component: 'input',
    maxLength: '100',
    required: true,
  },
  'C.1.2': {
    elementNumber: 'C.1.2',
    label: t('ICSR:elementName:C.1.2'),
    component: 'date',
    format: 'YYYYMMDDHHmmssZZ',
    allowedFormat: ['YYYYMMDDHHmmssZZ', 'YYYYMMDDHHmmss'],
    required: true,
  },
  'C.1.3': {
    elementNumber: 'C.1.3',
    label: t('ICSR:elementName:C.1.3'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.1.4': {
    elementNumber: 'C.1.4',
    label: t('ICSR:elementName:C.1.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
    ],
    required: true,
  },
  'C.1.5': {
    elementNumber: 'C.1.5',
    label: t('ICSR:elementName:C.1.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
    ],
    required: true,
  },
  'C.1.6.1': {
    elementNumber: 'C.1.6.1',
    label: t('ICSR:elementName:C.1.6.1'),
    component: 'radio',
    options: codeList?.CNR_BOOLEAN?.codes || [],
    valueField: 'key',
    textField: 'value',
    required: true,
  },
  'C.1.6.1.r': {
    elementNumber: 'C.1.6.1.r',
    list: [
      {
        elementNumber: 'C.1.6.1.r.1',
        label: t('ICSR:elementName:C.1.6.1.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'C.1.6.1.r.2',
        label: t('ICSR:elementName:C.1.6.1.r.2'),
        component: 'file',
      },
    ],
  },
  'C.1.6.1.r.1': {
    elementNumber: 'C.1.6.1.r.1',
    label: t('ICSR:elementName:C.1.6.1.r.1'),
    component: 'textarea',
    maxLength: '2000',
  },
  'C.1.6.1.r.2': {
    elementNumber: 'C.1.6.1.r.2',
    label: t('ICSR:elementName:C.1.6.1.r.2'),
    component: 'file',
    accept:
      '.pdf,.jpg,.jpeg,.txt,.rtf,.tiff,.tif,.html,.doc,.docx,.xls,.xlsx,.dicom',
  },
  'C.1.7': {
    elementNumber: 'C.1.7',
    label: t('ICSR:elementName:C.1.7'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'C.1.8.1': {
    elementNumber: 'C.1.8.1',
    label: t('ICSR:elementName:C.1.8.1'),
    component: 'combobox',
    maxLength: '100',
    required: true,
  },
  'C.1.8.2': {
    elementNumber: 'C.1.8.2',
    label: t('ICSR:elementName:C.1.8.2'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.3']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.1.9.1': {
    elementNumber: 'C.1.9.1',
    label: t('ICSR:elementName:C.1.9.1'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'C.1.9.1.r': {
    elementNumber: 'C.1.9.1.r',
    list: [
      {
        elementNumber: 'C.1.9.1.r.1',
        label: t('ICSR:elementName:C.1.9.1.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'C.1.9.1.r.2',
        label: t('ICSR:elementName:C.1.9.1.r.2'),
        component: 'input',
      },
    ],
  },
  'C.1.9.1.r.1': {
    elementNumber: 'C.1.9.1.r.1',
    label: t('ICSR:elementName:C.1.9.1.r.1'),
    component: 'textarea',
    maxLength: '100',
  },
  'C.1.9.1.r.2': {
    elementNumber: 'C.1.9.1.r.2',
    label: t('ICSR:elementName:C.1.9.1.r.2'),
    component: 'textarea',
    maxLength: '100',
  },
  'C.1.10.r': {
    elementNumber: 'C.1.10.r',
    label: t('ICSR:elementName:C.1.10.r'),
    list: [
      {
        elementNumber: 'C.1.10.r',
        label: t('ICSR:elementName:C.1.10.r'),
        component: 'input',
      },
    ],
    component: 'textarea',
    maxLength: '100',
  },
  'C.1.11.1': {
    elementNumber: 'C.1.11.1',
    label: t('ICSR:elementName:C.1.11.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.5']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'C.1.11.2': {
    elementNumber: 'C.1.11.2',
    label: t('ICSR:elementName:C.1.11.2'),
    component: 'textarea',
    maxLength: '2000',
  },
});

export default identification;
