import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoCaretDown } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from './Dropdown';

interface Props {
  className?: string;
  size?: SizeType;
  value?: any;
  data: any[];
  placeholder?: string;
  valueField?: string;
  textField?: string | ((i: any) => void);
  error?: boolean;
  disabled?: boolean;
  onChange?: (i: any) => void;
}
const sizeStyles: { [key in SizeType]: string } = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};
const textStyles: { [key in SizeType]: string } = {
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
};

function getText(item: any, textField?: string | ((i: any) => void)) {
  if (!item) return '';
  if (textField) {
    return typeof textField === 'string' ? item[textField] : textField(item);
  }
  return item;
}

function InlineFunction({
  className,
  size = 'md',
  value,
  data,
  placeholder,
  valueField,
  textField,
  error,
  disabled,
  onChange = () => null,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleSelect = useCallback(
    (item: any) => {
      if (inputRef.current) {
        const itemValue = valueField ? item[valueField] : item;
        const {
          value: inputValue,
          selectionStart,
          selectionEnd,
        } = inputRef.current;
        const start = selectionStart || 0;
        const end = selectionEnd || 0;
        const newValue =
          inputValue.slice(0, start) + itemValue + inputValue.slice(end);
        inputRef.current.value = newValue;
        inputRef.current.focus();
        inputRef.current.setSelectionRange(start, start + itemValue.length);
        onChange(newValue);
      }
    },
    [onChange, valueField],
  );

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value !== value) inputRef.current.value = value;
    }
  }, [value]);

  return (
    <Dropdown
      isOpen={open}
      className={twMerge(clsx('h-fit', disabled && 'disabled', className))}
      toggle={toggle}
    >
      <DropdownToggle>
        <input
          ref={inputRef}
          className={clsx(
            sizeStyles[size],
            'group-[.focused]:control-focus min-w-full max-w-full truncate pr-7',
            error && 'control-error',
          )}
          placeholder={placeholder}
          value={value || ''}
          onChange={handleChange}
        />
        <IoCaretDown
          tabIndex={-1}
          className={clsx(
            'absolute top-1/2 right-2 -translate-y-1/2 text-slate-400 group-[.focused]:text-slate-800 dark:text-slate-500 dark:group-[.focused]:text-slate-200',
            textStyles[size],
          )}
        />
      </DropdownToggle>
      <DropdownMenu>
        <ul className="min-w-none max-h-72 overflow-y-auto rounded border border-slate-300 bg-white p-1 dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300">
          {data.map((item: any, index: number) => (
            <DropdownItem
              key={index}
              tag="li"
              className={clsx(
                'mb-1 break-all rounded-sm px-2 py-1 last:mb-0 hover:cursor-pointer data-[focused=true]:bg-slate-300/40 dark:data-[focused=true]:bg-slate-700',
                textStyles[size],
              )}
              onClick={() => handleSelect(item)}
              onMouseOver={(e) => e.preventDefault()}
            >
              {getText(item, textField)}
            </DropdownItem>
          ))}
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
}

export default React.memo(InlineFunction);
