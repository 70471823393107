import React from 'react';

import { Approval, ApprovalLine } from 'types/approval';

import SignBlock from 'components/common/SignBlock';
import Button from 'components/common/Button';

import ICSRListTable from './ICSRListTable';

interface Props extends Approval {
  handleApprove?: (id: number, approvalLines: ApprovalLine[]) => void;
  handleReject?: (id: number, approvalLines: ApprovalLine[]) => void;
}

function ExpandedComponent({
  id,
  subject,
  lastModifiedDate,
  approvalLines,
  icsrs,
  handleApprove,
  handleReject,
}: Props) {
  return (
    <div className="flex flex-wrap [&>div]:flex [&>div]:w-full [&>div]:px-2 [&>div]:py-2 [&>div]:md:w-1/2">
      <div>
        <div className="w-40 font-semibold">Subject</div>
        <div className="flex-1">{subject}</div>
      </div>
      <div>
        <div className="w-40 font-semibold">Request Date</div>
        <div className="flex-1">{lastModifiedDate}</div>
      </div>
      <div>
        <div className="w-40 font-semibold">Requster</div>
        <div className="flex-1">{approvalLines?.[0].approverName}</div>
      </div>
      <div>
        <div className="w-40 font-semibold">Approval Line</div>
        <div className="flex-1">
          <SignBlock lines={approvalLines} />
        </div>
      </div>
      <div className="md:!w-full">
        <div className="w-40 font-semibold">ICSR List</div>
        <div className="flex-1">
          <ICSRListTable icsrs={icsrs} />
        </div>
      </div>
      <div className="mt-1 justify-end space-x-1 md:!w-full">
        {handleReject && (
          <Button color="red" onClick={() => handleReject(id, approvalLines)}>
            Reject
          </Button>
        )}
        {handleApprove && (
          <Button onClick={() => handleApprove(id, approvalLines)}>
            Approve
          </Button>
        )}
      </div>
    </div>
  );
}

export default ExpandedComponent;
