import React from 'react';
import clsx from 'clsx';

function ArrayString({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={clsx('-ml-1 -mt-1 flex flex-wrap items-center', className)}>
      {typeof children === 'string' &&
        children.split(',').map((str: string, idx: number) => {
          const noSpaceString = str.trim();
          return (
            noSpaceString !== 'null' && (
              <span
                key={idx}
                className="relative ml-1 mt-1 inline-block h-fit truncate rounded border border-sky-300 bg-sky-100 px-2"
              >
                {noSpaceString}
              </span>
            )
          );
        })}
    </div>
  );
}

export default ArrayString;
