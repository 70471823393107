import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getSenders } from 'api/senders';

import { CaseAction } from 'types/case';
import { Sender } from 'types/sender';

import { companyState } from 'state';

import Button from 'components/common/Button';
import Popup from 'components/common/Popup';
import Select from 'components/common/Select/Select';
import useCaseActions from '../hooks/useCaseActions';

function SubmissionPopup({
  isOpen,
  icsrIds,
  toggle,
  callback = () => null,
}: {
  isOpen: boolean;
  icsrIds: number[];
  toggle: () => void;
  callback?: () => void;
}) {
  const [{ selected: company }] = useRecoilState(companyState);
  const { submitCases } = useCaseActions();
  const [sender, setSender] = useState<Sender>();

  const { data: senders = [] } = useQuery(
    ['getSenders'],
    () =>
      getSenders({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleSubmit = () => {
    if (sender) {
      const { id: senderId, companyId } = sender;

      submitCases({
        companyId,
        actionType: CaseAction.SUBMIT,
        senderId,
        icsrIds,
      }).then(callback);
    }

    toggle();
  };

  return (
    <Popup isOpen={isOpen} className="w-[28rem]">
      <div className="border-b border-brand-600 py-2 font-semibold">
        Submission
      </div>
      <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
        <div>
          <div className="w-36">
            <em>Sender</em>
          </div>
          <div className="flex-1">
            <Select
              value={sender?.id}
              data={senders}
              onChange={(obj) => setSender(obj)}
              valueField="id"
              textField="organizationName"
            />
          </div>
        </div>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!sender} color="blue">
          Submit
        </Button>
      </div>
    </Popup>
  );
}

export default SubmissionPopup;
