import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BsInfoCircle } from 'react-icons/bs';

import Tooltip from 'components/common/Tooltip';

import { useCaseState } from './context/CaseContext';

function ValidationInfo({ elementNumber }: { elementNumber?: string }) {
  const { t, i18n } = useTranslation();
  const { region } = useCaseState();

  const validationInfo = useMemo(() => {
    const obj =
      (i18n.exists(`ICSR:elementRule:${region}:${elementNumber}`) &&
        t(`ICSR:elementRule:${region}:${elementNumber}`, {
          returnObjects: true,
        })) ||
      (i18n.exists(`ICSR:elementRule:ICH:${elementNumber}`) &&
        t(`ICSR:elementRule:ICH:${elementNumber}`, {
          returnObjects: true,
        }));
    if (Array.isArray(obj)) {
      return obj.map((str: string, idx: number) => (
        <div key={idx} className="flex">
          <span className="mr-1 inline-flex">{idx + 1}.</span>
          {str}
        </div>
      ));
    }
    return obj as string;
  }, [elementNumber, i18n, region, t]);

  return validationInfo ? (
    <Tooltip
      place="bottom-end"
      className="z-dropdown border !p-2"
      render={() => validationInfo}
    >
      <BsInfoCircle className="ml-1.5 mb-0.5 inline-block text-blue-500" />
    </Tooltip>
  ) : null;
}

export default ValidationInfo;
