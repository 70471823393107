import React from 'react';
import { Moment } from 'moment';

import { getYearRange } from './datePickerUtils';

interface Props {
  viewDate: Moment;
  handleYearClick: (e: React.MouseEvent) => void;
}
function Years({ viewDate, handleYearClick }: Props) {
  const yearRange = getYearRange(viewDate);
  return (
    <div className="grid w-64 grid-cols-4">
      {yearRange.map((year) => (
        <button
          key={year}
          data-year={year}
          className="block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
          onClick={handleYearClick}
        >
          {year}
        </button>
      ))}
    </div>
  );
}

export default Years;
