/* eslint-disable react/no-danger */
import React from 'react';

import { Notification } from 'types/notification';

function ExpandedComponent({ subject, createdTime, contents }: Notification) {
  return (
    <div className="flex flex-wrap [&>div]:flex [&>div]:w-full [&>div]:px-2 [&>div]:py-2 [&>div]:md:w-1/2">
      <div>
        <div className="w-40 font-semibold">Subject</div>
        <div className="flex-1">{subject}</div>
      </div>
      <div>
        <div className="w-40 font-semibold">Registration Date</div>
        <div className="flex-1">{createdTime}</div>
      </div>
      <div className="md:!w-full">
        <div className="w-40 font-semibold">content</div>
        <div className="flex-1">
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </div>
      </div>
    </div>
  );
}

export default ExpandedComponent;
