import getQueryString from 'utils/getQueryString';
import client from './client';

export const getMemos = ({
  icsrId,
  dataElementId,
  isClosedIncluded,
  query,
}: {
  icsrId?: string | number;
  dataElementId?: string | number;
  isClosedIncluded?: boolean;
  query: any;
}) => {
  const pQuery = `icsrId=${icsrId}${
    dataElementId ? `&dataElementId=${dataElementId}` : ''
  }${isClosedIncluded ? `&isClosedIncluded=${isClosedIncluded}` : ''}`;
  const queryString = getQueryString(query);
  return client.get(`/api/v1/memos?${pQuery}&${queryString}`);
};

export const postMemo = (data: any) => client.post('/api/v1/memos', data);

export const putMemo = ({ id, data }: { id?: string | number; data: any }) =>
  client.put(`/api/v1/memos/${id}`, data);

export const putAction = ({ id, data }: { id?: string | number; data: any }) =>
  client.put(`/api/v1/memos/${id}/action`, data);
