import React from 'react';

import { ICSRFormElement } from 'types/case';

import { DatePicker } from 'components/common/DatePicker';

function DateComponent({
  className,
  number,
  value,
  format,
  allowedFormat = [],
  disabled,
  onChange = () => null,
}: {
  className?: string;
  number: string;
  value?: string | null;
  format?: string;
  allowedFormat?: string[];
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  return (
    <DatePicker
      className={className}
      value={value || ''}
      format={format}
      allowedFormat={allowedFormat}
      onChange={(date) => {
        if (date?.isValid()) {
          onChange(number, {
            number,
            value: date.creationData().strict
              ? (date.creationData().input as string)
              : date.format(format),
            nullFlavor: undefined,
          });
        } else {
          onChange(number, { number, value: undefined, nullFlavor: undefined });
        }
      }}
      disabled={disabled}
    />
  );
}

export default DateComponent;
