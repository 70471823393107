import React, { useMemo } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import clsx from 'clsx';

import Icon from 'components/common/Icon';

import { NavItemType } from './NavItems';

function NavItem({ to, icon, label }: NavItemType) {
  const {
    params: { path, company },
  } = useMatch('/:company/:path/*') || { params: {} };
  const isActive = !!path && new RegExp(`^${to.split('/')[1]}$`).test(path);

  return useMemo(
    () => (
      <NavLink
        className={clsx(
          'mb-1 flex flex-shrink-0 items-center overflow-hidden rounded hover:bg-brand-500/10',
          isActive
            ? 'bg-brand-500/10 font-bold text-brand-500'
            : 'text-slate-500',
        )}
        to={`/${company}${to}`}
        title={label}
      >
        <span className="inline-block min-w-[2.25rem]">
          {icon && (
            <Icon
              className={clsx(
                'h-9 w-9 flex-shrink-0 p-2',
                isActive && 'stroke-[3]',
              )}
              lib={icon.lib}
              name={icon.name}
            />
          )}
        </span>
        <span className="ml-2 inline-block flex-1 text-left">{label}</span>
      </NavLink>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company, isActive],
  );
}

export default NavItem;
