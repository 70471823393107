import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getSenders } from 'api/senders';
import { getReceivers } from 'api/receivers';

import { companyState, coreState } from 'state';
import { EMAExportTypes, MFDSExportTypes } from 'types/history';
import { Region } from 'types/core';

import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Popup from 'components/common/Popup';
import Select from 'components/common/Select/Select';
import useCaseActions from '../hooks/useCaseActions';

const mfdsExportTypes = Object.entries(MFDSExportTypes).map(
  ([key, value]: any) => ({
    key,
    value,
  }),
);
const emaExportTypes = Object.entries(EMAExportTypes).map(
  ([key, value]: any) => ({
    key,
    value,
  }),
);

const exportTypes: { [key in Region]: any[] } = {
  MFDS: mfdsExportTypes,
  EMA: emaExportTypes,
};
interface ExporForm {
  exportType?: string;
  senderId?: number;
  receiverId?: number;
  translated: boolean;
  individual: boolean;
}

function ExportPopup({
  isOpen,
  icsrIds,
  toggle,
  callback = () => null,
}: {
  isOpen: boolean;
  icsrIds: number[];
  toggle: () => void;
  callback?: () => void;
}) {
  const [{ selected: company }] = useRecoilState(companyState);
  const [{ region }] = useRecoilState(coreState);
  const { exportCases } = useCaseActions();
  const [form, setForm] = useState<ExporForm>({
    translated: false,
    individual: false,
  });

  const { data: senders = [] } = useQuery(
    ['getSenders'],
    () =>
      getSenders({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: receivers = [] } = useQuery(
    ['getReceivers'],
    () =>
      getReceivers({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleSubmit = () => {
    exportCases({
      companyId: company?.id,
      icsrIds,
      ...form,
    }).then(callback);

    toggle();
  };

  const exportTypesForRegion = useMemo(() => exportTypes[region], [region]);

  return (
    <Popup isOpen={isOpen} className="w-[30rem]">
      <div className="border-b border-brand-600 py-2 font-semibold">Export</div>
      <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
        <div>
          <div className="w-36">
            <em>Type</em>
          </div>
          <div className="flex-1">
            <Select
              value={form.exportType}
              data={exportTypesForRegion}
              onChange={({ key: exportType }) =>
                setForm((prev) => ({ ...prev, exportType }))
              }
              valueField="key"
              textField="value"
            />
          </div>
        </div>
        <div>
          <div className="w-36">
            <em>Sender</em>
          </div>
          <div className="flex-1">
            <Select
              value={form.senderId}
              data={senders}
              onChange={({ id }) =>
                setForm((prev) => ({ ...prev, senderId: id }))
              }
              valueField="id"
              textField="organizationName"
            />
          </div>
        </div>
        <div>
          <div className="w-36">
            <em>Receiver</em>
          </div>
          <div className="flex-1">
            <Select
              value={form.receiverId}
              data={receivers}
              onChange={({ id }) =>
                setForm((prev) => ({ ...prev, receiverId: id }))
              }
              valueField="id"
              textField="name"
            />
          </div>
        </div>
        <div>
          <div className="w-36">
            <em>Translated</em>
          </div>
          <div className="flex-1">
            <Checkbox
              checked={form.translated}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setForm((prev) => ({ ...prev, translated: e.target.checked }))
              }
            />
          </div>
        </div>
        <div>
          <div className="w-36">
            <em>Individual</em>
          </div>
          <div className="flex-1">
            <Checkbox
              checked={form.individual}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setForm((prev) => ({ ...prev, individual: e.target.checked }))
              }
            />
          </div>
        </div>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!form.exportType || !form.senderId || !form.receiverId}
          color="blue"
        >
          Export
        </Button>
      </div>
    </Popup>
  );
}

export default ExportPopup;
