import { TFunction } from 'i18next';

const reactionEvent = (codeList: any, t: TFunction) => ({
  'E.i': {
    elementNumber: 'E.i',
    list: [
      {
        elementNumber: 'E.i.1.1a',
        label: t('ICSR:elementName:E.i.1.1a'),
        component: 'input',
      },
      {
        elementNumber: 'E.i.2.1a',
        label: t('ICSR:elementName:E.i.2.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'E.i.2.1b',
        label: t('ICSR:elementName:E.i.2.1b'),
        component: 'MedDRASearch',
      },
      {
        elementNumber: 'E.i.4',
        label: t('ICSR:elementName:E.i.4'),
        component: 'input',
      },
      {
        elementNumber: 'E.i.5',
        label: t('ICSR:elementName:E.i.5'),
        component: 'input',
      },
      {
        elementNumber: 'E.i.7',
        label: t('ICSR:elementName:E.i.7'),
        component: 'radio',
        data: codeList['2.16.840.1.113883.3.989.2.1.1.11']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
  },
  'E.i.1.1a': {
    elementNumber: 'E.i.1.1a',
    label: t('ICSR:elementName:E.i.1.1a'),
    component: 'input',
    type: 'teatarea',
    maxLength: '250',
  },
  'E.i.1.1b': {
    elementNumber: 'E.i.1.1b',
    label: t('ICSR:elementName:E.i.1.1b'),
    component: 'select',
    data: codeList['1.0.639.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'E.i.1.2': {
    elementNumber: 'E.i.1.2',
    label: t('ICSR:elementName:E.i.1.2'),
    component: 'input',
    type: 'teatarea',
    maxLength: '250',
  },
  'E.i.2.1a': {
    elementNumber: 'E.i.2.1a',
    label: t('ICSR:elementName:E.i.2.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
    required: true,
  },
  'E.i.2.1b': {
    elementNumber: 'E.i.2.1b',
    label: t('ICSR:elementName:E.i.2.1b'),
    component: 'MedDRASearch',
    required: true,
  },
  'E.i.3.1': {
    elementNumber: 'E.i.3.1',
    label: t('ICSR:elementName:E.i.3.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.10']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'E.i.3.2a': {
    elementNumber: 'E.i.3.2a',
    label: t('ICSR:elementName:E.i.3.2a'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2b': {
    elementNumber: 'E.i.3.2b',
    label: t('ICSR:elementName:E.i.3.2b'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2c': {
    elementNumber: 'E.i.3.2c',
    label: t('ICSR:elementName:E.i.3.2c'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2d': {
    elementNumber: 'E.i.3.2d',
    label: t('ICSR:elementName:E.i.3.2d'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2e': {
    elementNumber: 'E.i.3.2e',
    label: t('ICSR:elementName:E.i.3.2e'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2f': {
    elementNumber: 'E.i.3.2f',
    label: t('ICSR:elementName:E.i.3.2f'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NI'],
    required: true,
  },
  'E.i.3.2.CNR.1': {
    elementNumber: 'E.i.3.2.CNR.1',
    label: t('ICSR:elementName:E.i.3.2.CNR.1'),
    component: 'radio',
    options: codeList.CNR_EXPECTEDNESS?.codes,
    valueField: 'key',
    textField: 'value',
    required: true,
  },
  'E.i.3.2.CNR.2': {
    elementNumber: 'E.i.3.2.CNR.2',
    label: t('ICSR:elementName:E.i.3.2.CNR.2'),
    component: 'input',
    maxLength: '100',
  },
  'E.i.4': {
    elementNumber: 'E.i.4',
    label: t('ICSR:elementName:E.i.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'E.i.5': {
    elementNumber: 'E.i.5',
    label: t('ICSR:elementName:E.i.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'E.i.6a': {
    elementNumber: 'E.i.6a',
    label: t('ICSR:elementName:E.i.6a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '5',
  },
  'E.i.6b': {
    elementNumber: 'E.i.6b',
    label: t('ICSR:elementName:E.i.6b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'E.i.7': {
    elementNumber: 'E.i.7',
    label: t('ICSR:elementName:E.i.7'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.11']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'E.i.8': {
    elementNumber: 'E.i.8',
    label: t('ICSR:elementName:E.i.8'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'E.i.9': {
    elementNumber: 'E.i.9',
    label: t('ICSR:elementName:E.i.9'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
});

export default reactionEvent;
