import React from 'react';

import { User, UserType } from 'types/user';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { DatePicker } from 'components/common/DatePicker';
import Select from 'components/common/Select/Select';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';
import { Company } from 'types/company';

interface props {
  submitLabel: string;
  check?: boolean;
  form: User;
  errors: any;
  userTypeList: {
    key: string;
    value: typeof UserType[keyof typeof UserType];
  }[];
  companies?: Company[];
  checkAvailability?: () => void;
  resendEmail?: () => void;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}

function UserForm({
  submitLabel,
  check,
  form,
  errors,
  userTypeList,
  companies = [],
  checkAvailability = () => null,
  resendEmail = () => null,
  onChange,
  onSubmit,
}: props) {
  const {
    companyId,
    userName,
    email,
    phone,
    startDate,
    endDate,
    userType,
    editableEdc,
  } = form;

  return (
    <form className="max-w-4xl px-2" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div className="space-y-1">
          <div className="flex py-4">
            <div className="required w-1/2">Email</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full flex-1"
                value={email}
                onChange={(e) => onChange('email', e.target.value)}
                disabled={submitLabel !== 'Create'}
              />
              {submitLabel === 'Create' ? (
                <Button
                  className="ml-1"
                  color="slate"
                  onClick={checkAvailability}
                  disabled={!email || !!errors.email}
                  outline
                >
                  Check
                </Button>
              ) : (
                <Button
                  className="ml-1"
                  color="slate"
                  onClick={resendEmail}
                  outline
                >
                  Resend
                </Button>
              )}
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.email?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">Name</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={userName}
                onChange={(e) => onChange('userName', e.target.value)}
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.userName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">Phone</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={phone}
                onChange={(e) => onChange('phone', e.target.value)}
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.phone?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">Start Date</div>
            <div className="flex w-1/2 flex-wrap">
              <DatePicker
                className="w-full"
                value={startDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  onChange(
                    'startDate',
                    date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                  )
                }
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.startDate?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="w-1/2">End Date</div>
            <div className="flex w-1/2 flex-wrap">
              <DatePicker
                className="w-full"
                value={endDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  onChange(
                    'endDate',
                    date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                  )
                }
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.endDate?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">User Type</div>
            <div className="flex w-1/2 flex-wrap">
              <Select
                className="w-full"
                value={userType}
                data={userTypeList}
                textField="value"
                valueField="value"
                onChange={(obj) => onChange('userType', obj?.value)}
                disabled={!check || submitLabel === 'Save'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.userType?.message}
              </span>
            </div>
          </div>
          {submitLabel === 'Save' ? (
            <div className="flex py-4">
              <div className="required w-1/2">EDC Editor</div>
              <div className="flex w-1/2 flex-wrap">
                <RadioGroup
                  className="flex space-x-4"
                  name="editable-edc"
                  value={editableEdc?.toString()}
                  onChange={(value) =>
                    onChange('editableEdc', value && JSON.parse(value))
                  }
                >
                  <Radio size="md" value="true" label="ON" />
                  <Radio size="md" value="false" label="OFF" />
                </RadioGroup>
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.editableEdc?.message}
                </span>
              </div>
            </div>
          ) : null}
          {userType === UserType.user ? (
            <div className="flex py-4">
              <div className="required w-1/2">Company</div>
              <div className="flex w-1/2 flex-wrap">
                <Select
                  className="w-full"
                  value={companyId}
                  data={companies}
                  textField="companyName"
                  valueField="id"
                  onChange={(obj) => onChange('companyId', obj?.id)}
                  disabled={submitLabel !== 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.companyId?.message}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default UserForm;
