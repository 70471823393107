import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { TbDownload } from 'react-icons/tb';
import clsx from 'clsx';

import { fileUpload } from 'api/cases';

import { ICSRFormElement } from 'types/case';
import modal from 'utils/modal';

import FileUpload from 'components/common/FileUpload';
import Download from 'components/common/Donwload';

import { useCaseState } from './context/CaseContext';

function FileComponent({
  className,
  elementId,
  number,
  value,
  description = '',
  accept = '',
  disabled,
  onChange = () => null,
}: {
  className?: string;
  elementId?: number;
  number: string;
  value?: string | null;
  description?: string | null;
  accept?: string;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  const { t } = useTranslation();
  const { caseId } = useCaseState();

  const { mutate } = useMutation(fileUpload, {
    onSuccess({ data }) {
      onChange(number, {
        number,
        value: data.id,
        description: data.originalFileName,
        nullFlavor: undefined,
      });
    },
  });

  const handleChange = async (files: any) => {
    if (files) {
      const file: File = files[0];
      const formData = new FormData();

      if (file.size > 1024 * 1024 * 128) {
        modal.alert(t('modal:alert:title'), t('modal:alert:limitedFileSize'));
        return;
      }

      formData.append('file', file);

      mutate(formData);
    }
  };

  return (
    <div className={clsx('flex items-center space-x-2', className)}>
      <FileUpload
        className="w-full"
        value={description || ''}
        accept={accept}
        onChange={handleChange}
        disabled={disabled}
      />
      {value && (
        <button className="hover:opacity-80">
          {elementId ? (
            <Download
              src={`${process.env.REACT_APP_API_HOST}/api/v1/icsrs/${caseId}/files/${value}`}
            >
              <TbDownload fontSize="1.25rem" />
            </Download>
          ) : (
            <Download
              src={`${process.env.REACT_APP_API_HOST}/api/v1/files/${value}`}
            >
              <TbDownload fontSize="1.25rem" />
            </Download>
          )}
        </button>
      )}
    </div>
  );
}

export default FileComponent;
