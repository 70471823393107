import client from './client';

export const translate = ({
  sourceLanguageCode = 'auto',
  targetLanguageCode,
  text,
}: {
  sourceLanguageCode?: string;
  targetLanguageCode?: string;
  text?: string;
}): Promise<any> =>
  client.post('/api/v1/translate', {
    sourceLanguageCode,
    targetLanguageCode,
    text,
  });
