import React from 'react';
import { IoClose } from 'react-icons/io5';

import useListQuery from 'hooks/useListQuery';
import { getMemos } from 'api/memos';
import { Memo } from 'types/memo';

import Popup from 'components/common/Popup';
import Pagination from 'components/common/Pagination';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

import { useCaseState } from '../context/CaseContext';

function MemoListPopup({
  isOpen,
  elementId,
  elementNumber,
  toggle,
}: {
  isOpen: boolean;
  elementId?: number;
  elementNumber?: string;
  toggle: () => void;
}) {
  const { caseId } = useCaseState();
  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    refetch,
    changePage,
  } = useListQuery({
    queryKey: 'memoList',
    queryFn: (q) =>
      getMemos({
        icsrId: caseId,
        dataElementId: elementId,
        isClosedIncluded: true,
        query: q,
      }),
    options: {
      enabled: isOpen,
    },
  });

  return (
    <Popup isOpen={isOpen} className="max-h-[35rem] w-[70rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        Memo List
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-1">Element Number - {elementNumber}</div>
      <div className="overflow-auto border-t border-b bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              <LTH>Status</LTH>
              <LTH>Comment</LTH>
              <LTH>Created By</LTH>
              <LTH>Created Date</LTH>
              <LTH>Modified By</LTH>
              <LTH>Modified Date</LTH>
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={6}
            disableCheckbox
          >
            {data?.map(
              (
                {
                  status,
                  comment,
                  createdBy,
                  createdDate,
                  lastModifiedBy,
                  lastModifiedDate,
                }: Memo,
                idx: number,
              ) => (
                <LTR key={idx} type="body">
                  <LTD>{status}</LTD>
                  <LTD>{comment}</LTD>
                  <LTD>{createdBy}</LTD>
                  <LTD>{createdDate}</LTD>
                  <LTD>{lastModifiedBy}</LTD>
                  <LTD>{lastModifiedDate}</LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Pagination
          page={query.page}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          refresh={refetch}
          disabledChangeSize
        />
      </div>
    </Popup>
  );
}

export default MemoListPopup;
