import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserType } from 'types/user';
import { getUserInfo } from 'api/general';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { userState } from 'state';

function AuthCheck() {
  const setUser = useSetRecoilState(userState);

  const { data: user, isFetching } = useQuery(
    ['authCheck'],
    () => getUserInfo().then(({ data }) => data),
    {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setUser(data);
      },
      onError() {
        setUser(undefined);
      },
    },
  );

  if (isFetching) return null;

  if (user) {
    if (user.userType === UserType.manager) {
      return <Navigate replace to="/system-manage/users" />;
    }
    return <Navigate replace to="/select-company" />;
  }

  return <Outlet />;
}

export default AuthCheck;
