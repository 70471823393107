import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';

import modal from 'utils/modal';
import { requestEnd, requestStart } from 'components/Request';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError(error) {
        if (error instanceof AxiosError) {
          modal.alert(
            t('modal:alert:error'),
            error?.response?.data?.message || error?.message,
          );
        }
      },
    },
    mutations: {
      onMutate() {
        requestStart();
      },
      onSettled(data, error) {
        requestEnd();
        if (data) {
          toast.success('Request Success');
        }
        if (error instanceof AxiosError) {
          modal.alert(
            t('modal:alert:error'),
            error?.response?.data?.message || error?.message,
          );
        }
      },
    },
  },
});

export default queryClient;
