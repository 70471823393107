export const IcsrStructure = {
  identification: 'C.1',
  primaryInformation: 'C.2.r',
  sender: 'C.3',
  literatureReference: 'C.4.r',
  study: 'C.5',
  patientCharacteristics: 'D',
  reactionEvent: 'E.i',
  testResult: 'F.r',
  drug: 'G.k',
  narrative: 'H',
} as const;

export const ICSRElementCondition = {
  mandatory: 'Mandatory',
  conditionalMandatory: 'Conditional Mandatory',
  optional: 'Optional',
} as const;

export const ICSRElementConditionMap: {
  [key: string]: typeof ICSRElementCondition[keyof typeof ICSRElementCondition];
} = {
  'C.1.1': ICSRElementCondition.mandatory,
  'C.1.2': ICSRElementCondition.mandatory,
  'C.1.3': ICSRElementCondition.mandatory,
  'C.1.4': ICSRElementCondition.mandatory,
  'C.1.5': ICSRElementCondition.mandatory,
  'C.1.6.1': ICSRElementCondition.mandatory,
  'C.1.6.1.r.1': ICSRElementCondition.conditionalMandatory,
  'C.1.6.1.r.2': ICSRElementCondition.optional,
  'C.1.7': ICSRElementCondition.mandatory,
  'C.1.8.1': ICSRElementCondition.mandatory,
  'C.1.8.2': ICSRElementCondition.mandatory,
  'C.1.9.1': ICSRElementCondition.mandatory,
  'C.1.9.1.r.1': ICSRElementCondition.conditionalMandatory,
  'C.1.9.1.r.2': ICSRElementCondition.conditionalMandatory,
  'C.1.10.r': ICSRElementCondition.optional,
  'C.1.11.1': ICSRElementCondition.optional,
  'C.1.11.2': ICSRElementCondition.conditionalMandatory,
  'C.2.r.1.1': ICSRElementCondition.optional,
  'C.2.r.1.2': ICSRElementCondition.optional,
  'C.2.r.1.3': ICSRElementCondition.optional,
  'C.2.r.1.4': ICSRElementCondition.optional,
  'C.2.r.2.1': ICSRElementCondition.optional,
  'C.2.r.2.2': ICSRElementCondition.optional,
  'C.2.r.2.3': ICSRElementCondition.optional,
  'C.2.r.2.4': ICSRElementCondition.optional,
  'C.2.r.2.5': ICSRElementCondition.optional,
  'C.2.r.2.6': ICSRElementCondition.optional,
  'C.2.r.2.7': ICSRElementCondition.optional,
  'C.2.r.3': ICSRElementCondition.conditionalMandatory,
  'C.2.r.4': ICSRElementCondition.mandatory,
  'C.2.r.4.KR.1': ICSRElementCondition.optional,
  'C.2.r.5': ICSRElementCondition.conditionalMandatory,
  'C.3.1': ICSRElementCondition.mandatory,
  'C.3.1.KR.1': ICSRElementCondition.optional,
  'C.3.2': ICSRElementCondition.conditionalMandatory,
  'C.3.3.1': ICSRElementCondition.optional,
  'C.3.3.2': ICSRElementCondition.optional,
  'C.3.3.3': ICSRElementCondition.optional,
  'C.3.3.4': ICSRElementCondition.optional,
  'C.3.3.5': ICSRElementCondition.optional,
  'C.3.4.1': ICSRElementCondition.optional,
  'C.3.4.2': ICSRElementCondition.optional,
  'C.3.4.3': ICSRElementCondition.optional,
  'C.3.4.4': ICSRElementCondition.optional,
  'C.3.4.5': ICSRElementCondition.optional,
  'C.3.4.6': ICSRElementCondition.optional,
  'C.3.4.7': ICSRElementCondition.optional,
  'C.3.4.8': ICSRElementCondition.optional,
  'C.4.r.1': ICSRElementCondition.optional,
  'C.4.r.2': ICSRElementCondition.optional,
  'C.5.1.r.1': ICSRElementCondition.optional,
  'C.5.1.r.2': ICSRElementCondition.optional,
  'C.5.2': ICSRElementCondition.optional,
  'C.5.3': ICSRElementCondition.optional,
  'C.5.4': ICSRElementCondition.conditionalMandatory,
  'C.5.4.KR.1': ICSRElementCondition.optional,
  'D.1': ICSRElementCondition.mandatory,
  'D.1.1.1': ICSRElementCondition.optional,
  'D.1.1.2': ICSRElementCondition.optional,
  'D.1.1.3': ICSRElementCondition.optional,
  'D.1.1.4': ICSRElementCondition.optional,
  'D.2.1': ICSRElementCondition.optional,
  'D.2.2a': ICSRElementCondition.conditionalMandatory,
  'D.2.2b': ICSRElementCondition.conditionalMandatory,
  'D.2.2.1a': ICSRElementCondition.conditionalMandatory,
  'D.2.2.1b': ICSRElementCondition.conditionalMandatory,
  'D.2.3': ICSRElementCondition.optional,
  'D.3': ICSRElementCondition.optional,
  'D.4': ICSRElementCondition.optional,
  'D.5': ICSRElementCondition.optional,
  'D.6': ICSRElementCondition.optional,
  'D.7.1.r.1a': ICSRElementCondition.conditionalMandatory,
  'D.7.1.r.1b': ICSRElementCondition.conditionalMandatory,
  'D.7.1.r.2': ICSRElementCondition.optional,
  'D.7.1.r.3': ICSRElementCondition.optional,
  'D.7.1.r.4': ICSRElementCondition.optional,
  'D.7.1.r.5': ICSRElementCondition.optional,
  'D.7.1.r.6': ICSRElementCondition.optional,
  'D.7.2': ICSRElementCondition.conditionalMandatory,
  'D.7.3': ICSRElementCondition.optional,
  'D.8.r.1': ICSRElementCondition.conditionalMandatory,
  'D.8.r.1.KR.1a': ICSRElementCondition.conditionalMandatory,
  'D.8.r.1.KR.1b': ICSRElementCondition.conditionalMandatory,
  'D.8.r.2a': ICSRElementCondition.conditionalMandatory,
  'D.8.r.2b': ICSRElementCondition.conditionalMandatory,
  'D.8.r.3a': ICSRElementCondition.conditionalMandatory,
  'D.8.r.3b': ICSRElementCondition.conditionalMandatory,
  'D.8.r.4': ICSRElementCondition.optional,
  'D.8.r.5': ICSRElementCondition.optional,
  'D.8.r.6a': ICSRElementCondition.conditionalMandatory,
  'D.8.r.6b': ICSRElementCondition.conditionalMandatory,
  'D.8.r.7a': ICSRElementCondition.conditionalMandatory,
  'D.8.r.7b': ICSRElementCondition.conditionalMandatory,
  'D.9.1': ICSRElementCondition.optional,
  'D.9.2.r.1a': ICSRElementCondition.conditionalMandatory,
  'D.9.2.r.1b': ICSRElementCondition.conditionalMandatory,
  'D.9.2.r.2': ICSRElementCondition.conditionalMandatory,
  'D.9.3': ICSRElementCondition.conditionalMandatory,
  'D.9.4.r.1a': ICSRElementCondition.conditionalMandatory,
  'D.9.4.r.1b': ICSRElementCondition.conditionalMandatory,
  'D.9.4.r.2': ICSRElementCondition.conditionalMandatory,
  'D.10.1': ICSRElementCondition.optional,
  'D.10.2.1': ICSRElementCondition.optional,
  'D.10.2.2a': ICSRElementCondition.conditionalMandatory,
  'D.10.2.2b': ICSRElementCondition.conditionalMandatory,
  'D.10.3': ICSRElementCondition.optional,
  'D.10.4': ICSRElementCondition.optional,
  'D.10.5': ICSRElementCondition.optional,
  'D.10.6': ICSRElementCondition.conditionalMandatory,
  'D.10.7.1.r.1a': ICSRElementCondition.conditionalMandatory,
  'D.10.7.1.r.1b': ICSRElementCondition.conditionalMandatory,
  'D.10.7.1.r.2': ICSRElementCondition.optional,
  'D.10.7.1.r.3': ICSRElementCondition.optional,
  'D.10.7.1.r.4': ICSRElementCondition.optional,
  'D.10.7.1.r.5': ICSRElementCondition.optional,
  'D.10.7.2': ICSRElementCondition.optional,
  'D.10.8.r.1': ICSRElementCondition.optional,
  'D.10.8.r.1.KR.1a': ICSRElementCondition.conditionalMandatory,
  'D.10.8.r.1.KR.1b': ICSRElementCondition.conditionalMandatory,
  'D.10.8.r.2a': ICSRElementCondition.optional,
  'D.10.8.r.2b': ICSRElementCondition.optional,
  'D.10.8.r.3a': ICSRElementCondition.optional,
  'D.10.8.r.3b': ICSRElementCondition.optional,
  'D.10.8.r.4': ICSRElementCondition.optional,
  'D.10.8.r.5': ICSRElementCondition.optional,
  'D.10.8.r.6a': ICSRElementCondition.conditionalMandatory,
  'D.10.8.r.6b': ICSRElementCondition.conditionalMandatory,
  'D.10.8.r.7a': ICSRElementCondition.conditionalMandatory,
  'D.10.8.r.7b': ICSRElementCondition.conditionalMandatory,
  'E.i.1.1a': ICSRElementCondition.optional,
  'E.i.1.1b': ICSRElementCondition.conditionalMandatory,
  'E.i.1.2': ICSRElementCondition.optional,
  'E.i.2.1a': ICSRElementCondition.mandatory,
  'E.i.2.1b': ICSRElementCondition.mandatory,
  'E.i.3.1': ICSRElementCondition.optional,
  'E.i.3.2a': ICSRElementCondition.mandatory,
  'E.i.3.2b': ICSRElementCondition.mandatory,
  'E.i.3.2c': ICSRElementCondition.mandatory,
  'E.i.3.2d': ICSRElementCondition.mandatory,
  'E.i.3.2e': ICSRElementCondition.mandatory,
  'E.i.3.2f': ICSRElementCondition.mandatory,
  'E.i.4': ICSRElementCondition.optional,
  'E.i.5': ICSRElementCondition.optional,
  'E.i.6a': ICSRElementCondition.conditionalMandatory,
  'E.i.6b': ICSRElementCondition.conditionalMandatory,
  'E.i.7': ICSRElementCondition.mandatory,
  'E.i.8': ICSRElementCondition.optional,
  'E.i.9': ICSRElementCondition.optional,
  'F.r.1': ICSRElementCondition.conditionalMandatory,
  'F.r.2.1': ICSRElementCondition.conditionalMandatory,
  'F.r.2.2a': ICSRElementCondition.conditionalMandatory,
  'F.r.2.2b': ICSRElementCondition.conditionalMandatory,
  'F.r.3.1': ICSRElementCondition.conditionalMandatory,
  'F.r.3.2': ICSRElementCondition.conditionalMandatory,
  'F.r.3.3': ICSRElementCondition.conditionalMandatory,
  'F.r.3.4': ICSRElementCondition.conditionalMandatory,
  'F.r.4': ICSRElementCondition.optional,
  'F.r.5': ICSRElementCondition.optional,
  'F.r.6': ICSRElementCondition.optional,
  'F.r.7': ICSRElementCondition.optional,
  'G.k.1': ICSRElementCondition.mandatory,
  'G.k.2.1.KR.1a': ICSRElementCondition.conditionalMandatory,
  'G.k.2.1.KR.1b': ICSRElementCondition.conditionalMandatory,
  'G.k.2.1.1a': ICSRElementCondition.optional,
  'G.k.2.1.1b': ICSRElementCondition.optional,
  'G.k.2.1.2a': ICSRElementCondition.optional,
  'G.k.2.1.2b': ICSRElementCondition.optional,
  'G.k.2.2': ICSRElementCondition.mandatory,
  'G.k.2.3.r.1': ICSRElementCondition.optional,
  'G.k.2.3.r.1.KR.1a': ICSRElementCondition.conditionalMandatory,
  'G.k.2.3.r.1.KR.1b': ICSRElementCondition.conditionalMandatory,
  'G.k.2.3.r.2a': ICSRElementCondition.optional,
  'G.k.2.3.r.2b': ICSRElementCondition.optional,
  'G.k.2.3.r.3a': ICSRElementCondition.optional,
  'G.k.2.3.r.3b': ICSRElementCondition.conditionalMandatory,
  'G.k.2.4': ICSRElementCondition.optional,
  'G.k.2.5': ICSRElementCondition.optional,
  'G.k.3.1': ICSRElementCondition.optional,
  'G.k.3.2': ICSRElementCondition.conditionalMandatory,
  'G.k.3.3': ICSRElementCondition.optional,
  'G.k.4.r.1a': ICSRElementCondition.optional,
  'G.k.4.r.1b': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.2': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.3': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.4': ICSRElementCondition.optional,
  'G.k.4.r.5': ICSRElementCondition.optional,
  'G.k.4.r.6a': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.6b': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.7': ICSRElementCondition.optional,
  'G.k.4.r.8': ICSRElementCondition.optional,
  'G.k.4.r.9.1': ICSRElementCondition.optional,
  'G.k.4.r.9.2a': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.9.2b': ICSRElementCondition.optional,
  'G.k.4.r.10.1': ICSRElementCondition.optional,
  'G.k.4.r.10.2a': ICSRElementCondition.conditionalMandatory,
  'G.k.4.r.10.2b': ICSRElementCondition.optional,
  'G.k.4.r.11.1': ICSRElementCondition.optional,
  'G.k.4.r.11.2a': ICSRElementCondition.optional,
  'G.k.4.r.11.2b': ICSRElementCondition.optional,
  'G.k.5a': ICSRElementCondition.conditionalMandatory,
  'G.k.5b': ICSRElementCondition.conditionalMandatory,
  'G.k.6a': ICSRElementCondition.conditionalMandatory,
  'G.k.6b': ICSRElementCondition.conditionalMandatory,
  'G.k.7.r.1': ICSRElementCondition.optional,
  'G.k.7.r.2a': ICSRElementCondition.conditionalMandatory,
  'G.k.7.r.2b': ICSRElementCondition.conditionalMandatory,
  'G.k.8': ICSRElementCondition.optional,
  'G.k.9.i.1': ICSRElementCondition.optional,
  'G.k.9.i.2.r.1': ICSRElementCondition.optional,
  'G.k.9.i.2.r.2': ICSRElementCondition.optional,
  'G.k.9.i.2.r.2.KR.1': ICSRElementCondition.optional,
  'G.k.9.i.2.r.3': ICSRElementCondition.optional,
  'G.k.9.i.2.r.3.KR.1': ICSRElementCondition.optional,
  'G.k.9.i.2.r.3.KR.2': ICSRElementCondition.conditionalMandatory,
  'G.k.9.i.3.1a': ICSRElementCondition.conditionalMandatory,
  'G.k.9.i.3.1b': ICSRElementCondition.conditionalMandatory,
  'G.k.9.i.3.2a': ICSRElementCondition.conditionalMandatory,
  'G.k.9.i.3.2b': ICSRElementCondition.conditionalMandatory,
  'G.k.9.i.4': ICSRElementCondition.optional,
  'G.k.10.r': ICSRElementCondition.optional,
  'G.k.11': ICSRElementCondition.optional,
  'H.1': ICSRElementCondition.mandatory,
  'H.2': ICSRElementCondition.optional,
  'H.3.r.1a': ICSRElementCondition.conditionalMandatory,
  'H.3.r.1b': ICSRElementCondition.conditionalMandatory,
  'H.4': ICSRElementCondition.optional,
  'H.5.r.1a': ICSRElementCondition.optional,
  'H.5.r.1b': ICSRElementCondition.conditionalMandatory,
};
