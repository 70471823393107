import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getSender,
  getSenderDefaultValues,
  getSendingOfficerDeafultValues,
  putSender,
} from 'api/senders';

import { EMAGateway, Sender } from 'types/sender';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import SenderForm from './SenderForm';

function EditSender() {
  const { t } = useTranslation();
  const { senderId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getSenderDefaultValues(company?.id),
  });

  const { refetch } = useQuery(
    ['getSender'],
    () => getSender(senderId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Sender) {
        setValue([
          { id: data.id },
          { companyId: data.companyId },
          { type: data.type },
          { healthProfessionalType: data.healthProfessionalType },
          { organizationName: data.organizationName },
          {
            authorityGateways: data.authorityGateways,
          },
          { sendingOfficers: data.sendingOfficers },
          { version: data.version },
        ]);
      },
    },
  );

  const { mutate } = useMutation(putSender, {
    onSuccess() {
      refetch();
    },
  });

  const changeSender = (key: string, value: any) => {
    if (key === 'type' && value !== '3') {
      setValue([{ [key]: value }, { healthProfessionalType: '' }]);
    } else {
      setValue(key, value);
    }
  };

  const changeAuthorGateway = (author: string, key: string, value: any) => {
    setValue(
      'authorityGateways',
      produce(form.authorityGateways, (draft) => {
        if (draft[author]) {
          draft[author][key] = value;
        } else {
          draft[author] = { [key]: value } as EMAGateway;
        }
      }),
    );
  };

  const addSendingOfficer = () => {
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) => {
        draft.push(getSendingOfficerDeafultValues(!draft.length));
      }),
    );
  };

  const deleteSendingOfficer = (index: number) => {
    if (form.sendingOfficers[index].selected) {
      modal.alert(
        t('modal:alert:title'),
        t('modal:alert:disabledDefaultSender'),
      );
      return;
    }
    if (form.sendingOfficers[index].id) {
      setValue(
        'sendingOfficers',
        produce(form.sendingOfficers, (draft) => {
          const target = draft.find((_officer, idx) => index === idx);
          if (target) target.deleted = true;
        }),
      );
    } else {
      setValue(
        'sendingOfficers',
        produce(form.sendingOfficers, (draft) =>
          draft.filter((_officer, idx) => index !== idx),
        ),
      );
    }
  };

  const restoreSendingOfficer = (index: number) => {
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) => {
        const target = draft.find((_officer, idx) => index === idx);
        if (target) target.deleted = false;
      }),
    );
  };

  const changeSendingOfficer = (name: string, value: any, index: number) => {
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) => {
        if (name === 'selected') {
          draft.forEach((officer, idx) => {
            if (index === idx) {
              officer[name] = true;
            } else {
              officer[name] = false;
            }
          });
        } else {
          draft[index][name] = value;
        }
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' });
    register({ name: 'type' }, { required: t('error:required') });
    register(
      { name: 'healthProfessionalType' },
      {
        validate: (value: any, originForm: any) => {
          const { type } = originForm;
          if (type === '3') {
            if (value) return true;
            return t('error:required');
          }
          return true;
        },
      },
    );
    register({ name: 'organizationName' }, { required: t('error:required') });
    register({ name: 'authorityGateways' });
    register({ name: 'sendingOfficers' });
    register({ name: 'version' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Edit Sender"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <SenderForm
        submitLabel="Save"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        changeSender={changeSender}
        changeAuthorGateway={changeAuthorGateway}
        changeSendingOfficer={changeSendingOfficer}
        addSendingOfficer={addSendingOfficer}
        deleteSendingOfficer={deleteSendingOfficer}
        restoreSendingOfficer={restoreSendingOfficer}
      />
    </FormWrapper>
  );
}

export default EditSender;
