import { apiGatewayClient } from './client';

export const getPatientMedicalRecords = (
  companyId: number | undefined,
  sponsorStudyNumber: string,
) =>
  apiGatewayClient.get(
    `/pmr/${companyId}/items?sponsorStudyNumber=${sponsorStudyNumber}`,
  );

export const getPatientMedicalRecord = (
  companyId: number | undefined,
  number: string,
) => apiGatewayClient.get(`/pmr/${companyId}/items/${number}`);
