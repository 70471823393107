import React from 'react';

import { ICSRFormElement } from 'types/case';

import Combobox from 'components/common/Select/Combobox';

function ComboboxComponent({
  className,
  number,
  value,
  data = [],
  valueField,
  textField,
  disabled,
  onChange = () => null,
  onSelect = () => null,
}: {
  className?: string;
  number: string;
  value?: string | null;
  data: any[];
  valueField?: string;
  textField?: string;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
  onSelect?: (v: any) => void;
}) {
  return (
    <Combobox
      className={className}
      value={value}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={(val) => {
        onChange(number, {
          number,
          value: val,
          description: undefined,
          nullFlavor: undefined,
        });
      }}
      onSelect={onSelect}
      disabled={disabled}
    />
  );
}

export default ComboboxComponent;
