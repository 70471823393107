import React, { useMemo, useState } from 'react';
import { IoClose } from 'react-icons/io5';

import { Product } from 'types/product';
import { Study } from 'types/study';

import Button from 'components/common/Button';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import Popup from 'components/common/Popup';

import { useCaseState } from '../context/CaseContext';

import { CaseConfig } from '../EditCase';

function CaseConfigPopup({
  config,
  handleConfig,
}: {
  config: CaseConfig;
  handleConfig: (n: string, v: any) => void;
}) {
  const { products, studies } = useCaseState();
  const { productIds = [], studyId } = config;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const filteredStudies = useMemo(() => {
    return studies.filter((study: any) =>
      productIds.every((productId: number) =>
        study.productIdList.includes(productId),
      ),
    );
  }, [productIds, studies]);

  const selectedProduct = useMemo(
    () => products.find((product) => product.id === productIds[0]),
    [productIds, products],
  );

  const selectedStudy = useMemo(
    () => studies.find((study) => study.id === studyId),
    [studies, studyId],
  );
  return (
    <>
      <div className="flex space-x-2">
        <button
          className="control-sm flex h-fit max-w-fit flex-1 items-center space-x-0 overflow-hidden rounded border border-slate-300 px-1 py-1"
          onClick={toggle}
        >
          <div className="h-5 truncate rounded border border-sky-300 bg-sky-100 px-1.5 text-sm">
            <span>
              {selectedProduct?.brandName || selectedProduct?.productName}
            </span>
            {productIds.length > 1 && <span className="ml-1">...</span>}
          </div>
        </button>
        <button
          className="control-sm flex h-[1.875rem] max-w-fit flex-1 items-center space-x-0 overflow-hidden rounded border border-slate-300 px-1 py-1"
          onClick={toggle}
        >
          <div className="h-5 truncate rounded border border-sky-300 bg-sky-100 px-1.5 text-sm">
            <span>{selectedStudy?.sponsorStudyNumber}</span>
          </div>
        </button>
      </div>
      <Popup isOpen={open} className="w-[30rem]">
        <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
          Case Config.
          <button className="hover:opacity-50" onClick={toggle}>
            <IoClose />
          </button>
        </div>
        <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
          <div>
            <div className="w-36">
              <em>Product(s)</em>
            </div>
            <div className="flex-1">
              <MultiSelect
                value={productIds}
                data={products}
                valueField="id"
                textField={(dataItem: any) =>
                  dataItem?.brandName || dataItem?.productName || dataItem
                }
                onChange={(value: Product[]) => {
                  handleConfig(
                    'productIds',
                    value.map((item) => item.id),
                  );
                  handleConfig('studyId', null);
                }}
              />
            </div>
          </div>
          <div>
            <div className="w-36">
              <em>Study</em>
            </div>
            <div className="flex-1">
              <Select
                value={studyId}
                data={filteredStudies}
                valueField="id"
                textField="sponsorStudyNumber"
                onChange={(value: Study) => {
                  handleConfig('studyId', value.id);
                }}
              />
            </div>
          </div>
        </div>
        <div className="space-x-2 py-2 text-right">
          <Button onClick={toggle} color="blue">
            Ok
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default CaseConfigPopup;
