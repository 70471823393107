import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ichEn from './ich/en.json';
import ko from './ko.json';
import ichKo from './ich/ko.json';

const resource = {
  en: {
    translation: { ...en, ...ichEn },
  },
  ko: {
    translation: { ...ko, ...ichKo },
  },
};
i18n.use(initReactI18next).init({
  resources: resource,
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  nsSeparator: '::',
  keySeparator: ':',
  interpolation: { escapeValue: false },
});

export default i18n;
