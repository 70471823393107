import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';

import { getApprovals, getRequestApprovals } from 'api/approvals';
import { companyState } from 'state';

import Button from 'components/common/Button';
import { Tab, TabPanel, Tabs } from 'components/common/Tab';

type ApprovalType = 'request' | 'approved' | 'rejected';
const ApprovalTypeList: ApprovalType[] = ['request', 'rejected', 'approved'];

const columnStyles = 'relative flex-1 pr-2 last:pr-0';
const rowStyles =
  'relative shadow hover:bg-slate-100 flex w-full flex-wrap pl-2 py-2 text-left';
const rowBeforeStyles = {
  request:
    'before:content-[""] before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-blue-500 before:rounded-tl before:rounded-bl',
  approved:
    'before:content-[""] before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-green-500 before:rounded-tl before:rounded-bl',
  rejected:
    'before:content-[""] before:absolute before:top-0 before:left-0 before:w-1 before:h-full before:bg-red-500 before:rounded-tl before:rounded-bl',
};

function DashboardApproval() {
  const [{ selected: company }] = useRecoilState(companyState);
  const [tabValue, setTabValue] = useState<number>(0);

  const { data: approvalRequestCases = [] } = useQuery(
    ['approvalRequestCases', company?.id],
    () =>
      getRequestApprovals({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 10,
          order: 'desc',
          orderBy: 'lastModifiedDate',
          q: { deleted: ['false'] },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: approvalRejectedCases = [] } = useQuery(
    ['approvalRejectedCases', company?.id],
    () =>
      getApprovals({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 10,
          order: 'desc',
          orderBy: 'lastModifiedDate',
          q: { deleted: ['false'], status: 'REJECTED' },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: approvalApprovedCases = [] } = useQuery(
    ['approvalApprovedCases', company?.id],
    () =>
      getApprovals({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 10,
          order: 'desc',
          orderBy: 'lastModifiedDate',
          q: { deleted: ['false'], status: 'APPROVED' },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );

  const data = [
    approvalRequestCases,
    approvalRejectedCases,
    approvalApprovedCases,
  ];

  return (
    <div className="w-full p-2 xl:w-1/2">
      <div className="space-x-4 rounded border bg-white px-2 py-4 shadow xl:w-full xl:flex-shrink-0 xl:flex-grow-0 xl:basis-full xl:space-x-0 xl:space-y-4">
        <div className="px-2">
          <div className="m-0 flex justify-between text-xl font-bold text-gray-700">
            Approve Case
            <NavLink to="../approval">
              <Button color="red" size="sm" data-tab-value={tabValue} outline>
                More &gt;
              </Button>
            </NavLink>
          </div>
          <div className="border-b border-gray-500 leading-10 text-gray-600 [&_.tab-navigator]:mt-0">
            <Tabs value={tabValue} onChange={(value) => setTabValue(value)}>
              <Tab index={0}>{`${ApprovalTypeList[0].toUpperCase()}`}</Tab>
              <Tab index={1}>{`${ApprovalTypeList[1].toUpperCase()}`}</Tab>
              <Tab index={2}>{`${ApprovalTypeList[2].toUpperCase()}`}</Tab>
            </Tabs>
          </div>
        </div>
        <div className="overflow-auto pl-4 pr-2">
          <div className="sticky top-0 z-10 -mx-2 flex flex-wrap bg-white p-2">
            <div
              className={clsx(
                'flex-shrink-0 flex-grow-0 basis-24',
                columnStyles,
              )}
            >
              Type
            </div>
            <div className={clsx('flex-[1.5]', columnStyles)}>Subject</div>
            <div className={columnStyles}>Date of Request</div>
            <div className={columnStyles}>Requester</div>
          </div>
          <div>
            {ApprovalTypeList.map((type, index) => (
              <TabPanel key={index} index={index} value={tabValue}>
                <div className="relative mt-0 -ml-2 h-56 flex-col space-y-2 pt-2">
                  {data[index].length ? (
                    data[index].map((approval: any) => (
                      <div
                        key={approval.id}
                        className={clsx(rowStyles, rowBeforeStyles[type])}
                      >
                        <div
                          className={clsx(
                            'flex-shrink-0 flex-grow-0 basis-24',
                            columnStyles,
                          )}
                        >
                          {type.toUpperCase()}
                        </div>
                        <div className={clsx('flex-[1.5]', columnStyles)}>
                          {approval.subject}
                        </div>
                        <div className={columnStyles}>
                          {approval.lastModifiedDate &&
                            moment(approval.lastModifiedDate).format(
                              'YYYY-MM-DD HH:mm:ss Z',
                            )}
                        </div>
                        <div className={columnStyles}>
                          {approval.approvalLines?.[0].approverName}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex h-full items-center justify-center text-2xl text-gray-400">
                      No Data
                    </div>
                  )}
                </div>
              </TabPanel>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardApproval;
