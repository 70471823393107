import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { logout, updateToken } from 'api/auth';

import modal from 'utils/modal';
import tokenTimer from 'utils/tokenTimer';

import Loader from './Loader';

function AuthRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  const { mutate } = useMutation(updateToken, {
    onSuccess({ data }) {
      tokenTimer.set(data.expires_in);
      setAuthenticated(true);
    },
    onError(error) {
      if (error instanceof AxiosError) {
        modal.alert(
          t('modal:alert:error'),
          error?.response?.data?.message || error?.message,
        );
      }
      handleLogout();
    },
    onMutate() {},
    onSettled() {},
  });

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authenticated) return <Loader />;

  return <Outlet />;
}

export default AuthRoute;
