import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import isEmpty from 'lodash.isempty';

import { getCase, getFollowUps, postFollowUp, putCase } from 'api/cases';
import { getMemos } from 'api/memos';
import { getSenders } from 'api/senders';
import { getProducts } from 'api/products';
import { getStudies } from 'api/studies';
import { getReporters } from 'api/reporters';
import { getReceivers } from 'api/receivers';

import { FollowUp, ICSR, ICSRForm } from 'types/case';
import { Region, RegionalImplementationGuide } from 'types/core';
import { Memo } from 'types/memo';
import { Sender } from 'types/sender';
import { Product } from 'types/product';
import { Study } from 'types/study';
import { Reporter } from 'types/reporter';
import { Receiver } from 'types/receiver';

import { codeSetsState, companyState, coreState } from 'state';

import modal from 'utils/modal';
import { convertForm } from 'utils/convertICSRForm';

import useGoBack from 'hooks/useGoBack';

import EMA from 'components/case/rules/EMA';
import MFDS from 'components/case/rules/MFDS';

const ICSRElement = {
  MFDS,
  EMA,
};

type State = {
  caseId?: number;
  icsr?: ICSR;
  form?: ICSRForm;
  senders: Sender[];
  products: Product[];
  studies: Study[];
  reporters: Reporter[];
  receivers: Receiver[];
  followUpList: FollowUp[];
  validationMessages: any;
  memoMessages: any;
  region: Region;
  regionalImplementationGuide?: RegionalImplementationGuide;
  E2BR3Elements: { [key in string]: any };
};

const CaseStateContext = createContext<State | null>(null);
const CaseActionContext = createContext<any | null>(null);

export function CaseProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const goBack = useGoBack();
  const [{ region, regionList }] = useRecoilState(coreState);
  const [codeSets] = useRecoilState(codeSetsState);
  const [{ selected: company }] = useRecoilState(companyState);

  const [state, setState] = useState<State>({
    caseId: Number(caseId),
    validationMessages: {},
    memoMessages: {},
    senders: [],
    products: [],
    studies: [],
    reporters: [],
    receivers: [],
    followUpList: [],
    region,
    regionalImplementationGuide: regionList.find((r) => r.region === region)
      ?.regionalImplementationGuide,
    E2BR3Elements: ICSRElement[region](codeSets, t),
  });

  const { refetch: refetchCase } = useQuery(
    ['getCase', caseId],
    () => getCase(caseId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: ICSR) {
        try {
          const { form, validationMessages } = convertForm(data);
          setState((prev) => ({
            ...prev,
            caseId: data.id,
            icsr: data,
            form,
            validationMessages,
          }));
        } catch (error: any) {
          modal.alert(
            t('modal:alert:error'),
            t('modal:alert:irregularICSRForm'),
          );
          goBack();
        }
      },
    },
  );

  useQuery(
    ['getFollowUpList'],
    () =>
      getFollowUps({ companyId: company?.id, caseId }).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(followUpList) {
        setState((prev) => ({ ...prev, followUpList }));
      },
    },
  );

  const { data: memos = [], refetch: refetchMemo } = useQuery<Memo[]>(
    ['getMemos', caseId],
    () =>
      getMemos({
        icsrId: caseId,
        query: { perPage: 0 },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['getSenders'],
    () =>
      getSenders({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(senders: Sender[]) {
        setState((prev) => ({
          ...prev,
          senders,
        }));
      },
    },
  );

  useQuery(
    ['getProducts'],
    () =>
      getProducts({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(products: Product[]) {
        setState((prev) => ({
          ...prev,
          products,
        }));
      },
    },
  );

  useQuery(
    ['getStudies'],
    () =>
      getStudies({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(studies: Study[]) {
        setState((prev) => ({
          ...prev,
          studies,
        }));
      },
    },
  );

  useQuery(
    ['getReporters'],
    () =>
      getReporters({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(reporters: Reporter[]) {
        setState((prev) => ({
          ...prev,
          reporters,
        }));
      },
    },
  );

  useQuery(
    ['getReceivers'],
    () =>
      getReceivers({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(receivers: Receiver[]) {
        setState((prev) => ({
          ...prev,
          receivers,
        }));
      },
    },
  );

  const { mutate: updateCase } = useMutation(putCase, {
    onSuccess({ data }) {
      const { form, validationMessages } = convertForm(data);
      setState((prev) => ({
        ...prev,
        icsr: data,
        form,
        validationMessages,
      }));
    },
  });

  const { mutate: createFollowUpCase } = useMutation(
    () => postFollowUp(caseId),
    {
      onSuccess({ data }) {
        navigate(`../${data}/follow-up`);
      },
    },
  );

  useEffect(() => {
    const { validationMessages } = state;
    if (isEmpty(validationMessages)) return;

    function setParentCount(
      elementId: number,
      elementNumber: string,
      obj: any,
    ): any {
      if (!validationMessages[elementNumber][elementId].parent) return null;

      const { id: parentId, number: parentNumber } =
        validationMessages[elementNumber][elementId].parent;

      if (obj[parentNumber]) {
        if (obj[parentNumber][parentId]) {
          obj[parentNumber][parentId].count += 1;
        } else {
          obj[parentNumber][parentId] = {
            id: parentId,
            messages: [],
            count: 1,
          };
        }
        obj[parentNumber].count += 1;
      } else {
        obj[parentNumber] = {
          [parentId]: {
            id: parentId,
            messages: [],
            count: 1,
          },
          messages: [],
          count: 1,
        };
      }

      return setParentCount(parentId, parentNumber, obj);
    }

    const memoMessages = memos.reduce((acc: any, cur: any) => {
      const { dataElementId: elementId, dataElementNumber: elementNumber } =
        cur;
      if (!validationMessages?.[elementNumber]?.[elementId]) return acc;
      if (acc[elementNumber]) {
        if (acc[elementNumber][elementId]) {
          acc[elementNumber][elementId].messages.push(cur);
          acc[elementNumber][elementId].count += 1;
        } else {
          acc[elementNumber][elementId] = {
            id: elementId,
            messages: [cur],
            count: 1,
          };
        }
        acc[elementNumber].count += 1;
      } else {
        acc[elementNumber] = {
          [elementId]: {
            id: elementId,
            messages: [cur],
            count: 1,
          },
          messages: [],
          count: 1,
        };
      }
      setParentCount(elementId, elementNumber, acc);
      return acc;
    }, {});

    setState((prev) => ({ ...prev, memoMessages }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memos, state.validationMessages]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      region,
      regionalImplementationGuide: regionList.find((r) => r.region === region)
        ?.regionalImplementationGuide,
      E2BR3Elements: ICSRElement[region](codeSets, t),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region, codeSets, t]);

  useEffect(() => {
    const { icsr, regionalImplementationGuide } = state;
    if (icsr) {
      if (icsr.regionalImplementationGuide !== regionalImplementationGuide) {
        modal.alert(
          t('modal:alert:title'),
          t('modal:alert:notMatchRegionImplementationGuide'),
        );
        goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.icsr, state.regionalImplementationGuide]);

  const actions = useMemo(
    () => ({
      refetchCase,
      refetchMemo,
      updateCase,
      createFollowUpCase,
    }),
    [createFollowUpCase, refetchCase, refetchMemo, updateCase],
  );

  return (
    <CaseActionContext.Provider value={actions}>
      <CaseStateContext.Provider value={state}>
        {children}
      </CaseStateContext.Provider>
    </CaseActionContext.Provider>
  );
}

export function useCaseState() {
  const state = useContext(CaseStateContext);
  if (!state) throw new Error('Cannot find CaseProvider');
  return state;
}

export function useCaseAction() {
  const actions = useContext(CaseActionContext);
  if (!actions) throw new Error('Cannot find CaseProvider');
  return actions;
}
