import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDrag, useDrop } from 'react-dnd';
import { IoClose } from 'react-icons/io5';
import { TbDownload, TbMenu2 } from 'react-icons/tb';
import produce from 'immer';
import update from 'immutability-helper';
import clsx from 'clsx';

import {
  getTrackerColumnSpecs,
  getTrackers,
  postTrackers,
  putTrackers,
} from 'api/tracker';
import { TrackerColumn } from 'types/tracker';

import Popup from 'components/common/Popup';
import Button from 'components/common/Button';
import Textarea from 'components/common/Textarea';
import Checkbox from 'components/common/Checkbox';

import defaultTrakerColumns from 'utils/default_tracker_columns.json';

const LIST_ITEM_TYPE = 'TRACKER_COLUMN' as const;

const Item = React.memo(
  ({
    id,
    name,
    index,
    visible,
    moveItem,
    changeItem,
  }: {
    id: any;
    name: string;
    index: number;
    visible: boolean;
    moveItem: (dIdx: number, hIdx: number) => void;
    changeItem: (idx: number, target: string, value: any) => void;
  }) => {
    const ref = useRef<HTMLSpanElement>(null);

    const [{ handlerId }, drop] = useDrop(
      () => ({
        accept: LIST_ITEM_TYPE,
        hover: (item: any, monitor: any) => {
          if (!ref.current) {
            return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;

          if (dragIndex === hoverIndex) {
            return;
          }

          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

          const clientOffset = monitor.getClientOffset();
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;

          if (
            (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
            (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
          ) {
            return;
          }

          moveItem(dragIndex, hoverIndex);
          item.index = hoverIndex;
        },
        collect(monitor) {
          return {
            handlerId: monitor.getHandlerId(),
          };
        },
      }),
      [index],
    );

    const [{ isDragging }, drag, preview] = useDrag(
      {
        type: LIST_ITEM_TYPE,
        item: () => ({ id, index }),
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      },
      [id, index],
    );

    drag(drop(ref));

    return (
      <div
        className={clsx(
          'mb-2 flex cursor-move items-center border border-dashed border-gray-400 bg-white p-2',
          isDragging ? 'opacity-0' : 'opacity-100',
        )}
        ref={preview}
        data-index={index}
        data-handler-id={handlerId}
      >
        <span ref={ref}>
          <TbMenu2 className="mr-3" fontSize="1.5rem" />
        </span>
        <Textarea
          className="flex-1"
          size="sm"
          minRows={2}
          value={name || ''}
          placeholder={id}
          onChange={({ target: { value } }: { target: { value: string } }) =>
            changeItem(index, 'name', value)
          }
        />
        <Checkbox
          className="mx-2"
          checked={visible}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            changeItem(index, 'visible', e.target.checked)
          }
        />
      </div>
    );
  },
);

function List({
  columns,
  setColumns,
}: {
  columns: TrackerColumn[];
  setColumns: React.Dispatch<React.SetStateAction<TrackerColumn[]>>;
}) {
  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setColumns((prev: any[]) => {
        const dragItem = prev[dragIndex];
        return update(prev, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        });
      });
    },
    [setColumns],
  );

  const changeItem = useCallback(
    (index: number, target: string, value: any) => {
      setColumns(
        produce((draft: any) => {
          draft[index][target] = value;
        }),
      );
    },
    [setColumns],
  );

  return (
    <>
      {columns.map((item: any, index: number) => (
        <Item
          key={item.columnSpec}
          id={item.columnSpec}
          name={item.name}
          visible={item.visible}
          index={index}
          moveItem={moveItem}
          changeItem={changeItem}
        />
      ))}
    </>
  );
}

function TrackerTemplatePopup({
  isOpen,
  companyId,
  toggle,
}: {
  isOpen: boolean;
  companyId?: number;
  toggle: () => void;
}) {
  const [trackerId, setTrackerId] = useState<number | null>(null);
  const [columns, setColumns] = useState<TrackerColumn[]>([]);

  useQuery(
    ['getTrackerColumnSpecs', companyId],
    () => getTrackerColumnSpecs().then(({ data }) => data),
    {
      enabled: !!companyId,
      retry: false,
      refetchOnWindowFocus: false,
      async onSuccess(columnSpecs) {
        try {
          const {
            data: { id, columns: _columns },
          } = await getTrackers(companyId);

          const includedColumns: any[] = _columns.map((ele: any) => ({
            ...ele,
            visible: true,
          }));
          const excludedColumns: any[] = columnSpecs
            .filter((columnSpec: any) =>
              _columns.every(
                (column: any) => column.columnSpec !== columnSpec.columnSpec,
              ),
            )
            .map((ele: any) => ({ ...ele, visible: false }));

          setTrackerId(id);
          setColumns([...includedColumns, ...excludedColumns]);
        } catch (error: any) {
          setColumns(
            columnSpecs.map((ele: any) => ({ ...ele, visible: true })),
          );
        }
      },
    },
  );

  const { mutate: create } = useMutation(postTrackers, {
    onSuccess({ data }) {
      setTrackerId(data);
    },
  });
  const { mutate: save } = useMutation(putTrackers, {});

  const setDefaultColumns = () => {
    setColumns(
      produce((draft) => {
        draft.forEach((ele) => {
          ele.name =
            defaultTrakerColumns[
              ele.columnSpec as keyof typeof defaultTrakerColumns
            ];
        });
      }),
    );
  };

  const handleSubmit = () => {
    const data = columns
      .filter(({ visible }) => visible)
      .map(({ id, columnSpec, name }, index) => ({
        id,
        columnSpec,
        name,
        order: index,
      }));
    if (trackerId) {
      save({ trackerId, data: { columns: data } });
    } else {
      create({
        companyId,
        columns: data,
      });
    }
  };

  return (
    <Popup isOpen={isOpen} className="flex w-[42.5rem] flex-col">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        <div className="flex items-center space-x-2">
          <p>Mapping Rule</p>
          <button onClick={setDefaultColumns}>
            <TbDownload />
          </button>
        </div>
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-2 flex-1 space-y-4 overflow-y-scroll py-2 text-base">
        <List columns={columns} setColumns={setColumns} />
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="blue">
          Save
        </Button>
      </div>
    </Popup>
  );
}

export default TrackerTemplatePopup;
