import React from 'react';
import { TbPlus, TbTrash } from 'react-icons/tb';
import { useQuery } from '@tanstack/react-query';
import produce from 'immer';
import clsx from 'clsx';

import { getDatasetDomainItems, getDatasetDomains } from 'api/dataset';

import { DatasetDomainItemType, DatasetRuleType } from 'types/dataset';

import Select from 'components/common/Select/Select';

interface Props {
  importSettingId?: number;
  rule: DatasetRuleType;
  setRule: (rule: DatasetRuleType) => void;
}

function EDCItem({
  index,
  importSettingId,
  domains,
  domainName,
  itemName,
  itemDescription,
  codeList,
  handleDomain,
  handleItem,
  handleDelete,
}: any) {
  const { data: domainItems = [], isFetching } = useQuery(
    ['getDomainItems', domainName],
    () =>
      getDatasetDomainItems({
        importSettingId,
        domainName,
      }).then(({ data }) => data),
    {
      enabled: !!importSettingId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <tr className="text-center [&>td]:border [&>td]:p-1">
      <td>{index + 1}</td>
      <td>
        <Select
          size="sm"
          className="text-left"
          value={domainName}
          data={domains}
          onChange={(value: string) => handleDomain(value)}
        />
      </td>
      <td>
        <Select
          size="sm"
          className="text-left"
          value={itemName}
          data={domainItems}
          valueField="itemName"
          textField="itemName"
          loading={isFetching}
          onChange={(item: DatasetDomainItemType) => handleItem(item)}
        />
      </td>
      <td>{itemDescription}</td>
      <td>
        {codeList
          ?.map((code: any) => `${code.value}:${code.description}`)
          .join(' | ')}
      </td>
      <td>
        <button onClick={handleDelete}>
          <TbTrash />
        </button>
      </td>
    </tr>
  );
}
function EDCItemTable({ importSettingId, rule, setRule }: Props) {
  const { data: domains = [] } = useQuery(
    ['getDomains'],
    () => getDatasetDomains(importSettingId).then(({ data }) => data),
    {
      enabled: !!importSettingId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <table
      className={clsx(
        'w-full table-fixed border-collapse border-slate-800',
        !rule && 'disabled',
      )}
    >
      <thead className="text-center">
        <tr className="[&>th]:border [&>th]:p-1">
          <th className="w-12">No.</th>
          <th className="w-28">DOMAIN</th>
          <th className="w-32">ITEM</th>
          <th className="w-1/2">CRF ITEM</th>
          <th className="w-1/2">CODE</th>
          <th className="w-8">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setRule(
                  produce(rule, (draft) => {
                    draft.items.push({});
                  }),
                );
              }}
            >
              <TbPlus />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {rule?.items.map((ruleItem, index) => (
          <EDCItem
            key={index}
            index={index}
            importSettingId={importSettingId}
            domains={domains}
            {...ruleItem}
            handleDomain={(value: string) => {
              setRule(
                produce(rule, (draft) => {
                  draft.items[index] = { domainName: value };
                }),
              );
            }}
            handleItem={(item: DatasetDomainItemType) => {
              setRule(
                produce(rule, (draft) => {
                  draft.items[index] = {
                    domainName: rule.items[index].domainName,
                    ...item,
                  };
                }),
              );
            }}
            handleDelete={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setRule(
                produce(rule, (draft) => {
                  draft.items.splice(index, 1);
                }),
              );
            }}
          />
        ))}
      </tbody>
    </table>
  );
}

export default EDCItemTable;
