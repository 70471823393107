import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import ServerDown from 'components/ServerDown';
import AuthCheck from 'components/AuthCheck';
import AuthRoute from 'components/AuthRoute';

import Login from 'components/auth/Login';
import Register from 'components/auth/Register';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ResetPassword';

import Core from 'components/Core';
import SafetyManager from 'components/SafetyManager';
import SafetyUser from 'components/SafetyUser';
import CheckCompany from 'components/CheckCompany';
import SelectCompany from 'components/SelectCompany';
import Profile from 'components/Profile/Profile';

import CreateUser from 'components/user/CreateUser';
import EditUser from 'components/user/EditUser';
import UserList from 'components/user/UserList';
import CompanyList from 'components/company/CompanyList';

import CreateCompany from 'components/company/CreateCompany';
import EditCompany from 'components/company/EditCompany';

import NotificationList from 'components/notification/NotificationList';
import CreateNotification from 'components/notification/CreateNotification';
import EditNotification from 'components/notification/EditNotification';

import EDCSetupList from 'components/edcSetup/EDCSetupList';
import CreateDefaultEDCSetup from 'components/edcSetup/CreateDefaultEDCSetup';
import EditEDCSetup from 'components/edcSetup/EditEDCSetup';
import CreateEDCSetup from 'components/edcSetup/CreateEDCSetup';

import Home from 'components/home/Home';

import Data from 'components/data/Data';
import SenderList from 'components/data/senders/SenderList';
import ReceiverList from 'components/data/receivers/ReceiverList';
import ProductList from 'components/data/products/ProductList';
import StudyList from 'components/data/studies/StudyList';
import ReporterList from 'components/data/reporters/ReporterList';
import CreateSender from 'components/data/senders/CreateSender';
import CreateReceiver from 'components/data/receivers/CreateReceiver';
import CreateProduct from 'components/data/products/CreateProduct';
import CreateStudy from 'components/data/studies/CreateStudy';
import CreateReporter from 'components/data/reporters/CreateReporter';
import EditSender from 'components/data/senders/EditSender';
import EditReceiver from 'components/data/receivers/EditReceiver';
import EditProduct from 'components/data/products/EditProduct';
import EditStudy from 'components/data/studies/EditStudy';
import EditReporter from 'components/data/reporters/EditReporter';

import CaseList from 'components/case/CaseList';
import EditCase from 'components/case/EditCase';
import { CaseProvider } from 'components/case/context/CaseContext';

import Approval from 'components/approval/Approval';
import RequestList from 'components/approval/RequestList';
import RejectedList from 'components/approval/RejectedList';
import ApprovedList from 'components/approval/ApprovedList';

import Upload from 'components/upload/Upload';

import Tracker from 'components/tracker/Tracker';

import Submission from 'components/submission/Submission';

import History from 'components/history/History';
import ImportList from 'components/history/ImportList';
import ExportList from 'components/history/ExportList';
import TrackerList from 'components/history/TrackerList';

import Signal from 'components/signal/Signal';

import Admin from 'components/admin/Admin';
import MemberList from 'components/admin/members/MemberList';
import CreateMember from 'components/admin/members/CreateMember';
import EditMember from 'components/admin/members/EditMember';
import RolePrivileges from 'components/admin/role_privileges/RolePrivileges';
import Setting from 'components/admin/Setting';

import UserNotificationList from 'components/notification/UserNotificationList';
import ErrorBoundary from 'components/ErrorBoundary';

export default createBrowserRouter([
  {
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'server-down',
        element: <ServerDown />,
      },
      {
        element: <AuthCheck />,
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'register',
            element: <Register />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <AuthRoute />,
        children: [
          {
            element: <Core />,
            children: [
              {
                path: 'safety-manage/*',
                element: <SafetyManager />,
                children: [
                  {
                    path: 'users/*',
                    children: [
                      {
                        path: '',
                        element: <UserList />,
                      },
                      {
                        path: 'new',
                        element: <CreateUser />,
                      },
                      {
                        path: ':userId',
                        element: <EditUser />,
                      },
                    ],
                  },
                  {
                    path: 'companies/*',
                    children: [
                      {
                        path: '',
                        element: <CompanyList />,
                      },
                      {
                        path: 'new',
                        element: <CreateCompany />,
                      },
                      {
                        path: ':companyId',
                        element: <EditCompany />,
                      },
                    ],
                  },
                  {
                    path: 'notifications/*',
                    children: [
                      {
                        path: '',
                        element: <NotificationList />,
                      },
                      {
                        path: 'new',
                        element: <CreateNotification />,
                      },
                      {
                        path: ':notificationId',
                        element: <EditNotification />,
                      },
                    ],
                  },
                  {
                    path: 'edc-setup/*',
                    children: [
                      {
                        path: '',
                        element: <EDCSetupList />,
                      },
                      {
                        path: 'new',
                        element: <CreateDefaultEDCSetup />,
                      },
                      {
                        path: ':datasetId',
                        element: <EditEDCSetup />,
                      },
                    ],
                  },
                  {
                    path: '*',
                    element: <Navigate replace to="users" />,
                  },
                ],
              },
              {
                element: <SafetyUser />,
                children: [
                  {
                    path: 'profile',
                    element: <Profile />,
                  },
                  {
                    path: 'select-company',
                    element: <SelectCompany />,
                  },
                  {
                    path: ':company/*',
                    element: <CheckCompany />,
                    children: [
                      {
                        path: 'home',
                        element: <Home />,
                      },
                      {
                        path: 'data/*',
                        children: [
                          {
                            path: '',
                            element: <Navigate replace to="senders" />,
                          },
                          {
                            element: <Data />,
                            children: [
                              { path: 'senders', element: <SenderList /> },
                              { path: 'receivers', element: <ReceiverList /> },
                              { path: 'products', element: <ProductList /> },
                              { path: 'studies', element: <StudyList /> },
                              { path: 'reporters', element: <ReporterList /> },
                            ],
                          },
                          {
                            path: 'senders/new',
                            element: <CreateSender />,
                          },
                          {
                            path: 'receivers/new',
                            element: <CreateReceiver />,
                          },
                          {
                            path: 'products/new',
                            element: <CreateProduct />,
                          },
                          {
                            path: 'studies/new',
                            element: <CreateStudy />,
                          },
                          {
                            path: 'reporters/new',
                            element: <CreateReporter />,
                          },
                          {
                            path: 'senders/:senderId',
                            element: <EditSender />,
                          },
                          {
                            path: 'receivers/:receiverId',
                            element: <EditReceiver />,
                          },
                          {
                            path: 'products/:productId',
                            element: <EditProduct />,
                          },
                          {
                            path: 'studies/:studyId',
                            element: <EditStudy />,
                          },
                          {
                            path: 'reporters/:reporterId',
                            element: <EditReporter />,
                          },
                          {
                            path: '*',
                            element: <Navigate replace to="senders" />,
                          },
                        ],
                      },
                      {
                        path: 'cases/*',
                        children: [
                          {
                            path: '',
                            element: <CaseList />,
                          },
                          {
                            path: ':caseId/follow-up',
                            element: (
                              <Navigate replace to=".." relative="path" />
                            ),
                          },
                          {
                            path: ':caseId',
                            element: (
                              <CaseProvider>
                                <EditCase />
                              </CaseProvider>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'approval/*',
                        children: [
                          {
                            path: '',
                            element: <Navigate replace to="request" />,
                          },
                          {
                            element: <Approval />,
                            children: [
                              { path: 'request', element: <RequestList /> },
                              { path: 'rejected', element: <RejectedList /> },
                              { path: 'approved', element: <ApprovedList /> },
                            ],
                          },
                          {
                            path: '*',
                            element: <Navigate replace to="request" />,
                          },
                        ],
                      },
                      {
                        path: 'tracker',
                        element: <Tracker />,
                      },
                      {
                        path: 'upload',
                        element: <Upload />,
                      },
                      {
                        path: 'edc-setup/*',
                        children: [
                          {
                            path: '',
                            element: <EDCSetupList />,
                          },
                          {
                            path: 'new',
                            element: <CreateEDCSetup />,
                          },
                          {
                            path: ':datasetId',
                            element: <EditEDCSetup />,
                          },
                        ],
                      },
                      {
                        path: 'history/*',
                        children: [
                          {
                            path: '',
                            element: <Navigate replace to="imports" />,
                          },
                          {
                            element: <History />,
                            children: [
                              { path: 'imports', element: <ImportList /> },
                              { path: 'exports', element: <ExportList /> },
                              { path: 'trackers', element: <TrackerList /> },
                            ],
                          },
                          {
                            path: '*',
                            element: <Navigate replace to="imports" />,
                          },
                        ],
                      },
                      {
                        path: 'signals',
                        element: <Signal />,
                      },
                      {
                        path: 'submissions',
                        element: <Submission />,
                      },
                      {
                        path: 'admin/*',
                        children: [
                          {
                            path: '',
                            element: <Navigate replace to="senders" />,
                          },
                          {
                            element: <Admin />,
                            children: [
                              { path: 'members', element: <MemberList /> },
                              {
                                path: 'role-privileges',
                                element: <RolePrivileges />,
                              },
                              { path: 'setting', element: <Setting /> },
                            ],
                          },
                          {
                            path: 'members/new',
                            element: <CreateMember />,
                          },
                          {
                            path: 'members/:memberId',
                            element: <EditMember />,
                          },
                          {
                            path: '*',
                            element: <Navigate replace to="members" />,
                          },
                        ],
                      },
                      {
                        path: 'notifications',
                        element: <UserNotificationList />,
                      },
                      {
                        path: '*',
                        element: <Navigate replace to="home" />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <Navigate replace to="/login" />,
      },
    ],
  },
]);
