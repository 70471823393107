import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { getIngredients } from 'api/whodrug';
import { WHODrugSubstanceCode } from 'types/codes';

import Popup from 'components/common/Popup';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Select from 'components/common/Select/Select';
import Checkbox from 'components/common/Checkbox';
import { ThreeDots } from 'react-loader-spinner';

export const WHODrugIngredientSearchTypes = [
  { label: 'Ingredient', value: 'substance_name' },
  { label: 'CAS Number', value: 'cas_number' },
];

function WHODrugSubstance({
  isOpen,
  version,
  toggle,
  callback,
}: {
  isOpen: boolean;
  version?: string;
  toggle: () => void;
  callback: (c: WHODrugSubstanceCode) => void;
}) {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState('');
  const [searchType, setSearchType] = useState('');
  const [option, setOption] = useState({
    exactMatch: false,
  });
  const [query, setQuery] = useState('');

  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    ['getIngredients', query],
    async ({ pageParam = 0 }) => {
      const res = await getIngredients({
        query: { page: pageParam, perPage: 50 },
        version,
        keyword,
        searchType,
        exactMatch: option.exactMatch,
      });
      return res.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (prev, pages) => {
        const { page, size, items, totalCount } = prev;
        return page * size + items.length >= totalCount
          ? undefined
          : pages.length + 1;
      },
    },
  );

  const handleChangeOption = (name: string, checked: boolean) => {
    setOption((prev) => ({ ...prev, [name]: checked }));
  };

  const handleClick = (code: WHODrugSubstanceCode) => {
    callback(code);
    toggle();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setQuery(`${version}${searchType}${keyword}${JSON.stringify(option)}`);
  };

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    {
      offset: 100,
      debounce: 500,
      triggerOnNoScroll: false,
    },
  );

  useEffect(() => {
    if (searchType && keyword.length > 1) {
      remove();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Popup isOpen={isOpen} className="flex h-fit w-[85rem] flex-col">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        {`WHODrug Global (Ver. ${version})`}
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <form className="mt-2" onSubmit={handleSubmit}>
        <div className="flex items-center space-x-2">
          <Select
            className="w-40"
            value={searchType}
            data={WHODrugIngredientSearchTypes}
            valueField="value"
            textField="label"
            onChange={({ value }) => setSearchType(value)}
          />
          <Input
            className="w-[34rem]"
            value={keyword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setKeyword(e.target.value)
            }
          />
          <Button type="submit" disabled={!searchType || keyword.length < 2}>
            {t('common:search')}
          </Button>
          <div>
            <Checkbox
              name="exactMatch"
              label="Exact Match"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeOption('exactMatch', e.target.checked)
              }
            />
          </div>
        </div>
      </form>
      <div className="relative my-2 flex h-[35rem]">
        <div ref={scrollRef} className="overflow-auto bg-inherit ">
          <table className="w-full table-fixed border-separate border-spacing-0 text-slate-600">
            <thead className="sticky -top-px bg-white [&_th]:border-t [&_th]:border-b [&_th]:border-r [&_th]:p-2">
              <tr>
                <th className="border-l">Ingredient</th>
                <th>CAS Number</th>
              </tr>
            </thead>
            <tbody className="[&_td]:break-words [&_td]:break-all [&_td]:border-b [&_td]:border-r [&_td]:py-1 [&_td]:px-2 [&_td]:first:border-l">
              {data
                ? data.pages.map(({ items }) =>
                    items.map((item: WHODrugSubstanceCode) => (
                      <tr
                        key={item.substanceId}
                        className="cursor-pointer hover:bg-brand-400 hover:text-white"
                        onClick={() => handleClick(item)}
                      >
                        <td className="border-l">{item.substanceName}</td>
                        <td>{item.casNumber}</td>
                      </tr>
                    )),
                  )
                : null}
            </tbody>
          </table>
        </div>
        {isFetching ? (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-gray-500/75">
            <ThreeDots
              height="80"
              width="80"
              ariaLabel="tail-spin-loading"
              color="#6C63FF"
              radius="1"
              visible
            />
          </div>
        ) : null}
      </div>
    </Popup>
  );
}

export default WHODrugSubstance;
