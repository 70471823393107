import React, { useRef, useState } from 'react';
import produce from 'immer';

import { Company } from 'types/company';
import { TrackerDataStatus } from 'types/tracker';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { DatePicker } from 'components/common/DatePicker';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';
import Logo from 'components/common/Logo';

import TrackerTemplatePopup from './TrackerTemplatePopup';

interface props {
  submitLabel: string;
  check?: boolean;
  form: Company;
  tracker?: any;
  logoFileURL?: string;
  errors: any;
  checkAvailability?: () => void;
  createTrackerData?: () => void;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}

function CompanyForm({
  submitLabel,
  check,
  form,
  tracker,
  logoFileURL,
  errors,
  checkAvailability = () => null,
  createTrackerData = () => null,
  onChange,
  onSubmit,
}: props) {
  const logoRef = useRef<HTMLInputElement>(null);
  const [trackerEditorOpen, setTrackerEditorOpen] = useState(false);

  const {
    id,
    companyName,
    type,
    enableTracker,
    enableWhodd,
    whoDrugLicense,
    startDate,
    endDate,
    logo,
  } = form;

  const openTrackerEditor = () => setTrackerEditorOpen((prev) => !prev);

  return (
    <form className="max-w-4xl px-2" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div className="space-y-1">
          <div className="flex py-4">
            <div className="required w-1/2">Email</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full flex-1"
                value={companyName}
                onChange={(e) => onChange('companyName', e.target.value)}
                disabled={submitLabel === 'Save'}
              />
              {submitLabel === 'Create' && (
                <Button
                  className="ml-1"
                  color="slate"
                  onClick={checkAvailability}
                  disabled={!companyName || !!errors.companyName}
                  outline
                >
                  Check
                </Button>
              )}
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.companyName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">Type</div>
            <div className="flex w-1/2 flex-wrap">
              <RadioGroup
                className="flex space-x-4"
                name="type"
                value={type}
                onChange={(value) => onChange('type', value)}
                disabled={!check && submitLabel === 'Create'}
              >
                <Radio size="md" value="MAH" label="MAH" />
                <Radio size="md" value="CRO" label="CRO" />
              </RadioGroup>
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.type?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">Tracker</div>
            <div className="flex w-1/2 flex-wrap">
              <RadioGroup
                className="flex space-x-4"
                name="enable-tracker"
                value={enableTracker?.toString()}
                onChange={(value) =>
                  onChange('enableTracker', value && JSON.parse(value))
                }
                disabled={!check && submitLabel === 'Create'}
              >
                <Radio size="md" value="true" label="ON" />
                <Radio size="md" value="false" label="OFF" />
              </RadioGroup>
              {submitLabel === 'Save' ? (
                <Button
                  className="ml-4"
                  color="brand"
                  onClick={openTrackerEditor}
                >
                  Template
                </Button>
              ) : null}
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.enableTracker?.message}
              </span>
            </div>
          </div>
          {submitLabel === 'Save' && (
            <>
              <div className="flex py-4">
                <div className="required w-1/2">Tracker Data</div>
                <div className="flex w-1/2 flex-wrap">
                  <Button
                    color="brand"
                    onClick={createTrackerData}
                    disabled={
                      tracker && tracker.status !== TrackerDataStatus.CREATED
                    }
                  >
                    Create Data
                  </Button>
                  {tracker && (
                    <span className="ml-1">
                      {tracker.status === TrackerDataStatus.PROGRESS &&
                        'In Progress...'}
                      {tracker.status === TrackerDataStatus.COMPLETE &&
                        'Complete'}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex py-4">
                <div className="required w-1/2">WHODrug</div>
                <div className="flex w-1/2 flex-wrap">
                  <RadioGroup
                    className="flex space-x-4"
                    name="enable-whodd"
                    value={enableWhodd?.toString()}
                    onChange={(value) =>
                      onChange('enableWhodd', value && JSON.parse(value))
                    }
                  >
                    <Radio size="md" value="true" label="ON" />
                    <Radio size="md" value="false" label="OFF" />
                  </RadioGroup>
                  <span className="inline-block h-0 w-full text-sm text-red-600">
                    {errors.enableWhodd?.message}
                  </span>
                </div>
              </div>
              <div className="flex py-4">
                <div className="required w-1/2">WHODrug License</div>
                <div className="flex w-1/2 flex-wrap">
                  <DatePicker
                    className="w-full"
                    value={whoDrugLicense?.licenseValidDate}
                    format="YYYY-MM-DD"
                    onChange={(date) => {
                      const newWhoDrugLicense = produce(
                        whoDrugLicense || {},
                        (draft: any) => {
                          draft.licenseValidDate = date?.isValid()
                            ? date.format('YYYY-MM-DD')
                            : null;
                        },
                      );
                      onChange('whoDrugLicense', newWhoDrugLicense);
                    }}
                    disabled={!enableWhodd}
                  />
                  <span className="inline-block h-0 w-full text-sm text-red-600">
                    {errors.whoDrugLicense?.message}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="flex py-4">
            <div className="required w-1/2">Start Date</div>
            <div className="flex w-1/2 flex-wrap">
              <DatePicker
                className="w-full"
                value={startDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  onChange(
                    'startDate',
                    date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                  )
                }
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.startDate?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="required w-1/2">End Date</div>
            <div className="flex w-1/2 flex-wrap">
              <DatePicker
                className="w-full"
                value={endDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  onChange(
                    'endDate',
                    date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                  )
                }
                disabled={!check && submitLabel === 'Create'}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.endDate?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4">
            <div className="w-1/2">Logo</div>
            <div className="flex w-1/2 flex-wrap">
              <button
                type="button"
                onClick={() => logoRef.current && logoRef.current.click()}
                disabled={!check && submitLabel === 'Create'}
              >
                <Logo src={logoFileURL || logo} />
                <input
                  ref={logoRef}
                  className="hidden"
                  type="file"
                  accept="image/jpeg, image/png, image/svg"
                  onChange={(e) => {
                    onChange('logoFile', e.target.files);
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit" disabled={!check && submitLabel === 'Create'}>
          {submitLabel}
        </Button>
      </div>
      <TrackerTemplatePopup
        companyId={id}
        isOpen={trackerEditorOpen}
        toggle={openTrackerEditor}
      />
    </form>
  );
}

export default CompanyForm;
