import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ICSRFormElement } from 'types/case';

import Textarea from 'components/common/Textarea';

function TextareaComponent({
  className,
  number,
  value,
  maxLength,
  disabled,
  onChange = () => null,
}: {
  className?: string;
  number: string;
  value?: string | null;
  maxLength?: string;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  return (
    <div className={twMerge(clsx('text-right leading-tight', className))}>
      <Textarea
        className="w-full"
        minRows={3}
        maxRows={20}
        value={value || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(number, {
            number,
            value: e.target.value,
            nullFlavor: undefined,
          });
        }}
        disabled={disabled}
      />
      {maxLength && (
        <span className={clsx('text-sm', disabled && 'disabled')}>
          {value?.length || 0}/{maxLength}
        </span>
      )}
    </div>
  );
}

export default TextareaComponent;
