/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import { companyState, codeSetsState } from 'state';
import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';
import { Product } from 'types/product';
import modal from 'utils/modal';

import { useCaseState } from '../../context/CaseContext';
import useCaseConvert from '../../hooks/useCaseConvert';

import Title from '../../Title';
import LoadData from '../../LoadData';
import RootRepeatElement from '../../RootRepeatElement';
import CaseFormElement from '../../CaseFormElement';
import SubTitle from '../../SubTitle';
import Space from '../../Space';
import CaseTab, { CaseTabPanel } from '../../CaseTab';

function Drug({
  version,
  form,
  reactionEvents = [],
  primaryReporterCountry,
  receiverType,
  linkedStudy,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  reactionEvents?: any;
  primaryReporterCountry?: string;
  receiverType?: string;
  linkedStudy?: string;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const { t } = useTranslation();

  const [{ selected: company }] = useRecoilState(companyState);
  const { whoDrugLicense } = company || {};

  const [codeSets] = useRecoilState(codeSetsState);
  const { studies, products } = useCaseState();

  const { convertDrug } = useCaseConvert(codeSets);

  const handleChange = useCallback(
    (
      number: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const handleControl = useCallback(
    (number: string, draft: ICSRFormRootElement) => {
      if (number === 'G.k.9.i.2.r.2.KR.1') {
        const value = (draft.elements['G.k.9.i.2.r.2.KR.1'] as ICSRFormElement)
          ?.value;
        draft.elements['G.k.9.i.2.r.2'] = {
          ...draft.elements['G.k.9.i.2.r.2'],
          value: codeSets['2.16.840.1.113883.3.989.5.1.10.1.4']?.codes.find(
            ({ key }: { key: string }) => key === value,
          )?.value,
        };
        draft.elements['G.k.9.i.2.r.3'] = {
          ...draft.elements['G.k.9.i.2.r.3'],
          value: undefined,
        };
        draft.elements['G.k.9.i.2.r.3.KR.2'] = {
          ...draft.elements['G.k.9.i.2.r.3.KR.2'],
          value: undefined,
          description: undefined,
        };
        draft.elements['G.k.9.i.2.r.3.KR.1'] = {
          ...draft.elements['G.k.9.i.2.r.3.KR.1'],
          value: undefined,
          description: undefined,
        };
      }
      if (number === 'G.k.9.i.2.r.3.KR.1') {
        const value = (draft.elements['G.k.9.i.2.r.3.KR.1'] as ICSRFormElement)
          ?.value;
        draft.elements['G.k.9.i.2.r.3'] = {
          ...draft.elements['G.k.9.i.2.r.3'],
          value: codeSets['2.16.840.1.113883.3.989.5.1.10.1.5']?.codes.find(
            ({ key }: { key: string }) => key === value,
          )?.value,
        };
      }
      if (number === 'G.k.9.i.2.r.3.KR.2') {
        const value = (draft.elements['G.k.9.i.2.r.3.KR.2'] as ICSRFormElement)
          ?.value;
        draft.elements['G.k.9.i.2.r.3'] = {
          ...draft.elements['G.k.9.i.2.r.3'],
          value: codeSets['2.16.840.1.113883.3.989.5.1.10.1.6']?.codes.find(
            ({ key }: { key: string }) => key === value,
          )?.value,
        };
      }
    },
    [codeSets],
  );

  const disableDelete = useCallback(
    (f: ICSRFormRootElement) => {
      if (
        (f?.elements['G.k.9.i.1'] as ICSRFormElement)?.value === undefined &&
        (f?.elements['G.k.9.i.2.r'] as ICSRFormRootElement[])?.length
      ) {
        modal.alert(
          t('modal:alert:title'),
          t('modal:alert:impossibleDeleteReactionEventMatrix'),
        );
        return true;
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const handleLoad = useCallback(
    (product: Product) => {
      setForm(
        produce((draft) => {
          (draft['G.k'] as ICSRFormRootElement[]).push(convertDrug(product));
        }),
      );
      handleVersion();
    },
    [convertDrug, handleVersion, setForm],
  );

  const allReactionEvents = useMemo(
    () =>
      reactionEvents?.map((item: ICSRFormRootElement) => ({
        value: (item?.elements['E.i.CNR.1'] as ICSRFormElement)?.value,
        label:
          (item?.elements['E.i.2.1b'] as ICSRFormElement)?.description ||
          (item?.elements['E.i.1.1a'] as ICSRFormElement)?.value,
        deleted: (item?.elements['E.i.CNR.1'] as ICSRFormElement)?.deleted,
      })),
    [reactionEvents],
  );

  const filteredReactionEvents = useMemo(
    () =>
      reactionEvents
        ?.filter((item: any) => item && !item.deleted)
        .map((item: any) => ({
          value: item?.elements['E.i.CNR.1']?.value,
          label:
            item?.elements['E.i.2.1b']?.description ||
            item?.elements['E.i.1.1a']?.value,
          deleted: item?.elements['E.i.CNR.1']?.deleted,
        })),
    [reactionEvents],
  );

  const filteredProducts = useMemo(() => {
    if (!linkedStudy) return products || [];
    const study = studies?.find((item) => item.studyName === linkedStudy);
    return (
      products?.filter((product: any) =>
        study?.productIdList.includes(product.id),
      ) || []
    );
  }, [linkedStudy, products, studies]);

  const drugReactionEventList = useMemo(
    () => [
      {
        elementNumber: 'G.k.9.i.1',
        label: t('ICSR:elementName:G.k.9.i.1'),
        component: 'select',
        data: allReactionEvents,
        valueField: 'value',
        textField: 'label',
      },
      {
        elementNumber: 'G.k.9.i.4',
        label: t('ICSR:elementName:G.k.9.i.4'),
        component: 'radio',
        data: codeSets['2.16.840.1.113883.3.989.2.1.1.16']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
    [codeSets, allReactionEvents, t],
  );

  const availableList = useMemo(
    () => ({
      'G.k.9.i.2.r.2': (f: any) => !f?.elements['G.k.9.i.2.r.2.KR.1']?.value,
      'G.k.9.i.2.r.3': (f: any) => !f?.elements['G.k.9.i.2.r.2.KR.1']?.value,
      'G.k.9.i.2.r.3.KR.1': (f: any) =>
        !(f?.elements['G.k.9.i.2.r.2.KR.1']?.value !== '1'),
      'G.k.9.i.2.r.3.KR.2': (f: any) =>
        !(f?.elements['G.k.9.i.2.r.2.KR.1']?.value !== '2'),
    }),
    [],
  );

  const defaultTabList = useMemo(
    () => [
      { label: t('ICSR:elementName:G.k_'), validationCount: 0, memoCount: 0 },
      { label: t('ICSR:elementName:G.k.2_'), validationCount: 0, memoCount: 0 },
      {
        label: t('ICSR:elementName:G.k.4.r_'),
        validationCount: 0,
        memoCount: 0,
      },
      {
        label: t('ICSR:elementName:G.k.7.r_'),
        validationCount: 0,
        memoCount: 0,
      },
      {
        label: t('ICSR:elementName:G.k.9.i_'),
        validationCount: 0,
        memoCount: 0,
      },
    ],
    [t],
  );

  return (
    <>
      <Title number="G.k">
        <LoadData
          data={filteredProducts}
          textField={(dataItem) =>
            dataItem?.brandName || dataItem?.productName || dataItem
          }
          onLoad={handleLoad}
          disabled={disabled}
        />
      </Title>

      <RootRepeatElement
        className="[&>.repeat-content]:border-0 [&>.repeat-content]:p-0"
        number="G.k"
        version={version}
        element={form['G.k'] as ICSRFormRootElement[]}
        disabled={disabled}
        onChange={handleChange}
      >
        <CaseTab defaultTabList={defaultTabList}>
          <CaseTabPanel tabId={0}>
            <CaseFormElement number="G.k.1" />

            <Space />

            <CaseFormElement number="G.k.3.1" />
            <CaseFormElement number="G.k.3.2" />
            <CaseFormElement number="G.k.3.3" />

            <Space />

            <CaseFormElement number="G.k.5a" />
            <CaseFormElement number="G.k.5b" />
            <CaseFormElement number="G.k.6a" />
            <CaseFormElement number="G.k.6b" />
            <CaseFormElement number="G.k.8" />
            <CaseFormElement number="G.k.11" />

            <Space />

            <SubTitle number="G.k.10.r" />
            <RootRepeatElement number="G.k.10.r">
              <CaseFormElement number="G.k.10.r" />
            </RootRepeatElement>
          </CaseTabPanel>
          <CaseTabPanel tabId={1}>
            <SubTitle number="G.k.2" />
            <CaseFormElement number="G.k.2.1.KR.1a" />
            <CaseFormElement
              number="G.k.2.1.KR.1b"
              targetElementNumber="G.k.2.1.KR.1a"
              version={whoDrugLicense?.whoddVersionInUse}
              country={primaryReporterCountry}
              component={
                receiverType === 'CF' || receiverType === 'FR'
                  ? whoDrugLicense?.whoddVersionInUse
                    ? 'WHODrugMedicalProductSearch'
                    : 'input'
                  : 'MedicalProductSearch'
              }
            />
            <CaseFormElement number="G.k.2.1.1a" />
            <CaseFormElement number="G.k.2.1.1b" />
            <CaseFormElement number="G.k.2.1.2a" />
            <CaseFormElement number="G.k.2.1.2b" />
            <CaseFormElement number="G.k.2.2" />
            <CaseFormElement number="G.k.2.4" />
            <CaseFormElement number="G.k.2.5" />

            <Space />

            <SubTitle number="G.k.2.3.r" />
            <RootRepeatElement number="G.k.2.3.r">
              <CaseFormElement number="G.k.2.3.r.1" blank />
              <CaseFormElement number="G.k.2.3.r.1.KR.1a" />
              <CaseFormElement
                number="G.k.2.3.r.1.KR.1b"
                targetElementNumber="G.k.2.3.r.1.KR.1a"
                version={whoDrugLicense?.whoddVersionInUse}
                country={primaryReporterCountry}
                component={
                  receiverType === 'CF' || receiverType === 'FR'
                    ? whoDrugLicense?.whoddVersionInUse
                      ? 'WHODrugSubstanceSearch'
                      : 'input'
                    : 'SubstanceSearch'
                }
              />
              <CaseFormElement number="G.k.2.3.r.2a" />
              <CaseFormElement number="G.k.2.3.r.2b" />
              <CaseFormElement number="G.k.2.3.r.3a" />
              <CaseFormElement number="G.k.2.3.r.3b" />
            </RootRepeatElement>
          </CaseTabPanel>
          <CaseTabPanel tabId={2}>
            <SubTitle number="G.k.4.r" />
            <RootRepeatElement number="G.k.4.r">
              <CaseFormElement number="G.k.4.r.1a" />
              <CaseFormElement number="G.k.4.r.1b" />
              <CaseFormElement number="G.k.4.r.2" />
              <CaseFormElement number="G.k.4.r.3" />
              <CaseFormElement number="G.k.4.r.4" />
              <CaseFormElement number="G.k.4.r.5" />
              <CaseFormElement number="G.k.4.r.6a" />
              <CaseFormElement number="G.k.4.r.6b" />
              <CaseFormElement number="G.k.4.r.7" />
              <CaseFormElement number="G.k.4.r.8" />
              <CaseFormElement number="G.k.4.r.9.1" blank />
              <CaseFormElement number="G.k.4.r.9.2a" />
              <CaseFormElement number="G.k.4.r.9.2b" />
              <CaseFormElement number="G.k.4.r.10.1" blank />
              <CaseFormElement number="G.k.4.r.10.2a" />
              <CaseFormElement number="G.k.4.r.10.2b" />
              <CaseFormElement number="G.k.4.r.11.1" blank />
              <CaseFormElement number="G.k.4.r.11.2a" />
              <CaseFormElement number="G.k.4.r.11.2b" />
            </RootRepeatElement>
          </CaseTabPanel>
          <CaseTabPanel tabId={3}>
            <SubTitle number="G.k.7.r" />
            <RootRepeatElement number="G.k.7.r">
              <CaseFormElement number="G.k.7.r.1" blank />
              <CaseFormElement number="G.k.7.r.2a" />
              <CaseFormElement
                number="G.k.7.r.2b"
                valueToVersion="G.k.7.r.2a"
              />
            </RootRepeatElement>
          </CaseTabPanel>
          <CaseTabPanel tabId={4}>
            <SubTitle number="G.k.9.i" />
            <RootRepeatElement
              number="G.k.9.i"
              list={drugReactionEventList}
              disableDelete={disableDelete}
            >
              <CaseFormElement
                number="G.k.9.i.1"
                data={filteredReactionEvents}
              />
              <CaseFormElement number="G.k.9.i.4" />
              <CaseFormElement number="G.k.9.i.3.1a" />
              <CaseFormElement number="G.k.9.i.3.1b" />
              <CaseFormElement number="G.k.9.i.3.2a" />
              <CaseFormElement number="G.k.9.i.3.2b" />

              <Space />

              <SubTitle number="G.k.9.i.2.r" />
              <RootRepeatElement number="G.k.9.i.2.r" onControl={handleControl}>
                <CaseFormElement number="G.k.9.i.2.r.1" blank />
                <CaseFormElement
                  number="G.k.9.i.2.r.2"
                  available={availableList['G.k.9.i.2.r.2']}
                />
                <CaseFormElement
                  number="G.k.9.i.2.r.3"
                  available={availableList['G.k.9.i.2.r.3']}
                />

                <Space />

                <CaseFormElement number="G.k.9.i.2.r.2.KR.1" blank />
                <CaseFormElement
                  number="G.k.9.i.2.r.3.KR.1"
                  available={availableList['G.k.9.i.2.r.3.KR.1']}
                />
                <CaseFormElement
                  number="G.k.9.i.2.r.3.KR.2"
                  available={availableList['G.k.9.i.2.r.3.KR.2']}
                />
              </RootRepeatElement>
            </RootRepeatElement>
          </CaseTabPanel>
        </CaseTab>
      </RootRepeatElement>
    </>
  );
}

export default Drug;
