import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import produce from 'immer';

import Filter, { FilterType } from './Filter';
import AddFilter, { FilterAddType } from './AddFilter';

interface FilterBoxProps {
  filter?: {
    [key: string]: any;
  };
  filterList: FilterType[];
  setFilter?: (v: (base: any) => any) => void;
}

function FilterBox({
  filter = {},
  filterList,
  setFilter = () => null,
}: FilterBoxProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [addList, setAddList] = useState<FilterAddType[]>([]);
  const [showList, setShowList] = useState<FilterAddType[]>([]);

  const handleChange = useCallback(
    (key: string, value?: any) => {
      setFilter(
        produce((draft) => {
          draft[key] = value;
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const focusing = useCallback(() => {
    setTimeout(() => {
      if (ref.current) {
        (
          ref.current.childNodes[
            ref.current.childNodes.length - 2
          ] as HTMLElement
        ).dispatchEvent(new CustomEvent('openfilter'));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showFilter = useCallback(
    (item: FilterAddType, idx: number) => {
      setShowList(
        produce((draft) => {
          draft.push(item);
        }),
      );
      setAddList(
        produce((draft) => {
          draft[idx].disabled = true;
        }),
      );
      focusing();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const hideFilter = useCallback(
    (item: FilterAddType, idx: number) => {
      setShowList(
        produce((draft) => {
          draft.splice(idx, 1);
        }),
      );
      setAddList(
        produce((draft) => {
          const index = draft.findIndex((_item) => _item.id === item.id);
          if (index !== -1) draft[index].disabled = false;
        }),
      );
      handleChange(item.name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const FixList = useMemo(
    () =>
      filterList
        .filter(({ flex }) => !flex)
        .map((item) => {
          const { name, list } = item;
          if (name === 'searchList') {
            const target = Object.entries(filter).find(([fKey, fValue]) =>
              list?.find((lItem) => lItem.name === fKey && fValue),
            )?.[0];
            const targetItem = { ...item, target };
            return (
              <Filter
                key={name}
                item={targetItem}
                value={target && filter[target]}
                onChange={handleChange}
              />
            );
          }
          return (
            <Filter
              key={name}
              item={item}
              value={filter[name]}
              onChange={handleChange}
            />
          );
        }),
    [filter, filterList, handleChange],
  );

  useEffect(() => {
    const newAddList =
      filterList
        ?.filter(({ flex }) => flex)
        .map((item, idx) => ({
          ...item,
          id: idx,
          disabled: !!filter[item.name],
        })) || [];
    const newShowList = newAddList.filter(({ disabled }) => disabled) || [];
    setAddList(newAddList);
    setShowList(newShowList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterList]);

  return (
    <div ref={ref} className="flex flex-wrap [&>*]:m-1">
      {FixList}
      {showList.map((item, idx) => (
        <Filter
          key={item.name}
          item={item}
          value={filter[item.name]}
          onChange={handleChange}
          hideFilter={() => hideFilter(item, idx)}
        />
      ))}
      {addList.length > 0 && (
        <AddFilter list={addList} showFilter={showFilter} />
      )}
    </div>
  );
}

export default FilterBox;
