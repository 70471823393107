import { Registration, Study } from 'types/study';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getStudyDefaultValues = (companyId = -1): Study => ({
  companyId,
  productIdList: [],
  registrations: [],
  sponsorStudyNumber: '',
  studyName: '',
  studyType: '',
  deleted: false,
  version: 0,
});
export const getRegistrationDefaultValues = (): Registration => ({
  number: '',
  country: '',
  deleted: false,
});

export const getStudies = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/studys?companyId=${companyId}&${queryString}`);
};

export const getStudy = (studyId?: number | string) =>
  client.get(`/api/v1/studys/${studyId}`);

export const getStudyWithProduct = (productId?: number | string) =>
  client.get(`/api/v1/product/${productId}/study`);

export const postStudy = (data: any) => client.post('/api/v1/studys', data);

export const putStudy = (data: any) =>
  client.put(`/api/v1/studys/${data.id}`, data);

export const deleteStudies = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/studys?${queryString}`);
};
