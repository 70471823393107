import getQueryString from 'utils/getQueryString';
import client from './client';

export const getRoles = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/roles?companyId=${companyId}&${queryString}`);
};

export const getRole = (roleId: number | string) =>
  client.get(`/api/v1/roles/${roleId}`);

export const postRole = (data: any) => client.post('/api/v1/roles', data);

export const putRole = (roleId: number | string, data: any) =>
  client.put(`/api/v1/roles/${roleId}`, data);

export const putRoles = (data: any) => client.put('/api/v1/roles', data);

export const deleteRole = (roleId: number | string) =>
  client.delete(`/api/v1/roles/${roleId}`);
