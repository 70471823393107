/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { getYearRange } from './datePickerUtils';
import Days from './Days';
import Months from './Months';
import Years from './Years';

export const ViewType = {
  years: 'years' as const,
  months: 'months' as const,
  days: 'days' as const,
};

interface DatePickerDialogProps {
  type?: 'date' | 'range';
  focusingTarget?: string;
  selectedDate?: Moment;
  selectedDates?: (Moment | undefined)[];
  focusedDate?: Moment;
  hoverDate?: Moment;
  handleDateClick: (e: React.MouseEvent) => void;
  handleDateHover?: (e: React.MouseEvent) => void;
}
function DatePickerDialog({
  type = 'date',
  focusingTarget,
  selectedDate,
  selectedDates,
  focusedDate,
  hoverDate,
  handleDateClick = () => {},
  handleDateHover = () => {},
}: DatePickerDialogProps) {
  const [view, setView] = useState<keyof typeof ViewType>(ViewType.days);
  const [viewDate, setViewDate] = useState<Moment>(
    (selectedDate?.isValid() && selectedDate) ||
      (selectedDates?.[0]?.isValid() && selectedDates[0]) ||
      moment(),
  );

  const yearRange = getYearRange(viewDate);

  const handleViewButtonClick = () =>
    setView((prev) => {
      switch (prev) {
        case ViewType.days:
          return ViewType.months;
        case ViewType.months:
          return ViewType.years;
        default:
          return prev;
      }
    });

  const handlePrevButtonClick = () =>
    setViewDate((prev) => {
      switch (view) {
        case ViewType.days:
          return moment(prev).subtract(1, 'months');
        case ViewType.months:
          return moment(prev).subtract(1, 'years');
        case ViewType.years:
          return moment(prev).subtract(12, 'years');
        default:
          return prev;
      }
    });

  const handleNextButtonClick = () =>
    setViewDate((prev) => {
      switch (view) {
        case ViewType.days:
          return moment(prev).add(1, 'months');
        case ViewType.months:
          return moment(prev).add(1, 'years');
        case ViewType.years:
          return moment(prev).add(12, 'years');
        default:
          return prev;
      }
    });

  const handleNextViewButtonClick = () => {
    handleNextButtonClick();
    setView((prev) => {
      switch (prev) {
        case ViewType.days:
          return ViewType.months;
        case ViewType.months:
          return ViewType.years;
        default:
          return prev;
      }
    });
  };

  const handleMonthClick = ({ target }: React.MouseEvent) => {
    if (!(target instanceof HTMLButtonElement)) return;
    const {
      dataset: { month },
    } = target;
    if (month) {
      setViewDate((prev) => moment(prev).month(Number(month)));
      setView(ViewType.days);
    }
  };

  const handleYearClick = ({ target }: React.MouseEvent) => {
    if (!(target instanceof HTMLButtonElement)) return;
    const {
      dataset: { year },
    } = target;
    if (year) {
      setViewDate((prev) => moment(prev).year(Number(year)));
      setView(ViewType.months);
    }
  };

  useEffect(() => {
    if (type === 'date') {
      if (focusedDate?.isValid())
        setViewDate((prev) => {
          if (prev.isSame(focusedDate, 'month')) {
            return prev;
          }
          return focusedDate;
        });
    }
    if (type === 'range') {
      if (focusingTarget === 'start' && selectedDates?.[0]?.isValid()) {
        setViewDate(selectedDates[0]);
      } else if (focusingTarget === 'end' && selectedDates?.[1]?.isValid()) {
        if (selectedDates?.[0]?.isSame(selectedDates[1], 'month')) {
          setViewDate(selectedDates[1]);
        } else {
          setViewDate(moment(selectedDates[1]).subtract(1, 'month'));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedDate, selectedDates, focusingTarget]);

  return (
    <>
      <div className="mb-2 flex justify-between">
        <button
          type="button"
          className="rounded-full bg-white p-2.5 text-gray-500 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={handlePrevButtonClick}
        >
          <svg
            className="h-5 w-5 p-0.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="rounded-lg bg-white py-2.5 px-5 text-sm font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
          onClick={handleViewButtonClick}
        >
          {view === ViewType.days &&
            `${moment.months(viewDate.month())} ${viewDate.year()}`}
          {view === ViewType.months && `${viewDate.year()}`}
          {view === ViewType.years && `${yearRange[0]}-${yearRange[11]}`}
        </button>
        {type === 'range' && view === ViewType.days && (
          <>
            <button
              type="button"
              className="pointer-events-none rounded-full bg-white p-2.5 text-gray-500 opacity-0 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleNextButtonClick}
            >
              <svg
                className="h-5 w-5 rotate-180 p-0.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              type="button"
              className="pointer-events-none rounded-full bg-white p-2.5 text-gray-500 opacity-0 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handlePrevButtonClick}
            >
              <svg
                className="h-5 w-5 p-0.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              type="button"
              className="rounded-lg bg-white py-2.5 px-5 text-sm font-semibold text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
              data-next="next"
              onClick={handleNextViewButtonClick}
            >
              {`${moment.months(
                moment(viewDate).add(1, 'month').month(),
              )} ${moment(viewDate).add(1, 'month').year()}`}
            </button>
          </>
        )}
        <button
          type="button"
          className="rounded-full bg-white p-2.5 text-gray-500 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={handleNextButtonClick}
        >
          <svg
            className="h-5 w-5 rotate-180 p-0.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="p-1">
        {view === ViewType.days && (
          <div className="flex space-x-3">
            <Days
              selectedDate={selectedDate}
              selectedDates={selectedDates}
              focusedDate={focusedDate}
              hoverDate={hoverDate}
              viewDate={viewDate}
              handleDateClick={handleDateClick}
              handleDateHover={handleDateHover}
            />
            {type === 'range' && (
              <Days
                selectedDate={selectedDate}
                selectedDates={selectedDates}
                focusedDate={focusedDate}
                hoverDate={hoverDate}
                viewDate={moment(viewDate).add(1, 'months')}
                handleDateClick={handleDateClick}
                handleDateHover={handleDateHover}
              />
            )}
          </div>
        )}
        {view === ViewType.months && (
          <Months handleMonthClick={handleMonthClick} />
        )}
        {view === ViewType.years && (
          <Years viewDate={viewDate} handleYearClick={handleYearClick} />
        )}
      </div>
    </>
  );
}

export default DatePickerDialog;
