import React, { useCallback, useMemo } from 'react';
import produce from 'immer';

import {
  ICSRForm,
  ICSRFormElement,
  ICSRFormRootElement,
  ICSRFormType,
} from 'types/case';

import RootElement from 'components/case/RootElement';
import CaseFormElement from 'components/case/CaseFormElement';
import RootRepeatElement from 'components/case/RootRepeatElement';
import Title from 'components/case/Title';
import Space from 'components/case/Space';
import SubTitle from 'components/case/SubTitle';

function Identification({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const handleChange = useCallback(
    (number: string, value: ICSRFormType) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const worldWideNumberList = useMemo(() => {
    const value = (
      (form['C.1'] as ICSRFormRootElement)?.elements['C.1.1'] as ICSRFormElement
    )?.value;
    return value && [value];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(form['C.1'] as ICSRFormRootElement)?.elements['C.1.1']]);

  return (
    <>
      <Title number="C.1" />

      <RootElement
        number="C.1"
        version={version}
        element={form['C.1'] as ICSRFormRootElement}
        disabled={disabled}
        onChange={handleChange}
      >
        <CaseFormElement number="C.1.CNR.1" />
        <CaseFormElement number="C.1.1" />
        <CaseFormElement number="C.1.2" />
        <CaseFormElement number="C.1.3" />
        <CaseFormElement number="C.1.4" />
        <CaseFormElement number="C.1.5" />

        <Space />

        <SubTitle number="C.1.6" />
        <CaseFormElement number="C.1.6.1" />

        <SubTitle number="C.1.6.1.r" />
        <RootRepeatElement number="C.1.6.1.r">
          <CaseFormElement number="C.1.6.1.r.1" />
          <CaseFormElement number="C.1.6.1.r.2" />
        </RootRepeatElement>

        <CaseFormElement number="C.1.7" />

        <Space />

        <SubTitle number="C.1.8" />
        <CaseFormElement number="C.1.8.1" data={worldWideNumberList} />
        <CaseFormElement number="C.1.8.2" />

        <Space />

        <SubTitle number="C.1.9" />
        <CaseFormElement number="C.1.9.1" />

        <SubTitle number="C.1.9.1.r" />
        <RootRepeatElement number="C.1.9.1.r">
          <CaseFormElement number="C.1.9.1.r.1" />
          <CaseFormElement number="C.1.9.1.r.2" />
        </RootRepeatElement>

        <SubTitle number="C.1.10.r" />
        <RootRepeatElement number="C.1.10.r">
          <CaseFormElement number="C.1.10.r" />
        </RootRepeatElement>

        <CaseFormElement number="C.1.11.1" />
        <CaseFormElement number="C.1.11.2" />
      </RootElement>
    </>
  );
}

export default Identification;
