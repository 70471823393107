import React, { TdHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

function LTD({ className, children }: TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td className={twMerge(clsx('relative break-words px-2', className))}>
      {children}
    </td>
  );
}

export default LTD;
