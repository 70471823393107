import { TFunction } from 'i18next';
import ICH from './ICH';

const MFDS = (codeList: any, t: TFunction) => ({
  ...ICH(codeList, t),
  'C.1.CNR.1': {
    elementNumber: 'C.1.CNR.1',
    label: t('ICSR:elementName:C.1.CNR.1'),
    component: 'radio',
    options: codeList.CNR_MFDS_RECEIVER?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.1.2': {
    elementNumber: 'C.1.2',
    label: t('ICSR:elementName:C.1.2'),
    component: 'date',
    format: 'YYYYMMDDHHmmssZZ',
    allowedFormat: ['YYYYMMDDHHmmssZZ', 'YYYYMMDDHHmmss'],
    required: true,
  },
  'C.2.r.3': {
    elementNumber: 'C.2.r.3',
    label: t('ICSR:elementName:C.2.r.3'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'C.2.r.4.KR.1': {
    elementNumber: 'C.2.r.4.KR.1',
    label: t('ICSR:elementName:C.2.r.4.KR.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.1']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'C.3.1.KR.1': {
    elementNumber: 'C.3.1.KR.1',
    label: t('ICSR:elementName:C.3.1.KR.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'C.5.4.KR.1': {
    elementNumber: 'C.5.4.KR.1',
    label: t('ICSR:elementName:C.5.4.KR.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.3']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'D.8.r.1.KR.1a': {
    elementNumber: 'D.8.r.1.KR.1a',
    label: t('ICSR:elementName:D.8.r.1.KR.1a'),
    component: 'input',
    maxLength: '20',
  },
  'D.8.r.1.KR.1b': {
    elementNumber: 'D.8.r.1.KR.1b',
    label: t('ICSR:elementName:D.8.r.1.KR.1b'),
    maxLength: '10',
  },
  'D.9.3': {
    elementNumber: 'D.9.3',
    label: t('ICSR:elementName:D.9.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'D.10.8.r.1.KR.1a': {
    elementNumber: 'D.10.8.r.1.KR.1a',
    label: t('ICSR:elementName:D.10.8.r.1.KR.1a'),
    component: 'input',
    maxLength: '20',
  },
  'D.10.8.r.1.KR.1b': {
    elementNumber: 'D.10.8.r.1.KR.1b',
    label: t('ICSR:elementName:D.10.8.r.1.KR.1b'),
    maxLength: '10',
  },
  'G.k': {
    elementNumber: 'G.k',
    list: [
      {
        elementNumber: 'G.k.1',
        label: t('ICSR:elementName:G.k.1'),
        component: 'radio',
        data: codeList['2.16.840.1.113883.3.989.2.1.1.13']?.codes,
        valueField: 'key',
        textField: 'value',
      },
      {
        elementNumber: 'G.k.2.1.KR.1b',
        label: t('ICSR:elementName:G.k.2.1.KR.1b'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.2.2',
        label: t('ICSR:elementName:G.k.2.2'),
        component: 'input',
      },
    ],
  },
  'G.k.2.1.KR.1a': {
    elementNumber: 'G.k.2.1.KR.1a',
    label: t('ICSR:elementName:G.k.2.1.KR.1a'),
    component: 'input',
    maxLength: '20',
  },
  'G.k.2.1.KR.1b': {
    elementNumber: 'G.k.2.1.KR.1b',
    label: t('ICSR:elementName:G.k.2.1.KR.1b'),
    maxLength: '10',
  },
  'G.k.2.3.r': {
    elementNumber: 'G.k.2.3.r',
    list: [
      {
        elementNumber: 'G.k.2.3.r.1',
        label: t('ICSR:elementName:G.k.2.3.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.2.3.r.1.KR.1a',
        label: t('ICSR:elementName:G.k.2.3.r.1.KR.1a'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.2.3.r.1.KR.1b',
        label: t('ICSR:elementName:G.k.2.3.r.1.KR.1b'),
        component: 'input',
      },
    ],
  },
  'G.k.2.3.r.1.KR.1a': {
    elementNumber: 'G.k.2.3.r.1.KR.1a',
    label: t('ICSR:elementName:G.k.2.3.r.1.KR.1a'),
    component: 'input',
    maxLength: '20',
  },
  'G.k.2.3.r.1.KR.1b': {
    elementNumber: 'G.k.2.3.r.1.KR.1b',
    label: t('ICSR:elementName:G.k.2.3.r.1.KR.1b'),
    maxLength: '10',
  },
  'G.k.4.r.9.2a': {
    elementNumber: 'G.k.4.r.9.2a',
    label: t('ICSR:elementName:G.k.4.r.9.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.9.2b': {
    elementNumber: 'G.k.4.r.9.2b',
    label: t('ICSR:elementName:G.k.4.r.9.2b'),
    component: 'input',
    maxLength: '15',
  },
  'G.k.4.r.10.2a': {
    elementNumber: 'G.k.4.r.10.2a',
    label: t('ICSR:elementName:G.k.4.r.10.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.10.2b': {
    elementNumber: 'G.k.4.r.10.2b',
    label: t('ICSR:elementName:G.k.4.r.10.2b'),
    component: 'input',
    maxLength: '15',
  },
  'G.k.4.r.11.2a': {
    elementNumber: 'G.k.4.r.11.2a',
    label: t('ICSR:elementName:G.k.4.r.11.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.11.2b': {
    elementNumber: 'G.k.4.r.11.2b',
    label: t('ICSR:elementName:G.k.4.r.11.2b'),
    component: 'input',
    maxLength: '15',
  },
  'G.k.9.i.2.r.2.KR.1': {
    elementNumber: 'G.k.9.i.2.r.2.KR.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.2.KR.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.4']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.9.i.2.r.3.KR.1': {
    elementNumber: 'G.k.9.i.2.r.3.KR.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.3.KR.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.5']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['NA'],
    valueLabel: true,
  },
  'G.k.9.i.2.r.3.KR.2': {
    elementNumber: 'G.k.9.i.2.r.3.KR.2',
    label: t('ICSR:elementName:G.k.9.i.2.r.3.KR.2'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.10.1.6']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
});

export default MFDS;
