import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import {
  getReceiver,
  getReceiverDefaultValues,
  putReceiver,
} from 'api/receivers';

import { Receiver } from 'types/receiver';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import ReceiverForm from './ReceiverForm';

function EditReceiver() {
  const { t } = useTranslation();
  const { receiverId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getReceiverDefaultValues(company?.id),
  });

  const { refetch } = useQuery(
    ['getReceiver'],
    () => getReceiver(receiverId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Receiver) {
        setValue([
          { id: data.id },
          { companyId: data.companyId },
          { type: data.type },
          { name: data.name },
          { receiverId: data.receiverId },
          { nsaeSolicited: data.nsaeSolicited },
          { nsaeNonSolicited: data.nsaeNonSolicited },
          { saeSolicited: data.saeSolicited },
          { saeNonSolicited: data.saeNonSolicited },
          { version: data.version },
        ]);
      },
    },
  );

  const { mutate } = useMutation(putReceiver, {
    onSuccess() {
      refetch();
    },
  });

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'name' }, { required: t('error:required') });
    register({ name: 'receiverId' });
    register({ name: 'nsaeSolicited' });
    register({ name: 'nsaeNonSolicited' });
    register({ name: 'saeSolicited' });
    register({ name: 'saeNonSolicited' });
    register({ name: 'version' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Edit Receiver"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <ReceiverForm
        submitLabel="Save"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default EditReceiver;
