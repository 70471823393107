import getQueryString from 'utils/getQueryString';
import client from './client';

export const getRequestApprovals = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/approvals/to-be-approved?companyId=${companyId}&${queryString}`,
  );
};

export const getApprovals = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/approvals?companyId=${companyId}&${queryString}`);
};

export const putApproval = ({
  approvalId,
  approvalLineId,
  data,
}: {
  approvalId?: number | string;
  approvalLineId?: number | string;
  data: any;
}) =>
  client.put(
    `/api/v1/approvals/${approvalId}/approval-lines/${approvalLineId}`,
    data,
  );

export const postApproval = (data: any) =>
  client.post('/api/v1/approvals', data);
