import React, { useEffect, useState } from 'react';
import { TbChevronLeft, TbChevronRight, TbRefresh } from 'react-icons/tb';
import { IoClose } from 'react-icons/io5';
import clsx from 'clsx';
import produce from 'immer';

import Popup from './Popup';
import Button from './Button';
import RadioGroup from './RadioGroup';
import Radio from './Radio';
import Checkbox from './Checkbox';

const PAGE_MAX = 7 as const;

const createButtons = (
  buttons: any,
  start: number,
  end: number,
  page: number,
  onChangePage: any,
) => {
  for (let i = start; i <= end; i++) {
    buttons.push(
      <button
        className={clsx(
          'rounded py-1 px-3 hover:bg-slate-200 dark:hover:bg-slate-600',
          i === page &&
            'pointer-events-none bg-slate-800 text-slate-200 dark:bg-slate-200 dark:text-slate-800',
        )}
        key={i}
        onClick={() => onChangePage(i)}
      >
        {i}
      </button>,
    );
  }
};
const createEllipsisButton = (buttons: any, key: string) => {
  buttons.push(
    <button className="py-1 px-2" key={key} disabled>
      ...
    </button>,
  );
};

interface PaginationProps {
  page?: number;
  perPage?: number;
  lastPage?: number;
  totalElements?: number;
  disabledChangeSize?: boolean;
  showDeletedItem?: 'false';
  onChangePage?: (p: number) => void;
  onChangePerPage?: (p: number) => void;
  setFilter?: (v: (base: any) => any) => void;
  refresh?: () => void;
}
function Pagination({
  page = 1,
  perPage = 10,
  lastPage = 10,
  totalElements = 0,
  disabledChangeSize,
  showDeletedItem,
  onChangePage = () => null,
  onChangePerPage = () => null,
  setFilter,
  refresh,
}: PaginationProps) {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(perPage);
  const toggle = () => setOpen((prev) => !prev);

  const setPageSize = () => {
    onChangePerPage(size);
    toggle();
  };

  const buttons: any[] = [];

  if (lastPage < 1) {
    createButtons(buttons, 1, 1, 1, onChangePage);
  } else if (lastPage <= PAGE_MAX) {
    // 1 2 3 4 5 6 7
    createButtons(buttons, 1, lastPage, page, onChangePage);
  } else if (page < 5) {
    // 1 2 3 4 5 ... 10
    createButtons(buttons, 1, 5, page, onChangePage);
    createEllipsisButton(buttons, 'right');
    createButtons(buttons, lastPage, lastPage, page, onChangePage);
  } else if (lastPage < page + 4) {
    // 1 ... 6 7 8 9 10
    createButtons(buttons, 1, 1, page, onChangePage);
    createEllipsisButton(buttons, 'left');
    createButtons(buttons, lastPage - 4, lastPage, page, onChangePage);
  } else {
    // 1 ... 5 6 7 ... 10
    createButtons(buttons, 1, 1, page, onChangePage);
    createEllipsisButton(buttons, 'left');
    createButtons(buttons, page - 1, page + 1, page, onChangePage);
    createEllipsisButton(buttons, 'right');
    createButtons(buttons, lastPage, lastPage, page, onChangePage);
  }

  useEffect(() => {
    setSize(perPage);
  }, [perPage]);

  return (
    <div className="mx-1 my-4 flex justify-between font-semibold">
      <div
        className={clsx(
          'flex-1',
          setFilter ? 'block' : 'hidden md:invisible md:block',
        )}
      >
        <Checkbox
          className="w-fit"
          checked={!showDeletedItem}
          label="Show deleted items"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (setFilter) {
              setFilter(
                produce((draft) => {
                  draft.deleted = e.target.checked
                    ? undefined
                    : [e.target.checked.toString()];
                }),
              );
            }
          }}
        />
      </div>
      <div className="flex flex-1 items-center justify-center space-x-2">
        <button
          className={clsx(
            'space-x-1 hover:opacity-80',
            disabledChangeSize && 'pointer-events-none',
          )}
          onClick={toggle}
        >
          <span>
            {(page - 1) * perPage + 1}-{Math.min(page * perPage, totalElements)}
          </span>
          <span>of</span>
          <span>{totalElements}</span>
        </button>
        <button onClick={refresh}>
          <TbRefresh className="cursor-pointer transition-transform hover:-rotate-90" />
        </button>
      </div>
      <div className="flex flex-1 items-center justify-end">
        <button
          className="h-full rounded py-1 px-2 hover:bg-slate-200 dark:hover:bg-slate-600"
          disabled={page === 1}
          onClick={() => onChangePage(page - 1)}
        >
          <TbChevronLeft />
        </button>
        {buttons}
        <button
          className="h-full rounded py-1 px-2 hover:bg-slate-200 dark:hover:bg-slate-600"
          disabled={page === lastPage}
          onClick={() => onChangePage(page + 1)}
        >
          <TbChevronRight />
        </button>
      </div>
      <Popup isOpen={open} className="w-48">
        <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
          Page Size
          <button className="hover:opacity-50" onClick={toggle}>
            <IoClose />
          </button>
        </div>
        <div className="mb-2 py-2 ">
          <div>
            <RadioGroup
              className="space-y-2"
              name="control-reason"
              value={String(size)}
              onChange={(val) => val && setSize(Number(val))}
            >
              <Radio value="10" label="10 items" />
              <Radio value="20" label="20 items" />
              <Radio value="50" label="50 items" />
              <Radio value="100" label="100 items" />
              <Radio value="200" label="200 items" />
            </RadioGroup>
          </div>
        </div>
        <div className="space-x-2 py-2 text-right">
          <Button onClick={toggle} color="gray" outline>
            Cancel
          </Button>
          <Button onClick={setPageSize} color="blue">
            Save
          </Button>
        </div>
      </Popup>
    </div>
  );
}

export default React.memo(Pagination);
