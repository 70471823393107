const caseFormDeleted = (root: any): any => {
  try {
    if (Array.isArray(root)) {
      root.forEach((ele: any) => caseFormDeleted(ele));
    } else {
      root.deleted = !root.deleted;
      Object.values(root.elements).forEach((ele: any) => {
        if (Array.isArray(ele)) {
          caseFormDeleted(ele);
        } else {
          ele.deleted = !ele.deleted;
        }
      });
    }
  } catch (error: any) {
    console.error(error);
  }
};

export default caseFormDeleted;
