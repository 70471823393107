import { TFunction } from 'i18next';

const primaryinformation = (codeList: any, t: TFunction) => ({
  'C.2.r': {
    elementNumber: 'C.2.r',
    list: [
      {
        elementNumber: 'C.2.r.1.2',
        label: t('ICSR:elementName:C.2.r.1.2'),
        component: 'input',
      },
      {
        elementNumber: 'C.2.r.1.4',
        label: t('ICSR:elementName:C.2.r.1.4'),
        component: 'input',
      },
      {
        elementNumber: 'C.2.r.2.1',
        label: t('ICSR:elementName:C.2.r.2.1'),
        component: 'input',
      },
      {
        elementNumber: 'C.2.r.4',
        label: t('ICSR:elementName:C.2.r.4'),
        component: 'radio',
        data: codeList['2.16.840.1.113883.3.989.2.1.1.6']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
  },
  'C.2.r.1.1': {
    elementNumber: 'C.2.r.1.1',
    label: t('ICSR:elementName:C.2.r.1.1'),
    component: 'input',
    maxLength: '50',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'C.2.r.1.2': {
    elementNumber: 'C.2.r.1.2',
    label: t('ICSR:elementName:C.2.r.1.2'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.1.3': {
    elementNumber: 'C.2.r.1.3',
    label: t('ICSR:elementName:C.2.r.1.3'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.1.4': {
    elementNumber: 'C.2.r.1.4',
    label: t('ICSR:elementName:C.2.r.1.4'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.1': {
    elementNumber: 'C.2.r.2.1',
    label: t('ICSR:elementName:C.2.r.2.1'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.2': {
    elementNumber: 'C.2.r.2.2',
    label: t('ICSR:elementName:C.2.r.2.2'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.3': {
    elementNumber: 'C.2.r.2.3',
    label: t('ICSR:elementName:C.2.r.2.3'),
    component: 'input',
    maxLength: '100',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.4': {
    elementNumber: 'C.2.r.2.4',
    label: t('ICSR:elementName:C.2.r.2.4'),
    component: 'input',
    maxLength: '35',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.5': {
    elementNumber: 'C.2.r.2.5',
    label: t('ICSR:elementName:C.2.r.2.5'),
    component: 'input',
    maxLength: '40',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.6': {
    elementNumber: 'C.2.r.2.6',
    label: t('ICSR:elementName:C.2.r.2.6'),
    component: 'input',
    maxLength: '15',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.2.7': {
    elementNumber: 'C.2.r.2.7',
    label: t('ICSR:elementName:C.2.r.2.7'),
    component: 'input',
    maxLength: '33',
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'C.2.r.3': {
    elementNumber: 'C.2.r.3',
    label: t('ICSR:elementName:C.2.r.3'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'C.2.r.4': {
    elementNumber: 'C.2.r.4',
    label: t('ICSR:elementName:C.2.r.4'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.6']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK'],
    valueLabel: true,
    required: true,
  },
  'C.2.r.5': {
    elementNumber: 'C.2.r.5',
    label: t('ICSR:elementName:C.2.r.5'),
    component: 'radio',
    options: codeList.CNR_PRIMARY_SOURCE?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
});

export default primaryinformation;
