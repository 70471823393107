import { ICSRFormRootElement } from 'types/case';

export const CaseStructure = {
  identification: 'C.1',
  primaryInformation: 'C.2.r',
  sender: 'C.3',
  literatureReference: 'C.4.r',
  study: 'C.5',
  patientCharacteristics: 'D',
  reactionEvent: 'E.i',
  testResult: 'F.r',
  drug: 'G.k',
  narrative: 'H',
} as const;

export const getCaseForm = (): {
  [key in string]: ICSRFormRootElement | ICSRFormRootElement[];
} => ({
  'C.1': {
    number: 'C.1',
    elements: {},
  },
  'C.2.r': [],
  'C.3': {
    number: 'C.3',
    elements: {},
  },
  'C.4.r': [],
  'C.5': {
    number: 'C.5',
    elements: {},
  },
  'D': {
    number: 'D',
    elements: {},
  },
  'E.i': [],
  'F.r': [],
  'G.k': [],
  'H': {
    number: 'H',
    elements: {},
  },
});
