export const SignalStatus = {
  wait: 'WAIT',
  processing: 'PROCESSING',
  cancel: 'CANCEL',
  error: 'ERROR',
  complete: 'COMPLETE',
} as const;

export interface Signal {
  [key: string]: any;
  id: number;
  companyId: number;
  commandType: string;
  productName: string;
  status: typeof SignalStatus[keyof typeof SignalStatus];
  signalCount: number;
  downloadUrl?: any;
  errorMessage?: any;
  startDate?: string;
  endDate?: string;
  createdDate?: string;
}
