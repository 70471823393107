/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { Moment } from 'moment';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import {
  getCurrentDays,
  getDaysOfWeek,
  getNextDays,
  getPreviousDays,
} from './datePickerUtils';

function Days({
  selectedDate,
  selectedDates,
  focusedDate,
  hoverDate,
  viewDate,
  handleDateClick,
  handleDateHover,
}: {
  selectedDate?: Moment;
  selectedDates?: (Moment | undefined)[];
  focusedDate?: Moment;
  hoverDate?: Moment;
  viewDate: Moment;
  handleDateClick: (e: React.MouseEvent) => void;
  handleDateHover?: (e: React.MouseEvent) => void;
}) {
  const daysOfWeek = getDaysOfWeek();
  const previousDays = getPreviousDays(viewDate);
  const currentDays = getCurrentDays(viewDate);
  const nextDays = getNextDays(viewDate, previousDays, currentDays);

  const getStyles = (date: Moment) => {
    const [startDate, endDate] = selectedDates || [];
    let styles = '';

    if (selectedDate && date.isSame(selectedDate, 'day')) {
      styles = 'bg-brand-600 text-white hover:bg-brand-600';
    }

    if (
      hoverDate &&
      ((startDate && date.isBetween(startDate, hoverDate)) ||
        (endDate && date.isBetween(hoverDate, endDate)))
    ) {
      styles = 'bg-gray-100';
    }

    if (startDate && endDate && date.isBetween(startDate, endDate)) {
      styles =
        'bg-brand-600/20 hover:bg-brand-600/40 dark:bg-brand-600/60 dark:hover:bg-brand-600/80';
    }

    if (
      (startDate && date.isSame(startDate, 'day')) ||
      (endDate && date.isSame(endDate, 'day'))
    ) {
      styles = 'bg-brand-600 text-white hover:bg-brand-600';
    }

    return styles;
  };

  return (
    <div className="flex">
      <div>
        <div className="mb-1 grid grid-cols-7">
          {daysOfWeek.map((day) => (
            <span
              key={day}
              className="h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
            >
              {day}
            </span>
          ))}
        </div>
        <div className="grid w-64 grid-cols-7">
          {previousDays.map((date: Moment) => (
            <button
              key={date.valueOf()}
              className={twMerge(
                clsx(
                  'dark:text-white/ box-content block flex-1 cursor-pointer rounded-full border border-slate-800/0 text-center text-sm font-semibold leading-9 text-gray-900 opacity-60 hover:bg-gray-100 dark:hover:bg-gray-600',
                  date.isSame(focusedDate, 'day') &&
                    'border-slate-800 bg-gray-100',
                  getStyles(date),
                ),
              )}
              data-date={date.valueOf()}
              onClick={handleDateClick}
              onMouseOver={handleDateHover}
            >
              {date.date()}
            </button>
          ))}
          {currentDays.map((date: Moment) => (
            <button
              key={date.valueOf()}
              className={twMerge(
                clsx(
                  'box-content block flex-1 cursor-pointer rounded-full border border-slate-800/0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-600',
                  date.isSame(focusedDate, 'day') &&
                    'border-slate-800 bg-gray-200',
                  getStyles(date),
                ),
              )}
              data-date={date.valueOf()}
              onClick={handleDateClick}
              onMouseOver={handleDateHover}
            >
              {date.date()}
            </button>
          ))}
          {nextDays.map((date: Moment) => (
            <button
              key={date.valueOf()}
              className={twMerge(
                clsx(
                  'box-content block flex-1 cursor-pointer rounded-full border border-slate-800/0 text-center text-sm font-semibold leading-9 text-gray-900 opacity-60 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600',
                  date.isSame(focusedDate, 'day') &&
                    ' border-slate-800 bg-gray-100',
                  getStyles(date),
                ),
              )}
              data-date={date.valueOf()}
              onClick={handleDateClick}
              onMouseOver={handleDateHover}
            >
              {date.date()}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Days;
