import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { clsx } from 'clsx';

import { login } from 'api/auth';
import useForm from 'hooks/useForm';
import storage from 'utils/storage';
import tokenTimer from 'utils/tokenTimer';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';

function LoginPage() {
  const navigate = useNavigate();
  const rememberRef = useRef<HTMLInputElement>(null);

  const { form, setValue, handleSubmit } = useForm({
    defaultValues: {
      username: (storage.getItem('csafer_user_name') as string) || '',
      password: '',
    },
  });

  const { mutate, error } = useMutation(login, {
    onSuccess({ data: auth }, variables) {
      if (rememberRef.current?.checked) {
        storage.setItem('csafer_user_name', variables.username);
      } else {
        storage.removeItem('csafer_user_name');
      }
      tokenTimer.set(auth.expires_in);
      navigate('/select-company');
    },
    onError(err: AxiosError<any>) {
      return err;
    },
    onMutate() {},
    onSettled() {},
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onSubmit = handleSubmit(async (data) => mutate(data));

  return (
    <div className="flex h-[var(--mainHeight)] min-h-[600px] w-screen flex-col items-center justify-center bg-slate-200 dark:bg-slate-900">
      <div className="flex max-w-min rounded-lg bg-white p-10 shadow-lg dark:bg-slate-800">
        <form className="w-[380px]" onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center">
            <div className="mb-4 h-16 w-16 bg-icon bg-cover" />
            <h1 className="mb-8 text-2xl font-bold dark:text-white">
              Sign in to your account
            </h1>
          </div>
          <div className="mb-4">
            <label className="block pb-1 font-semibold dark:text-white">
              Email address
            </label>
            <Input
              className="w-full"
              type="text"
              name="username"
              value={form.username}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block pb-1 font-semibold dark:text-white">
              Password
            </label>
            <Input
              className="w-full"
              type="password"
              name="password"
              value={form.password}
              onChange={handleChange}
            />
          </div>
          <div className="text-left">
            <span
              className={clsx(
                error ? 'block' : 'hidden',
                'mb-4 text-sm text-red-600',
              )}
            >
              {error?.response?.data?.message || error?.message}
            </span>
          </div>
          <div className="flex items-center pb-6">
            <div className="flex flex-1 items-center">
              <Checkbox
                ref={rememberRef}
                color="slate"
                label="Remember Me"
                defaultChecked={!!storage.getItem('csafer_user_name')}
              />
            </div>
            <Link
              to="/forgot-password"
              className="font-semibold text-brand-600 hover:text-brand-400"
            >
              Forgot Password?
            </Link>
          </div>
          <div>
            <Button className="w-full" type="submit">
              Log in
            </Button>
          </div>
        </form>
      </div>
      <div className="mt-4 text-slate-500 opacity-80">
        Copyright &copy; C&R Research
      </div>
    </div>
  );
}

export default LoginPage;
