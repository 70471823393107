import React from 'react';
import clsx from 'clsx';

import {
  ApprovalAction,
  ApprovalLine,
  ApprovalLineStatus,
} from 'types/approval';

const statusStyles = {
  [ApprovalLineStatus.WAIT]: 'opacity-60 pointer-events-none',
  [ApprovalLineStatus.PROGRESS]: 'cursor-default',
  [ApprovalLineStatus.COMPLETE]: 'pointer-events-none [&>span]:opacity-60',
};

function Sign({ line }: { line: ApprovalLine }) {
  return (
    <div
      className={clsx(
        'relative mt-0.5 h-14 w-14 border border-gray-600 text-center text-5xl text-gray-600',
        statusStyles[line.status],
      )}
    >
      <span>S</span>
      {line.action === ApprovalAction.APPROVE && (
        <div className="absolute top-0.5 left-0.5 h-[50px] w-[50px] rounded-full border border-red-600 text-center text-[0.625rem] leading-[48px] text-red-600">
          <span className="inline-block align-middle leading-normal">
            {line.approverName}
            <br />
            {line.actionDate.split('T')[0].replace(/-/gi, '.')}
          </span>
        </div>
      )}
    </div>
  );
}

function SignBlock({ lines }: { lines: ApprovalLine[] }) {
  return (
    <div className="flex items-center justify-start space-x-1">
      {lines.length > 1 && <Sign line={lines[1]} />}
      {lines.length > 2 && <Sign line={lines[2]} />}
    </div>
  );
}

export default SignBlock;
