import { useLocation, useNavigate } from 'react-router-dom';

function useGoBack() {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('..', { relative: 'path' });
    }
  };
}

export default useGoBack;
