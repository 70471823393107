/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import TypeModify from 'utils/typeModify';

type TabsProps = TypeModify<
  HTMLAttributes<HTMLDivElement>,
  {
    value?: number | string;
    onChange?: (v: number) => void;
    children?: React.ReactNode;
  }
>;

export function Tabs({
  className,
  value,
  onChange,
  children,
  ...rest
}: TabsProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={twMerge(clsx('z-10 -mb-px space-x-4', className))}
      role="tablist"
      {...rest}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          return React.cloneElement(item, {
            value,
            onChange,
          });
        }
        return child;
      })}
    </div>
  );
}

type TabProps = TypeModify<
  HTMLAttributes<HTMLButtonElement>,
  {
    tabpanelId?: string;
    index: number | string;
    value?: number | string;
    onChange?: (v: number | string) => void;
    children?: React.ReactNode;
  }
>;

export function Tab({
  className,
  tabpanelId,
  index,
  value,
  onChange,
  children,
  ...rest
}: TabProps) {
  const handleClick = () => onChange && onChange(index);
  const active = value === index;

  return (
    <button
      className={twMerge(
        clsx(
          'group inline-block transition-colors hover:text-brand-600 aria-selected:font-semibold aria-selected:text-brand-600',
          className,
        ),
      )}
      role="tab"
      type="button"
      tabIndex={active ? 0 : -1}
      aria-selected={active}
      aria-controls={tabpanelId}
      onClick={handleClick}
      {...rest}
    >
      {children}
      <div className="tab-navigator pointer-events-none mt-1 h-1 w-full rounded-sm transition-colors group-aria-selected:bg-brand-600" />
    </button>
  );
}

type TabPanelProps = TypeModify<
  HTMLAttributes<HTMLDivElement>,
  {
    value: number | string;
    index: number | string;
    children?: React.ReactNode;
  }
>;

export function TabPanel({
  className,
  value,
  index,
  children,
  ...rest
}: TabPanelProps) {
  return value === index ? (
    <div
      className={clsx(value === index ? 'block' : 'hidden', className)}
      role="tabpanel"
      {...rest}
    >
      {children}
    </div>
  ) : null;
}
