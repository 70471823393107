import React, { useCallback, useState } from 'react';
import { TbAlertCircle, TbDownload, TbInfoSquare } from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { getSubmissions } from 'api/submission';

import { companyState, coreState } from 'state';
import useList from 'hooks/useList';
import { ACKCode, Submission, SubmissionStatus } from 'types/submission';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Download from 'components/common/Donwload';
import Tooltip from 'components/common/Tooltip';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import SubmissionPopup from './SubmissionPopup';

const listHeads: ListHead[] = [
  {
    name: 'submissionNo',
    label: 'Submission No.',
    sort: true,
    style: { width: '10rem' },
  },
  {
    name: 'submissionDate',
    label: 'Submission Date',
    sort: true,
    style: { width: '12rem' },
  },
  {
    name: 'processingDate',
    label: 'Processing Date',
    sort: true,
    style: { width: '12rem' },
  },
  {
    name: 'acknowledgementBatchNumber',
    label: 'ACK ID',
    sort: true,
    style: { width: '10rem' },
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    type: 'select',
    data: Object.entries(SubmissionStatus).map(([key, value]) => ({
      key,
      value,
    })),
    valueField: 'value',
    textField: 'value',
    width: '16rem',
    style: { width: '12rem' },
  },
  {
    name: 'submitterName',
    label: 'Submitter',
    sort: true,
    style: { width: '8rem' },
  },
  {
    name: 'transmissionAcknowledgementCode',
    label: 'ACK Code',
    sort: true,
    filter: true,
    type: 'select',
    data: Object.entries(ACKCode).map(([key, value]) => ({ key, value })),
    valueField: 'value',
    textField: 'value',
    style: { width: '8rem' },
  },
  {
    name: 'message',
    label: 'ACK Message',
    style: { width: '5rem', textAlign: 'center' },
  },
  {
    name: 'commitAcceptCount',
    label: 'Success Reports',
    style: { width: '5rem', textAlign: 'center' },
  },
  {
    name: 'commitRejectCount',
    label: 'Failure Reports',
    style: { width: '5rem', textAlign: 'center' },
  },
  {
    name: 'detail',
    label: 'Detail',
    style: { width: '5rem', textAlign: 'center' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'submissionNo', label: 'Submission No.' }],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function SubmissionList() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selected, setSelected] = useState<number>();

  const [{ region }] = useRecoilState(coreState);
  const [{ selected: company }] = useRecoilState(companyState);

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    refreshKey: region,
    queryKey: 'submission',
    queryFn: (q) => getSubmissions({ companyId: company?.id, query: q }),
  });

  const toggle = (id?: number) => {
    setSelected(id);
    setPopupOpen((prev) => !prev);
  };

  const cb = useCallback(
    (
      { status, comment, id }: Partial<Submission>,
      companyId?: number | string,
    ) => {
      if (status === SubmissionStatus.processingFailed) {
        return (
          <Tooltip place="left" content={comment}>
            <TbAlertCircle className="mx-auto text-red-500" />
          </Tooltip>
        );
      }

      if (status === SubmissionStatus.processingCompeleted) {
        return (
          <Download
            src={`${process.env.REACT_APP_API_HOST}/api/v1/submissions/${id}/ack-xml?companyId=${companyId}`}
            className="hover:opacity-50"
          >
            <TbDownload className="mx-auto" />
          </Download>
        );
      }

      return null;
    },
    [],
  );

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
            disableCheckbox
          >
            {data?.map(
              ({
                id,
                submissionNo,
                acknowledgementBatchNumber,
                submissionDate,
                submitterName,
                processingDate,
                status,
                commitAcceptCount,
                commitRejectCount,
                transmissionAcknowledgementCode,
                comment,
              }: Submission) => (
                <LTR key={id} type="body">
                  <LTD>{submissionNo}</LTD>
                  <LTD>{submissionDate}</LTD>
                  <LTD>{processingDate}</LTD>
                  <LTD>{acknowledgementBatchNumber}</LTD>
                  <LTD>{status}</LTD>
                  <LTD>{submitterName}</LTD>
                  <LTD>{transmissionAcknowledgementCode}</LTD>
                  <LTD>{cb({ status, comment, id }, company?.id)}</LTD>
                  <LTD className="text-center">{commitAcceptCount}</LTD>
                  <LTD className="text-center">{commitRejectCount}</LTD>
                  <LTD className="text-center">
                    <button onClick={() => toggle(id)}>
                      <TbInfoSquare className="mt-1 hover:opacity-50" />
                    </button>
                  </LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
      <SubmissionPopup
        isOpen={popupOpen}
        submissionId={selected}
        toggle={toggle}
      />
    </>
  );
}

export default SubmissionList;
