import { Notification } from 'types/notification';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getNotificationDefaultValues = (): Notification => ({
  subject: '',
  contents: '',
  visible: true,
  deleted: false,
  version: 0,
});

export const getNotifications = ({ query }: { query?: any }) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/notifications?${queryString}`);
};

export const getNotification = (notificationId?: number | string) =>
  client.get(`/api/v1/notifications/${notificationId}`);

export const postNotification = (data: any) =>
  client.post('/api/v1/notifications', data);

export const putNotification = ({
  notificationId,
  data,
}: {
  notificationId?: number | string;
  data: any;
}) => client.put(`/api/v1/notifications/${notificationId}`, data);

export const deleteNotifications = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/notifications?${queryString}`);
};
