import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';

import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';
import modal from 'utils/modal';

import Title from 'components/case/Title';
import RootRepeatElement from 'components/case/RootRepeatElement';
import CaseFormElement from 'components/case/CaseFormElement';
import SubTitle from 'components/case/SubTitle';
import Space from 'components/case/Space';

function ReactionEvent({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (
      number: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const disableDelete = useCallback(
    (f: ICSRFormRootElement) => {
      let disable = false;
      const reactionEvent = (f?.elements['E.i.CNR.1'] as ICSRFormElement)
        ?.value;
      (form['G.k'] as ICSRFormRootElement[]).forEach((drug: any) =>
        drug?.elements['G.k.9.i']?.forEach((assessed: any) => {
          if (
            assessed?.elements['G.k.9.i.1']?.value === reactionEvent &&
            !assessed.deleted
          ) {
            disable = true;
          }
        }),
      );

      if (disable)
        modal.alert(
          t('modal:alert:title'),
          t('modal:alert:impossibleDeleteReactionEvent'),
        );

      return disable;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form['G.k'], t],
  );

  return (
    <>
      <Title number="E.i" />

      <RootRepeatElement
        number="E.i"
        version={version}
        element={form['E.i'] as ICSRFormRootElement[]}
        disabled={disabled}
        onChange={handleChange}
        disableDelete={disableDelete}
      >
        <CaseFormElement number="E.i.1.1a" />
        <CaseFormElement number="E.i.1.1b" />
        <CaseFormElement number="E.i.1.2" />
        <CaseFormElement number="E.i.3.1" />
        <CaseFormElement number="E.i.2.1a" />
        <CaseFormElement number="E.i.2.1b" valueToVersion="E.i.2.1a" />

        <Space />

        <SubTitle number="E.i.3.2" />
        <CaseFormElement number="E.i.3.2a" />
        <CaseFormElement number="E.i.3.2b" />
        <CaseFormElement number="E.i.3.2c" />
        <CaseFormElement number="E.i.3.2d" />
        <CaseFormElement number="E.i.3.2e" />
        <CaseFormElement number="E.i.3.2f" />
        <CaseFormElement number="E.i.3.2.CNR.1" />
        <CaseFormElement number="E.i.3.2.CNR.2" />

        <Space />

        <CaseFormElement number="E.i.4" />
        <CaseFormElement number="E.i.5" />
        <CaseFormElement number="E.i.6a" />
        <CaseFormElement number="E.i.6b" />
        <CaseFormElement number="E.i.7" />
        <CaseFormElement number="E.i.8" />
        <CaseFormElement number="E.i.9" />
      </RootRepeatElement>
    </>
  );
}

export default ReactionEvent;
