import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';
import { Region } from 'types/core';

import { IcsrStructure } from 'utils/E2BR3';

import { Tab, Tabs } from 'components/common/Tab';

import { useCaseState } from './context/CaseContext';

import MFDS from './form/MFDS';
import EMA from './form/EMA';

const regionComponent = {
  MFDS,
  EMA,
};

function CaseForm({
  form,
  version,
  region,
  disabled,
  setForm,
  handleVersion,
}: {
  form: ICSRForm;
  version: number;
  region: Region;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const { validationMessages, memoMessages } = useCaseState();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = useCallback(
    (value: number) => {
      if (tabValue !== value) scrollRef.current?.scrollTo(0, 0);
      setTabValue(value);
    },
    [tabValue],
  );

  const tabList = useMemo(
    () =>
      Object.entries(IcsrStructure).map(([key, value]) => ({
        label: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
        name: key,
        validationCount: validationMessages[value]?.count || 0,
        memoCount: memoMessages[value]?.count || 0,
      })),
    [memoMessages, validationMessages],
  );

  const primaryReporterCountry = useMemo(() => {
    const primaryReporter = (form['C.2.r'] as ICSRFormRootElement[])?.find(
      (PI: any) => {
        if (PI?.elements['C.2.r.5']?.value === '1') return true;
        return false;
      },
    );
    return (primaryReporter?.elements['C.2.r.3'] as ICSRFormElement)?.value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form['C.2.r']]);

  const sponsorStudyNumber = useMemo(
    () => {
      const study = form['C.5'] as ICSRFormRootElement;
      const ssNumber = (study?.elements['C.5.3'] as ICSRFormElement)?.value;
      return ssNumber;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form['C.5']],
  );

  const receiverType = useMemo(
    () =>
      (
        (form['C.1'] as ICSRFormRootElement)?.elements[
          'C.1.CNR.1'
        ] as ICSRFormElement
      )?.value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form['C.1']],
  );

  const linkedStudy = useMemo(
    () =>
      (
        (form['C.5'] as ICSRFormRootElement)?.elements[
          'C.5.2'
        ] as ICSRFormElement
      )?.value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form['C.5']],
  );

  const Component = regionComponent[region];

  return (
    <div className="relative z-0 flex flex-1 space-x-2 overflow-hidden">
      <Tabs
        className="flex h-full w-fit max-w-[12rem] flex-col space-x-0 space-y-2 overflow-y-auto bg-white p-2"
        onChange={handleChangeTab}
      >
        {tabList.map(({ label, validationCount, memoCount }, index) => (
          <Tab
            key={label}
            className={clsx(
              'relative bg-slate-50/50 py-2 px-1 shadow [&>.tab-navigator]:hidden',
              tabValue === index &&
                'bg-brand-200/50 font-semibold text-brand-600',
            )}
            index={index}
          >
            {label}
            <div className="absolute -top-2 -left-2 space-x-px">
              {validationCount ? (
                <div className="inline-block rounded-full bg-red-500 px-2 text-xs text-white">
                  {validationCount}
                </div>
              ) : null}
              {memoCount ? (
                <div className="inline-block rounded-full bg-yellow-500 px-2 text-xs text-white">
                  {memoCount}
                </div>
              ) : null}
            </div>
          </Tab>
        ))}
      </Tabs>
      <div
        ref={scrollRef}
        className="dropdown-container flex flex-1 flex-wrap overflow-auto bg-white p-2 xl:p-4"
      >
        <div className="w-full min-w-[1024px]">
          <Component
            tabValue={tabValue}
            form={form}
            primaryReporterCountry={primaryReporterCountry}
            sponsorStudyNumber={sponsorStudyNumber}
            receiverType={receiverType}
            linkedStudy={linkedStudy}
            version={version}
            handleVersion={handleVersion}
            disabled={disabled}
            setForm={setForm}
          />
        </div>
      </div>
    </div>
  );
}

export default CaseForm;
