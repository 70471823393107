import getQueryString from 'utils/getQueryString';
import { Member } from 'types/member';
import client from './client';

export const getMemberDefaultValues = (companyId = -1): Member => ({
  companyId,
  userName: '',
  email: '',
  phone: '',
  startDate: '',
  endDate: '',
  managerFlag: 0,
  productIdList: [],
  studyIdList: [],
});

export const getMembers = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/members?companyId=${companyId}&${queryString}`);
};

export const getMember = (memberId?: number | string) =>
  client.get(`/api/v1/members/${memberId}`);

export const getMemberByUserId = ({
  companyId,
  userId,
}: {
  companyId?: number | string;
  userId?: number | string;
}) => client.get(`/api/v1/members/companies/${companyId}/users/${userId}`);

export const postMember = (data: any) => client.post('/api/v1/members', data);

export const putMember = ({
  memberId,
  data,
}: {
  memberId?: number | string;
  data: any;
}) => client.put(`/api/v1/members/${memberId}`, data);

export const checkEmail = ({
  companyId,
  email,
}: {
  companyId?: number | string;
  email: string;
}) => client.get(`/api/v1/members/email/${email}?companyId=${companyId}`);
