import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import produce from 'immer';

import { Notification } from 'types/notification';

function Notice({ data }: { data: Notification[] }) {
  const location = useLocation();
  const [cookies, setCookie] = useCookies();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const handleClose = useCallback(
    (notificationId: number | undefined, invisible?: boolean) => {
      setNotifications(
        produce((draft) => {
          const target = draft.find(
            ({ id }: Notification) => notificationId === id,
          );
          if (target) target.visible = false;
        }),
      );
      if (invisible) {
        setCookie(`csafer_noticeId_${notificationId}`, 'no', {
          maxAge: 2592000,
        });
      }
    },
    [setCookie],
  );

  useEffect(() => {
    if (data) setNotifications(data);
  }, [data]);

  if (/(^\/.*\/home)/.test(location.pathname)) {
    return (
      <div className="fixed top-0 left-0 z-popup space-x-2 space-y-2">
        {notifications.map(
          ({ id, visible, subject, contents }) =>
            visible &&
            cookies[`csafer_noticeId_${id}`] !== 'no' && (
              <div
                key={id}
                className="relative top-5 left-5 inline-flex h-[380px] w-[460px] flex-col border bg-slate-500 drop-shadow-lg"
              >
                <h3 className="mt-0 bg-brand-500 px-[16px] py-[6px] text-sm font-semibold text-white">
                  {subject}
                </h3>
                <div className="flex-1 overflow-auto bg-slate-50">
                  <div
                    className="w-fit px-[18px] py-[16px] text-xs"
                    dangerouslySetInnerHTML={{ __html: contents }}
                  />
                </div>
                <div className="flex justify-between px-[16px] py-[6px] text-xs text-slate-100">
                  <label className="m-0">
                    <input
                      className="mr-2"
                      type="checkbox"
                      onChange={() => handleClose(id, true)}
                    />
                    다시보지않기
                  </label>
                  <button type="button" onClick={() => handleClose(id)}>
                    닫기
                  </button>
                </div>
              </div>
            ),
        )}
      </div>
    );
  }

  return null;
}

export default React.memo(Notice);
