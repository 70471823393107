import React from 'react';
import clsx from 'clsx';

import SidebarUser from './SidebarUser';
import SidebarNav from './SidebarNav';
import SidebarNotifications from './SidebarNotifications';
import SidebarHelp from './SidebarHelp';
import SidebarCollapse from './SidebarCollapse';

function Sidebar() {
  return (
    <div
      className={clsx(
        'group z-40 flex w-[var(--sidebarWidth)] flex-col whitespace-nowrap border-r border-slate-200 bg-white transition-[width]',
        'dark:bg-slate-900',
      )}
    >
      <SidebarUser />
      <SidebarNav />
      <SidebarNotifications />
      <SidebarHelp />
      <SidebarCollapse />
    </div>
  );
}

export default React.memo(Sidebar);
