import React, { Fragment, useState } from 'react';
import { TbDownload, TbInfoSquare } from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { getHistories } from 'api/history';

import { companyState } from 'state';
import useList from 'hooks/useList';
import { Export, ExportTypes, HistoryStatus } from 'types/history';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Download from 'components/common/Donwload';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import HistoryPopup from './HistoryPopup';

const listHeads: ListHead[] = [
  {
    name: 'fileName',
    label: 'File Name',
    sort: true,
    style: { width: '24rem' },
  },
  {
    name: 'exportType',
    label: 'Type',
    sort: true,
    filter: true,
    type: 'select',
    data: Object.entries(ExportTypes).map(([key, value]) => ({ key, value })),
    valueField: 'key',
    textField: 'value',
    width: '12.5rem',
    style: { width: '12rem' },
  },
  {
    name: 'createdDate',
    label: 'Created Date',
    sort: true,
    style: { width: '8rem' },
  },
  {
    name: 'exportUser',
    label: 'User',
    sort: true,
    style: { width: '8rem' },
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    type: 'select',
    data: Object.entries(HistoryStatus).map(([key, value]) => ({ key, value })),
    valueField: 'key',
    textField: 'value',
    width: '10rem',
    style: { width: '8rem' },
  },
  {
    name: 'downloadUrl',
    label: 'Data File',
    style: { width: '6rem', textAlign: 'center' },
  },
  {
    name: 'detail',
    label: 'Detail',
    style: { width: '5rem', textAlign: 'center' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'fileName', label: 'File Name' }],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function ExportList() {
  const type = 'export' as const;
  const [popupOpen, setPopupOpen] = useState(false);
  const [selected, setSelected] = useState<number>();

  const [{ selected: company }] = useRecoilState(companyState);

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    queryKey: 'exports',
    queryFn: (q) => getHistories({ companyId: company?.id, type, query: q }),
  });

  const toggle = (id?: number) => {
    setSelected(id);
    setPopupOpen((prev) => !prev);
  };

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
            disableCheckbox
          >
            {data?.map(
              ({
                id,
                fileName,
                exportType,
                createdDate,
                exportUser,
                status,
                downloadUrl,
              }: Export) => (
                <LTR key={id} type="body">
                  <LTD>{fileName}</LTD>
                  <LTD>{exportType}</LTD>
                  <LTD>{createdDate}</LTD>
                  <LTD>{exportUser}</LTD>
                  <LTD>
                    {
                      listHeads
                        .find(({ name }) => name === 'status')
                        ?.data?.find(({ key }) => key === status)?.value
                    }
                  </LTD>
                  <LTD>
                    {downloadUrl && (
                      <Download src={downloadUrl} className="hover:opacity-50">
                        <TbDownload className="mx-auto" />
                      </Download>
                    )}
                  </LTD>
                  <LTD className="text-center">
                    <button onClick={() => toggle(id)}>
                      <TbInfoSquare className="mt-1 hover:opacity-50" />
                    </button>
                  </LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
      <HistoryPopup
        isOpen={popupOpen}
        type={type}
        historyId={selected}
        toggle={toggle}
      />
    </>
  );
}

export default ExportList;
