import React, { InputHTMLAttributes, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

type props = TypeModify<
  InputHTMLAttributes<HTMLInputElement>,
  {
    value?: string | FileList;
    size?: SizeType;
    error?: boolean;
    onChange?: (files: FileList | null) => void;
  }
>;
const sizeStyles: { [key in SizeType]: string } = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};

function FileUpload({
  size = 'md',
  className,
  value,
  accept,
  onChange = () => null,
  multiple,
  disabled,
}: props) {
  const fileRef = useRef<HTMLInputElement>(null);

  const fileName: string = useMemo(() => {
    if (typeof value === 'string') return value;
    if (value?.length) {
      return value.length > 1 ? `${value.length} files` : value[0].name;
    }
    if (fileRef.current) fileRef.current.value = '';
    return '';
  }, [value]);

  const handleFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.files);

  return (
    <button
      type="button"
      className={twMerge(clsx('flex items-stretch', className))}
      onClick={() => {
        if (fileRef.current) {
          fileRef.current.click();
        }
      }}
      disabled={disabled}
    >
      <div className="flex items-center rounded-l bg-slate-500 px-2 text-white">
        SELECT
      </div>
      <input
        className={clsx('flex-1 rounded-none rounded-r', sizeStyles[size])}
        value={fileName}
        disabled={disabled}
        readOnly
      />
      <input
        type="file"
        className="hidden"
        ref={fileRef}
        accept={accept}
        multiple={multiple}
        onChange={handleFileUploadChange}
      />
    </button>
  );
}

export default FileUpload;
