import React from 'react';
import { IoClose } from 'react-icons/io5';

import useListQuery from 'hooks/useListQuery';
import { getAudits } from 'api/audits';
import { Audit } from 'types/audit';

import Popup from 'components/common/Popup';
import Pagination from 'components/common/Pagination';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

import { useCaseState } from '../context/CaseContext';

function AuditTrailPopup({
  isOpen,
  elementId,
  elementNumber,
  translate,
  toggle,
}: {
  isOpen: boolean;
  elementId?: number;
  elementNumber?: string;
  translate?: boolean;
  toggle: () => void;
}) {
  const { caseId } = useCaseState();
  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    refetch,
    changePage,
  } = useListQuery({
    queryKey: 'auditTrail',
    queryFn: (q) => getAudits({ caseId, elementId, translate, query: q }),
    options: {
      enabled: isOpen,
    },
  });

  return (
    <Popup isOpen={isOpen} className="max-h-[35rem] w-[70rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        Audit Trail
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-1">Element Number - {elementNumber}</div>
      <div className="overflow-auto border-t border-b bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              <LTH>Entry Date</LTH>
              <LTH>Entry User</LTH>
              <LTH>Value</LTH>
              <LTH>reason</LTH>
              <LTH>revisionType</LTH>
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={5}
            disableCheckbox
          >
            {data?.map(
              (
                {
                  modifiedDate,
                  modifiedBy,
                  description,
                  reason,
                  revisionType,
                }: Audit,
                idx: number,
              ) => (
                <LTR key={idx} type="body">
                  <LTD>{modifiedDate}</LTD>
                  <LTD>{modifiedBy}</LTD>
                  <LTD>{description}</LTD>
                  <LTD>{reason}</LTD>
                  <LTD>{revisionType}</LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Pagination
          page={query.page}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          refresh={refetch}
          disabledChangeSize
        />
      </div>
    </Popup>
  );
}

export default AuditTrailPopup;
