import React from 'react';
import { Outlet } from 'react-router-dom';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

const tabList = [
  { label: 'Senders', to: 'senders' },
  { label: 'Receivers', to: 'receivers' },
  { label: 'Products', to: 'products' },
  { label: 'Studies', to: 'studies' },
  { label: 'Reporters', to: 'reporters' },
];

function Data() {
  return (
    <MainTemplate>
      <Header title="Data" tabList={tabList} />
      <Content className="flex flex-col overflow-hidden pt-1">
        <Outlet />
      </Content>
    </MainTemplate>
  );
}

export default Data;
