import getQueryString from 'utils/getQueryString';
import { User } from 'types/user';
import client from './client';

export const getUserDefaultValues = (): User => ({
  userName: '',
  email: '',
  phone: '',
  startDate: '',
  endDate: '',
  registDate: '',
  managerFlag: 0,
  userType: '',
  editableEdc: false,
  deleted: false,
});

export const getUsers = ({ query }: { query?: any }) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/general/users?${queryString}`);
};

export const getUser = (userId?: number | string) =>
  client.get(`/api/v1/general/users/${userId}`);

export const postUser = (data: any) =>
  client.post('/api/v1/general/users', data);

export const putUser = ({
  userId,
  data,
}: {
  userId?: number | string;
  data: any;
}) => client.put(`/api/v1/general/users/${userId}`, data);

export const checkEmail = (email: string) =>
  client.get(`/api/v1/general/users/email/${email}`);

export const getUserByToken = (token: string) =>
  client.get(`/api/v1/general/tokens/${token}`);

export const resendRegistEmail = (userId?: number | string) =>
  client.put(`/api/v1/general/users/${userId}/token`);
