import { useMutation } from '@tanstack/react-query';
import { updateToken } from 'api/auth';
import React, { useEffect, useState } from 'react';
import { TbClock } from 'react-icons/tb';

import tokenTimer from 'utils/tokenTimer';
import Button from './common/Button';

function calcRemainTime() {
  const { expireTime } = tokenTimer;
  const remainTime = Math.ceil((expireTime - new Date().valueOf()) / 1000);
  return remainTime > 0 ? remainTime : 0;
}

function timeToString(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = time - hours * 3600 - minutes * 60;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
}

function Timer() {
  const [time, setTime] = useState(0);

  const { mutate } = useMutation(updateToken, {
    onSuccess({ data }) {
      tokenTimer.set(data.expires_in);
    },
    onMutate() {},
    onSettled() {},
  });

  useEffect(() => {
    setTime(calcRemainTime());
    const interval = setInterval(() => {
      const remainTime = calcRemainTime();
      setTime(remainTime);
      if (remainTime <= 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Button
      size="sm"
      color="slate"
      className="flex items-center"
      onClick={() => mutate()}
      outline
    >
      <TbClock className="text-slate-500" />
      <span className="inline-block w-10 text-right font-medium text-slate-500">
        {timeToString(time)}
      </span>
    </Button>
  );
}

export default Timer;
