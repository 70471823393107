import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { getMember, getMemberDefaultValues, putMember } from 'api/members';
import { getProducts } from 'api/products';
import { getStudies } from 'api/studies';
import { getRoles } from 'api/roles';
import { resendRegistEmail } from 'api/users';

import { RoleType } from 'types/role';
import { Member } from 'types/member';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import MemberForm from './MemberForm';

function EditMember() {
  const { t } = useTranslation();
  const { memberId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getMemberDefaultValues(company?.id),
  });

  useQuery(['getMember'], () => getMember(memberId).then(({ data }) => data), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess(data: Member) {
      setValue([
        { id: data.id },
        { companyId: data.companyId },
        { userId: data.userId },
        { email: data.email },
        { userName: data.userName },
        { phone: data.phone },
        { startDate: data.startDate },
        { endDate: data.endDate },
        { roleId: data.roleId },
        { productIdList: data.productIdList },
        { studyIdList: data.studyIdList },
      ]);
    },
  });

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );
  const { data: studies } = useQuery(['getStudies'], () =>
    getStudies({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );
  const { data: roles } = useQuery(['getRoles'], () =>
    getRoles({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(resendRegistEmail);

  const { mutate: submit } = useMutation(putMember);

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const resendEmail = () => mutate(form.userId);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      submit({ memberId, data });
    }
  });

  const filterdRoles = useMemo(
    () => roles?.filter((role: any) => role.type !== RoleType.ADMIN),
    [roles],
  );

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' }, { required: t('error:required') });
    register(
      { name: 'email' },
      {
        required: t('error:required'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: t('error:invalidEmail'),
        },
      },
    );
    register({ name: 'userName' }, { required: t('error:required') });
    register({ name: 'phone' }, { required: t('error:required') });
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' }, { required: t('error:required') });
    register({ name: 'roleId' }, { required: t('error:required') });
    register({ name: 'productIdList' });
    register({ name: 'studyIdList' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Edit Member"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <MemberForm
        submitLabel="Save"
        form={form}
        roles={filterdRoles || []}
        products={products || []}
        studies={studies || []}
        errors={errors}
        resendEmail={resendEmail}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default EditMember;
