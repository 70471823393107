import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import clsx from 'clsx';

import { DatasetRuleType } from 'types/dataset';

import InlineFunction from 'components/common/InlineFunction';

interface Props {
  rule?: DatasetRuleType;
  sequence: number;
  setRule: (rule: DatasetRuleType) => void;
}
function ElementRuleTable({ rule, sequence, setRule }: Props) {
  const { t } = useTranslation();
  const EDCMappingFunctions = useMemo(
    () => [
      {
        value: '#SUBJECTSEQ',
        description: `#SUBJECTSEQ : ${t('edcMappingFunction:SUBJECT')}`,
      },
      {
        value: '#CONCAT()',
        description: `#CONCAT('1','2') : ${t('edcMappingFunction:CONCAT')}`,
      },
      {
        value: '#ITEM()',
        description: `#ITEM(1) : ${t('edcMappingFunction:ITEM')}`,
      },
      {
        value: '#NOW()',
        description: `#NOW('yyyyMMddHHmmssZ') : ${t('edcMappingFunction:NOW')}`,
      },
      {
        value: '#NUMBER()',
        description: `#NUMBER(1000,1) : ${t(
          'edcMappingFunction:NUMBER',
        )} (ex 1001)`,
      },
      {
        value: '#SUM()',
        description: `#SUM(1,2) : ${t('edcMappingFunction:SUM')}`,
      },
      {
        value: '#SUBTRACT()',
        description: `#SUBTRACT(1,2) : ${t('edcMappingFunction:SUBTRACT')} `,
      },
      {
        value: '#MULTIPLY()',
        description: `#MULTIPLY(1,2) : ${t('edcMappingFunction:MULTIPLY')}`,
      },
      {
        value: '#DIVIDE()',
        description: `#DIVIDE(1,2) : ${t('edcMappingFunction:DIVIDE')}`,
      },
    ],
    [t],
  );

  return (
    <table
      className={clsx(
        'w-full table-fixed border-collapse border-slate-800',
        !rule && 'disabled',
      )}
    >
      <thead className="text-center">
        <tr className="[&>th]:border [&>th]:p-1">
          <th className="w-12">Seq.</th>
          <th className="w-1/2">Code</th>
          <th className="w-1/2">Value</th>
        </tr>
      </thead>
      <tbody>
        {sequence !== -1 && (
          <tr className="text-center [&>td]:border [&>td]:p-1">
            <td>{sequence + 1}</td>
            <td>
              {rule?.codes
                ?.map(({ value, description }) => `${value}:${description}`)
                .join(' | ')}
            </td>
            <td>
              <InlineFunction
                value={rule?.value || ''}
                size="sm"
                data={EDCMappingFunctions}
                valueField="value"
                textField="description"
                onChange={(value) =>
                  rule &&
                  setRule(
                    produce(rule, (draft) => {
                      draft.value = value;
                    }),
                  )
                }
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ElementRuleTable;
