import React, { useState } from 'react';
import { TbDots } from 'react-icons/tb';
import clsx from 'clsx';

import { CaseStatus, ICSRFormElement } from 'types/case';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/common/Dropdown';

import { useCaseState } from './context/CaseContext';
import AuditTrailPopup from './popup/AuditTrailPopup';
import MemoListPopup from './popup/MemoListPopup';
import CreateMemoPopup from './popup/CreateMemoPopup';

const dropItemStyles =
  'cursor-pointer px-2 py-1 hover:bg-slate-100 dark:hover:bg-slate-900 [&>button]:w-full [&>button]:text-left';

function Popover({
  number,
  elementId,
  translatedValue,
  visibleTranslate,
  disabled,
  onChange,
}: {
  number: string;
  elementId?: number;
  translatedValue?: string;
  visibleTranslate?: boolean;
  disabled?: boolean;
  onChange: (n: string, v: ICSRFormElement) => void;
}) {
  const { icsr } = useCaseState();
  const [open, setOpen] = useState(false);
  const [openAuditTrail, setOpenAuditTrail] = useState(false);
  const [openMemoList, setOpenMemoList] = useState(false);
  const [openCreateMemo, setOpenCreateMemo] = useState(false);

  const clear = () => {
    onChange(number, {
      number,
      value: undefined,
      description: undefined,
      nullFlavor: undefined,
      translatedValue: undefined,
    });
  };

  const translate = () => {
    onChange(number, {
      translatedValue: '',
    });
  };

  return (
    <>
      <Dropdown
        className="ml-2 mt-1"
        location="left"
        isOpen={open}
        toggle={() => setOpen((prev) => !prev)}
      >
        <DropdownToggle>
          <TbDots />
        </DropdownToggle>
        <DropdownMenu
          className={clsx(
            '!w-fit rounded border border-slate-200 bg-white py-0.5 text-slate-600 shadow',
            'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
          )}
        >
          <DropdownItem className={dropItemStyles}>
            <button
              onClick={clear}
              disabled={
                disabled || icsr?.status !== CaseStatus.WRITING_IN_PROGRESS
              }
            >
              Clear Value
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button
              onClick={() => setOpenAuditTrail(true)}
              disabled={!elementId}
            >
              Audit Trail
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button onClick={() => setOpenMemoList(true)} disabled={!elementId}>
              Memo List
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button
              onClick={() => setOpenCreateMemo(true)}
              disabled={
                !elementId ||
                (icsr?.status !== CaseStatus.WRITING_IN_PROGRESS &&
                  icsr?.status !== CaseStatus.APPROVAL_IN_PROGRESS)
              }
            >
              Create Memo
            </button>
          </DropdownItem>
          {visibleTranslate && (
            <DropdownItem className={dropItemStyles}>
              <button
                onClick={translate}
                disabled={
                  (icsr?.status !== CaseStatus.WRITING_IN_PROGRESS &&
                    icsr?.status !== CaseStatus.APPROVAL_IN_PROGRESS) ||
                  typeof translatedValue === 'string'
                }
              >
                Translate
              </button>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <AuditTrailPopup
        isOpen={openAuditTrail}
        elementId={elementId}
        elementNumber={number}
        toggle={() => setOpenAuditTrail((prev) => !prev)}
      />
      <MemoListPopup
        isOpen={openMemoList}
        elementId={elementId}
        elementNumber={number}
        toggle={() => setOpenMemoList((prev) => !prev)}
      />
      <CreateMemoPopup
        isOpen={openCreateMemo}
        elementId={elementId}
        elementNumber={number}
        toggle={() => setOpenCreateMemo((prev) => !prev)}
      />
    </>
  );
}

export default Popover;
