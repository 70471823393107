import React, { useCallback, useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import storage from 'utils/storage';

document.documentElement.style.setProperty(
  '--sidebarWidth',
  storage.getItem('sidebarWidth') || '12rem',
);

const isWide = () =>
  getComputedStyle(document.body).getPropertyValue('--sidebarWidth') ===
  '12rem';

function SidebarCollapse() {
  const [wide, setWide] = useState(isWide());

  const toggleWide = useCallback(() => {
    if (isWide()) {
      document.documentElement.style.setProperty('--sidebarWidth', '4.25rem');
      storage.setItem('sidebarWidth', '4.25rem');
      setWide(false);
    } else {
      document.documentElement.style.setProperty('--sidebarWidth', '12rem');
      storage.setItem('sidebarWidth', '12rem');
      setWide(true);
    }
  }, []);

  return (
    <div className="flex flex-col overflow-hidden px-4 pb-2">
      <button
        className="mb-1 flex h-9 min-w-[2.25rem] flex-shrink-0 items-center overflow-hidden rounded text-slate-500 hover:text-brand-500"
        onClick={toggleWide}
      >
        {wide ? (
          <TbChevronLeft className="h-9 w-9 flex-shrink-0 p-2" />
        ) : (
          <TbChevronRight className="h-9 w-9 flex-shrink-0 p-2" />
        )}
        <span className="ml-2 inline-block flex-1 text-left">Collaps</span>
      </button>
    </div>
  );
}

export default SidebarCollapse;
