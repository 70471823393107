import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ICSRFormElement } from 'types/case';

import Select from 'components/common/Select/Select';
import Textarea from 'components/common/Textarea';

import { useCaseState } from './context/CaseContext';

import InputComponent from './InputComponent';
import TextareaComponent from './TextareaComponent';
import ComboboxComponent from './ComboboxComponent';
import RadioComponent from './RadioComponent';
import DateComponent from './DateComponent';
import FileComponent from './FileComponent';
import SelectComponent from './SelectComponent';
import MedDRAComponent from './MedDRAComponent';
import MedicalProductComponent from './MedicalProductComponent';
import MedicalSubstanceComponent from './MedicalSubstanceComponent';
import WHODrugProductComponent from './WHODrugProductComponent';
import WHODrugSubstanceComponent from './WHODrugSubstanceComponent';
import Popover from './Popover';
import Translate from './Translate';
import ErrorField from './ErrorField';
import MemoField from './MemoField';
import ValidationInfo from './ValidationInfo';

const componentMap: { [key in string]: any } = {
  input: InputComponent,
  textarea: TextareaComponent,
  combobox: ComboboxComponent,
  radio: RadioComponent,
  date: DateComponent,
  file: FileComponent,
  select: SelectComponent,
  MedDRASearch: MedDRAComponent,
  MedicalProductSearch: MedicalProductComponent,
  SubstanceSearch: MedicalSubstanceComponent,
  WHODrugMedicalProductSearch: WHODrugProductComponent,
  WHODrugSubstanceSearch: WHODrugSubstanceComponent,
};

function CaseFormElement({
  full,
  blank,
  parentId,
  number,
  targetElementNumber,
  element,
  data,
  version,
  country,
  component,
  disabled,
  onChange = () => null,
  onSelect,
}: {
  full?: boolean;
  blank?: boolean;
  parentId?: number;
  number: string;
  targetElementNumber?: string;
  element?: ICSRFormElement;
  data?: any;
  version?: string;
  valueToVersion?: string;
  component?: string;
  country?: string;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
  onSelect?: (s: any) => void;
  available?: (f: any) => boolean;
}) {
  const { E2BR3Elements } = useCaseState();
  const {
    required,
    label,
    component: defaultComponent,
    nullFlavorList,
    ...rest
  } = E2BR3Elements[number];
  const Component = componentMap[component || defaultComponent];

  const {
    id: elementId,
    value,
    description,
    nullFlavor,
    translatedValue,
    deleted,
  } = element || {};

  return (
    <div
      className={twMerge(
        clsx('mb-4 w-1/2 p-2', full && 'w-full', blank && 'w-full'),
      )}
    >
      <div
        className={clsx('flex items-start space-x-2', blank && 'w-1/2 pr-2')}
      >
        <div
          className={clsx(
            'relative flex-1 break-all 2xl:break-keep',
            required && 'required',
          )}
        >
          {label}
          <ValidationInfo elementNumber={number} />
        </div>
        <Component
          className={twMerge(
            clsx('relative w-1/2 flex-none', full && 'w-[calc(75%+0.25rem)]'),
          )}
          elementId={elementId}
          parentId={parentId}
          number={number}
          targetElementNumber={targetElementNumber}
          value={value}
          description={description}
          data={data}
          version={version}
          country={country}
          disabled={disabled || deleted}
          onChange={onChange}
          onSelect={onSelect}
          {...rest}
        />
        <div className="w-20 flex-none px-1">
          {nullFlavorList && (
            <Select
              className="[&_.casfer-select-action]:hidden [&_.control]:px-2 [&_.control]:text-center [&_.csafer-select-list]:text-center"
              placeholder="NF"
              value={nullFlavor}
              data={nullFlavorList}
              onChange={(val) => {
                onChange(number, {
                  number,
                  value: undefined,
                  description: undefined,
                  nullFlavor: val,
                });
              }}
              disabled={disabled || deleted}
              readOnly
            />
          )}
        </div>
        <Popover
          number={number}
          elementId={elementId}
          onChange={onChange}
          disabled={disabled || deleted}
          translatedValue={translatedValue}
          visibleTranslate={Component === TextareaComponent}
        />
      </div>
      {typeof translatedValue === 'string' && (
        <div
          className={clsx(
            'mt-1 flex items-start space-x-2',
            blank && 'w-1/2 pr-2',
          )}
        >
          <div className={clsx('relative flex-1 break-all 2xl:break-keep')} />
          <Textarea
            className={twMerge(
              clsx('relative w-1/2 flex-none', full && 'w-[calc(75%+0.25rem)]'),
            )}
            value={translatedValue}
            minRows={3}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              onChange(number, { translatedValue: e.target.value });
            }}
            disabled={disabled || deleted}
          />
          <Translate
            number={number}
            elementId={elementId}
            value={value}
            translatedValue={translatedValue}
            disabled={disabled || deleted}
            onChange={onChange}
          />
        </div>
      )}
      <ErrorField
        className={clsx(blank && 'w-1/2')}
        parentId={parentId}
        elementId={elementId}
        elementNumber={number}
      />
      <MemoField elementId={elementId} elementNumber={number} />
    </div>
  );
}

export default CaseFormElement;
