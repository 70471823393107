import { AECode, Product, Substance } from 'types/product';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getProductDefaultValues = (companyId = -1): Product => ({
  companyId,
  productName: '',
  brandName: '',
  description: '',
  substances: [],
  aeCodeList: [],
  deleted: false,
  version: 0,
});
export const getSubstanceDefaultValues = (): Substance => ({
  name: '',
  strengthNumber: '',
  strengthUnit: '',
  termId: '',
  termIdVersion: '',
  deleted: false,
});
export const getAEListDefaultValues = (): AECode => ({
  lltName: '',
  lltCode: '',
  deleted: false,
});

export const getProducts = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/products?companyId=${companyId}&${queryString}`);
};

export const getProduct = (productId?: number | string) =>
  client.get(`/api/v1/products/${productId}`);

export const postProduct = (data: Product) =>
  client.post('/api/v1/products', data);

export const putProduct = (data: Product) =>
  client.put(`/api/v1/products/${data.id}`, data);

export const deleteProducts = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/products?${queryString}`);
};
