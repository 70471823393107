import getQueryString from 'utils/getQueryString';
import { dictionaryClient } from './client';

export const getMeddraVersion = () =>
  dictionaryClient.get('/api/v1/meddra/versions');

export const getMeddraTerms = ({
  query,
  version,
  language,
  keyword,
}: {
  query?: any;
  version?: string;
  language: string;
  keyword: string;
}) => {
  const queryString = getQueryString(query);
  return dictionaryClient.get(
    `/api/v1/meddra/low-level-terms?version=${version}&language=${language}&keyword=${keyword}&${queryString}`,
  );
};
