import {
  ICSR,
  ICSRElement,
  ICSRForm,
  ICSRFormElement,
  ICSRFormRootElement,
  ICSRRootElement,
} from 'types/case';
import { getCaseForm } from './caseForm';

export const ICSRFormMap: { [key in string]: any } = {
  'C.1': { root: true },
  'C.1.CNR.1': { root: false },
  'C.1.1': { root: false },
  'C.1.2': { root: false },
  'C.1.3': { root: false },
  'C.1.4': { root: false },
  'C.1.5': { root: false },
  'C.1.6.1': { root: false },
  'C.1.6.1.r': { root: true, repeat: true },
  'C.1.6.1.r.1': { root: false },
  'C.1.6.1.r.2': { root: false },
  'C.1.7': { root: false },
  'C.1.8.1': { root: false },
  'C.1.8.2': { root: false },
  'C.1.9.1': { root: false },
  'C.1.9.1.r': { root: true, repeat: true },
  'C.1.9.1.r.1': { root: false },
  'C.1.9.1.r.2': { root: false },
  'C.1.10.r': { root: false, repeat: true },
  'C.1.11.1': { root: false },
  'C.1.11.2': { root: false },
  'C.2.r': { root: true, repeat: true },
  'C.2.r.1.1': { root: false },
  'C.2.r.1.2': { root: false },
  'C.2.r.1.3': { root: false },
  'C.2.r.1.4': { root: false },
  'C.2.r.2.1': { root: false },
  'C.2.r.2.2': { root: false },
  'C.2.r.2.3': { root: false },
  'C.2.r.2.4': { root: false },
  'C.2.r.2.5': { root: false },
  'C.2.r.2.6': { root: false },
  'C.2.r.2.7': { root: false },
  'C.2.r.3': { root: false },
  'C.2.r.4': { root: false },
  'C.2.r.4.KR.1': { root: false },
  'C.2.r.5': { root: false },
  'C.3': { root: true },
  'C.3.1': { root: false },
  'C.3.1.KR.1': { root: false },
  'C.3.2': { root: false },
  'C.3.3.1': { root: false },
  'C.3.3.2': { root: false },
  'C.3.3.3': { root: false },
  'C.3.3.4': { root: false },
  'C.3.3.5': { root: false },
  'C.3.4.1': { root: false },
  'C.3.4.2': { root: false },
  'C.3.4.3': { root: false },
  'C.3.4.4': { root: false },
  'C.3.4.5': { root: false },
  'C.3.4.6': { root: false },
  'C.3.4.7': { root: false },
  'C.3.4.8': { root: false },
  'C.4.r': { root: true, repeat: true },
  'C.4.r.1': { root: false },
  'C.4.r.2': { root: false },
  'C.5': { root: true },
  'C.5.1.r': { root: true, repeat: true },
  'C.5.1.r.1': { root: false },
  'C.5.1.r.2': { root: false },
  'C.5.2': { root: false },
  'C.5.3': { root: false },
  'C.5.4': { root: false },
  'C.5.4.KR.1': { root: false },
  'D': { root: true },
  'D.1': { root: false },
  'D.1.1.1': { root: false },
  'D.1.1.2': { root: false },
  'D.1.1.3': { root: false },
  'D.1.1.4': { root: false },
  'D.3': { root: false },
  'D.4': { root: false },
  'D.5': { root: false },
  'D.6': { root: false },
  'D.7.2': { root: false },
  'D.7.3': { root: false },
  'D.9.1': { root: false },
  'D.9.3': { root: false },
  'D.2': { root: true, repeat: false },
  'D.2.1': { root: false },
  'D.2.2a': { root: false },
  'D.2.2b': { root: false },
  'D.2.2.1a': { root: false },
  'D.2.2.1b': { root: false },
  'D.2.3': { root: false },
  'D.7.1.r': { root: true, repeat: true },
  'D.7.1.r.1a': { root: false },
  'D.7.1.r.1b': { root: false },
  'D.7.1.r.2': { root: false },
  'D.7.1.r.3': { root: false },
  'D.7.1.r.4': { root: false },
  'D.7.1.r.5': { root: false },
  'D.7.1.r.6': { root: false },
  'D.8.r': { root: true, repeat: true },
  'D.8.r.1': { root: false },
  'D.8.r.1.KR.1a': { root: false },
  'D.8.r.1.KR.1b': { root: false },
  'D.8.r.1.EU.1': { root: false },
  'D.8.r.1.EU.2': { root: false },
  'D.8.r.1.EU.3': { root: false },
  'D.8.r.1.EU.4': { root: false },
  'D.8.r.1.EU.5': { root: false },
  'D.8.r.1.EU.6': { root: false },
  'D.8.r.1.EU.7': { root: false },
  'D.8.r.1.EU.8': { root: false },
  'D.8.r.2a': { root: false },
  'D.8.r.2b': { root: false },
  'D.8.r.3a': { root: false },
  'D.8.r.3b': { root: false },
  'D.8.r.4': { root: false },
  'D.8.r.5': { root: false },
  'D.8.r.6a': { root: false },
  'D.8.r.6b': { root: false },
  'D.8.r.7a': { root: false },
  'D.8.r.7b': { root: false },
  'D.8.r.EU.r': { root: true, repeat: true },
  'D.8.r.EU.r.1': { root: false },
  'D.8.r.EU.r.2a': { root: false },
  'D.8.r.EU.r.2b': { root: false },
  'D.8.r.EU.r.3a': { root: false },
  'D.8.r.EU.r.3b': { root: false },
  'D.9.2.r': { root: true, repeat: true },
  'D.9.2.r.1a': { root: false },
  'D.9.2.r.1b': { root: false },
  'D.9.2.r.2': { root: false },
  'D.9.4.r': { root: true, repeat: true },
  'D.9.4.r.1a': { root: false },
  'D.9.4.r.1b': { root: false },
  'D.9.4.r.2': { root: false },
  'D.10': { root: true, repeat: false },
  'D.10.1': { root: false },
  'D.10.2.1': { root: false },
  'D.10.2.2': { root: false },
  'D.10.2.2a': { root: false },
  'D.10.2.2b': { root: false },
  'D.10.3': { root: false },
  'D.10.4': { root: false },
  'D.10.5': { root: false },
  'D.10.6': { root: false },
  'D.10.7': { root: true, repeat: false },
  'D.10.7.2': { root: false },
  'D.10.7.1.r': { root: true, repeat: true },
  'D.10.7.1.r.1a': { root: false },
  'D.10.7.1.r.1b': { root: false },
  'D.10.7.1.r.2': { root: false },
  'D.10.7.1.r.3': { root: false },
  'D.10.7.1.r.4': { root: false },
  'D.10.7.1.r.5': { root: false },
  'D.10.8.r': { root: true, repeat: true },
  'D.10.8.r.1': { root: false },
  'D.10.8.r.1.KR.1a': { root: false },
  'D.10.8.r.1.KR.1b': { root: false },
  'D.10.8.r.1.EU.1': { root: false },
  'D.10.8.r.1.EU.2': { root: false },
  'D.10.8.r.1.EU.3': { root: false },
  'D.10.8.r.1.EU.4': { root: false },
  'D.10.8.r.1.EU.5': { root: false },
  'D.10.8.r.1.EU.6': { root: false },
  'D.10.8.r.1.EU.7': { root: false },
  'D.10.8.r.1.EU.8': { root: false },
  'D.10.8.r.2a': { root: false },
  'D.10.8.r.2b': { root: false },
  'D.10.8.r.3a': { root: false },
  'D.10.8.r.3b': { root: false },
  'D.10.8.r.4': { root: false },
  'D.10.8.r.5': { root: false },
  'D.10.8.r.6a': { root: false },
  'D.10.8.r.6b': { root: false },
  'D.10.8.r.7a': { root: false },
  'D.10.8.r.7b': { root: false },
  'D.10.8.r.EU.r': { root: true, repeat: true },
  'D.10.8.r.EU.r.1': { root: false },
  'D.10.8.r.EU.r.2a': { root: false },
  'D.10.8.r.EU.r.2b': { root: false },
  'D.10.8.r.EU.r.3a': { root: false },
  'D.10.8.r.EU.r.3b': { root: false },
  'E.i': { root: true, repeat: true },
  'E.i.CNR.1': { root: false },
  'E.i.3.2.CNR.2': { root: false },
  'E.i.1.1a': { root: false },
  'E.i.1.1b': { root: false },
  'E.i.1.2': { root: false },
  'E.i.2.1a': { root: false },
  'E.i.2.1b': { root: false },
  'E.i.3.1': { root: false },
  'E.i.3.2': { root: false },
  'E.i.3.2a': { root: false },
  'E.i.3.2b': { root: false },
  'E.i.3.2c': { root: false },
  'E.i.3.2d': { root: false },
  'E.i.3.2e': { root: false },
  'E.i.3.2f': { root: false },
  'E.i.3.2.CNR.1': { root: false },
  'E.i.4': { root: false },
  'E.i.5': { root: false },
  'E.i.6a': { root: false },
  'E.i.6b': { root: false },
  'E.i.7': { root: false },
  'E.i.8': { root: false },
  'E.i.9': { root: false },
  'F.r': { root: true, repeat: true },
  'F.r.1': { root: false },
  'F.r.2.1': { root: false },
  'F.r.2.2a': { root: false },
  'F.r.2.2b': { root: false },
  'F.r.3.1': { root: false },
  'F.r.3.2': { root: false },
  'F.r.3.3': { root: false },
  'F.r.3.4': { root: false },
  'F.r.4': { root: false },
  'F.r.5': { root: false },
  'F.r.6': { root: false },
  'F.r.7': { root: false },
  'G.k': { root: true, repeat: true },
  'G.k.CNR.1': { root: false },
  'G.k.1': { root: false },
  'G.k.2.1.KR.1a': { root: false },
  'G.k.2.1.KR.1b': { root: false },
  'G.k.2.1.1a': { root: false },
  'G.k.2.1.1b': { root: false },
  'G.k.2.1.2a': { root: false },
  'G.k.2.1.2b': { root: false },
  'G.k.2.2': { root: false },
  'G.k.2.2.EU.1': { root: false },
  'G.k.2.2.EU.2': { root: false },
  'G.k.2.2.EU.3': { root: false },
  'G.k.2.2.EU.4': { root: false },
  'G.k.2.2.EU.5': { root: false },
  'G.k.2.2.EU.6': { root: false },
  'G.k.2.2.EU.7': { root: false },
  'G.k.2.2.EU.8': { root: false },
  'G.k.2.4': { root: false },
  'G.k.2.5': { root: false },
  'G.k.3.1': { root: false },
  'G.k.3.2': { root: false },
  'G.k.3.3': { root: false },
  'G.k.5a': { root: false },
  'G.k.5b': { root: false },
  'G.k.6a': { root: false },
  'G.k.6b': { root: false },
  'G.k.8': { root: false },
  'G.k.11': { root: false },
  'G.k.2.3.r': { root: true, repeat: true },
  'G.k.2.3.r.1': { root: false },
  'G.k.2.3.r.1.KR.1a': { root: false },
  'G.k.2.3.r.1.KR.1b': { root: false },
  'G.k.2.3.r.2a': { root: false },
  'G.k.2.3.r.2b': { root: false },
  'G.k.2.3.r.3a': { root: false },
  'G.k.2.3.r.3b': { root: false },
  'G.k.4.r': { root: true, repeat: true },
  'G.k.4.r.1a': { root: false },
  'G.k.4.r.1b': { root: false },
  'G.k.4.r.2': { root: false },
  'G.k.4.r.3': { root: false },
  'G.k.4.r.4': { root: false },
  'G.k.4.r.5': { root: false },
  'G.k.4.r.6a': { root: false },
  'G.k.4.r.6b': { root: false },
  'G.k.4.r.7': { root: false },
  'G.k.2.2.EU.9.r': { root: true, repeat: true },
  'G.k.2.2.EU.9.r.1': { root: false },
  'G.k.2.2.EU.9.r.2': { root: false },
  'G.k.2.2.EU.9.r.3': { root: false },
  'G.k.2.2.EU.9.r.4': { root: false },
  'G.k.4.r.8': { root: false },
  'G.k.4.r.9.1': { root: false },
  'G.k.4.r.9.2a': { root: false },
  'G.k.4.r.9.2b': { root: false },
  'G.k.4.r.10.1': { root: false },
  'G.k.4.r.10.2a': { root: false },
  'G.k.4.r.10.2b': { root: false },
  'G.k.4.r.11.1': { root: false },
  'G.k.4.r.11.2a': { root: false },
  'G.k.4.r.11.2b': { root: false },
  'G.k.7.r': { root: true, repeat: true },
  'G.k.7.r.1': { root: false },
  'G.k.7.r.2a': { root: false },
  'G.k.7.r.2b': { root: false },
  'G.k.9.i': { root: true, repeat: true },
  'G.k.9.i.1': { root: false },
  'G.k.9.i.3.1a': { root: false },
  'G.k.9.i.3.1b': { root: false },
  'G.k.9.i.3.2a': { root: false },
  'G.k.9.i.3.2b': { root: false },
  'G.k.9.i.4': { root: false },
  'G.k.9.i.2.r': { root: true, repeat: true },
  'G.k.9.i.2.r.1': { root: false },
  'G.k.9.i.2.r.1.EU.1': { root: false },
  'G.k.9.i.2.r.2': { root: false },
  'G.k.9.i.2.r.2.KR.1': { root: false },
  'G.k.9.i.2.r.2.EU.1': { root: false },
  'G.k.9.i.2.r.3': { root: false },
  'G.k.9.i.2.r.3.KR.1': { root: false },
  'G.k.9.i.2.r.3.KR.2': { root: false },
  'G.k.9.i.2.r.3.EU.1': { root: false },
  'G.k.10.r': { root: false, repeat: true },
  'H': { root: true },
  'H.1': { root: false },
  'H.2': { root: false },
  'H.4': { root: false },
  'H.3.r': { root: true, repeat: true },
  'H.3.r.1a': { root: false },
  'H.3.r.1b': { root: false },
  'H.5.r': { root: true, repeat: true },
  'H.5.r.1a': { root: false },
  'H.5.r.1b': { root: false },
};

interface Message {
  message: string;
  messageNumber: string;
  parent?: number;
}
interface ValidationMessage {
  id: number;
  count: number;
  messages: Message[];
  parent?: {
    id: number;
    number: string;
  };
}
type ValidationMessages = {
  [x in string]: {
    [y in number]: ValidationMessage;
  } & {
    count: number;
    messages: Message[];
  };
};

const repeat = (obj: ICSRForm) => {
  const elements: (ICSRRootElement | ICSRElement)[] = [];
  Object.entries(obj).forEach(([number, data]) => {
    if (!ICSRFormMap[number]) return;
    if (!ICSRFormMap[number].root) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          const ele = item.elements[number] as ICSRFormElement;
          elements.push({
            number,
            id: ele.id,
            value: ele.value,
            nullFlavor: ele.nullFlavor,
            description: ele.description,
            translatedValue: ele.translatedValue,
            deleted: ele.deleted,
          });
        });
      } else {
        const ele = data as ICSRFormElement;
        elements.push({
          number,
          id: ele.id,
          value: ele.value,
          nullFlavor: ele.nullFlavor,
          description: ele.description,
          translatedValue: ele.translatedValue,
          deleted: ele.deleted,
        });
      }
    }
    if (ICSRFormMap[number].root) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          elements.push({
            number,
            id: item.id,
            elements: repeat(item.elements),
            deleted: item.deleted,
          });
        });
      } else {
        const ele = data as ICSRFormRootElement;
        elements.push({
          number,
          id: ele.id,
          elements: repeat(ele.elements),
          deleted: ele.deleted,
        });
      }
    }
  });
  return elements;
};

export const convertICSR = ({
  companyId,
  caseId,
  form,
  alarms,
  nonReporting,
  productIds,
  studyId,
  reason,
  regionalImplementationGuide,
  version,
}: {
  companyId?: number;
  caseId?: string | number;
  form: ICSRForm;
  alarms?: any[];
  nonReporting?: boolean;
  productIds: number[];
  studyId?: number | null;
  reason?: string;
  regionalImplementationGuide?: string;
  version?: number;
}) => {
  return {
    companyId,
    id: caseId && Number(caseId),
    regionalImplementationGuide,
    version,
    nonReporting,
    productIds,
    studyId,
    reason,
    receivers: alarms,
    elements: repeat(form),
  };
};

const elementForm = (
  array: ICSRRootElement[],
  form: ICSRForm,
  validationMessages: ValidationMessages,
  parent?: {
    id: number;
    number: string;
  },
) => {
  array.forEach((ele: any) => {
    if (!ICSRFormMap[ele.number]) return;
    if (validationMessages[ele.number]) {
      validationMessages[ele.number][ele.id] = {
        id: ele.id,
        parent,
        messages: ele.validationMessages,
        count: ele.validationMessages.length,
      };
      validationMessages[ele.number].count += ele.validationMessages.length;
    } else {
      validationMessages[ele.number] = {
        [ele.id]: {
          id: ele.id,
          parent,
          messages: ele.validationMessages,
          count: ele.validationMessages.length,
        },
        messages: [],
        count: ele.validationMessages.length,
      };
    }
    validationMessages[ele.number][ele.id].messages = validationMessages[
      ele.number
    ][ele.id].messages.filter((message) => {
      const { messageNumber } = message;
      const newMessage = { ...message, parent: ele.id };
      if (ele.number === messageNumber) return true;
      if (validationMessages[messageNumber]) {
        validationMessages[messageNumber].messages.push(newMessage);
        validationMessages[messageNumber].count += 1;
      } else {
        validationMessages[messageNumber] = {
          messages: [newMessage],
          count: 1,
        };
      }
      return false;
    });
    if (ICSRFormMap[ele.number].repeat) {
      if (ICSRFormMap[ele.number].root) {
        const elements = elementForm(ele.elements, {}, validationMessages, {
          id: ele.id,
          number: ele.number,
        });
        form[ele.number] = [
          ...((form[ele.number] as ICSRFormRootElement[]) || []),
          {
            ...ele,
            elements,
          },
        ];
      } else {
        form[ele.number] = [
          ...((form[ele.number] as ICSRFormRootElement[]) || []),
          {
            ...ele,
            elements: {
              [ele.number]: ele,
            },
          },
        ];
      }
    } else if (ICSRFormMap[ele.number].root) {
      const elements = elementForm(
        ele.elements,
        (form[ele.number] as ICSRFormRootElement)?.elements || {},
        validationMessages,
        {
          id: ele.id,
          number: ele.number,
        },
      );
      form[ele.number] = {
        ...ele,
        elements,
      };
    } else {
      form[ele.number] = ele;
    }
    if (parent) {
      validationMessages[parent.number][parent.id].count +=
        validationMessages[ele.number][ele.id].count;
      validationMessages[parent.number].count +=
        validationMessages[ele.number][ele.id].count;
    }
  });
  return form;
};

export const convertForm = (icsr: ICSR) => {
  const { elements, validationMessages: rootValidationMessages } = icsr;
  const validationMessages: ValidationMessages = {};
  const form = elementForm(elements, getCaseForm(), validationMessages);
  rootValidationMessages.forEach((message: Message) => {
    const { messageNumber } = message;
    if (validationMessages[messageNumber]) {
      validationMessages[messageNumber].messages.push(message);
      validationMessages[messageNumber].count += 1;
    } else {
      validationMessages[messageNumber] = {
        messages: [message],
        count: 1,
      };
    }
  });
  return { form, validationMessages };
};
