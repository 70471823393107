/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { Reducer, useCallback, useReducer } from 'react';
import produce from 'immer';

interface Error {
  condition: any;
  value: any;
  form: any;
}
interface State<T> {
  form: T;
  errors: any;
  conditions: any;
}
interface Action {
  type:
    | 'SET_VALUE'
    | 'SET_VALUES'
    | 'SET_CONDITION'
    | 'SET_ERROR'
    | 'SET_ERRORS'
    | 'CLEAR_ERROR'
    | 'CLEAR_ERRORS'
    | 'SUBMIT';
  payload?: any;
}
const checkEmpty = (value: any) => value == null || value?.length === 0;
const checkError = ({ condition, value, form }: Error) => {
  if (!condition) return null;
  const required =
    condition.required &&
    checkEmpty(value) &&
    (condition.required || 'Required');
  const pattern =
    condition.pattern &&
    !condition.pattern.value.test(value) &&
    condition.pattern.message;
  const validate = !condition.validate || condition.validate(value, form);
  if (required) {
    return {
      type: 'required',
      message: required,
    };
  }
  if (pattern) {
    return {
      type: 'pattern',
      message: pattern,
    };
  }
  if (validate !== true) {
    return {
      type: 'disabled',
      message: typeof validate === 'string' && validate,
    };
  }
  return null;
};
// const checkErrors = ({ conditions, data, form }: any) => {
//   const errors: any = {};
//   data.forEach((item: any) => {
//     Object.entries(item).forEach(([name, value]) => {
//       const condition = conditions[name];
//       const error = checkError({ condition, value, form });
//       if (error) errors[name] = error;
//     });
//   });
//   return errors;
// };
const formReducer = <T>(state: State<T>, action: Action) => {
  switch (action.type) {
    case 'SET_VALUE': {
      const { form, errors, conditions } = state;
      const { name, value } = action.payload;
      const condition = conditions[name];
      const error = checkError({ condition, value, form });
      return {
        ...state,
        form: produce(form, (draft: any) => {
          draft[name] = value;
        }),
        errors: produce(errors, (draft: any) => {
          if (error) draft[name] = error;
          else delete draft[name];
        }),
      };
    }
    case 'SET_VALUES': {
      const { form } = state;
      const data = action.payload;
      return {
        ...state,
        form: produce(form, (draft: any) => {
          data.forEach((item: any) => {
            Object.entries(item).forEach(([name, value]) => {
              draft[name] = value;
            });
          });
        }),
        // errors: { ...errors, ...checkErrors({ conditions, data, form }) },
      };
    }
    case 'SET_CONDITION': {
      const { conditions } = state;
      const { name, options } = action.payload;
      return {
        ...state,
        conditions: produce(conditions, (draft: any) => {
          draft[name] = options;
        }),
      };
    }
    case 'SET_ERROR': {
      const { errors } = state;
      const { name, type, message } = action.payload;
      return {
        ...state,
        errors: produce(errors, (draft: any) => {
          if (name && type) {
            draft[name] = { type, message };
          } else {
            draft.message = message;
          }
        }),
      };
    }
    case 'SET_ERRORS': {
      const { errors } = action.payload;
      return {
        ...state,
        errors,
      };
    }
    case 'CLEAR_ERROR': {
      const { errors } = state;
      const { name } = action.payload;
      return {
        ...state,
        errors: produce(errors, (draft: any) => {
          delete draft[name];
        }),
      };
    }
    case 'CLEAR_ERRORS': {
      return {
        ...state,
        errors: {},
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useForm = <T extends { [key: string]: any }>({
  defaultValues,
}: {
  defaultValues: T;
}) => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action>>(formReducer, {
    form: defaultValues,
    errors: {},
    conditions: {},
  });

  const register = useCallback(
    ({ name }: { name: string }, options?: any) =>
      dispatch({ type: 'SET_CONDITION', payload: { name, options } }),
    [],
  );

  const setValue = useCallback((names: string | any[], namesValue?: any) => {
    if (Array.isArray(names)) {
      dispatch({ type: 'SET_VALUES', payload: names });
    } else {
      dispatch({
        type: 'SET_VALUE',
        payload: { name: names, value: namesValue },
      });
    }
  }, []);

  const setError = useCallback(
    (name: string, type: string, message?: string | null) => {
      setTimeout(() => {
        dispatch({ type: 'SET_ERROR', payload: { name, type, message } });
      });
    },
    [],
  );

  const clearError = useCallback((name?: string) => {
    setTimeout(() => {
      if (name) dispatch({ type: 'CLEAR_ERROR', payload: { name } });
      else dispatch({ type: 'CLEAR_ERRORS' });
    });
  }, []);

  const handleSubmit = useCallback(
    (callback: (data: T) => Promise<void>) =>
      async (e: React.SyntheticEvent) => {
        if (e) {
          e.preventDefault();
          e.persist();
        }
        // checkValidation
        const { form, conditions } = state;
        const errors: any = {};
        Object.keys(conditions).forEach((key) => {
          const condition = conditions[key];
          const value = form[key];
          const error = checkError({ condition, value, form });
          if (error) errors[key] = error;
        });
        dispatch({ type: 'SET_ERRORS', payload: { errors } });
        if (!Object.keys(errors).length) callback(form);
      },
    [state],
  );

  return {
    form: state.form,
    errors: state.errors,
    register,
    setValue,
    setError,
    clearError,
    handleSubmit,
  } as {
    form: typeof state.form;
    errors: typeof state.errors;
    register: typeof register;
    setValue: typeof setValue;
    setError: typeof setError;
    clearError: typeof clearError;
    handleSubmit: typeof handleSubmit;
  };
};

export default useForm;
