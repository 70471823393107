import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useMutation } from '@tanstack/react-query';

import { postMemo } from 'api/memos';

import Button from 'components/common/Button';
import Popup from 'components/common/Popup';

import { useCaseAction, useCaseState } from '../context/CaseContext';

function CreateMemoPopup({
  isOpen,
  elementId,
  elementNumber,
  toggle,
}: {
  isOpen: boolean;
  elementId?: number;
  elementNumber?: string;
  toggle: () => void;
}) {
  const { caseId } = useCaseState();
  const { refetchMemo } = useCaseAction();

  const [comment, setComment] = useState('');

  const { mutate: create } = useMutation(postMemo, {
    onSuccess() {
      refetchMemo();
      toggle();
    },
  });

  const handleCreate = () =>
    create({
      icsrId: Number(caseId),
      dataElementId: elementId,
      comment,
    });

  return (
    <Popup isOpen={isOpen} className="w-[28rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        Audit Trail
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div>Element Number - {elementNumber}</div>
      <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
        <em>Comment</em>
        <textarea
          value={comment}
          className="mt-2 h-60 w-full resize-none border p-2"
          maxLength={255}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setComment(e.target.value)
          }
        />
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button onClick={handleCreate} disabled={!comment} color="blue">
          Create
        </Button>
      </div>
    </Popup>
  );
}

export default CreateMemoPopup;
