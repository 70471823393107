import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import {
  CaseValidationType,
  CaseStatus,
  CaseValidationResult,
  Case,
  ICSR,
} from 'types/case';

import { memberState } from 'state';

function useCaseValidate() {
  const [member] = useRecoilState(memberState);

  const checkPermission = useCallback(
    (type: keyof typeof CaseValidationType) => {
      switch (type) {
        case CaseValidationType.APPROVE:
        case CaseValidationType.COMPLETE:
          return member?.privileges?.CASE_COMPLETE_EDIT;
        case CaseValidationType.SEND:
          return member?.privileges?.CASE_SUBMISSION_EDIT;
        case CaseValidationType.RESET:
          return member?.privileges?.CASE_LOCK_EDIT;
        default:
          break;
      }
      return true;
    },
    [member],
  );

  const checkCasesValidate = useCallback(
    (type: keyof typeof CaseValidationType, data: Case[]) => {
      if (!checkPermission(type))
        return CaseValidationResult.REQUIRED_PERMISSION;
      if (!data?.length) return CaseValidationResult.EMPTY;
      if (type !== CaseValidationType.DELETE && data.length > 20)
        return CaseValidationResult.NUMBER_LIMIT;
      if (type === CaseValidationType.SEND) {
        if (
          data?.findIndex(
            ({ status }: Case) =>
              status !== CaseStatus.APPROVED &&
              status !== CaseStatus.SUBMIT_FAILED &&
              status !== CaseStatus.PROCESSING_FAILED,
          ) !== -1
        ) {
          return CaseValidationResult.WRONG_STATUS;
        }
        return CaseValidationResult.SUCCESS;
      }
      if (type === CaseValidationType.APPROVE) {
        if (
          data?.findIndex(
            ({ status }: Case) => status !== CaseStatus.WRITING_IN_PROGRESS,
          ) !== -1
        ) {
          return CaseValidationResult.WRONG_STATUS;
        }
        if (!data?.every(({ validated }: Case) => validated)) {
          return CaseValidationResult.ONLY_VALIDATION_SUCCESS_CASE;
        }
        if (!data?.every(({ nonReporting }: Case) => !nonReporting)) {
          return CaseValidationResult.ONLY_REPORT_CASE;
        }
        return CaseValidationResult.SUCCESS;
      }
      if (type === CaseValidationType.DELETE) {
        if (
          data?.findIndex(
            ({ status }: Case) => status !== CaseStatus.WRITING_IN_PROGRESS,
          ) !== -1
        ) {
          return CaseValidationResult.WRONG_STATUS;
        }
        return CaseValidationResult.SUCCESS;
      }
      return CaseValidationResult.SUCCESS;
    },
    [checkPermission],
  );

  const checkCaseValidate = useCallback(
    (type: keyof typeof CaseValidationType, data?: ICSR) => {
      if (!checkPermission(type))
        return CaseValidationResult.REQUIRED_PERMISSION;
      if (!data) return CaseValidationResult.EMPTY;
      if (type === CaseValidationType.COMPLETE) {
        if (data?.status === CaseStatus.WRITING_IN_PROGRESS)
          return CaseValidationResult.SUCCESS;
        return CaseValidationResult.WRONG_STATUS;
      }
      if (type === CaseValidationType.APPROVE) {
        if (
          data?.status === CaseStatus.WRITING_IN_PROGRESS ||
          data?.status === CaseStatus.APPROVAL_IN_PROGRESS
        )
          return CaseValidationResult.SUCCESS;
        return CaseValidationResult.WRONG_STATUS;
      }
      if (type === CaseValidationType.RESET) {
        const check =
          data?.status === CaseStatus.APPROVED ||
          data?.status === CaseStatus.COMPLETED ||
          data?.status === CaseStatus.SUBMIT_FAILED ||
          data?.status === CaseStatus.PROCESSING_FAILED;
        if (check) return CaseValidationResult.SUCCESS;
        return CaseValidationResult.WRONG_STATUS;
      }
      if (type === CaseValidationType.SEND) {
        if (
          data?.status === CaseStatus.APPROVED ||
          data?.status === CaseStatus.SUBMIT_FAILED ||
          data?.status === CaseStatus.PROCESSING_FAILED
        ) {
          return CaseValidationResult.SUCCESS;
        }
        if (
          data?.status === CaseStatus.SUBMITTED ||
          data?.status === CaseStatus.PROCESSING_COMPLETED
        ) {
          return CaseValidationResult.ALREADY_COMPLETED;
        }
        return CaseValidationResult.WRONG_STATUS;
      }
      if (type === CaseValidationType.DELETE) {
        if (data?.status === CaseStatus.WRITING_IN_PROGRESS)
          return CaseValidationResult.SUCCESS;
        return CaseValidationResult.WRONG_STATUS;
      }
      if (type === CaseValidationType.RESTORE) {
        if (data?.deleted) return CaseValidationResult.SUCCESS;
        return CaseValidationResult.WRONG_STATUS;
      }
      return CaseValidationResult.SUCCESS;
    },
    [checkPermission],
  );

  return { checkCaseValidate, checkCasesValidate };
}

export default useCaseValidate;
