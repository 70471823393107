/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { IoCaretDown, IoClose } from 'react-icons/io5';
import { TbLoader } from 'react-icons/tb';
import debounce from 'lodash.debounce';
import includes from 'lodash.includes';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import produce from 'immer';

import { SizeType } from 'utils/commonType';
import { getText, getValue, scrollIntoView } from './utils';
import { controlStyles } from './styles';

interface Props {
  className?: string;
  size?: SizeType;
  value?: any[];
  data?: any[];
  placeholder?: string;
  valueField?: string;
  textField?: string | ((i: any, idx: number) => void);
  error?: boolean;
  menuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChange?: (i: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const filterItems = (...props: any) => {
  const [items, value, textField]: [
    items: any[],
    value: string,
    textField?: string | ((i: any, idx: number) => void),
  ] = props;
  return items.filter((item, index) =>
    includes(
      getText(item, index, textField).toLowerCase(),
      value.toLowerCase(),
    ),
  );
};

function MultiSelect({
  className,
  size = 'md',
  value,
  data = [],
  placeholder,
  valueField,
  textField,
  error,
  menuIsOpen,
  closeMenuOnSelect = false,
  loading,
  disabled,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const focusedRef = useRef<HTMLDivElement>();

  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState<any[]>([]);
  const [focusedItem, setFocusedItem] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [dropItems, setDropItems] = useState<any[]>([]);

  const debounced = useRef(
    debounce((...props) => {
      setItems(filterItems(...props));
    }, 500),
  );

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const blurInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const pushItem = (item: any) => {
    const newSelectedItems = produce(selectedItems, (draft) => {
      draft.push(item);
    });
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  const popItem = () => {
    const newSelectedItems = produce(selectedItems, (draft) => {
      draft.pop();
    });
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  const removeItem = (target: any) => {
    const newSelectedItems = selectedItems.filter((item) => target !== item);
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };
  const clearItem = () => {
    setSelectedItems([]);
    onChange([]);
  };

  const openMenu = (direction: 'first' | 'last') => {
    const openAtIndex = direction === 'first' ? 0 : items.length - 1;
    setIsMenuOpen(true);
    setFocusedItem(items[openAtIndex]);
  };

  const focusValue = (direction: 'prev' | 'next') => {};

  const focusItem = (direction: 'first' | 'up' | 'down' | 'last') => {
    if (!items.length) return;

    let nextFocus = 0;
    const focusedIndex = items.indexOf(focusedItem);

    if (direction === 'up') {
      nextFocus = focusedIndex > 0 ? focusedIndex - 1 : items.length - 1;
    } else if (direction === 'down') {
      nextFocus = (focusedIndex + 1) % items.length;
    } else if (direction === 'last') {
      nextFocus = items.length - 1;
    }

    setFocusedItem(items[nextFocus]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!isMenuOpen) {
      openMenu('first');
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    setIsFocused(true);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    setIsFocused(false);
    setIsMenuOpen(false);
    setInputValue('');
  };

  const handleSelect = (item: any) => {
    pushItem(item);
    setInputValue('');
    focusInput();
    if (closeMenuOnSelect) {
      setIsMenuOpen(false);
    }
  };

  const handleRemoveMouseDown =
    (target: any) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      removeItem(target);
    };

  const handleClearMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    clearItem();
  };

  const handleControlMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      openMenu('first');
    }
  };
  const handleMenuMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
  };

  const handleItemMouseOver = (item: any) => {
    if (isMenuOpen) {
      setFocusedItem(item);
    }
  };

  const handleControlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowLeft':
        focusValue('prev');
        break;
      case 'ArrowRight':
        focusValue('next');
        break;
      case 'Backspace':
        if (inputValue) return;
        popItem();
        break;
      case 'Tab':
        if (!e.shiftKey && isMenuOpen) {
          focusItem('down');
          break;
        }
        return;
      case 'Enter':
        if (e.keyCode === 229) {
          break;
        }
        handleSelect(focusedItem);
        break;
      case 'Escape':
        setIsMenuOpen(false);
        setInputValue('');
        break;
      case 'ArrowUp':
        if (menuIsOpen || isMenuOpen) {
          focusItem('up');
        } else {
          openMenu('last');
        }
        break;
      case 'ArrowDown':
        if (menuIsOpen || isMenuOpen) {
          focusItem('down');
        } else {
          openMenu('first');
        }
        break;
      default:
        return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    debounced.current(dropItems, inputValue, textField);
  }, [dropItems, inputValue, textField]);

  useEffect(() => {
    if (value) {
      setSelectedItems(
        data.filter((item: any) => value.includes(getValue(item, valueField))),
      );
    }
  }, [data, value, valueField]);

  useEffect(() => {
    if (menuRef.current && focusedRef.current) {
      scrollIntoView(menuRef.current, focusedRef.current);
    }
  }, [focusedItem]);

  useEffect(() => {
    const newDropItems = data.filter((item) => !selectedItems.includes(item));
    setDropItems(data.filter((item) => !selectedItems.includes(item)));
    setItems(filterItems(newDropItems, inputValue, textField));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const getFocusedItemRef: React.Ref<HTMLDivElement> = (ref) => {
    if (ref) {
      focusedRef.current = ref;
    }
  };

  return (
    <div
      className={twMerge(
        clsx('relative h-fit w-full', className, disabled && 'disabled'),
      )}
    >
      <div
        className={twMerge(
          clsx(
            controlStyles[size],
            'relative flex w-full flex-wrap items-center justify-between pr-0 transition-all',
            isFocused && 'control-focus',
            error && 'control-error',
          ),
        )}
        onMouseDown={handleControlMouseDown}
        onKeyDown={handleControlKeyDown}
      >
        <div
          className={clsx(
            '-mt-1 -ml-1 flex-1 overflow-hidden ',
            selectedItems.length ? 'flex flex-wrap' : 'grid',
          )}
        >
          {selectedItems.length ? (
            selectedItems.map((item, idx) => (
              <div
                key={idx}
                className="csafer-select-multiValue mt-1 ml-1 flex min-w-0 rounded-sm bg-slate-200"
              >
                <div className="pl-1.5 pr-1 leading-normal">
                  {getText(item, data.indexOf(item), textField)}
                </div>
                <button
                  className="group flex items-center rounded-sm p-1 hover:bg-orange-400"
                  onMouseDown={handleRemoveMouseDown(item)}
                >
                  <IoClose
                    className={clsx(
                      'group-hover:text-red-500',
                      isFocused
                        ? 'text-slate-700 dark:text-slate-200'
                        : `text-slate-400 dark:text-slate-500`,
                    )}
                  />
                </button>
              </div>
            ))
          ) : (
            <div className="csafer-selet-value col-start-1 col-end-3 row-start-1 row-end-2 truncate">
              <span className="text-slate-300">{placeholder}</span>
            </div>
          )}
          <div
            className={clsx(
              'col-start-1 col-end-3 row-start-1 row-end-2 mt-1 ml-1 inline-grid flex-auto grid-cols-[0px_min-content]',
              'after:invisible after:col-start-2 after:col-end-auto after:row-start-1	after:row-end-auto after:whitespace-pre after:content-[attr(data-value)]',
            )}
            data-value={inputValue}
          >
            <input
              ref={inputRef}
              className="col-start-2 col-end-auto row-start-1 row-end-auto w-full min-w-[2px] bg-inherit text-inherit opacity-100 "
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center space-x-1 px-2">
          {selectedItems.length ? (
            <button
              tabIndex={-1}
              className="casfer-select-indicator"
              onMouseDown={handleClearMouseDown}
            >
              <IoClose
                className={clsx(
                  'hover:text-slate-500',
                  isFocused
                    ? 'text-slate-700 dark:text-slate-200'
                    : `text-slate-400 dark:text-slate-500`,
                )}
              />
            </button>
          ) : null}
          <button tabIndex={-1} className="casfer-select-indicator">
            <IoCaretDown
              className={clsx(
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
        </div>
      </div>
      {(menuIsOpen || isMenuOpen) && (
        <div
          ref={menuRef}
          className="csafer-select-list absolute z-dropdown mt-1 max-h-[16rem] w-full overflow-auto rounded border border-slate-300 bg-white py-1 shadow"
          onMouseDown={handleMenuMouseDown}
        >
          {loading ? (
            <div className="inline-flex items-center rounded-md px-3 py-1 text-sm font-semibold leading-6 transition duration-150 ease-in-out">
              <TbLoader className="-ml-1 mr-3 h-5 w-5 animate-spin" />
              Loading...
            </div>
          ) : (
            <div className="min-w-fit">
              {items.map((item, idx) => (
                <div
                  key={idx}
                  ref={item === focusedItem ? getFocusedItemRef : undefined}
                  tabIndex={-1}
                  className={twMerge(
                    clsx(
                      'cursor-pointer py-1.5 px-3',
                      item === focusedItem && 'bg-brand-200',
                      item === selectedItems && 'bg-brand-500 text-white',
                    ),
                  )}
                  onClick={() => handleSelect(item)}
                  onMouseOver={() => handleItemMouseOver(item)}
                >
                  {getText(item, idx, textField)}
                </div>
              ))}
              {items.length === 0 && (
                <div className="text-center text-slate-500">No Items</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(MultiSelect);
