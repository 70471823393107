import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import modal from 'utils/modal';
import { convertICSR } from 'utils/convertICSRForm';
import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';

import Button from 'components/common/Button';
import Popup from 'components/common/Popup';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';
import Textarea from 'components/common/Textarea';

import { useCaseAction, useCaseState } from '../context/CaseContext';

import { CaseConfig } from '../EditCase';

function UpdateCasePopup({
  form,
  config,
  disabled,
}: {
  form: ICSRForm;
  config: CaseConfig;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { icsr, caseId, regionalImplementationGuide } = useCaseState();
  const { updateCase } = useCaseAction();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>();
  const [text, setText] = useState<string>();

  const toggle = () => setOpen((prev) => !prev);

  const handleClick = () => {
    const identification = form['C.1'] as ICSRFormRootElement;
    const uniqueCaseIdentification = (
      identification.elements['C.1.8.1'] as ICSRFormElement
    )?.value;
    const recentReportDate = (
      identification.elements['C.1.5'] as ICSRFormElement
    )?.value;

    if (!uniqueCaseIdentification) {
      modal.alert(
        t('modal:alert:title'),
        t('modal:alert:requiredUniqueCaseID'),
      );
      return;
    }
    if (!recentReportDate) {
      modal.alert(
        t('modal:alert:title'),
        t('modal:alert:requiredUniqueCaseID'),
      );
      return;
    }

    setOpen(true);
  };

  const handleUpdate = () => {
    const { nonReporting, productIds, studyId } = config;
    const data = convertICSR({
      form,
      nonReporting,
      productIds,
      studyId,
      reason: value === 'Others' ? text : value,
      regionalImplementationGuide,
      version: icsr?.version,
    });
    updateCase({ id: caseId, data });
    toggle();
  };

  if (disabled) return null;

  return (
    <>
      <Button
        className="absolute bottom-8 right-12 z-50 shadow"
        onClick={handleClick}
      >
        Save
      </Button>
      <Popup isOpen={open} className="w-[28rem]">
        <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
          {t('popup:inputReason:0')}
          <br />
          {t('popup:inputReason:1')}
          <button className="hover:opacity-50" onClick={toggle}>
            <IoClose />
          </button>
        </div>
        <div className="mb-2 py-2">
          <RadioGroup
            className="space-y-2"
            name="control-reason"
            value={value}
            onChange={(val) => setValue(val)}
          >
            <Radio value="Error" label="Input Error" />
            <Radio value="New" label="New Data" />
            <Radio value="Edited" label="Edited Data" />
            <Radio value="Others" label="Others" />
          </RadioGroup>

          <Textarea
            value={text}
            className="mt-2 w-full"
            maxLength={255}
            minRows={4}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setText(e.target.value)
            }
            disabled={value !== 'Others'}
          />
        </div>
        <div className="space-x-2 py-2 text-right">
          <Button color="gray" onClick={toggle} outline>
            Cancel
          </Button>
          <Button color="blue" onClick={handleUpdate} disabled={!value}>
            Update
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default UpdateCasePopup;
