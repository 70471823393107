import { t } from 'i18next';

import { logout, updateToken } from 'api/auth';
import modal from 'utils/modal';

import router from 'Router';

import { buttonStyles } from 'components/common/Modal/Confirm';

const PIVOT_TIME = 300;

class TokenTimer {
  expireTime = 0;

  extendId: number | undefined = undefined;

  expireId: number | undefined = undefined;

  set(time: number) {
    const quotient = Math.floor(time / PIVOT_TIME);
    const remainder = time % PIVOT_TIME;
    const extendAlarmTime =
      quotient > 1 ? (quotient - 1) * PIVOT_TIME + remainder : remainder;

    this.expireTime = new Date().valueOf() + time * 1000;
    this.remove();

    if (time > 0) {
      this.extendId = window.setTimeout(async () => {
        modal.custom({
          title: 'Extend Token',
          message: `${t('modal:confirm:extendToken:0')} \n ${t(
            'modal:confirm:extendToken:1',
          )}`,
          actions: [
            ['Cancel', () => {}, true],
            [
              'Extend',
              async () => {
                try {
                  const { data } = await updateToken();
                  this.set(data.expires_in);
                } catch (error: any) {
                  modal.alert(
                    t('modal:alert:error'),
                    error.response?.data?.message || error.message,
                  );
                }
              },
              true,
              buttonStyles.info,
            ],
          ],
        });
        this.extendId = undefined;
      }, extendAlarmTime * 1000);
    }
    this.expireId = window.setTimeout(() => {
      modal.alert('Expire Token', t('modal:alert:logoutDialog'));
      this.expireId = undefined;
      logout();
      router.navigate('/login');
    }, time * 1000);
  }

  remove() {
    clearTimeout(this.extendId);
    clearTimeout(this.expireId);
    this.extendId = undefined;
    this.expireId = undefined;
  }
}

const tokenTimer = new TokenTimer();

export default tokenTimer;
