import getQueryString from 'utils/getQueryString';
import { dictionaryClient } from './client';

export const getVersion = () => dictionaryClient.get('/api/v1/whodrug/version');

export const getMedicalProducts = ({
  query,
  keyword,
  version,
  searchType,
  country = null,
  genericOrPreferred,
  genericAndPreferred,
  exactMatch,
}: {
  query?: any;
  keyword: string;
  searchType: string;
  version?: string;
  country?: string | null;
  genericOrPreferred?: boolean;
  genericAndPreferred?: boolean;
  exactMatch?: boolean;
}) => {
  const queryString = getQueryString(query);
  return dictionaryClient.get(
    `/api/v1/whodrug/medicinalProducts?version=${version}&searchType=${searchType}&keyword=${keyword}${
      country ? `&country=${country}` : ''
    }${genericOrPreferred ? '&genericOrPreferred=Y' : ''}${
      genericAndPreferred ? '&genericAndPreferred=Y' : ''
    }${exactMatch ? '&exactMatch=Y' : ''}&${queryString}`,
  );
};

export const getIngredients = ({
  query,
  keyword,
  version,
  searchType,
  exactMatch,
}: {
  query?: any;
  keyword: string;
  searchType: string;
  version?: string;
  exactMatch?: boolean;
}) => {
  const queryString = getQueryString(query);
  return dictionaryClient.get(
    `/api/v1/whodrug/ingredients?version=${version}&searchType=${searchType}&keyword=${keyword}${
      exactMatch ? '&exactMatch=Y' : ''
    }&${queryString}`,
  );
};
