import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ContentProps {
  className?: string;
  children?: React.ReactNode;
}

function Content({ className, children }: ContentProps) {
  return (
    <div className={twMerge(clsx('flex-1 bg-inherit px-4', className))}>
      {children}
    </div>
  );
}

export default Content;
