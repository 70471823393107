import { Receiver, ReceiverType } from 'types/receiver';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getReceiverDefaultValues = (
  companyId = -1,
  type = ReceiverType.OTHER,
): Receiver => ({
  companyId,
  type,
  name: '',
  receiverId: '',
  deleted: false,
  version: 0,
});

export const getReceivers = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/receiver-summaries?companyId=${companyId}&${queryString}`,
  );
};

export const getReceiver = (receiverId?: number | string) =>
  client.get(`/api/v1/receivers/${receiverId}`);

export const postReceiver = (data: any) =>
  client.post('/api/v1/receivers', data);

export const putReceiver = (data: any) =>
  client.put(`/api/v1/receivers/${data.id}`, data);

export const deleteReceivers = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/receivers?${queryString}`);
};
