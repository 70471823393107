export interface NavItemType {
  id?: number;
  icon?: {
    lib: string;
    name: string;
  };
  label: string;
  to: string;
  content?: NavItemType[];
}

export const UserNav = {
  'home': {
    id: 1000,
    icon: {
      lib: 'tb',
      name: 'TbHome',
    },
    label: 'Home',
    to: '/home',
  },
  // summary: {
  //   id: 1100,
  //   icon: {
  //     lib: 'tb',
  //     name: 'TbChartBar',
  //   },
  //   label: 'Summary',
  //   to: '/summary',
  // },
  'data': {
    id: 1200,
    icon: {
      lib: 'tb',
      name: 'TbFolder',
    },
    label: 'Data',
    to: '/data/senders',
  },
  'case': {
    id: 1300,
    icon: {
      lib: 'tb',
      name: 'TbId',
    },
    label: 'Case',
    to: '/cases',
  },
  'sign': {
    id: 2000,
    icon: {
      lib: 'tb',
      name: 'TbWritingSign',
    },
    label: 'Approval',
    to: '/approval/request',
  },
  'tracker': {
    id: 1400,
    icon: {
      lib: 'tb',
      name: 'TbClipboardList',
    },
    label: 'Tracker',
    to: '/tracker',
  },
  'upload': {
    id: 1500,
    icon: {
      lib: 'tb',
      name: 'TbUpload',
    },
    label: 'Upload',
    to: '/upload',
  },
  'edc-setup': {
    id: 1600,
    icon: {
      lib: 'tb',
      name: 'TbPresentation',
    },
    label: 'EDC Setup',
    to: '/edc-setup',
  },
  'history': {
    id: 1700,
    icon: {
      lib: 'tb',
      name: 'TbHistory',
    },
    label: 'History',
    to: '/history/imports',
  },
  'signal': {
    id: 2100,
    icon: {
      lib: 'tb',
      name: 'TbEyeCheck',
    },
    label: 'Signal',
    to: '/signals',
  },
  'submission': {
    id: 1800,
    icon: {
      lib: 'tb',
      name: 'TbSend',
    },
    label: 'Submission',
    to: '/submissions',
  },
  'admin': {
    id: 1900,
    icon: {
      lib: 'tb',
      name: 'TbSettings',
    },
    label: 'Admin',
    to: '/admin/members',
  },
};

export const ManagerNav = [
  {
    id: 2100,
    icon: {
      lib: 'tb',
      name: 'TbUsers',
    },
    label: 'User',
    to: '/users',
  },
  {
    id: 2200,
    icon: {
      lib: 'tb',
      name: 'TbSitemap',
    },
    label: 'Company',
    to: '/companies',
  },
  {
    id: 2300,
    icon: {
      lib: 'tb',
      name: 'TbBell',
    },
    label: 'Notification',
    to: '/notifications',
  },
  {
    id: 2400,
    icon: {
      lib: 'tb',
      name: 'TbPresentation',
    },
    label: 'EDC Setup',
    to: '/edc-setup',
  },
];
