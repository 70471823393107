import React, { HTMLAttributes, useState } from 'react';
import { TbChevronDown } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import TypeModify from 'utils/typeModify';
import Checkbox from '../Checkbox';
import LTH from './LTH';
import LTD from './LTD';

type Props = TypeModify<
  HTMLAttributes<HTMLTableRowElement>,
  {
    type?: 'head' | 'body';
    selected?: boolean;
    select?: (seleted: boolean) => void;
    expanded?: boolean;
    colSpan?: number;
    expandedComponent?: React.ReactNode;
    deleted?: boolean;
  }
>;

function LTR({
  className,
  type,
  selected,
  select,
  expanded,
  colSpan,
  expandedComponent,
  children,
  deleted,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(!!expanded);

  if (type === 'head') {
    return (
      <tr className={twMerge(clsx(`relative bg-inherit`, className))}>
        {select && (
          <LTH className="w-10">
            <Checkbox
              className="justify-center [&>input]:mr-0 [&>input]:outline-none"
              checked={selected}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.stopPropagation();
                select(e.target.checked);
              }}
            />
          </LTH>
        )}
        {children}
      </tr>
    );
  }

  if (expandedComponent) {
    return (
      <>
        <tr
          className={twMerge(
            clsx(
              'h-14 border-t-4 border-white hover:bg-slate-200',
              isExpanded && 'bg-slate-200',
              className,
            ),
          )}
        >
          <LTD>
            <button
              type="button"
              className="group/button mx-auto inline-block rounded-full border border-blue-600 p-0.5 hover:bg-blue-600"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              <TbChevronDown
                fontSize="1rem"
                className={twMerge(
                  clsx(
                    `pointer-events-none origin-center text-blue-600 transition-transform group-hover/button:text-white`,
                    isExpanded ? '-rotate-180' : 'rotate-0',
                  ),
                )}
              />
            </button>
          </LTD>
          {children}
        </tr>
        <tr>
          <td colSpan={colSpan} className="bg-slate-200 p-0">
            <div
              ref={(ref) => {
                if (ref) {
                  const clientHeight = ref.children?.[0].clientHeight;
                  const height = clientHeight
                    ? `${Math.floor(clientHeight)}px`
                    : '100vh';
                  ref.style.maxHeight = isExpanded ? height : '0';
                }
              }}
              className="min-h-0 overflow-hidden transition-[max-height] duration-300"
            >
              {expandedComponent}
            </div>
          </td>
        </tr>
      </>
    );
  }

  return (
    <tr
      className={twMerge(
        clsx(
          'relative h-14 border-t-4 border-white hover:bg-slate-50',
          selected && 'bg-brand-50/80',
          deleted &&
            'opacity-50 after:absolute after:top-[calc(50%-2px)] after:left-0 after:h-px after:w-full after:bg-red-500 after:content-[""]',
          className,
        ),
      )}
    >
      {select && (
        <LTD
          className={clsx(
            selected &&
              'before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:rounded-tl-lg before:rounded-bl-lg before:bg-brand-600',
          )}
        >
          <Checkbox
            className="justify-center [&>input]:mr-0 [&>input]:outline-none"
            checked={selected}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              select(e.target.checked);
            }}
            disabled={deleted}
          />
        </LTD>
      )}
      {children}
    </tr>
  );
}

export default LTR;
