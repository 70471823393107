function getCoords(ele: HTMLElement) {
  const top = ele.offsetTop;
  const left = ele.offsetLeft;
  const bottom = ele.offsetTop + ele.offsetHeight;
  const right = ele.offsetWidth + ele.offsetLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    bottom: Math.round(bottom),
    right: Math.round(right),
  };
}

export default getCoords;
