export const RoleType = {
  MANAGER: 'SYSTEM_MANAGER',
  ADMIN: 'COMPANY_ADMIN',
  COMMON: 'COMMON',
} as const;

export interface Privileges {
  [key: string]: any;
  DATA_VIEW: boolean;
  DATA_EDIT: boolean;
  CASE_VIEW: boolean;
  CASE_EDIT: boolean;
  CASE_COMPLETE_EDIT: boolean;
  CASE_APPROVE_ONE_EDIT: boolean;
  CASE_APPROVE_TWO_EDIT: boolean;
  CASE_SUBMISSION_EDIT: boolean;
  CASE_LOCK_EDIT: boolean;
  TRACKER_VIEW?: boolean;
  UPLOAD_EDIT: boolean;
  HISTORY_VIEW: boolean;
  SEND_VIEW: boolean;
  ADMIN_VIEW: boolean;
  ADMIN_EDIT: boolean;
}

export interface Role {
  [key: string]: any;
  id: number;
  companyId: number;
  type: typeof RoleType[keyof typeof RoleType];
  name: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  privileges: Privileges;
}
