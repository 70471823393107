import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { CaseStatus, ICSRForm } from 'types/case';
import usePrompt from 'hooks/usePrompt';
import { compareObject } from 'utils/compareObject';

import { memberState } from 'state';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

import { useCaseState } from './context/CaseContext';
import UpdateCasePopup from './popup/UpdateCasePopup';

import CaseForm from './CaseForm';
import CaseBase from './CaseBase';

export interface CaseConfig {
  nonReporting: boolean;
  productIds: number[];
  studyId: number | null;
}

function EditCase() {
  const [member] = useRecoilState(memberState);

  const { icsr, form: originForm, region } = useCaseState();
  const [config, setConfig] = useState<CaseConfig>({
    nonReporting: false,
    productIds: [],
    studyId: null,
  });
  const [form, setForm] = useState<ICSRForm>({});
  const [version, setVersion] = useState(0);

  const handleVersion = useCallback(() => setVersion((prev) => prev + 1), []);

  usePrompt({
    when: () => compareObject(form, originForm),
  });

  useEffect(() => {
    if (icsr) {
      const { nonReporting, productIds, studyId } = icsr;
      setConfig({ nonReporting, productIds, studyId });
      handleVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icsr]);

  useEffect(() => originForm && setForm(originForm), [originForm]);

  return (
    <MainTemplate>
      <Header title="Edit Case" breadcrumbs={[{ label: 'List', to: '..' }]} />
      <Content className="flex flex-col overflow-hidden bg-slate-100">
        <div className="flex h-full flex-col">
          <div className="dropdown-container z-0 -mr-4 flex h-full flex-col overflow-auto pr-4">
            <div className="flex h-full flex-col">
              <div className="dropdown-container z-0 flex h-full w-full">
                <div className="relative flex h-full w-full flex-col">
                  <CaseBase config={config} setConfig={setConfig} />
                  <CaseForm
                    form={form}
                    setForm={setForm}
                    version={version}
                    handleVersion={handleVersion}
                    region={region}
                    disabled={
                      icsr?.deleted ||
                      !member?.privileges?.CASE_EDIT ||
                      icsr?.status !== CaseStatus.WRITING_IN_PROGRESS
                    }
                  />
                  <UpdateCasePopup
                    form={form}
                    config={config}
                    disabled={
                      icsr?.deleted ||
                      icsr?.status !== CaseStatus.WRITING_IN_PROGRESS ||
                      !member?.privileges?.CASE_EDIT
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </MainTemplate>
  );
}

export default EditCase;
