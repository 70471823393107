import React, { InputHTMLAttributes, useRef } from 'react';
import uniqueId from 'lodash.uniqueid';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { ColorType, SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

type CheckboxType = TypeModify<
  InputHTMLAttributes<HTMLInputElement>,
  {
    color?: ColorType;
    size?: SizeType;
    label?: string | React.ReactNode;
  }
>;
const sizeStyles: { [key in SizeType]: string } = {
  sm: 'checkbox checkbox-sm',
  md: 'checkbox checkbox-md',
  lg: 'checkbox checkbox-lg',
};

const colorStyles: { [key in ColorType]: string } = {
  blue: 'text-blue-600 focus:ring-blue-500 focus:ring-offset-blue-500',
  brand: 'text-brand-600 focus:ring-brand-500 focus:ring-offset-brand-500',
  green: 'text-green-600 focus:ring-green-500 focus:ring-offset-green-500',
  red: 'text-red-600 focus:ring-red-500 focus:ring-offset-red-500',
  orange: 'text-orange-600 focus:ring-orange-500 focus:ring-offset-orange-500',
  slate: 'text-slate-600 focus:ring-slate-500 focus:ring-offset-slate-500',
  gray: 'text-gray-600 focus:ring-gray-500 focus:ring-offset-gray-500',
  yellow: 'text-yellow-600 focus:ring-yellow-500 focus:ring-offset-yellow-500',
  cyan: 'text-cyan-600 focus:ring-cyan-500 focus:ring-offset-cyan-500',
  indigo: 'text-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-500',
};

function Checkbox(
  { className, size = 'md', color = 'brand', label, ...rest }: CheckboxType,
  ref: React.Ref<HTMLInputElement>,
) {
  const id = useRef(uniqueId('checkbox-'));
  return (
    <div className={twMerge(clsx('flex items-center', className))} {...rest}>
      <input
        id={id.current}
        ref={ref}
        type="checkbox"
        className={clsx(
          sizeStyles[size],
          colorStyles[color],
          'select-none appearance-none rounded border border-gray-300 bg-white/10',
          'checked:border-transparent checked:bg-current checked:bg-checkbox checked:bg-[length:100%_100%] checked:bg-no-repeat',
          'focus:outline focus:outline-2 focus:outline-offset-2 focus:ring-offset-2',
          'dark:border-gray-600',
        )}
        {...rest}
      />
      {label && (
        <label
          htmlFor={id.current}
          className="ml-2 block flex-1 cursor-pointer text-slate-700 dark:text-slate-300"
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default React.forwardRef(Checkbox);
