export default class EventManager {
  dispatch: React.Dispatch<any> | null;

  constructor() {
    this.dispatch = null;
  }

  on(dispatch: React.Dispatch<any>) {
    this.dispatch = dispatch;
  }

  emit(payload: any) {
    if (this.dispatch) this.dispatch(payload);
  }
}
