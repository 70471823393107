import getQueryString from 'utils/getQueryString';
import client from './client';

export const getSignals = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/signal?companyId=${companyId}&${queryString}`);
};

export const postSignal = (data: any) => client.post('/api/v1/signal', data);

export const putSignal = ({
  companyId,
  signalId,
}: {
  companyId?: number;
  signalId?: number;
}) => client.put(`/api/v1/signal/cancel/${signalId}?companyId=${companyId}`);

export const deleteSignals = (companyId: number | undefined, ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/signal?companyId=${companyId}&${queryString}`);
};
