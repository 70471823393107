import React, { TableHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

function LTable({
  className,
  children,
}: TableHTMLAttributes<HTMLTableRowElement>) {
  return (
    <table
      className={twMerge(
        clsx(
          'relative w-full border-b border-slate-200 bg-inherit text-sm',
          className,
        ),
      )}
    >
      {children}
    </table>
  );
}

export default LTable;
