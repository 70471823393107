export const ReceiverType = {
  REGULATORY_AUTHORITY: 'REGULATORY_AUTHORITY',
  OTHER: 'OTHER',
} as const;

export interface Receiver {
  id?: number;
  companyId: number;
  type: keyof typeof ReceiverType;
  name: string;
  receiverId: string;
  nsaeSolicited?: number;
  nsaeNonSolicited?: number;
  saeSolicited?: number;
  saeNonSolicited?: number;
  deleted: boolean;
  version: number;
}
