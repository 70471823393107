import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getAEListDefaultValues,
  getProduct,
  getProductDefaultValues,
  getSubstanceDefaultValues,
  putProduct,
} from 'api/products';

import { Product } from 'types/product';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import ProductForm from './ProductForm';

function EditProduct() {
  const { t } = useTranslation();
  const { productId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const {
    form,
    errors,
    register,
    setError,
    clearError,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: getProductDefaultValues(company?.id),
  });

  const { refetch } = useQuery(
    ['getProduct'],
    () => getProduct(productId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Product) {
        setValue([
          { id: data.id },
          { companyId: data.companyId },
          { productName: data.productName },
          { brandName: data.brandName },
          { description: data.description },
          { substances: data.substances },
          { aeCodeList: data.aeCodeList },
          { version: data.version },
        ]);
      },
    },
  );

  const { mutate } = useMutation(putProduct, {
    onSuccess() {
      refetch();
    },
  });

  const changeProduct = (key: string, value: any) => {
    setValue(key, value);
  };

  const addSubstance = () => {
    setValue(
      'substances',
      produce(form.substances, (draft) => {
        draft.push(getSubstanceDefaultValues());
      }),
    );
  };

  const deleteSubstance = (index: number) => {
    if (form.substances[index].id) {
      setValue(
        'substances',
        produce(form.substances, (draft) => {
          const target = draft.find((_officer, idx) => index === idx);
          if (target) target.deleted = true;
        }),
      );
    } else {
      setValue(
        'substances',
        produce(form.substances, (draft) =>
          draft.filter((_officer, idx) => index !== idx),
        ),
      );
    }
  };

  const restoreSubstance = (index: number) => {
    setValue(
      'substances',
      produce(form.substances, (draft) => {
        const target = draft.find((_officer, idx) => index === idx);
        if (target) target.deleted = false;
      }),
    );
  };

  const changeSubstance = (key: string, value: any, index: number) => {
    setValue(
      'substances',
      produce(form.substances, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const addAECodeList = () => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) => {
        draft.push(getAEListDefaultValues());
      }),
    );
  };

  const deleteAECodeList = (index: number) => {
    if (form.aeCodeList[index].id) {
      setValue(
        'aeCodeList',
        produce(form.aeCodeList, (draft) => {
          const target = draft.find((_officer, idx) => index === idx);
          if (target) target.deleted = true;
        }),
      );
    } else {
      setValue(
        'aeCodeList',
        produce(form.aeCodeList, (draft) =>
          draft.filter((_officer, idx) => index !== idx),
        ),
      );
    }
  };

  const restoreAECodeList = (index: number) => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) => {
        const target = draft.find((_officer, idx) => index === idx);
        if (target) target.deleted = false;
      }),
    );
  };

  const changeAECodeList = (key: string, value: any, index: number) => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' });
    register(
      { name: 'productName' },
      {
        validate: (value: any, originForm: any) => {
          if (value || originForm.brandName) {
            clearError('brandName');
            return true;
          }
          setError('brandName', 'disabled', t('error:requiredProductName'));
          return t('error:requiredProductName');
        },
      },
    );
    register(
      { name: 'brandName' },
      {
        validate: (value: any, originForm: any) => {
          if (value || originForm.productName) {
            clearError('productName');
            return true;
          }
          setError('productName', 'disabled', t('error:requiredProductName'));
          return t('error:requiredProductName');
        },
      },
    );
    register({ name: 'description' });
    register({ name: 'substances' });
    register({ name: 'aeCodeList' });
    register({ name: 'version' });
  }, [clearError, register, setError, t]);

  return (
    <FormWrapper
      title="Edit Product"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <ProductForm
        submitLabel="Save"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        changeProduct={changeProduct}
        addSubstance={addSubstance}
        deleteSubstance={deleteSubstance}
        restoreSubstance={restoreSubstance}
        changeSubstance={changeSubstance}
        addAECodeList={addAECodeList}
        deleteAECodeList={deleteAECodeList}
        restoreAECodeList={restoreAECodeList}
        changeAECodeList={changeAECodeList}
      />
    </FormWrapper>
  );
}

export default EditProduct;
