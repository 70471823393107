/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbFileExport } from 'react-icons/tb';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { Moment } from 'moment';
import { useRecoilState } from 'recoil';

import { getTrackers, getTrackersData, postTrackerReports } from 'api/tracker';
import { getProducts } from 'api/products';

import { TrackerColumn } from 'types/tracker';
import modal from 'utils/modal';
import { filterToString } from 'utils/getQueryString';

import { companyState } from 'state';

import Select from 'components/common/Select/Select';
import { RangePicker } from 'components/common/DatePicker';
import Button from 'components/common/Button';
import { buttonStyles } from 'components/common/Modal/Confirm';

const SearchTypes: {
  [key: string]: string;
} = {
  dateOfMostRecentInformationForThisReport: 'Date of Most Recent Information',
  regulatoryAuthorityReportedDate: 'Submission Date',
} as const;

function ExportTracker() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const [tracker, setTracker] = useState<{
    id?: number;
    columns: TrackerColumn[];
  }>({ columns: [] });
  const [form, setForm] = useState<{
    productId?: number;
    type?: string;
    period?: (Moment | undefined)[];
  }>({});
  const [search, setSearch] = useState<{
    productId?: number;
    type?: string;
    period?: (Moment | undefined)[];
  }>();

  useQuery(
    ['getTrackers'],
    () => getTrackers(company?.id).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setTracker(data);
      },
    },
  );

  const { data: products } = useQuery(
    ['getProducts'],
    () =>
      getProducts({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getTrackersData', search],
      async ({ pageParam = 1 }) => {
        const res = await getTrackersData({
          id: tracker.id,
          companyId: company?.id,
          query: {
            page: pageParam,
            perPage: 100,
            q: {
              deleted: ['false'],
              productId: search?.productId,
              [search?.type as string]: search?.period,
            },
          },
        });
        return res.data;
      },
      {
        enabled: !!search,
        refetchOnWindowFocus: false,
        getNextPageParam: (page, pages) => {
          return page.last ? undefined : pages.length + 1;
        },
      },
    );

  const { mutate } = useMutation(postTrackerReports, {
    onSuccess() {
      modal.custom({
        title: t('modal:confirm:title'),
        message: t('modal:confirm:goExportPage'),
        actions: [
          ['Cancel', () => {}, true],
          [
            'Ok',
            () => navigate('./../history/trackers'),
            true,
            buttonStyles.info,
          ],
        ],
      });
    },
  });

  const handleChange = ({ name, value }: any) =>
    setForm((prev) => ({ ...prev, [name]: value }));

  const handleSearch = () => setSearch(form);

  const handleExport = async () => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:export'))
    ) {
      mutate({
        data: {
          companyId: company?.id,
          productId: form?.productId,
          comment: `${SearchTypes[search?.type as string]}[${
            search?.period?.[0]?.format('YYYY-MM-DD') || ''
          }~${search?.period?.[1]?.format('YYYY-MM-DD') || ''}]`,
          query: filterToString({
            deleted: ['false'],
            productId: form?.productId?.toString(),
            [form?.type as string]: form?.period,
          }),
        },
        query: { q: { deleted: ['false'] } },
      });
    }
  };

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    {
      offset: 100,
      debounce: 500,
      triggerOnNoScroll: false,
    },
  );

  return (
    <>
      <div className="flex flex-wrap justify-between py-3">
        <div className="flex flex-wrap lg:space-x-3">
          <div className="w-60">
            <Select
              size="sm"
              placeholder="Product"
              value={form.productId}
              data={products || []}
              valueField="id"
              textField={(dataItem: any) =>
                dataItem?.brandName || dataItem?.productName || dataItem
              }
              onChange={(obj) =>
                handleChange({ name: 'productId', value: obj.id })
              }
            />
          </div>
          <div className="w-60">
            <Select
              size="sm"
              placeholder="Type"
              value={form.type}
              data={Object.entries(SearchTypes).map(([key, value]) => ({
                key,
                value,
              }))}
              valueField="key"
              textField="value"
              onChange={(value) =>
                handleChange({ name: 'type', value: value.key })
              }
            />
          </div>
          <div className="w-60">
            <RangePicker
              size="sm"
              value={form.period}
              onChange={(dates) =>
                handleChange({ name: 'period', value: dates })
              }
            />
          </div>
          <div>
            <Button size="sm" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </div>
        <div
          className="flex cursor-pointer items-center space-x-2 [&_svg]:h-[1.875rem] [&_svg]:w-[1.875rem] [&_svg]:rounded-sm [&_svg]:bg-gray-100 
              [&_svg]:p-1.5 [&_svg]:text-slate-500 dark:[&_svg]:border-slate-600 dark:[&_svg]:text-slate-500
              [&_svg:hover]:bg-slate-400 [&_svg:hover]:text-slate-200 dark:[&_svg:hover]:bg-slate-600"
        >
          <button onClick={handleExport} disabled={!search}>
            <TbFileExport title="Export" />
          </button>
        </div>
      </div>
      <div ref={scrollRef} className="flex-1 overflow-auto bg-inherit">
        <table className="relative w-full border-collapse border-slate-900 text-sm">
          <thead className="sticky top-0 bg-slate-500 font-semibold text-white [&_th]:border [&_th]:border-slate-900 [&_th]:p-2">
            <tr>
              {tracker.columns.map(({ id, name }) => (
                <th key={id}>
                  {name.split('\n').map((str: string, idx: number) => (
                    <div key={idx} className="whitespace-nowrap">
                      {str}
                    </div>
                  ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-slate-100 text-center text-xs [&_td]:border [&_td]:border-slate-900 [&_td]:p-1">
            {data
              ? data.pages.map(({ content }) =>
                  content.map(
                    (row: { [key: string]: string }, rIdx: number) => (
                      <tr key={rIdx}>
                        {tracker.columns.map(({ columnSpec }) => (
                          <td key={columnSpec}>
                            {row[columnSpec]
                              ?.split('\n')
                              ?.map((str: string, idx: number) => (
                                <span key={idx} className="line-clamp-[12]">
                                  {str}
                                </span>
                              ))}
                          </td>
                        ))}
                      </tr>
                    ),
                  ),
                )
              : null}
          </tbody>
        </table>
      </div>
      <div className="mx-1 my-4 flex items-center justify-center font-semibold">
        {data ? (
          <span>
            {`1 -
                  ${data.pages.reduce((acc: number, { content }) => {
                    acc += content.length;
                    return acc;
                  }, 0)} of ${data.pages[0].totalElements}`}
          </span>
        ) : null}
      </div>
    </>
  );
}

export default ExportTracker;
