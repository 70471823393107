import React, { useState } from 'react';

import MedDRA from 'components/popup/MedDRA';
import { ICSRFormElement } from 'types/case';
import { MedDRACode } from 'types/codes';
import SearchInput from 'components/common/SearchInput';
import { useTranslation } from 'react-i18next';
import modal from 'utils/modal';
import Input from 'components/common/Input';
import clsx from 'clsx';

function MedDRAComponent({
  className,
  number,
  version,
  value,
  description,
  disabled,
  onChange = () => null,
}: {
  className?: string;
  number: string;
  version?: string;
  value?: string | null;
  description?: string;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const handleClick = async () => {
    if (!version) {
      modal.alert(t('modal:alert:title'), t('modal:alert:selectMedDRAVersion'));
      return;
    }
    toggle();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(number, {
      value: e.target.value,
      description: undefined,
      nullFlavor: undefined,
    });
  };

  return (
    <div className={clsx('space-y-1', className)}>
      <SearchInput
        className="w-full"
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        disabled={disabled}
        readOnly
      />
      <Input
        className="w-full"
        value={description}
        disabled={disabled}
        readOnly
      />
      <MedDRA
        isOpen={open}
        version={version}
        toggle={toggle}
        callback={({ lltCode, lltName }: MedDRACode) => {
          if (disabled) return;
          onChange(number, {
            value: String(lltCode),
            description: lltName,
            nullFlavor: undefined,
          });
        }}
      />
    </div>
  );
}

export default MedDRAComponent;
