import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions, useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import moment, { Moment } from 'moment';
import produce from 'immer';

import { OrderType } from 'utils/commonType';
import modal from 'utils/modal';

import useListQuery from './useListQuery';

export interface QueryFilterType {
  [key: string]: string | string[] | (Moment | undefined)[] | undefined;
}
export interface QueryType {
  page?: number;
  perPage?: number;
  order?: OrderType;
  orderBy?: string;
  q?: QueryFilterType;
}

const useList = ({
  defaultQuery,
  queryKey,
  refreshKey,
  queryFn,
  deleteFn = () => axios.delete(''),
  options,
}: {
  defaultQuery?: QueryType;
  queryKey: string;
  refreshKey?: string | number;
  queryFn: (data: any) => Promise<AxiosResponse<any, any>>;
  deleteFn?: (data: any) => Promise<AxiosResponse<any, any>>;
  options?: UseQueryOptions<
    AxiosResponse<any, any>,
    AxiosError<any, any>,
    AxiosResponse<any, any>,
    any
  >;
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data,
    page,
    lastPage,
    totalElements,
    query,
    selected,
    setQuery,
    setFilter,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    refetch,
    ...rest
  } = useListQuery({
    defaultQuery,
    queryKey,
    refreshKey,
    queryFn,
    options,
  });

  const { mutate } = useMutation(() => deleteFn(selected), {
    onSuccess() {
      refetch();
      // setSelected([]);
    },
  });

  const handleDelete = async () => {
    if (
      await modal.confirm(
        t('modal:confirm:title'),
        t('modal:confirm:delete'),
        'warn',
      )
    ) {
      mutate();
    }
  };

  useEffect(() => {
    const newParams = qs.stringify(
      produce(query, (draft: any) => {
        if (!query.q) return;
        Object.entries(query.q).forEach(([key, value]) => {
          if (!Array.isArray(value)) return;
          if (moment.isMoment(value[0]))
            draft.q[key][0] = value[0].toISOString();
          if (moment.isMoment(value[1]))
            draft.q[key][1] = value[1].toISOString();
        });
      }),
      { skipNulls: true },
    );
    setSearchParams(newParams, { state, replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const newQuery: any = qs.parse(searchParams.toString());
    if (newQuery.page) newQuery.page = Number(newQuery.page);
    if (newQuery.perPage) newQuery.perPage = Number(newQuery.perPage);
    setQuery((prev) => ({
      ...prev,
      ...newQuery,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    page,
    lastPage,
    totalElements,
    query,
    selected,
    setQuery,
    setFilter,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    handleDelete,
    refetch,
    ...rest,
  };
};

export default useList;
