import React, { Fragment } from 'react';

import getRandomInt from 'utils/getRandomInt';

import LTR from './LTR';
import LTD from './LTD';

function ListSkeleton({
  disableCheckbox,
  rows: _rows,
  cols: _cols,
}: {
  disableCheckbox?: boolean;
  rows: number;
  cols: number;
}) {
  const rows = new Array(_rows).fill(null);
  const cols = new Array(_cols).fill(null);
  return (
    <>
      {rows.map((_row, rIdx) => (
        <Fragment key={rIdx}>
          <LTR
            className="animate-pulse"
            select={disableCheckbox ? undefined : () => null}
          >
            {cols.map((_col, cIdx) => (
              <LTD key={cIdx}>
                <div
                  className="h-4 rounded-full bg-gray-200 dark:bg-gray-700"
                  style={{
                    width: `${getRandomInt(30, 90)}%`,
                  }}
                />
              </LTD>
            ))}
          </LTR>
        </Fragment>
      ))}
    </>
  );
}

export default React.memo(ListSkeleton);
