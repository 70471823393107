import React, { useState } from 'react';
import { IoMdReturnRight } from 'react-icons/io';
import { Memo } from 'types/memo';

import { useCaseState } from './context/CaseContext';
import UpdateMemoPopup from './popup/UpdateMemoPopup';

function MemoField({
  elementId,
  elementNumber,
}: {
  elementId?: number;
  elementNumber: string;
}) {
  const { memoMessages } = useCaseState();

  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState<Memo>();

  const toggle = () => setOpen((prev) => !prev);

  if (!elementId) return null;

  return (
    <div className="py-2 text-sm">
      {memoMessages[elementNumber]?.[elementId]?.messages
        .reverse()
        .map((message: Memo) => (
          <div
            key={message.id}
            className="flex w-full items-center text-orange-500"
          >
            <IoMdReturnRight />
            <button
              className="ml-2"
              onClick={() => {
                setMemo(message);
                setOpen(true);
              }}
            >{`[${message.createdBy}] : ${message.comment}`}</button>
          </div>
        ))}
      <UpdateMemoPopup
        isOpen={open}
        memo={memo}
        elementNumber={elementNumber}
        toggle={toggle}
      />
    </div>
  );
}

export default MemoField;
