import React, { useCallback } from 'react';
import produce from 'immer';

import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';

import Title from 'components/case/Title';
import RootRepeatElement from 'components/case/RootRepeatElement';
import CaseFormElement from 'components/case/CaseFormElement';

function LiteratureReference({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const handleChange = useCallback(
    (
      number: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  return (
    <>
      <Title number="C.4.r" />

      <RootRepeatElement
        number="C.4.r"
        version={version}
        element={form['C.4.r'] as ICSRFormRootElement[]}
        onChange={handleChange}
        disabled={disabled}
      >
        <CaseFormElement number="C.4.r.1" />
        <CaseFormElement number="C.4.r.2" />
      </RootRepeatElement>
    </>
  );
}

export default LiteratureReference;
