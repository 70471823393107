export function getValue(item: any, valueField: string | undefined) {
  if (!valueField) return item;
  if (typeof item === 'string') return item;
  return item[valueField];
}

export function getText(
  item: any,
  index: number,
  textField: string | ((i: any, idx: number) => void) | undefined,
) {
  if (!textField) return item;
  if (typeof item === 'string') return item;
  return typeof textField === 'string'
    ? item[textField]
    : textField(item, index);
}

export function isDocumentElement(
  el: HTMLElement | typeof window,
): el is typeof window {
  return [document.documentElement, document.body, window].indexOf(el) > -1;
}

export function scrollTo(el: HTMLElement | typeof window, top: number): void {
  // with a scroll distance, we perform scroll on the element
  if (isDocumentElement(el)) {
    window.scrollTo(0, top);
    return;
  }

  el.scrollTop = top;
}

export function scrollIntoView(
  menuEl: HTMLElement,
  focusedEl: HTMLElement,
): void {
  const menuRect = menuEl.getBoundingClientRect();
  const focusedRect = focusedEl.getBoundingClientRect();
  const overScroll = focusedEl.offsetHeight / 3;

  if (focusedRect.bottom + overScroll > menuRect.bottom) {
    scrollTo(
      menuEl,
      Math.min(
        focusedEl.offsetTop +
          focusedEl.clientHeight -
          menuEl.offsetHeight +
          overScroll,
        menuEl.scrollHeight,
      ),
    );
  } else if (focusedRect.top - overScroll < menuRect.top) {
    scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
  }
}
