import { TFunction } from 'i18next';

const patientCharacteristics = (codeList: any, t: TFunction) => ({
  'D.1': {
    elementNumber: 'D.1',
    label: t('ICSR:elementName:D.1'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
    required: true,
  },
  'D.1.1.1': {
    elementNumber: 'D.1.1.1',
    label: t('ICSR:elementName:D.1.1.1'),
    component: 'input',
    maxLength: '20',
    nullFlavorList: ['MSK'],
  },
  'D.1.1.2': {
    elementNumber: 'D.1.1.2',
    label: t('ICSR:elementName:D.1.1.2'),
    component: 'input',
    maxLength: '20',
    nullFlavorList: ['MSK'],
  },
  'D.1.1.3': {
    elementNumber: 'D.1.1.3',
    label: t('ICSR:elementName:D.1.1.3'),
    component: 'input',
    maxLength: '20',
    nullFlavorList: ['MSK'],
  },
  'D.1.1.4': {
    elementNumber: 'D.1.1.4',
    label: t('ICSR:elementName:D.1.1.4'),
    component: 'combobox',
    maxLength: '20',
    nullFlavorList: ['MSK'],
  },
  'D.2': {
    elementNumber: 'D.2',
    label: t('ICSR:elementName:D.2'),
  },
  'D.2.1': {
    elementNumber: 'D.2.1',
    label: t('ICSR:elementName:D.2.1'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
    ],
    nullFlavorList: ['MSK'],
  },
  'D.2.2a': {
    elementNumber: 'D.2.2a',
    label: t('ICSR:elementName:D.2.2a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '5',
  },
  'D.2.2b': {
    elementNumber: 'D.2.2b',
    label: t('ICSR:elementName:D.2.2b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26_1']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'D.2.2.1a': {
    elementNumber: 'D.2.2.1a',
    label: t('ICSR:elementName:D.2.2.1a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '3',
  },
  'D.2.2.1b': {
    elementNumber: 'D.2.2.1b',
    label: t('ICSR:elementName:D.2.2.1b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26_2']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'D.2.3': {
    elementNumber: 'D.2.3',
    label: t('ICSR:elementName:D.2.3'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.9']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'D.3': {
    elementNumber: 'D.3',
    label: t('ICSR:elementName:D.3'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '6',
    decimalPoint: true,
  },
  'D.4': {
    elementNumber: 'D.4',
    label: t('ICSR:elementName:D.4'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '3',
  },
  'D.5': {
    elementNumber: 'D.5',
    label: t('ICSR:elementName:D.5'),
    component: 'radio',
    options: codeList['1.0.5218']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
    valueLabel: true,
  },
  'D.6': {
    elementNumber: 'D.6',
    label: t('ICSR:elementName:D.6'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK'],
  },
  'D.7.1.r': {
    elementNumber: 'D.7.1.r',
    list: [
      {
        elementNumber: 'D.7.1.r.1a',
        label: t('ICSR:elementName:D.7.1.r.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'D.7.1.r.1b',
        label: t('ICSR:elementName:D.7.1.r.1b'),
        component: 'MedDRASearch',
      },
      {
        elementNumber: 'D.7.1.r.2',
        label: t('ICSR:elementName:D.7.1.r.2'),
        component: 'date',
      },
      {
        elementNumber: 'D.7.1.r.4',
        label: t('ICSR:elementName:D.7.1.r.4'),
        component: 'date',
      },
    ],
  },
  'D.7.1.r.1a': {
    elementNumber: 'D.7.1.r.1a',
    label: t('ICSR:elementName:D.7.1.r.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.7.1.r.1b': {
    elementNumber: 'D.7.1.r.1b',
    label: t('ICSR:elementName:D.7.1.r.1b'),
    component: 'MedDRASearch',
  },
  'D.7.1.r.2': {
    elementNumber: 'D.7.1.r.2',
    label: t('ICSR:elementName:D.7.1.r.2'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.7.1.r.3': {
    elementNumber: 'D.7.1.r.3',
    label: t('ICSR:elementName:D.7.1.r.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'D.7.1.r.4': {
    elementNumber: 'D.7.1.r.4',
    label: t('ICSR:elementName:D.7.1.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.7.1.r.5': {
    elementNumber: 'D.7.1.r.5',
    label: t('ICSR:elementName:D.7.1.r.5'),
    component: 'textarea',
    maxLength: '2000',
  },
  'D.7.1.r.6': {
    elementNumber: 'D.7.1.r.6',
    label: t('ICSR:elementName:D.7.1.r.6'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'D.7.2': {
    elementNumber: 'D.7.2',
    label: t('ICSR:elementName:D.7.2'),
    component: 'textarea',
    maxLength: '10000',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'D.7.3': {
    elementNumber: 'D.7.3',
    label: t('ICSR:elementName:D.7.3'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'D.8.r': {
    elementNumber: 'D.8.r',
    list: [
      {
        elementNumber: 'D.8.r.1',
        label: t('ICSR:elementName:D.8.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'D.8.r.4',
        label: t('ICSR:elementName:D.8.r.4'),
        component: 'date',
      },
      {
        elementNumber: 'D.8.r.5',
        label: t('ICSR:elementName:D.8.r.5'),
        component: 'date',
      },
    ],
  },
  'D.8.r.1': {
    elementNumber: 'D.8.r.1',
    label: t('ICSR:elementName:D.8.r.1'),
    component: 'textarea',
    targetElementNumber: 'D.8.r.1.KR.1b',
    nullFlavorList: ['UNK', 'NA'],
    maxLength: '250',
  },
  'D.8.r.2a': {
    elementNumber: 'D.8.r.2a',
    label: t('ICSR:elementName:D.8.r.2a'),
    component: 'input',
    maxLength: '10',
  },
  'D.8.r.2b': {
    elementNumber: 'D.8.r.2b',
    label: t('ICSR:elementName:D.8.r.2b'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.3a': {
    elementNumber: 'D.8.r.3a',
    label: t('ICSR:elementName:D.8.r.3a'),
    component: 'input',
    maxLength: '10',
  },
  'D.8.r.3b': {
    elementNumber: 'D.8.r.3b',
    label: t('ICSR:elementName:D.8.r.3b'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.8.r.4': {
    elementNumber: 'D.8.r.4',
    label: t('ICSR:elementName:D.8.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.8.r.5': {
    elementNumber: 'D.8.r.5',
    label: t('ICSR:elementName:D.8.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.8.r.6a': {
    elementNumber: 'D.8.r.6a',
    label: t('ICSR:elementName:D.8.r.6a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.8.r.6b': {
    elementNumber: 'D.8.r.6b',
    label: t('ICSR:elementName:D.8.r.6b'),
    component: 'MedDRASearch',
  },
  'D.8.r.7a': {
    elementNumber: 'D.8.r.7a',
    label: t('ICSR:elementName:D.8.r.7a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.8.r.7b': {
    elementNumber: 'D.8.r.7b',
    label: t('ICSR:elementName:D.8.r.7b'),
    component: 'MedDRASearch',
  },
  'D.9.1': {
    elementNumber: 'D.9.1',
    label: t('ICSR:elementName:D.9.1'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.9.2.r': {
    elementNumber: 'D.9.2.r',
    list: [
      {
        elementNumber: 'D.9.2.r.1a',
        label: t('ICSR:elementName:D.9.2.r.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'D.9.2.r.1b',
        label: t('ICSR:elementName:D.9.2.r.1b'),
        component: 'MedDRASearch',
      },
    ],
  },
  'D.9.2.r.1a': {
    elementNumber: 'D.9.2.r.1a',
    label: t('ICSR:elementName:D.9.2.r.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.9.2.r.1b': {
    elementNumber: 'D.9.2.r.1b',
    label: t('ICSR:elementName:D.9.2.r.1b'),
    component: 'MedDRASearch',
  },
  'D.9.2.r.2': {
    elementNumber: 'D.9.2.r.2',
    label: t('ICSR:elementName:D.9.2.r.2'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.9.3': {
    elementNumber: 'D.9.3',
    label: t('ICSR:elementName:D.9.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'D.9.4.r': {
    elementNumber: 'D.9.4.r',
    list: [
      {
        elementNumber: 'D.9.4.r.1a',
        label: t('ICSR:elementName:D.9.4.r.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'D.9.4.r.1b',
        label: t('ICSR:elementName:D.9.4.r.1b'),
        component: 'MedDRASearch',
      },
    ],
  },
  'D.9.4.r.1a': {
    elementNumber: 'D.9.4.r.1a',
    label: t('ICSR:elementName:D.9.4.r.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.9.4.r.1b': {
    elementNumber: 'D.9.4.r.1b',
    label: t('ICSR:elementName:D.9.4.r.1b'),
    component: 'MedDRASearch',
  },
  'D.9.4.r.2': {
    elementNumber: 'D.9.4.r.2',
    label: t('ICSR:elementName:D.9.4.r.2'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.10': {
    elementNumber: 'D.10',
    label: t('ICSR:elementName:D.10'),
  },
  'D.10.1': {
    elementNumber: 'D.10.1',
    label: t('ICSR:elementName:D.10.1'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'D.10.2.1': {
    elementNumber: 'D.10.2.1',
    label: t('ICSR:elementName:D.10.2.1'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.2.2a': {
    elementNumber: 'D.10.2.2a',
    label: t('ICSR:elementName:D.10.2.2a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '3',
  },
  'D.10.2.2b': {
    elementNumber: 'D.10.2.2b',
    label: t('ICSR:elementName:D.10.2.2b'),
    component: 'select',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26_3']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'D.10.3': {
    elementNumber: 'D.10.3',
    label: t('ICSR:elementName:D.10.3'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.4': {
    elementNumber: 'D.10.4',
    label: t('ICSR:elementName:D.10.4'),
    component: 'input',
    maxLength: '6',
    decimalPoint: true,
  },
  'D.10.5': {
    elementNumber: 'D.10.5',
    label: t('ICSR:elementName:D.10.5'),
    component: 'input',
    maxLength: '3',
  },
  'D.10.6': {
    elementNumber: 'D.10.6',
    label: t('ICSR:elementName:D.10.6'),
    component: 'radio',
    options: codeList['1.0.5218']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
    valueLabel: true,
  },
  'D.10.7.1.r': {
    elementNumber: 'D.10.7.1.r',
    list: [
      {
        elementNumber: 'D.10.7.1.r.1a',
        label: t('ICSR:elementName:D.10.7.1.r.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'D.10.7.1.r.1b',
        label: t('ICSR:elementName:D.10.7.1.r.1b'),
        component: 'MedDRASearch',
      },
      {
        elementNumber: 'D.10.7.1.r.2',
        label: t('ICSR:elementName:D.10.7.1.r.2'),
        component: 'date',
      },
      {
        elementNumber: 'D.10.7.1.r.4',
        label: t('ICSR:elementName:D.10.7.1.r.4'),
        component: 'date',
      },
    ],
  },
  'D.10.7.1.r.1a': {
    elementNumber: 'D.10.7.1.r.1a',
    label: t('ICSR:elementName:D.10.7.1.r.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.10.7.1.r.1b': {
    elementNumber: 'D.10.7.1.r.1b',
    label: t('ICSR:elementName:D.10.7.1.r.1b'),
    component: 'MedDRASearch',
  },
  'D.10.7.1.r.2': {
    elementNumber: 'D.10.7.1.r.2',
    label: t('ICSR:elementName:D.10.7.1.r.2'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.7.1.r.3': {
    elementNumber: 'D.10.7.1.r.3',
    label: t('ICSR:elementName:D.10.7.1.r.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['MSK', 'UNK', 'ASKU', 'NASK'],
  },
  'D.10.7.1.r.4': {
    elementNumber: 'D.10.7.1.r.4',
    label: t('ICSR:elementName:D.10.7.1.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.7.1.r.5': {
    elementNumber: 'D.10.7.1.r.5',
    label: t('ICSR:elementName:D.10.7.1.r.5'),
    component: 'textarea',
    maxLength: '2000',
  },
  'D.10.7.2': {
    elementNumber: 'D.10.7.2',
    label: t('ICSR:elementName:D.10.7.2'),
    component: 'textarea',
    maxLength: '10000',
  },
  'D.10.8.r': {
    elementNumber: 'D.10.8.r',
    list: [
      {
        elementNumber: 'D.10.8.r.1',
        label: t('ICSR:elementName:D.10.8.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'D.10.8.r.4',
        label: t('ICSR:elementName:D.10.8.r.4'),
        component: 'date',
      },
      {
        elementNumber: 'D.10.8.r.5',
        label: t('ICSR:elementName:D.10.8.r.5'),
        component: 'date',
      },
    ],
  },
  'D.10.8.r.1': {
    elementNumber: 'D.10.8.r.1',
    label: t('ICSR:elementName:D.10.8.r.1'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.10.8.r.2a': {
    elementNumber: 'D.10.8.r.2a',
    label: t('ICSR:elementName:D.10.8.r.2a'),
    component: 'input',
    maxLength: '10',
  },
  'D.10.8.r.2b': {
    elementNumber: 'D.10.8.r.2b',
    label: t('ICSR:elementName:D.10.8.r.2b'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.3a': {
    elementNumber: 'D.10.8.r.3a',
    label: t('ICSR:elementName:D.10.8.r.3a'),
    component: 'input',
    maxLength: '10',
  },
  'D.10.8.r.3b': {
    elementNumber: 'D.10.8.r.3b',
    label: t('ICSR:elementName:D.10.8.r.3b'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.10.8.r.4': {
    elementNumber: 'D.10.8.r.4',
    label: t('ICSR:elementName:D.10.8.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.8.r.5': {
    elementNumber: 'D.10.8.r.5',
    label: t('ICSR:elementName:D.10.8.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'D.10.8.r.6a': {
    elementNumber: 'D.10.8.r.6a',
    label: t('ICSR:elementName:D.10.8.r.6a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.10.8.r.6b': {
    elementNumber: 'D.10.8.r.6b',
    label: t('ICSR:elementName:D.10.8.r.6b'),
    component: 'MedDRASearch',
  },
  'D.10.8.r.7a': {
    elementNumber: 'D.10.8.r.7a',
    label: t('ICSR:elementName:D.10.8.r.7a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'D.10.8.r.7b': {
    elementNumber: 'D.10.8.r.7b',
    label: t('ICSR:elementName:D.10.8.r.7b'),
    component: 'MedDRASearch',
  },
});

export default patientCharacteristics;
