import React, { HTMLAttributes } from 'react';

import Modify from 'utils/typeModify';

import ListSkeleton from './ListSkeleton';

type Props = Modify<
  HTMLAttributes<HTMLTableSectionElement>,
  {
    isFetching?: boolean;
    rows?: number;
    cols?: number;
    disableCheckbox?: boolean;
  }
>;

function LTBody({
  className,
  isFetching,
  rows = 0,
  cols = 0,
  disableCheckbox,
  children,
}: Props) {
  return (
    <tbody className={className}>
      {isFetching ? (
        <ListSkeleton
          rows={rows}
          cols={cols}
          disableCheckbox={disableCheckbox}
        />
      ) : (
        children
      )}
    </tbody>
  );
}

export default LTBody;
