import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { getMedicalProducts } from 'api/whodrug';
import { WHODrugProductCode } from 'types/codes';

import Popup from 'components/common/Popup';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Select from 'components/common/Select/Select';
import Checkbox from 'components/common/Checkbox';
import { ThreeDots } from 'react-loader-spinner';

const WHODrugSearchTypes = [
  { label: 'MPID', value: 'medicinal_prod_id' },
  { label: 'Drug Name', value: 'drug_name' },
  { label: 'Drug Code', value: 'drug_code' },
  { label: 'Ingredient', value: 'substance_name' },
];

function WHODrugProduct({
  isOpen,
  version,
  country,
  toggle,
  callback,
}: {
  isOpen: boolean;
  version?: string;
  country?: string;
  toggle: () => void;
  callback: (c: WHODrugProductCode) => void;
}) {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState('');
  const [searchType, setSearchType] = useState('');
  const [option, setOption] = useState({
    genericOrPreferred: false,
    genericAndPreferred: false,
    exactMatch: false,
  });
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState('');

  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    ['getMedicalProducts', query],
    async ({ pageParam = 0 }) => {
      const res = await getMedicalProducts({
        query: { page: pageParam, perPage: 50 },
        version,
        country,
        keyword,
        searchType,
        genericOrPreferred: option.genericOrPreferred,
        genericAndPreferred: option.genericAndPreferred,
        exactMatch: option.exactMatch,
      });
      return res.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (prev, pages) => {
        const { page, size, items, totalCount } = prev;
        return page * size + items.length >= totalCount
          ? undefined
          : pages.length + 1;
      },
    },
  );

  const handleChangeOption = (name: string, checked: boolean) => {
    setOption((prev) => ({ ...prev, [name]: checked }));
  };

  const handleClick = (code: WHODrugProductCode) => {
    callback(code);
    toggle();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setQuery(
      `${version}${country}${searchType}${keyword}${JSON.stringify(option)}`,
    );
  };

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    {
      offset: 100,
      debounce: 500,
      triggerOnNoScroll: false,
    },
  );

  useEffect(() => {
    if (searchType && keyword.length > 1) {
      remove();
      refetch();
    } else {
      setMessage(`* ${t('WHODrug:searchRequirement')}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Popup isOpen={isOpen} className="flex h-fit w-[85rem] flex-col">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        {`WHODrug Global (Ver. ${version})`}
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <form className="mt-2" onSubmit={handleSubmit}>
        <div className="mb-1 flex justify-between">
          <div className="flex">
            <Input className="mr-1 w-20" value={country || 'ALL'} readOnly />
            <Select
              className="w-40"
              value={searchType}
              data={WHODrugSearchTypes}
              valueField="value"
              textField="label"
              onChange={({ value }) => setSearchType(value)}
            />
          </div>
          <div className="flex space-x-4">
            <Checkbox
              name="genericOrPreferred"
              label="Generic or Preferred"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeOption('genericOrPreferred', e.target.checked)
              }
            />
            <Checkbox
              name="genericAndPreferred"
              label="Generic and Preferred"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeOption('genericAndPreferred', e.target.checked)
              }
            />
            <Checkbox
              name="exactMatch"
              label="Exact Match"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeOption('exactMatch', e.target.checked)
              }
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="flex-1">
            <Input
              className="w-full"
              value={keyword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setKeyword(e.target.value)
              }
            />
            <div>{message}</div>
          </div>
          <Button
            type="submit"
            disabled={isFetchingNextPage || !searchType || keyword.length < 2}
          >
            {t('common:search')}
          </Button>
        </div>
      </form>
      <div className="relative my-2 flex h-[35rem]">
        <div ref={scrollRef} className="overflow-auto bg-inherit">
          <table className="w-full table-fixed border-separate border-spacing-0 text-slate-600">
            <thead className="sticky -top-px bg-white [&_th]:border-t [&_th]:border-b [&_th]:border-r [&_th]:p-2">
              <tr>
                <th className="border-l">MPID</th>
                <th>Drug Name</th>
                <th>Drug Code</th>
                <th>Name Specifier</th>
                <th>Ingredient(s)</th>
                <th>Generic</th>
                <th>Preferred</th>
                <th>Country</th>
                <th>MAH</th>
                <th>Pharm. Form</th>
                <th>Pharm. strength</th>
                <th>ATC Name</th>
              </tr>
            </thead>
            <tbody className="[&_td]:break-words [&_td]:break-all [&_td]:border-b [&_td]:border-r [&_td]:py-1 [&_td]:px-2 [&_td]:first:border-l">
              {data
                ? data.pages.map(({ items }) =>
                    items.map((item: WHODrugProductCode) => (
                      <tr
                        key={item.medicinalProdId}
                        className="cursor-pointer hover:bg-brand-400 hover:text-white"
                        onClick={() => handleClick(item)}
                      >
                        <td className="border-l">{item.medicinalProdId}</td>
                        <td>{item.drugName}</td>
                        <td>{item.drugCode}</td>
                        <td>{item.nameSpecifier}</td>
                        <td>{item.substanceName}</td>
                        <td>{item.generic}</td>
                        <td>{item.preferred}</td>
                        <td>{item.country}</td>
                        <td>{item.marketingAuthorizationHolder}</td>
                        <td>{item.pharmForm}</td>
                        <td>{item.strength}</td>
                        <td>{item.atcName}</td>
                      </tr>
                    )),
                  )
                : null}
            </tbody>
          </table>
        </div>
        {isFetching ? (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-gray-500/75">
            <ThreeDots
              height="80"
              width="80"
              ariaLabel="tail-spin-loading"
              color="#6C63FF"
              radius="1"
              visible
            />
          </div>
        ) : null}
      </div>
    </Popup>
  );
}

export default WHODrugProduct;
