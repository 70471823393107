import getQueryString from 'utils/getQueryString';
import client from './client';

export const getSubmissions = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/submission-summaries?companyId=${companyId}&${queryString}`,
  );
};

export const getSubmissionsDetails = ({
  submissionId,
  companyId,
  query,
}: {
  submissionId?: number | string;
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/submission-summaries/${submissionId}?companyId=${companyId}&${queryString}`,
  );
};
