import React, { useCallback, useState } from 'react';
import { TbHelp } from 'react-icons/tb';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/common/Dropdown';
import { UserType } from 'types/user';

import { userState } from 'state';

const dropItemStyles = clsx(
  'group px-3 py-1 hover:bg-slate-100',
  'dark:hover:bg-slate-900',
);

function SidebarHelp() {
  const [user] = useRecoilState(userState);

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const openFreshworksWidget = useCallback(() => {
    if (!user) return;
    const { userName: name, email } = user;
    window.FreshworksWidget('identify', 'ticketForm', { name, email });
    window.FreshworksWidget('open');
    toggle();
  }, [user]);

  if (user?.userType !== UserType.user) return null;

  return (
    <div className="overflow-hidden px-4">
      <Dropdown isOpen={open} toggle={toggle} location="up" tabIndex={0}>
        <DropdownToggle className="flex h-9 w-full min-w-[2.25rem] items-center overflow-hidden">
          <button className="mb-1 flex h-9 min-w-[2.25rem] flex-shrink-0 items-center overflow-hidden rounded text-slate-500 hover:text-brand-500">
            <TbHelp className="h-9 w-9 flex-shrink-0 p-2" />
            <span className="ml-2 inline-block flex-1 text-left">Help</span>
          </button>
        </DropdownToggle>
        <DropdownMenu className="mt-1" width="12rem">
          <ul
            className={clsx(
              'min-w-none rounded border border-slate-200 bg-white py-2 text-slate-600 shadow',
              'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
            )}
          >
            <DropdownItem className={dropItemStyles}>
              <a
                className="block"
                href="https://docs.v2.csafer.co.kr"
                target="_blank"
                rel="noreferrer"
              >
                Manual
              </a>
            </DropdownItem>
            <DropdownItem className={dropItemStyles}>
              <a
                className="block"
                href="https://cnrres.notion.site/CsafeR-Education-5beafbbb694141b4bf92463354f14bf5"
                target="_blank"
                rel="noreferrer"
              >
                Education
              </a>
            </DropdownItem>
            <DropdownItem className={dropItemStyles}>
              <button
                className="w-full text-left"
                onClick={openFreshworksWidget}
              >
                Support Ticket
              </button>
            </DropdownItem>
          </ul>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default SidebarHelp;
