import { useCallback, useEffect, useState } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import produce from 'immer';
import { Moment } from 'moment-timezone';

import { OrderType } from 'utils/commonType';

export interface QueryFilterType {
  [key: string]: string | string[] | (Moment | undefined)[] | undefined;
}
export interface QueryType {
  page?: number;
  perPage?: number;
  order?: OrderType;
  orderBy?: string;
  q?: QueryFilterType;
}

const useListQuery = ({
  defaultQuery,
  queryKey,
  refreshKey,
  queryFn,
  options,
}: {
  defaultQuery?: QueryType;
  queryKey: string;
  refreshKey?: string | number;
  queryFn: (data: any) => Promise<AxiosResponse<any, any>>;
  options?: UseQueryOptions<
    AxiosResponse<any, any>,
    AxiosError<any, any>,
    AxiosResponse<any, any>,
    any
  >;
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [queryRefreshKey, setQueryRefreshKey] = useState<
    string | number | undefined
  >(refreshKey);
  const [query, setQuery] = useState<QueryType>({
    page: defaultQuery?.page || 1,
    perPage: defaultQuery?.perPage || 10,
    order: defaultQuery?.order || 'desc',
    orderBy: defaultQuery?.orderBy || 'id',
    q: { deleted: ['false'], ...defaultQuery?.q },
  });

  const {
    data: res,
    refetch,
    ...rest
  } = useQuery([queryKey, queryRefreshKey, query], () => queryFn(query), {
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  const setFilter = useCallback(
    (v: (base: any) => any) =>
      setQuery((prev) => ({ ...prev, page: 1, q: v(prev.q) })),
    [],
  );

  const changeSort = useCallback((order: OrderType, orderBy: string) => {
    setQuery(
      produce((draft) => {
        draft.order = order;
        draft.orderBy = orderBy;
      }),
    );
  }, []);

  const changePage = useCallback((p: number) => {
    setQuery(
      produce((draft) => {
        draft.page = p;
      }),
    );
    setSelected([]);
  }, []);

  const changePerPage = useCallback((p: number) => {
    setQuery(
      produce((draft) => {
        draft.perPage = p;
      }),
    );
    setSelected([]);
  }, []);

  const select = useCallback(
    (key: number | undefined, checked: boolean) =>
      setSelected((prev) => {
        if (key && checked) {
          return [...prev, key];
        }
        return prev.filter((item: number) => item !== key);
      }),
    [],
  );

  const selectAll = useCallback(
    () => setSelected((res?.data.content || []).map((item: any) => item.id)),
    [res],
  );

  const reset = useCallback(() => setSelected([]), []);

  useEffect(() => {
    if (queryRefreshKey !== refreshKey) {
      setQueryRefreshKey(refreshKey);
      setQuery({
        page: defaultQuery?.page || 1,
        perPage: defaultQuery?.perPage || 10,
        order: defaultQuery?.order || 'desc',
        orderBy: defaultQuery?.orderBy || 'id',
        q: { deleted: ['false'], ...defaultQuery?.q },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  return {
    data: res?.data.content || null,
    page: res?.data.number || 0,
    lastPage: res?.data.totalPages || 0,
    totalElements: res?.data.totalElements || 0,
    query,
    selected,
    setQuery,
    setFilter,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    refetch,
    ...rest,
  };
};

export default useListQuery;
