import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { IoClose } from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import ISO6391 from 'iso-639-1';

import { codeSetsState } from 'state';
import { getMeddraTerms } from 'api/meddra';
import { MedDRACode } from 'types/codes';

import Popup from 'components/common/Popup';
import Select from 'components/common/Select/Select';
import Input from 'components/common/Input';
import Button from 'components/common/Button';

function MedDRA({
  isOpen,
  version,
  toggle,
  callback,
}: {
  isOpen: boolean;
  version?: string;
  toggle: () => void;
  callback: (c: MedDRACode) => void;
}) {
  const { t } = useTranslation();
  const [codeSets] = useRecoilState(codeSetsState);

  const [language, setLanguage] = useState('en');
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState('');

  const {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    ['getMeddraTerms', query],
    async ({ pageParam = 0 }) => {
      const res = await getMeddraTerms({
        query: { page: pageParam, perPage: 50 },
        version,
        language,
        keyword,
      });
      return res.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (prev, pages) => {
        const { page, size, items, totalCount } = prev;
        return page * size + items.length >= totalCount
          ? undefined
          : pages.length + 1;
      },
    },
  );

  const handleClick = (code: MedDRACode) => {
    callback(code);
    toggle();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setQuery(`${version}${language}${keyword}`);
  };

  const languageList = useMemo(
    () =>
      codeSets.meddra_version
        ?.find(({ version: meddraVersion }: any) => meddraVersion === version)
        ?.languages.map((value: string) => ({
          value,
          label: ISO6391.getName(value),
        })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [version],
  );

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    {
      offset: 100,
      debounce: 500,
      triggerOnNoScroll: false,
    },
  );

  useEffect(() => {
    if (keyword.length > 1) {
      remove();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Popup isOpen={isOpen} className="flex h-fit w-[80rem] flex-col">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        {`MedDRA Code v${version}`}
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <form className="mt-2 flex space-x-2" onSubmit={handleSubmit}>
        <Select
          className="w-40"
          value={language}
          data={languageList}
          placeholder="Select"
          valueField="value"
          textField="label"
          onChange={({ value }) => setLanguage(value)}
        />
        <Input
          className="w-[30rem]"
          value={keyword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setKeyword(e.target.value)
          }
        />
        <Button type="submit" disabled={keyword.length < 2}>
          {t('common:search')}
        </Button>
      </form>
      <div className="relative my-2 flex h-[35rem]">
        <div ref={scrollRef} className="overflow-auto bg-inherit">
          <table className="w-full table-fixed border-separate border-spacing-0 text-slate-600">
            <thead className="sticky top-0 bg-white [&_th]:border-t [&_th]:border-b [&_th]:border-r [&_th]:p-2">
              <tr>
                <th className="border-l">LLT Code</th>
                <th>LLT Name</th>
                <th>PT Name</th>
              </tr>
            </thead>
            <tbody className="[&_td]:break-all [&_td]:border-b [&_td]:border-r [&_td]:py-1 [&_td]:px-2 [&_td]:first:border-l">
              {data
                ? data.pages.map(({ items }) =>
                    items.map((item: MedDRACode) => (
                      <tr
                        key={item.lltCode}
                        className="cursor-pointer hover:bg-brand-400 hover:text-white"
                        onClick={() => handleClick(item)}
                      >
                        <td className="border-l">{item.lltCode}</td>
                        <td>{item.lltName}</td>
                        <td>{item.ptName}</td>
                      </tr>
                    )),
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </Popup>
  );
}

export default MedDRA;
