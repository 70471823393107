import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

import modal from 'utils/modal';
import {
  getPatientMedicalRecord,
  getPatientMedicalRecords,
} from 'api/patients';
import { getCase } from 'api/cases';
import { getCaseForm } from 'utils/caseForm';
import { ICSRFormMap } from 'utils/convertICSRForm';

function elementForm(array: any[], initForm: any) {
  const form: any = initForm;
  array.forEach(
    ({
      number,
      elements,
      value,
      translatedValue,
      nullFlavor,
      description,
      deleted,
    }: any) => {
      if (!ICSRFormMap[number]) return;
      if (ICSRFormMap[number].repeat) {
        if (ICSRFormMap[number].root) {
          const rootElements = elementForm(elements, {});
          form[number] = [
            ...(form[number] || []),
            {
              number,
              elements: rootElements,
              deleted,
            },
          ];
        } else {
          form[number] = [
            ...(form[number] || []),
            {
              elements: {
                [number]: {
                  number,
                  value,
                  translatedValue,
                  nullFlavor,
                  description,
                  deleted,
                },
              },
            },
          ];
        }
      } else if (ICSRFormMap[number].root) {
        const rootElements = elementForm(
          elements,
          form[number]?.elements || {},
        );
        form[number] = {
          number,
          elements: rootElements,
          deleted,
        };
      } else {
        form[number] = {
          number,
          value,
          translatedValue,
          nullFlavor,
          description,
          deleted,
        };
      }
    },
  );

  return form;
}

function usePatientMedicalRecord(
  companyId: number | undefined,
  sponsorStudyNumber: string | undefined,
) {
  const { t } = useTranslation();
  const [patientMedicalRecords, setPatientMedicalRecords] = useState([]);

  const debounced = useRef(async (ssNumber: string) => {
    try {
      const { data } = await getPatientMedicalRecords(companyId, ssNumber);
      setPatientMedicalRecords(data);
    } catch (error: any) {
      setPatientMedicalRecords([]);
    }
  });

  const getRecord = useCallback(
    async (value: string) => {
      try {
        const { data } = await getPatientMedicalRecord(companyId, value);
        const { CaseID: caseId } = data.Item;

        const check = await modal.confirm(
          t('case:patientRecord:0'),
          t('case:patientRecord:1'),
        );
        if (check) {
          const {
            data: { elements },
          } = await getCase(caseId);
          const form = elementForm(elements, getCaseForm());
          const patientCharacteristics = form['D'];
          const drugs = form['G.k']
            .filter(
              (drug: any) =>
                // only suspect & concomitant drug
                drug.elements['G.k.1']?.value === '1' ||
                drug.elements['G.k.1']?.value === '2',
            )
            .map((drug: any) => {
              drug.elements['G.k.CNR.1'] = { value: uuidV4() };
              drug.elements['G.k.9.i'] = [];
              return drug;
            });
          return [patientCharacteristics, drugs];
        }
      } catch (error: any) {
        console.error(error);
      }
      return [];
    },
    [companyId, t],
  );

  useEffect(() => {
    if (sponsorStudyNumber) {
      debounced.current(sponsorStudyNumber);
    }
  }, [sponsorStudyNumber]);

  return {
    patientMedicalRecords,
    getRecord,
  };
}

export default usePatientMedicalRecord;
