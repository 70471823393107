import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { postImportFile } from 'api/import';
import { getProducts } from 'api/products';
import { getStudies } from 'api/studies';
import { getDatasets } from 'api/dataset';

import { Upload, UploadFilenameExtension, UploadTypes } from 'types/upload';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { codeSetsState, companyState } from 'state';

import { DatePicker } from 'components/common/DatePicker';
import FileUpload from 'components/common/FileUpload';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import Button from 'components/common/Button';
import Download from 'components/common/Donwload';

const UploadTypeList = Object.entries(UploadTypes).map(
  ([key, value]: [string, string]) => ({ key, value }),
);

function UploadForm() {
  const { t } = useTranslation();
  const [{ selected: company }] = useRecoilState(companyState);
  const [
    {
      CNR_MFDS_RECEIVER: { codes },
    },
  ] = useRecoilState(codeSetsState);
  const { form, errors, register, setValue, handleSubmit } = useForm<Upload>({
    defaultValues: { productIds: [] },
  });

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { data: studies } = useQuery(['getStudies'], () =>
    getStudies({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { data: datasets } = useQuery(['getDatasets'], () =>
    getDatasets({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(postImportFile);

  const onSubmit = handleSubmit(async ({ files, ...rest }) => {
    if (files) {
      if (
        await modal.confirm(t('modal:confirm:title'), t('modal:confirm:import'))
      ) {
        const formData = new FormData();
        [].forEach.call(files, (file) => {
          formData.append('file', file);
        });
        mutate({
          companyId: company?.id,
          formData,
          ...rest,
        });
      }
    }
  });

  const filteredStudys = useMemo(() => {
    if (!studies) return [];
    return studies?.filter((study: any) =>
      form.productIds.every((productId: number) =>
        study.productIdList.includes(productId),
      ),
    );
  }, [form.productIds, studies]);

  useEffect(() => {
    register({ name: 'type' }, { required: t('error:required') });
    register(
      { name: 'importSettingId' },
      {
        validate: (value: any, originForm: any) =>
          originForm.type !== UploadTypes.CUBE ||
          value != null ||
          t('error:required'),
      },
    );
    register(
      { name: 'receiverType' },
      {
        validate: (value: any, originForm: any) =>
          originForm.type !== UploadTypes.CUBE ||
          !!value ||
          t('error:required'),
      },
    );
    register({ name: 'productIds' }, { required: t('error:required') });
    register({ name: 'studyId' });
    register({ name: 'creationDate' });
    register({ name: 'recentDate' });
    register({ name: 'files' }, { required: t('error:required') });
  }, [register, t]);

  return (
    <form className="max-w-4xl" onSubmit={onSubmit}>
      <div className="space-y-1 pt-1">
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Type</div>
          <div className="flex w-1/2 flex-wrap">
            <Select
              className="w-full"
              value={form.type}
              data={UploadTypeList}
              valueField="key"
              textField="value"
              onChange={(obj) => setValue('type', obj.key)}
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.type?.message}
            </span>
          </div>
        </div>
        {form.type === UploadTypes.CUBE && (
          <>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">EDC Data Setting Rule</div>
              <div className="flex w-1/2 flex-wrap">
                <Select
                  className="w-full"
                  value={form.importSettingId}
                  data={datasets}
                  valueField="id"
                  textField="name"
                  onChange={(obj) => setValue('importSettingId', obj.id)}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.importSettingId?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">MFDS Receiver Type</div>
              <div className="flex w-1/2 flex-wrap">
                <Select
                  className="w-full"
                  value={form.receiverType}
                  data={codes}
                  valueField="key"
                  textField={(dataItem: any) =>
                    dataItem && `${dataItem.key}:${dataItem.value}`
                  }
                  onChange={(obj) => setValue('receiverType', obj.key)}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.receiverType?.message}
                </span>
              </div>
            </div>
          </>
        )}
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Product</div>
          <div className="flex w-1/2 flex-wrap">
            <MultiSelect
              className="w-full"
              value={form.productIds}
              data={products || []}
              valueField="id"
              textField={(item) => item.brandName || item.productName || item}
              onChange={(obj: any) =>
                setValue(
                  'productIds',
                  obj.map((el: any) => el.id),
                )
              }
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.productIds?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Study</div>
          <div className="w-1/2">
            <Select
              className="w-full"
              value={form.studyId}
              data={filteredStudys}
              valueField="id"
              textField="sponsorStudyNumber"
              onChange={(obj: any) => setValue('studyId', obj.id)}
              disabled={!form.productIds.length}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Date of Creation (C.1.2)</div>
          <div className="w-1/2">
            <DatePicker
              className="w-full"
              format="YYYYMMDDHHmmssZZ"
              allowedFormat={['YYYYMMDDHHmmssZZ', 'YYYYMMDDHHmmss']}
              value={form.creationDate}
              onChange={(date) =>
                setValue(
                  'creationDate',
                  date?.isValid() ? date.format('YYYYMMDDHHmmssZZ') : undefined,
                )
              }
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">
            Date of Most Recent Information for This Report (C.1.5)
          </div>
          <div className="w-1/2">
            <DatePicker
              className="w-full"
              value={form.recentDate}
              format="YYYYMMDD"
              onChange={(date) =>
                setValue(
                  'recentDate',
                  date?.isValid() ? date.format('YYYYMMDD') : undefined,
                )
              }
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Search File</div>
          <div className="flex w-1/2 flex-wrap">
            <FileUpload
              className="w-full"
              value={form.files}
              onChange={(value: any) => setValue('files', value)}
              accept={form.type && UploadFilenameExtension[form.type]}
              // error={errors.files}
              disabled={!form.type}
              multiple
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.organizationName?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <p>The upload result can be checked on the History page.</p>
        </div>
      </div>
      <div className="my-4 space-x-1 text-right">
        <Download
          src={`${process.env.REACT_APP_API_HOST}/api/v1/transform/import/template`}
        >
          <Button color="red">Template Download</Button>
        </Download>
        <Button type="submit" color="slate">
          Upload
        </Button>
      </div>
    </form>
  );
}

export default React.memo(UploadForm);
