import { TFunction } from 'i18next';
import ICH from './ICH';

const EMA = (codeList: any, t: TFunction) => ({
  ...ICH(codeList, t),
  'C.1.CNR.1': {
    elementNumber: 'C.1.CNR.1',
    label: 'EMA Receiver Type',
    component: 'radio',
    options: codeList.CNR_EMA_RECEIVER?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.2.r.4': {
    elementNumber: 'C.2.r.4',
    label: t('ICSR:elementName:C.2.r.4'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.6']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.4.r.1': {
    elementNumber: 'C.4.r.1',
    label: t('ICSR:elementName:C.4.r.1'),
    component: 'textarea',
    maxLength: '500',
  },
  'C.5.1.r.2': {
    elementNumber: 'C.5.1.r.2',
    label: t('ICSR:elementName:C.5.1.r.2'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'D.1': {
    elementNumber: 'D.1',
    label: t('ICSR:elementName:D.1'),
    component: 'input',
    maxLength: '60',
    nullFlavorList: ['MSK', 'UNK'],
    required: true,
  },
  'D.5': {
    elementNumber: 'D.5',
    label: t('ICSR:elementName:D.5'),
    component: 'radio',
    options: codeList['1.0.5218']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
    valueLabel: true,
  },
  'D.6': {
    elementNumber: 'D.6',
    label: t('ICSR:elementName:D.6'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
  },
  'D.7.1.r.2': {
    elementNumber: 'D.7.1.r.2',
    label: t('ICSR:elementName:D.7.1.r.2'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.7.1.r.3': {
    elementNumber: 'D.7.1.r.3',
    label: t('ICSR:elementName:D.7.1.r.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'D.7.1.r.4': {
    elementNumber: 'D.7.1.r.4',
    label: t('ICSR:elementName:D.7.1.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.7.2': {
    elementNumber: 'D.7.2',
    label: t('ICSR:elementName:D.7.2'),
    component: 'textarea',
    maxLength: '10000',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'D.8.r.1.EU.1': {
    elementNumber: 'D.8.r.1.EU.1',
    label: t('ICSR:elementName:D.8.r.1.EU.1'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.2': {
    elementNumber: 'D.8.r.1.EU.2',
    label: t('ICSR:elementName:D.8.r.1.EU.2'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.3': {
    elementNumber: 'D.8.r.1.EU.3',
    label: t('ICSR:elementName:D.8.r.1.EU.3'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.4': {
    elementNumber: 'D.8.r.1.EU.4',
    label: t('ICSR:elementName:D.8.r.1.EU.4'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.5': {
    elementNumber: 'D.8.r.1.EU.5',
    label: t('ICSR:elementName:D.8.r.1.EU.5'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.6': {
    elementNumber: 'D.8.r.1.EU.6',
    label: t('ICSR:elementName:D.8.r.1.EU.6'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.7': {
    elementNumber: 'D.8.r.1.EU.7',
    label: t('ICSR:elementName:D.8.r.1.EU.7'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.1.EU.8': {
    elementNumber: 'D.8.r.1.EU.8',
    label: t('ICSR:elementName:D.8.r.1.EU.8'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.8.r.EU.r': {
    elementNumber: 'D.8.r.EU.r',
    list: [
      {
        elementNumber: 'D.8.r.EU.r.1',
        label: 'Substance / Specified Substance Name',
        component: 'input',
      },
    ],
  },
  'D.8.r.EU.r.1': {
    elementNumber: 'D.8.r.EU.r.1',
    label: t('ICSR:elementName:D.8.r.EU.r.1'),
    component: 'input',
    maxLength: '250',
  },
  'D.8.r.EU.r.2a': {
    elementNumber: 'D.8.r.EU.r.2a',
    label: t('ICSR:elementName:D.8.r.EU.r.2a'),
    component: 'input',
    maxLength: '10',
  },
  'D.8.r.EU.r.2b': {
    elementNumber: 'D.8.r.EU.r.2b',
    label: t('ICSR:elementName:D.8.r.EU.r.2b'),
    component: 'input',
    maxLength: '100',
  },
  'D.8.r.EU.r.3a': {
    elementNumber: 'D.8.r.EU.r.3a',
    label: t('ICSR:elementName:D.8.r.EU.r.3a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '10',
  },
  'D.8.r.EU.r.3b': {
    elementNumber: 'D.8.r.EU.r.3b',
    label: t('ICSR:elementName:D.8.r.EU.r.3b'),
    component: 'input',
    placeholder: 'UNIT',
    maxLength: '50',
  },
  'D.8.r.4': {
    elementNumber: 'D.8.r.4',
    label: t('ICSR:elementName:D.8.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.8.r.5': {
    elementNumber: 'D.8.r.5',
    label: t('ICSR:elementName:D.8.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.9.1': {
    elementNumber: 'D.9.1',
    label: t('ICSR:elementName:D.9.1'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.10.3': {
    elementNumber: 'D.10.3',
    label: t('ICSR:elementName:D.10.3'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.10.6': {
    elementNumber: 'D.10.6',
    label: t('ICSR:elementName:D.10.6'),
    component: 'radio',
    options: codeList['1.0.5218']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
    valueLabel: true,
  },
  'D.10.7.1.r.2': {
    elementNumber: 'D.10.7.1.r.2',
    label: t('ICSR:elementName:D.10.7.1.r.2'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.10.7.1.r.3': {
    elementNumber: 'D.10.7.1.r.3',
    label: t('ICSR:elementName:D.10.7.1.r.3'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'D.10.7.1.r.4': {
    elementNumber: 'D.10.7.1.r.4',
    label: t('ICSR:elementName:D.10.7.1.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.10.8.r.1': {
    elementNumber: 'D.10.8.r.1',
    label: t('ICSR:elementName:D.10.8.r.1'),
    component: 'textarea',
    maxLength: '250',
  },
  'D.10.8.r.1.EU.1': {
    elementNumber: 'D.10.8.r.1.EU.1',
    label: t('ICSR:elementName:D.10.8.r.1.EU.1'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.2': {
    elementNumber: 'D.10.8.r.1.EU.2',
    label: t('ICSR:elementName:D.10.8.r.1.EU.2'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.3': {
    elementNumber: 'D.10.8.r.1.EU.3',
    label: t('ICSR:elementName:D.10.8.r.1.EU.3'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.4': {
    elementNumber: 'D.10.8.r.1.EU.4',
    label: t('ICSR:elementName:D.10.8.r.1.EU.4'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.5': {
    elementNumber: 'D.10.8.r.1.EU.5',
    label: t('ICSR:elementName:D.10.8.r.1.EU.5'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.6': {
    elementNumber: 'D.10.8.r.1.EU.6',
    label: t('ICSR:elementName:D.10.8.r.1.EU.6'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.7': {
    elementNumber: 'D.10.8.r.1.EU.7',
    label: t('ICSR:elementName:D.10.8.r.1.EU.7'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.1.EU.8': {
    elementNumber: 'D.10.8.r.1.EU.8',
    label: t('ICSR:elementName:D.10.8.r.1.EU.8'),
    component: 'textarea',
    maxLength: '1000',
  },
  'D.10.8.r.EU.r': {
    elementNumber: 'D.10.8.r.EU.r',
    list: [
      {
        elementNumber: 'D.10.8.r.EU.r.1',
        label: 'Substance / Specified Substance Name',
        component: 'input',
      },
    ],
  },
  'D.10.8.r.EU.r.1': {
    elementNumber: 'D.10.8.r.EU.r.1',
    label: t('ICSR:elementName:D.10.8.r.EU.r.1'),
    component: 'input',
    maxLength: '250',
  },
  'D.10.8.r.EU.r.2a': {
    elementNumber: 'D.10.8.r.EU.r.2a',
    label: t('ICSR:elementName:D.10.8.r.EU.r.2a'),
    component: 'input',
    maxLength: '10',
  },
  'D.10.8.r.EU.r.2b': {
    elementNumber: 'D.10.8.r.EU.r.2b',
    label: t('ICSR:elementName:D.10.8.r.EU.r.2b'),
    component: 'input',
    maxLength: '100',
  },
  'D.10.8.r.EU.r.3a': {
    elementNumber: 'D.10.8.r.EU.r.3a',
    label: t('ICSR:elementName:D.10.8.r.EU.r.3a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '10',
  },
  'D.10.8.r.EU.r.3b': {
    elementNumber: 'D.10.8.r.EU.r.3b',
    label: t('ICSR:elementName:D.10.8.r.EU.r.3b'),
    component: 'input',
    placeholder: 'UNIT',
    maxLength: '50',
  },
  'D.10.8.r.4': {
    elementNumber: 'D.10.8.r.4',
    label: t('ICSR:elementName:D.10.8.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'D.10.8.r.5': {
    elementNumber: 'D.10.8.r.5',
    label: t('ICSR:elementName:D.10.8.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'E.i.4': {
    elementNumber: 'E.i.4',
    label: t('ICSR:elementName:E.i.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'E.i.5': {
    elementNumber: 'E.i.5',
    label: t('ICSR:elementName:E.i.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'G.k.2.2.EU.1': {
    elementNumber: 'G.k.2.2.EU.1',
    label: t('ICSR:elementName:G.k.2.2.EU.1'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.2': {
    elementNumber: 'G.k.2.2.EU.2',
    label: t('ICSR:elementName:G.k.2.2.EU.2'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.3': {
    elementNumber: 'G.k.2.2.EU.3',
    label: t('ICSR:elementName:G.k.2.2.EU.3'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.4': {
    elementNumber: 'G.k.2.2.EU.4',
    label: t('ICSR:elementName:G.k.2.2.EU.4'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.5': {
    elementNumber: 'G.k.2.2.EU.5',
    label: t('ICSR:elementName:G.k.2.2.EU.5'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.6': {
    elementNumber: 'G.k.2.2.EU.6',
    label: t('ICSR:elementName:G.k.2.2.EU.6'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.7': {
    elementNumber: 'G.k.2.2.EU.7',
    label: t('ICSR:elementName:G.k.2.2.EU.7'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.8': {
    elementNumber: 'G.k.2.2.EU.8',
    label: t('ICSR:elementName:G.k.2.2.EU.8'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.2.EU.9.r': {
    elementNumber: 'G.k.2.2.EU.9.r',
    list: [
      {
        elementNumber: 'G.k.2.2.EU.9.r.1',
        label: 'Device component name (free text)',
        component: 'input',
      },
    ],
  },
  'G.k.2.2.EU.9.r.1': {
    elementNumber: 'G.k.2.2.EU.9.r.1',
    label: t('ICSR:elementName:G.k.2.2.EU.9.r.1'),
    component: 'input',
    maxLength: '250',
  },
  'G.k.2.2.EU.9.r.2': {
    elementNumber: 'G.k.2.2.EU.9.r.2',
    label: t('ICSR:elementName:G.k.2.2.EU.9.r.2'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.2.2.EU.9.r.3': {
    elementNumber: 'G.k.2.2.EU.9.r.3',
    label: t('ICSR:elementName:G.k.2.2.EU.9.r.3'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.2.2.EU.9.r.4': {
    elementNumber: 'G.k.2.2.EU.9.r.4',
    label: t('ICSR:elementName:G.k.2.2.EU.9.r.4'),
    component: 'input',
    maxLength: '35',
  },
  'G.k.4.r.4': {
    elementNumber: 'G.k.4.r.4',
    label: t('ICSR:elementName:G.k.4.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'G.k.4.r.5': {
    elementNumber: 'G.k.4.r.5',
    label: t('ICSR:elementName:G.k.4.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'G.k.4.r.7': {
    elementNumber: 'G.k.4.r.7',
    label: t('ICSR:elementName:G.k.4.r.7'),
    component: 'input',
    maxLength: '35',
    nullFlavorList: ['UNK', 'ASKU'],
  },
  'G.k.4.r.9.2a': {
    elementNumber: 'G.k.4.r.9.2a',
    label: t('ICSR:elementName:G.k.4.r.9.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.9.2b': {
    elementNumber: 'G.k.4.r.9.2b',
    label: t('ICSR:elementName:G.k.4.r.9.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.4.r.10.2a': {
    elementNumber: 'G.k.4.r.10.2a',
    label: t('ICSR:elementName:G.k.4.r.10.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.10.2b': {
    elementNumber: 'G.k.4.r.10.2b',
    label: t('ICSR:elementName:G.k.4.r.10.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.4.r.11.2a': {
    elementNumber: 'G.k.4.r.11.2a',
    label: t('ICSR:elementName:G.k.4.r.11.2a'),
    component: 'input',
    maxLength: '25',
  },
  'G.k.4.r.11.2b': {
    elementNumber: 'G.k.4.r.11.2b',
    label: t('ICSR:elementName:G.k.4.r.11.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.9.i.2.r.1.EU.1': {
    elementNumber: 'G.k.9.i.2.r.1.EU.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.1.EU.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.1.5.4']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.9.i.2.r.2.EU.1': {
    elementNumber: 'G.k.9.i.2.r.2.EU.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.2.EU.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.1.5.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.9.i.2.r.3.EU.1': {
    elementNumber: 'G.k.9.i.2.r.3.EU.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.3.EU.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.5.1.1.5.3']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'H.1': {
    elementNumber: 'H.1',
    label: t('ICSR:elementName:H.1'),
    component: 'textarea',
    maxLength: '100000',
  },
});

export default EMA;
