/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { IoCaretDown } from 'react-icons/io5';
import { TbLoader } from 'react-icons/tb';
import debounce from 'lodash.debounce';
import includes from 'lodash.includes';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';
import { getText, getValue, scrollIntoView } from './utils';
import { controlStyles } from './styles';

interface Props {
  className?: string;
  size?: SizeType;
  value?: string | null;
  data?: any[];
  placeholder?: string;
  valueField?: string;
  textField?: string | ((i: any, idx: number) => void);
  error?: boolean;
  menuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (i: any) => void;
  onSelect?: (i: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

function Combobox({
  className,
  size = 'md',
  value,
  data = [],
  placeholder,
  valueField,
  textField,
  error,
  menuIsOpen,
  closeMenuOnSelect = true,
  loading,
  readOnly,
  disabled,
  onChange = () => null,
  onSelect = () => null,
  onFocus = () => null,
  onBlur = () => null,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const focusedRef = useRef<HTMLDivElement>();

  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState<any[]>([]);
  const [focusedItem, setFocusedItem] = useState<any>();

  const debounced = useRef(
    debounce((_items: any[], _value = '') => {
      const newItems = _items.filter((item, index) =>
        includes(
          getText(item, index, textField).toLowerCase(),
          _value.toLowerCase(),
        ),
      );
      setItems(newItems);
    }, 500),
  );

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const blurInput = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const openMenu = (focusItem: 'first' | 'last') => {
    const openAtIndex = focusItem === 'first' ? 0 : items.length - 1;

    setIsMenuOpen(true);
    setFocusedItem(items[openAtIndex]);
  };

  const focusItem = (direction: 'first' | 'up' | 'down' | 'last') => {
    if (!items.length) return;

    let nextFocus = 0;
    const focusedIndex = items.indexOf(focusedItem);

    if (direction === 'up') {
      nextFocus = focusedIndex > 0 ? focusedIndex - 1 : items.length - 1;
    } else if (direction === 'down') {
      nextFocus = (focusedIndex + 1) % items.length;
    } else if (direction === 'last') {
      nextFocus = items.length - 1;
    }

    setFocusedItem(items[nextFocus]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (!isMenuOpen) {
      openMenu('first');
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    setIsFocused(true);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    onChange(inputValue);
    setIsFocused(false);
    setIsMenuOpen(false);
  };

  const handleSelect = (item: any) => {
    const selectedValue = getValue(item, valueField);
    onChange(selectedValue);
    onSelect(selectedValue);
    if (typeof selectedValue === 'string') {
      setInputValue(selectedValue);
    }
    focusInput();
    if (closeMenuOnSelect) {
      setIsMenuOpen(false);
    }
  };

  const handleControlMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      openMenu('first');
    }
  };
  const handleMenuMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
  };

  const handleItemMouseOver = (item: any) => {
    setFocusedItem(item);
  };

  const handleControlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Tab':
        if (!e.shiftKey && isMenuOpen) {
          focusItem('down');
          break;
        }
        return;
      case 'Enter':
        if (e.keyCode === 229) {
          break;
        }
        handleSelect(focusedItem);
        break;
      case 'Escape':
        setIsMenuOpen(false);
        break;
      case 'ArrowUp':
        if (menuIsOpen || isMenuOpen) {
          focusItem('up');
        } else {
          openMenu('last');
        }
        break;
      case 'ArrowDown':
        if (menuIsOpen || isMenuOpen) {
          focusItem('down');
        } else {
          openMenu('first');
        }
        break;
      default:
        return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    debounced.current(data, inputValue);
  }, [data, inputValue]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (menuRef.current && focusedRef.current) {
      scrollIntoView(menuRef.current, focusedRef.current);
    }
  }, [focusedItem]);

  const getFocusedItemRef: React.Ref<HTMLDivElement> = (ref) => {
    if (ref) {
      focusedRef.current = ref;
    }
  };

  return (
    <div
      className={twMerge(
        clsx('relative h-fit w-full', className, disabled && 'disabled'),
      )}
    >
      <div
        className={twMerge(
          clsx(
            controlStyles[size],
            'relative flex w-full flex-wrap items-center justify-between pr-0 transition-all',
            isFocused && 'control-focus',
            error && 'control-error',
          ),
        )}
        onMouseDown={handleControlMouseDown}
        onKeyDown={handleControlKeyDown}
      >
        <div className="flex-1 overflow-hidden">
          <input
            ref={inputRef}
            className="w-full bg-inherit text-inherit opacity-100 placeholder:text-slate-300"
            value={inputValue}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={disabled}
            readOnly={readOnly}
          />
        </div>
        <div className="casfer-select-action flex flex-shrink-0 items-center space-x-1 px-2">
          <button tabIndex={-1} className="casfer-select-indicator">
            <IoCaretDown
              className={clsx(
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
        </div>
      </div>
      {(menuIsOpen || isMenuOpen) && (
        <div
          ref={menuRef}
          className="csafer-select-list absolute z-dropdown mt-1 max-h-[16rem] w-full overflow-auto rounded border border-slate-300 bg-white py-1 shadow"
          onMouseDown={handleMenuMouseDown}
        >
          {loading ? (
            <div className="inline-flex items-center rounded-md px-3 py-1 text-sm font-semibold leading-6 transition duration-150 ease-in-out">
              <TbLoader className="-ml-1 mr-3 h-5 w-5 animate-spin" />
              Loading...
            </div>
          ) : (
            <div className="min-w-fit">
              {items.map((item, idx) => (
                <div
                  key={idx}
                  ref={item === focusedItem ? getFocusedItemRef : undefined}
                  tabIndex={-1}
                  className={twMerge(
                    clsx(
                      'cursor-pointer py-1.5 px-3',
                      item === focusedItem && 'bg-brand-200',
                      getValue(item, valueField) === inputValue &&
                        'bg-brand-500 text-white',
                    ),
                  )}
                  onClick={() => handleSelect(item)}
                  onMouseOver={() => handleItemMouseOver(item)}
                >
                  {getText(item, idx, textField)}
                </div>
              ))}
              {items.length === 0 && (
                <div className="text-center text-slate-500">No Items</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(Combobox);
