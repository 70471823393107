import moment, { Moment } from 'moment';

export type DateType = string | number | Moment | undefined;

// 요일 생성
export const getDaysOfWeek = () => {
  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const dayOfWeek = moment().day(i).format('dd');
    daysOfWeek.push(dayOfWeek);
  }
  return daysOfWeek;
};

// 이번 달 1일 앞에 들어갈 지난 달 마지막 날짜 생성
export const getPreviousDays = (date: Moment) => {
  const previousDays = [];
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'months');
  const previousMonthDays = previousMonth.daysInMonth();
  for (let i = firstDayDate.day(); i > 0; i--) {
    const d = moment(previousMonth).date(previousMonthDays - i + 1);
    previousDays.push(d);
  }
  return previousDays;
};

// 이번 달 1~30(31)일자 생성
export const getCurrentDays = (date: Moment) => {
  const currentDays = [];
  const daysInMonth = moment(date).daysInMonth();
  for (let i = 1; i <= daysInMonth; i++) {
    const d = moment(date).date(i);
    currentDays.push(d);
  }
  return currentDays;
};

// 이번 달 마지막 날 이후 들어갈 다음달 초기 날짜 생성
export const getNextDays = (
  date: Moment,
  previousDays: Moment[],
  currentDays: Moment[],
) => {
  // daysCnt는 현재 달력에서 보이는 이전달의 마지막 날짜들부터 이번달 일자들 총합
  // baseNum은 마지막 날 이후 들어갈 다음달 초기 날짜 개수
  const daysCnt = previousDays.length + currentDays.length;
  const baseNum = 42;
  const nextDays = [];
  const nextMonth = moment(date).add(1, 'months');
  for (let i = 1; i <= baseNum - daysCnt; i++) {
    const d = moment(nextMonth).date(i);
    nextDays.push(d);
  }
  return nextDays;
};

export const getYearRange = (date: Moment) => {
  const yearRange = [];
  for (
    let i = Math.floor(date.year() / 10) * 10 - 1;
    i <= Math.floor(date.year() / 10) * 10 + 10;
    i++
  ) {
    yearRange.push(i);
  }
  return yearRange;
};
