import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import Modify from 'utils/typeModify';

function Portal({ children }: { children: React.ReactNode }) {
  return createPortal(children, document.body);
}

type props = Modify<
  HTMLAttributes<HTMLDivElement>,
  {
    isOpen: boolean;
    children?: React.ReactNode;
  }
>;

function PopupContainer({ isOpen, className, children = '', ...rest }: props) {
  return isOpen ? (
    <Portal>
      <div
        className="relative z-popup"
        aria-labelledby="popup"
        role="dialog"
        aria-modal="true"
        {...rest}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div
          className={twMerge(
            clsx(
              `dropdown-container fixed top-1/2 left-1/2 flex max-h-[90vh] max-w-[90vw] -translate-x-1/2 -translate-y-1/2 flex-col rounded bg-white px-4 text-left shadow-xl transition-all`,
              className,
            ),
          )}
        >
          {children}
        </div>
      </div>
    </Portal>
  ) : null;
}

export default PopupContainer;
