import { v4 as uuidV4 } from 'uuid';

import { ICSRFormRootElement } from 'types/case';

const repeatForm: { [key in string]: any } = {
  'E.i': () => ({
    number: 'E.i',
    elements: {
      'E.i.CNR.1': { value: uuidV4() },
      'E.i.3.2a': { nullFlavor: 'NI' },
      'E.i.3.2b': { nullFlavor: 'NI' },
      'E.i.3.2c': { nullFlavor: 'NI' },
      'E.i.3.2d': { nullFlavor: 'NI' },
      'E.i.3.2e': { nullFlavor: 'NI' },
      'E.i.3.2f': { nullFlavor: 'NI' },
    },
  }),
  'G.k': () => ({
    number: 'G.k',
    elements: {
      'G.k.CNR.1': { value: uuidV4() },
    },
  }),
};

export const getRepeatForm = (number: string): ICSRFormRootElement => {
  return repeatForm[number]?.();
};

export default repeatForm;
