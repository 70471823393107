import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

function LTHead({
  className,
  children,
}: HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead
      className={twMerge(
        clsx(
          'sticky top-0 z-10 bg-inherit text-left leading-tight text-slate-800 drop-shadow',
          className,
        ),
      )}
    >
      {children}
    </thead>
  );
}

export default LTHead;
