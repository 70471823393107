import clsx from 'clsx';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { TbCircleCheck } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

import { ActionType } from 'utils/modal';

function Custom({
  title,
  message,
  custom,
  toggle,
}: {
  title: string;
  message: string;
  custom: { actions: ActionType[]; icon?: JSX.Element | React.ReactNode };
  toggle: () => void;
}) {
  const { actions, icon: Icon } = custom;
  return (
    <div className="fixed top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="overflow-hidden sm:flex sm:items-start">
          {Icon || (
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-slate-100 sm:mx-0 sm:h-10 sm:w-10">
              <TbCircleCheck className="text-slate-600" fontSize="1.5rem" />
            </div>
          )}
          <div className="mt-3 min-w-0 flex-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex justify-between">
              <h3
                className="min-w-0 flex-1 break-words pr-12 text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {title}
              </h3>
              <button onClick={toggle}>
                <IoClose
                  className="text-slate-500 hover:text-inherit"
                  fontSize="1.5rem"
                />
              </button>
            </div>
            <div className="mt-2 pr-12">
              <p className="text-sm text-gray-500">
                {typeof message === 'string'
                  ? message.split('\n').map((str: string) => (
                      <span className="inline-block" key={str}>
                        {str}
                      </span>
                    ))
                  : message}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="space-x-2 px-4 py-3 sm:flex sm:justify-end sm:px-6">
        {actions.map(([label, action, close, className]) => (
          <button
            key={label}
            className={twMerge(
              clsx(
                'mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto',
                className,
              ),
            )}
            onClick={() => {
              action();
              if (close) toggle();
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Custom;
