import { TFunction } from 'i18next';

const sender = (codeList: any, t: TFunction) => ({
  'C.3.1': {
    elementNumber: 'C.3.1',
    label: t('ICSR:elementName:C.3.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.7']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'C.3.2': {
    elementNumber: 'C.3.2',
    label: t('ICSR:elementName:C.3.2'),
    component: 'input',
    maxLength: '100',
  },
  'C.3.3.1': {
    elementNumber: 'C.3.3.1',
    label: t('ICSR:elementName:C.3.3.1'),
    component: 'input',
    maxLength: '60',
  },
  'C.3.3.2': {
    elementNumber: 'C.3.3.2',
    label: t('ICSR:elementName:C.3.3.2'),
    component: 'input',
    maxLength: '50',
  },
  'C.3.3.3': {
    elementNumber: 'C.3.3.3',
    label: t('ICSR:elementName:C.3.3.3'),
    component: 'input',
    maxLength: '60',
  },
  'C.3.3.4': {
    elementNumber: 'C.3.3.4',
    label: t('ICSR:elementName:C.3.3.4'),
    component: 'input',
    maxLength: '60',
  },
  'C.3.3.5': {
    elementNumber: 'C.3.3.5',
    label: t('ICSR:elementName:C.3.3.5'),
    component: 'input',
    maxLength: '60',
  },
  'C.3.4.1': {
    elementNumber: 'C.3.4.1',
    label: t('ICSR:elementName:C.3.4.1'),
    component: 'input',
    maxLength: '100',
  },
  'C.3.4.2': {
    elementNumber: 'C.3.4.2',
    label: t('ICSR:elementName:C.3.4.2'),
    component: 'input',
    maxLength: '35',
  },
  'C.3.4.3': {
    elementNumber: 'C.3.4.3',
    label: t('ICSR:elementName:C.3.4.3'),
    component: 'input',
    maxLength: '40',
  },
  'C.3.4.4': {
    elementNumber: 'C.3.4.4',
    label: t('ICSR:elementName:C.3.4.4'),
    component: 'input',
    maxLength: '15',
  },
  'C.3.4.5': {
    elementNumber: 'C.3.4.5',
    label: t('ICSR:elementName:C.3.4.5'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'C.3.4.6': {
    elementNumber: 'C.3.4.6',
    label: t('ICSR:elementName:C.3.4.6'),
    component: 'input',
    maxLength: '33',
  },
  'C.3.4.7': {
    elementNumber: 'C.3.4.7',
    label: t('ICSR:elementName:C.3.4.7'),
    component: 'input',
    maxLength: '33',
  },
  'C.3.4.8': {
    elementNumber: 'C.3.4.8',
    label: t('ICSR:elementName:C.3.4.8'),
    component: 'input',
    maxLength: '100',
  },
});

export default sender;
