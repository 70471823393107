import dataEn from './en';
import dataKo from './ko';
import oidMap from './code.map.json';

const data: { [key in string]: any } = {
  en: { oidMap, ...dataEn },
  ko: { oidMap, ...dataKo },
};

export default data;
