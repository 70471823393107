import React from 'react';
import { useLocation } from 'react-router-dom';

import useList from 'hooks/useList';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';
import { Notification } from 'types/notification';
import { deleteNotifications, getNotifications } from 'api/notifications';
import { useQuery } from '@tanstack/react-query';
import ExpandedComponent from './ExpandedComponent';

const listHeads: ListHead[] = [
  {
    name: 'active',
    style: { width: '2.5rem' },
  },
  {
    name: 'subject',
    label: 'Subject',
    sort: true,
  },
  {
    name: 'createdTime',
    label: 'Registration Date',
    sort: true,
    filter: true,
    type: 'date',
    style: { width: '15rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'subject', label: 'Subject' }],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function UserNotificationList() {
  const { state } = useLocation();
  const { data: visibleNotifications } = useQuery(
    ['visibleNotifications'],
    () =>
      getNotifications({
        query: {
          perPage: 0,
          q: {
            deleted: ['false'],
            visible: ['true'],
            // createdTime: [moment().subtract(30, 'days')],
          },
        },
      }).then(({ data }) => data.content),
    {
      retry: 3,
      staleTime: 5 * 60 * 1000,
    },
  );

  const {
    data: invisibleNotifications,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    queryKey: 'invisibleNotifications',
    defaultQuery: {
      q: { visible: ['false'] },
    },
    queryFn: (q) => getNotifications({ query: q }),
    deleteFn: (s) => deleteNotifications(s),
  });

  return (
    <MainTemplate>
      <Header title="Notification List" />
      <Content className="flex flex-col overflow-hidden pt-1">
        <div className="-ml-1 flex justify-between py-2">
          <FilterBox
            filter={query.q}
            filterList={filterList}
            setFilter={setFilter}
          />
        </div>
        <div className="overflow-auto bg-inherit">
          <LTable className="table-fixed">
            <LTHead>
              <LTR type="head">
                {listHeads.map(({ name, label, sort, style }) => (
                  <LTH key={name} style={style}>
                    {sort ? (
                      <SortColumn
                        name={name}
                        order={query.order}
                        orderBy={query.orderBy}
                        onChange={changeSort}
                      >
                        {label}
                      </SortColumn>
                    ) : (
                      label
                    )}
                  </LTH>
                ))}
              </LTR>
            </LTHead>
            <LTBody
              isFetching={isFetching}
              rows={invisibleNotifications?.length || query.perPage}
              cols={listHeads.length}
              disableCheckbox
            >
              {visibleNotifications?.map((notification: Notification) => (
                <LTR
                  key={notification.id}
                  type="body"
                  className="bg-orange-300 hover:bg-orange-400"
                  expanded={state?.selected === notification.id}
                  expandedComponent={<ExpandedComponent {...notification} />}
                  colSpan={3}
                >
                  <LTD>{notification.subject}</LTD>
                  <LTD>{notification.createdTime}</LTD>
                </LTR>
              ))}
              {invisibleNotifications?.map((notification: Notification) => (
                <LTR
                  key={notification.id}
                  type="body"
                  expandedComponent={<ExpandedComponent {...notification} />}
                  colSpan={3}
                >
                  <LTD>{notification.subject}</LTD>
                  <LTD>{notification.createdTime}</LTD>
                </LTR>
              ))}
            </LTBody>
          </LTable>
        </div>
        <Pagination
          page={query.page}
          perPage={query.perPage}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          onChangePerPage={changePerPage}
          refresh={refetch}
        />
      </Content>
    </MainTemplate>
  );
}

export default UserNotificationList;
