import React from 'react';

import bugFixing from 'assets/images/bug_fixing.svg';

import Button from './common/Button';

function ErrorBoundary() {
  return (
    <div className="h-screen w-screen overflow-y-auto">
      <div className="flex h-full min-h-[560px] w-full flex-col items-center justify-center">
        <img className="h-auto w-48 sm:w-80" src={bugFixing} alt="error" />
        <div className="mt-4 whitespace-pre px-4 text-center text-2xl text-gray-600 sm:mt-8 sm:text-4xl">
          Oops!
          <br /> <span className="text-2xl">Something went wrong.</span>
        </div>
        <div className="mt-4 sm:mt-8">
          <Button
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            RELOAD
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorBoundary;
