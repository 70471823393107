import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import modal from 'utils/modal';

type ReturnBooleanFuncType = () => boolean;

function usePrompt({
  when,
  message,
}: {
  when?: boolean | ReturnBooleanFuncType;
  message?: string;
}) {
  const { t } = useTranslation();

  const blocker = useBlocker(true);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (
        blocker.location.state?.force ||
        (typeof when === 'function' ? !when() : !when)
      ) {
        setTimeout(() => {
          blocker.proceed();
        });
        return;
      }
      (async () => {
        const res = await modal.confirm(
          t('modal:confirm:title'),
          message || t('modal:confirm:prompt'),
        );
        if (res) {
          blocker.proceed();
        } else {
          blocker.reset();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);
}

export default usePrompt;
