import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { ICSRFormElement, ICSRFormRootElement, ICSRFormType } from 'types/case';

import CaseFormElement from './CaseFormElement';
import RootRepeatElement from './RootRepeatElement';
import CaseTab from './CaseTab';
import SubTitle from './SubTitle';

export interface RootElementProps {
  className?: string;
  version?: number;
  number: string;
  element?: ICSRFormRootElement;
  onChange?: (n: string, v: ICSRFormType) => void;
  onControl?: (n: string, d: any) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

function RootElement({
  className,
  version,
  number,
  element,
  onChange = () => null,
  onControl,
  disabled,
  children,
}: RootElementProps) {
  const [rootVersion, setRootVersion] = useState(version);
  const [root, setRoot] = useState<ICSRFormRootElement | undefined>(element);

  const handleChange = useCallback(
    (
      cNumber: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setRoot((prev) =>
        prev
          ? produce(prev, (draft) => {
              draft.elements[cNumber] = {
                ...draft.elements[cNumber],
                ...value,
              };
              if (onControl) onControl(cNumber, draft);
            })
          : {
              number,
              elements: {
                [cNumber]: value,
              },
            },
      );
    },
    [number, onControl],
  );

  const handleRootChange = useCallback(
    (
      cNumber: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setRoot((prev) =>
        prev
          ? produce(prev, (draft) => {
              draft.elements[cNumber] = value;
            })
          : {
              number,
              elements: {
                [cNumber]: value,
              },
            },
      );
    },
    [number],
  );

  useEffect(() => {
    onChange(number, root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  useEffect(() => {
    setRoot(element);
    setRootVersion(version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <div className={twMerge(clsx('mb-8 flex flex-wrap'), className)}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          if (item.type === CaseFormElement) {
            return React.cloneElement(item, {
              parentId: root?.id,
              version:
                item.props.version ||
                (item.props.valueToVersion &&
                  (
                    root?.elements?.[
                      item.props.valueToVersion
                    ] as ICSRFormElement
                  )?.value),
              element: root?.elements?.[item.props.number],
              disabled:
                disabled ||
                (item.props.available && !item.props.available(root)),
              onChange: handleChange,
            });
          }
          if ((item.type as any)?.type?.name === RootElement) {
            return React.cloneElement(item, {
              version: rootVersion,
              element: root?.elements?.[item.props.number],
              disabled,
              onChange: handleRootChange,
            });
          }
          if (item.type === RootRepeatElement) {
            return React.cloneElement(item, {
              version: rootVersion,
              parentId: root?.id,
              element: root?.elements?.[item.props.number],
              disabled,
              onChange: handleRootChange,
            });
          }
          if (item.type === CaseTab) {
            return React.cloneElement(item, {
              version: rootVersion,
              element: root,
              disabled,
              onChange: handleChange,
              onRootChange: handleRootChange,
            });
          }
          if (item.type === SubTitle) {
            return React.cloneElement(item, {
              parentId: root?.id,
            });
          }
        }
        return child;
      })}
    </div>
  );
}

export default React.memo(RootElement);
