import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getSenderDefaultValues,
  getSendingOfficerDeafultValues,
  postSender,
} from 'api/senders';

import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import SenderForm from './SenderForm';

function CreateSender() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getSenderDefaultValues(company?.id),
  });

  const { mutate } = useMutation(postSender, {
    onSuccess() {
      navigate('../senders', { replace: true });
    },
  });

  const changeSender = (key: string, value: any) => {
    if (key === 'type' && value !== '3') {
      setValue([{ [key]: value }, { healthProfessionalType: '' }]);
    } else {
      setValue(key, value);
    }
  };

  const changeAuthorGateway = (author: string, key: string, value: any) => {
    setValue(
      'authorityGateways',
      produce(form.authorityGateways, (draft) => {
        draft[author][key] = value;
      }),
    );
  };

  const addSendingOfficer = () => {
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) => {
        draft.push(getSendingOfficerDeafultValues(!draft.length));
      }),
    );
  };

  const deleteSendingOfficer = (index: number) => {
    if (form.sendingOfficers[index].selected) {
      modal.alert(
        t('modal:alert:title'),
        t('modal:alert:disabledDefaultSender'),
      );
      return;
    }
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) =>
        draft.filter((_officer, idx) => index !== idx),
      ),
    );
  };

  const changeSendingOfficer = (key: string, value: any, index: number) => {
    setValue(
      'sendingOfficers',
      produce(form.sendingOfficers, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'companyId' });
    register({ name: 'type' }, { required: t('error:required') });
    register(
      { name: 'healthProfessionalType' },
      {
        validate: (value: any, originForm: any) => {
          const { type } = originForm;
          if (type === '3') {
            if (value) return true;
            return t('error:required');
          }
          return true;
        },
      },
    );
    register({ name: 'organizationName' }, { required: t('error:required') });
    register({ name: 'authorityGateways' });
    register({ name: 'sendingOfficers' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Sender"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <SenderForm
        submitLabel="Create"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        changeSender={changeSender}
        changeAuthorGateway={changeAuthorGateway}
        changeSendingOfficer={changeSendingOfficer}
        addSendingOfficer={addSendingOfficer}
        deleteSendingOfficer={deleteSendingOfficer}
      />
    </FormWrapper>
  );
}

export default CreateSender;
