import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { putAction, putMemo } from 'api/memos';
import { Memo } from 'types/memo';
import modal from 'utils/modal';

import Button from 'components/common/Button';
import Popup from 'components/common/Popup';

import { useCaseAction } from '../context/CaseContext';

function UpdateMemoPopup({
  isOpen,
  memo,
  elementNumber,
  toggle,
}: {
  isOpen: boolean;
  elementNumber: string;
  memo?: Memo;
  toggle: () => void;
}) {
  const { t } = useTranslation();
  const { refetchMemo } = useCaseAction();

  const [comment, setComment] = useState('');

  const { mutate: update } = useMutation(putMemo, {
    onSuccess() {
      refetchMemo();
      toggle();
    },
  });

  const { mutate: close } = useMutation(putAction, {
    onSuccess() {
      refetchMemo();
      toggle();
    },
  });

  const handleUpdate = () => {
    const { id, version } = memo || {};
    update({ id, data: { id, comment, version } });
  };

  const handleClose = async () => {
    const { id } = memo || {};

    const check = await modal.confirm(
      t('modal:confirm:title'),
      t('modal:confirm:close'),
    );
    if (check) {
      close({
        id,
        data: {
          actionType: 'CLOSE',
        },
      });
    }
  };

  useEffect(() => {
    if (memo) setComment(memo.comment);
  }, [memo]);

  return (
    <Popup isOpen={isOpen} className="w-[28rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        Memo
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div>Element Number - {elementNumber}</div>
      <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
        <em>Comment</em>
        <textarea
          value={comment}
          className="mt-2 h-60 w-full resize-none border p-2"
          maxLength={255}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setComment(e.target.value)
          }
        />
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button color="gray" onClick={toggle} outline>
          Cancel
        </Button>
        <Button color="green" onClick={handleClose}>
          Close Memo
        </Button>
        <Button color="blue" onClick={handleUpdate}>
          Update
        </Button>
      </div>
    </Popup>
  );
}

export default UpdateMemoPopup;
