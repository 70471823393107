import React, { PropsWithChildren, HTMLAttributes } from 'react';

import TypeModify from 'utils/typeModify';

type RadioGroupType = TypeModify<
  HTMLAttributes<HTMLDivElement>,
  {
    value?: string | string[] | number;
    name: string;
    enableClickReset?: boolean;
    disabled?: boolean;
    onChange?: (v?: string) => void;
  }
>;

function RadioGroup({
  value,
  name,
  children,
  enableClickReset,
  disabled,
  onChange = () => null,
  ...rest
}: PropsWithChildren<RadioGroupType>) {
  return (
    <div {...rest}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          return React.cloneElement(item, {
            checked: value && value === item.props.value,
            id: `${name}-${index}`,
            name,
            disabled,
            enableClickReset,
            onChange,
          });
        }
        return child;
      })}
    </div>
  );
}

export default RadioGroup;
