import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

function Logo({ className, src }: { className?: string; src?: string }) {
  const [errored, setErrored] = useState(false);
  const handleError = () => {
    setErrored(true);
  };

  useEffect(() => {
    setErrored(false);
  }, [src]);

  return (
    <div className={twMerge(clsx('inline-block h-8 w-8', className))}>
      {errored || !src ? (
        <div className="h-full w-full rounded-full bg-gray-300" />
      ) : (
        <img
          className="h-full w-full rounded-full"
          src={src}
          alt="logo"
          onError={handleError}
        />
      )}
    </div>
  );
}

export default Logo;
