import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { IoCaretDown, IoClose } from 'react-icons/io5';
import moment, { Moment } from 'moment';

import RangePicker from '../DatePicker/RangePicker';
import { Dropdown, DropdownMenu, DropdownToggle } from '../Dropdown';

interface DateFilterProps {
  value: (Moment | undefined)[];
  label?: string;
  onChange?: (v: (Moment | undefined)[]) => void;
  hideFilter?: () => void;
}

const format = 'YYYY-MM-DD' as const;

function DateFilter({
  value,
  label,
  onChange = () => {},
  hideFilter,
}: DateFilterProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const startDateRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState<(Moment | undefined)[]>();

  const toggle = () => setOpen((prev) => !prev);

  const handleChange = (ds?: (Moment | undefined)[]) => onChange(ds || []);

  useEffect(() => {
    if (dropdownRef.current) {
      const ele = dropdownRef.current;
      ele.addEventListener('openfilter', () => {
        ele.focus();
        setOpen(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (value[0] === undefined || moment.isMoment(value[0])) &&
      (value[1] === undefined || moment.isMoment(value[1]))
    ) {
      setDates(value);
    } else {
      onChange([
        moment(moment(value[0]).format(format)),
        moment(moment(value[1]).format(format)),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (open) startDateRef.current?.focus();
  }, [open]);

  return (
    <Dropdown ref={dropdownRef} isOpen={open} toggle={toggle}>
      <DropdownToggle className="flex items-center justify-between">
        <div
          tabIndex={0}
          role="button"
          className={twMerge(
            clsx(
              'control control-sm group-[.focused]:control-focus w-fit pr-7',
              (value[0] || value[1]) && '!bg-slate-800 !text-slate-200',
            ),
          )}
        >
          <div className="flex w-full flex-wrap items-center">
            {value[0] || value[1] ? (
              <span className="flex items-center">
                <span>{label}</span>:&nbsp;
                <span>{`${
                  (value[0] && moment(value[0]).format(format)) ?? ''
                }~${
                  (value[1] && moment(value[1]).format(format)) ?? ''
                }`}</span>
              </span>
            ) : (
              <span className="text-slate-400">{label}</span>
            )}
          </div>
        </div>
        {hideFilter ? (
          <IoClose
            tabIndex={-1}
            className={twMerge(
              clsx(
                'absolute top-1/2 right-2 -translate-y-1/2 text-sm text-slate-400 group-[.focused]:text-slate-800 dark:text-slate-200 dark:group-[.focused]:text-slate-200',
                (value[0] || value[1]) && '!bg-slate-800 !text-slate-200',
              ),
            )}
            onClick={hideFilter}
          />
        ) : (
          <IoCaretDown
            tabIndex={-1}
            className={twMerge(
              clsx(
                'absolute top-1/2 right-2 -translate-y-1/2 text-sm text-slate-400 group-[.focused]:text-slate-800 dark:text-slate-200 dark:group-[.focused]:text-slate-200',
                (value[0] || value[1]) && '!bg-slate-800 !text-slate-200',
              ),
            )}
          />
        )}
      </DropdownToggle>
      <DropdownMenu
        className="rounded border border-slate-300 bg-white p-2 text-sm dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300"
        width="18rem"
        onClick={(e) => e.stopPropagation()}
      >
        <RangePicker
          size="sm"
          value={dates}
          startDateRef={startDateRef}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.target instanceof HTMLInputElement) {
              const { target } = e.target.dataset;
              if (target === 'end' && e.key === 'Tab') {
                e.preventDefault();
                dropdownRef.current?.focus();
                toggle();
              }
            }
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
}

export default DateFilter;
