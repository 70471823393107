import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TbEdit, TbTrash } from 'react-icons/tb';

import useList from 'hooks/useList';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';
import { Notification } from 'types/notification';
import { deleteNotifications, getNotifications } from 'api/notifications';

const listHeads: ListHead[] = [
  {
    name: 'subject',
    label: 'Subject',
    sort: true,
  },
  {
    name: 'createdTime',
    label: 'Registration Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'visible',
    label: 'Visible',
    style: { width: '8rem' },
  },
  {
    name: 'edit',
    style: { width: '5rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'subject', label: 'Subject' }],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function NotificationList() {
  const location = useLocation();

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    selected,
    setFilter,
    refetch,
    select,
    selectAll,
    reset,
    changeSort,
    changePage,
    changePerPage,
    handleDelete,
  } = useList({
    queryKey: 'notifications',
    queryFn: (q) => getNotifications({ query: q }),
    deleteFn: (s) => deleteNotifications(s),
  });

  return (
    <MainTemplate>
      <Header title="Notification List" />
      <Content className="flex flex-col overflow-hidden pt-1">
        <div className="-ml-1 flex justify-between py-2">
          <FilterBox
            filter={query.q}
            filterList={filterList}
            setFilter={setFilter}
          />
          <div
            className="flex cursor-pointer items-center space-x-2 pl-4 [&_svg]:h-[1.875rem] [&_svg]:w-[1.875rem] [&_svg]:rounded-sm [&_svg]:bg-gray-100 
              [&_svg]:p-1.5 [&_svg]:text-slate-500 dark:[&_svg]:border-slate-600 dark:[&_svg]:text-slate-500
              [&_svg:hover]:bg-slate-400 [&_svg:hover]:text-slate-200 dark:[&_svg:hover]:bg-slate-600"
          >
            <button onClick={handleDelete} disabled={!selected.length}>
              <TbTrash title="delete" />
            </button>
            <NavLink to="./new" state={{ prevUrl: location.pathname }}>
              <Button color="blue" size="sm">
                Create
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="overflow-auto bg-inherit">
          <LTable className="table-fixed">
            <LTHead>
              <LTR
                type="head"
                selected={data?.length === selected.length}
                select={(checked) => (checked ? selectAll() : reset())}
              >
                {listHeads.map(({ name, label, sort, style }) => (
                  <LTH key={name} style={style}>
                    {sort ? (
                      <SortColumn
                        name={name}
                        order={query.order}
                        orderBy={query.orderBy}
                        onChange={changeSort}
                      >
                        {label}
                      </SortColumn>
                    ) : (
                      label
                    )}
                  </LTH>
                ))}
              </LTR>
            </LTHead>
            <LTBody
              isFetching={isFetching}
              rows={data?.length || query.perPage}
              cols={listHeads.length}
            >
              {data?.map(
                ({ id, subject, createdTime, visible }: Notification) => (
                  <LTR
                    key={id}
                    type="body"
                    selected={selected.findIndex((item) => item === id) !== -1}
                    select={(checked) => select(id, checked)}
                  >
                    <LTD>{subject}</LTD>
                    <LTD>{createdTime}</LTD>
                    <LTD>{visible ? 'Visible' : 'Invisible'}</LTD>
                    <LTD>
                      <NavLink
                        to={`./${id}`}
                        state={{ prevUrl: location.pathname }}
                        className="flex items-center hover:opacity-60"
                        color="green"
                      >
                        <TbEdit className="mr-1" /> Edit
                      </NavLink>
                    </LTD>
                  </LTR>
                ),
              )}
            </LTBody>
          </LTable>
        </div>
        <Pagination
          page={query.page}
          perPage={query.perPage}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          onChangePerPage={changePerPage}
          refresh={refetch}
        />
      </Content>
    </MainTemplate>
  );
}

export default NotificationList;
