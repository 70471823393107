/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseCircle, IoSearch } from 'react-icons/io5';
import { TbChevronDown } from 'react-icons/tb';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';

interface SearchListType {
  name: string;
  label: string;
}
interface SearchType {
  className?: string;
  width?: string;
  target?: string;
  value?: string;
  size?: SizeType;
  list?: SearchListType[];
  onChange?: (n: string, v: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const searchListFilterStyles = {
  sm: 'control control-sm focus-within:control-focus group flex items-center justify-between',
  md: 'control control-md focus-within:control-focus group flex items-center justify-between',
  lg: 'control control-lg focus-within:control-focus group flex items-center justify-between',
};

function SearchListFilter(
  {
    className,
    width,
    target,
    size = 'md',
    value = '',
    list = [],
    onChange = () => null,
    onKeyDown,
  }: SearchType,
  ref: React.Ref<HTMLInputElement>,
) {
  const filterRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(list[0]);
  const [inputValue, setInputValue] = useState('');

  const toggle = () => setOpen((prev) => !prev);
  const debounced = useRef(debounce((cb) => cb(), 500));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = selectedItem;
    setInputValue(e.target.value);
    debounced.current(() => onChange(name, e.target.value));
  };

  const handleReset = () => {
    const { name } = selectedItem;
    setInputValue('');
    debounced.current(() => onChange(name, ''));
  };

  const handleChangeTarget = (item: SearchListType) => {
    const { name } = selectedItem;
    onChange(name, '');
    setInputValue('');
    setSelectedItem(item);
    setOpen(false);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (buttonRef.current) buttonRef.current.focus();
  };

  const handleBlur = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSelectedItem(list.find((item) => item.name === target) || list[0]);
  }, [list, target]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={clsx(searchListFilterStyles[size], className, 'relative')}
      style={{ width }}
    >
      {list && (
        <div
          ref={filterRef}
          className="-ml-3 mr-2 min-w-fit border-r pl-3 pr-1"
          onMouseDown={handleMouseDown}
        >
          <button
            ref={buttonRef}
            className="flex w-full items-center justify-between text-left text-slate-400 focus:text-slate-700"
            onClick={toggle}
            onBlur={handleBlur}
          >
            <span className="flex-1 truncate">{selectedItem?.label}</span>
            <TbChevronDown className="ml-1 mt-1" />
          </button>
          <div
            className={clsx(
              'absolute left-0 z-dropdown',
              open ? 'block' : 'hidden',
            )}
          >
            <div className="mt-2 w-fit rounded border bg-white py-1 text-sm">
              {list.map((item) => (
                <button
                  key={item.name}
                  className="block w-full px-3 py-1 text-left hover:bg-slate-200"
                  onClick={() => handleChangeTarget(item)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      <input
        ref={ref}
        className="min-w-0 flex-1 bg-inherit"
        placeholder="Search"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
      <div className="ml-1 flex items-center justify-center">
        {value?.length ? (
          <IoCloseCircle
            className="cursor-pointer text-slate-400 hover:!text-slate-800 dark:text-slate-500 dark:hover:!text-slate-200"
            onClick={handleReset}
          />
        ) : (
          <IoSearch className="text-slate-400 dark:text-slate-500" />
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(SearchListFilter);
