export interface Import {
  id: number;
  errorMessage: string;
  companyId: number;
  fileName: string;
  downloadUrl: string;
  importType: string;
  status: string;
  importUser: string;
  importSettingId: number;
  importSettingName: string;
  uploadDate: string;
}
export interface Export {
  id: number;
  errorMessage: null;
  companyId: number;
  fileName: string;
  downloadUrl: string;
  exportType: string;
  status: string;
  exportUser: string;
  createdDate: string;
}
export interface Tracker {
  id: number;
  productName: string;
  comment: string;
  createdDate: string;
  createdBy: string;
  status: string;
  downloadUrl: string;
}

export interface HistoryDetail {
  id: number;
  companyId: number;
  historyId: number;
  caseId: number;
  caseNumber: string;
  reportType: string;
  type: string;
  status: string;
  sponsorStudyNumber: string;
  productName: string;
  errorReportUrl?: string;
  importType?: string;
  exportType?: string;
  studyName?: string;
}

export const HistoryStatus = {
  READY: 'READY',
  SUCCESS: 'SUCCESS',
  PROCESSING: 'PROCESSING',
  FAIL: 'FAIL',
} as const;

export const ExportTypes = {
  ICH_E2B_R3: 'ICH E2B(R3) XML',
  ICH_E2B_R3_KR: 'ICH E2B(R3) XML with KR elements',
  ICH_E2B_R3_EU: 'ICH E2B(R3) XML with EU elements',
  PDF: 'PDF(CIOMS-I)',
  EXCEL: 'EXCEL',
} as const;

export const MFDSExportTypes = {
  ICH_E2B_R3: 'ICH E2B(R3) XML',
  ICH_E2B_R3_KR: 'ICH E2B(R3) XML with KR elements',
  PDF: 'PDF(CIOMS-I)',
  EXCEL: 'EXCEL',
} as const;

export const EMAExportTypes = {
  ICH_E2B_R3: 'ICH E2B(R3) XML',
  ICH_E2B_R3_EU: 'ICH E2B(R3) XML with EU elements',
} as const;
