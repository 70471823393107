import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

function DropdownPortal({
  containerRef,
  children,
}: {
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
}) {
  const element =
    (function recursive(
      ele: HTMLElement | null | undefined,
    ): Element | undefined {
      if (!ele) return undefined;
      return ele.classList.contains('dropdown-container')
        ? ele
        : recursive(ele.parentElement);
    })(containerRef?.current?.parentElement) ?? document.body;
  return createPortal(children, element);
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  width?: string;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  menuRef?: React.MutableRefObject<HTMLDivElement | null>;
  children?: React.ReactNode;
}

function DropdownMenu({
  isOpen,
  containerRef,
  menuRef,
  className,
  width,
  children,
  ...rest
}: Props) {
  return (
    <DropdownPortal containerRef={containerRef}>
      <div
        ref={menuRef}
        className={twMerge(
          clsx(
            isOpen ? 'block' : 'hidden',
            'absolute top-0 left-0 z-dropdown',
            className,
          ),
        )}
        role="menu"
        style={{ width }}
        tabIndex={-1}
        {...rest}
      >
        {children}
      </div>
    </DropdownPortal>
  );
}

export default React.memo(
  DropdownMenu,
  (prev, next) => !(prev.isOpen || next.isOpen),
);
