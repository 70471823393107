import { useCallback } from 'react';
import produce from 'immer';

import { getCaseForm } from 'utils/caseForm';
import { getRepeatForm } from 'utils/caseRepeatForm';
import { ICSRFormRootElement } from 'types/case';
import { Study } from 'types/study';
import { Product } from 'types/product';

import { useCaseState } from '../context/CaseContext';

const useCaseConvert = (codeList: any) => {
  const { region } = useCaseState();

  const convertStudy = useCallback(
    (
      study: Study,
      origin = getCaseForm()['C.5'] as ICSRFormRootElement,
    ): ICSRFormRootElement => {
      const studyTypeOid = codeList.oidMap.studyType;
      const studyTypeCode = codeList[studyTypeOid]?.codes.find(
        (code: any) => code.key === study.studyType,
      )?.value;

      const countryOid = codeList.oidMap.countries;
      const countryCodes = codeList[countryOid]?.codes;
      const registrations = study.registrations.map((registration: any) => {
        const countryCode = countryCodes.find(
          (code: any) => code.key === registration.country,
        );
        return {
          elements: {
            'C.5.1.r.1': {
              value: registration.number || undefined,
            },
            'C.5.1.r.2': {
              value: registration.country || undefined,
              description: countryCode?.value,
            },
          },
        };
      });

      return produce(origin, (draft) => {
        draft.elements['C.5.1.r'] = registrations;
        draft.elements['C.5.2'] = {
          ...draft.elements['C.5.2'],
          value: study.studyName,
        };
        draft.elements['C.5.3'] = {
          ...draft.elements['C.5.3'],
          value: study.sponsorStudyNumber,
        };
        draft.elements['C.5.4'] = {
          ...draft.elements['C.5.4'],
          value: study.studyType,
          description: studyTypeCode,
        };
        if (region === 'MFDS') {
          draft.elements['C.5.4.KR.1'] = {
            ...draft.elements['C.5.4.KR.1'],
            value: undefined,
          };
        }
      });
    },
    [codeList, region],
  );

  const convertDrug = useCallback(
    (product: Product): ICSRFormRootElement => {
      const strengthUnitOid = codeList.oidMap.strengthUnit;
      const strengthUnitCodes = codeList[strengthUnitOid]?.codes;
      const substances = product.substances.map((substance: any) => {
        const strengthUnitCode = strengthUnitCodes.find(
          (code: any) => code.key === substance.strengthUnit,
        );
        return produce(
          {
            elements: {},
          },
          (draft: any) => {
            draft.elements['G.k.2.3.r.1'] = {
              value: substance.name || undefined,
            };
            if (region === 'MFDS') {
              draft.elements['G.k.2.3.r.1.KR.1a'] = { value: undefined };
              draft.elements['G.k.2.3.r.1.KR.1b'] = { value: undefined };
            }
            draft.elements['G.k.2.3.r.2a'] = {
              value: substance.termIdVersion || undefined,
            };
            draft.elements['G.k.2.3.r.2b'] = {
              value: substance.termId || undefined,
            };
            draft.elements['G.k.2.3.r.3a'] = {
              value: substance.strengthNumber || undefined,
            };
            draft.elements['G.k.2.3.r.3b'] = {
              value: substance.strengthUnit || undefined,
              description: strengthUnitCode?.value,
            };
          },
        );
      });

      return produce(getRepeatForm('G.k'), (draft) => {
        draft.elements['G.k.2.2'] = {
          value: product.brandName || product.productName,
        };
        draft.elements['G.k.2.3.r'] = substances;
      });
    },
    [codeList, region],
  );

  return { convertStudy, convertDrug };
};

export default useCaseConvert;
