import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getProfile, putProfile } from 'api/profile';

import { Profile } from 'types/profile';
import useForm from 'hooks/useForm';

import { userState } from 'state';

import Button from '../common/Button';
import Input from '../common/Input';

function AccountDetails() {
  const [user] = useRecoilState(userState);

  const { form, setValue, handleSubmit } = useForm({
    defaultValues: { email: '', userName: '', phone: '' },
  });

  useQuery(
    ['getProfile'],
    () => getProfile(user?.id).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess({ id, email, userName, phone, userType }: Profile) {
        setValue([{ id }, { email }, { userName }, { phone }, { userType }]);
      },
    },
  );

  const { mutate: submit } = useMutation(putProfile);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.name, e.target.value);

  const onSubmit = handleSubmit(async (data) => submit(data));

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-1">
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Email</div>
          <div className="w-1/2">
            <Input
              className="w-full"
              name="email"
              value={form.email}
              disabled
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Name</div>
          <div className="w-1/2">
            <Input
              className="w-full"
              name="userName"
              value={form.userName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Phone</div>
          <div className="w-1/2">
            <Input
              className="w-full"
              name="phone"
              value={form.phone}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="my-4 space-x-1 text-right">
        <Button type="submit" color="slate">
          Save
        </Button>
      </div>
    </form>
  );
}

export default AccountDetails;
