import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getUser,
  getUserDefaultValues,
  putUser,
  resendRegistEmail,
} from 'api/users';
import { getCompanies } from 'api/companies';

import { User, UserType } from 'types/user';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import UserForm from './UserForm';

const userTypeList = Object.entries(UserType).map(([key, value]) => ({
  key,
  value,
}));

function EditUser() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getUserDefaultValues(),
  });

  useQuery(['getUser'], () => getUser(userId).then(({ data }) => data), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess(data: User) {
      setValue([
        { id: data.id },
        { email: data.email },
        { userName: data.userName },
        { phone: data.phone },
        { startDate: data.startDate },
        { endDate: data.endDate },
        { userType: data.userType },
        { companyId: data.companyId },
        { editableEdc: data.editableEdc },
        { managerFlag: data.managerFlag || 0 },
      ]);
    },
  });

  const { data: companies } = useQuery(['getCompanies'], () =>
    getCompanies({ query: { perPage: 0, q: { deleted: ['false'] } } }).then(
      ({ data }) => data.content,
    ),
  );

  const { mutate: resend } = useMutation(resendRegistEmail);

  const { mutate: submit } = useMutation(putUser);

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const resendEmail = () => resend(userId);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      submit({ userId, data });
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'email' });
    register({ name: 'userName' }, { required: t('error:required') });
    register({ name: 'phone' }, { required: t('error:required') });
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' });
    register({ name: 'userType' }, { required: t('error:required') });
    register({ name: 'editableEdc' }, { required: t('error:required') });
    register({ name: 'companyId' });
    register({ name: 'managerFlag' });
  }, [t, register]);

  return (
    <FormWrapper title="Edit User" breadcrumbs={[{ label: 'List', to: '..' }]}>
      <UserForm
        submitLabel="Save"
        form={form}
        errors={errors}
        companies={companies}
        userTypeList={userTypeList}
        resendEmail={resendEmail}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default EditUser;
