import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';

import { getExpeditedReports } from 'api/dashboard';
import { companyState } from 'state';

import ArrayString from 'components/common/ArrayString';

const columnStyles = 'relative flex-1 pr-2 last:pr-0';
const rowStyles =
  'relative shadow hover:bg-slate-100 flex w-full flex-wrap pl-2 py-2 text-left';

function DashboardDeadline() {
  const [{ selected: company }] = useRecoilState(companyState);

  const { data: expeditedReports = [] } = useQuery(
    ['getExpeditedReports', company?.id],
    () =>
      getExpeditedReports({
        companyId: company?.id,
        daysBeforeExpiration: company?.daysBeforeReportingDeadlineAlert || 0,
        isNextDaysInclude: company?.alertIncludeNextDays,
        query: { page: 1, perPage: 500 },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="w-full p-2">
      <div className="space-x-4 rounded border bg-white px-2 py-4 shadow xl:w-full xl:flex-shrink-0 xl:flex-grow-0 xl:basis-full xl:space-x-0">
        <div className="px-2">
          <div className="m-0 flex justify-between text-xl font-bold text-gray-700">
            Expedited Reports
          </div>
        </div>
        <div className="mt-2 overflow-auto pl-4 pr-2">
          <div className="sticky top-0 z-10 -mx-2 flex flex-wrap bg-white p-2">
            <div className={clsx('flex-1', columnStyles)}>Case No.</div>
            <div className={clsx('flex-1', columnStyles)}>Products</div>
            <div className={clsx('flex-1', columnStyles)}>AEs</div>
            <div
              className={clsx(
                'flex-shrink-0 flex-grow-0 basis-24',
                columnStyles,
              )}
            >
              Deadline
            </div>
          </div>
          <div>
            <div className="relative mt-0 -ml-2 h-56 flex-col space-y-2 pt-2">
              {expeditedReports.length ? (
                expeditedReports.map((report: any) => (
                  <NavLink
                    key={report.icsrId}
                    to={`../cases/${report.icsrId}`}
                    className={rowStyles}
                  >
                    <div className={clsx('flex-1', columnStyles)}>
                      {report.sendersSafetyReportUniqueIdentifier}
                    </div>
                    <div className={clsx('flex-1', columnStyles)}>
                      <ArrayString>{report.productNames}</ArrayString>
                    </div>
                    <div className={clsx('flex-1', columnStyles)}>
                      <ArrayString>{report.reactionEventTerms}</ArrayString>
                    </div>
                    <div
                      className={clsx(
                        'flex-shrink-0 flex-grow-0 basis-24',
                        columnStyles,
                      )}
                    >
                      {report.reportingDeadline}
                    </div>
                  </NavLink>
                ))
              ) : (
                <div className="flex h-full items-center justify-center text-2xl text-gray-400">
                  No Data
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 -mb-2 px-2">
          Total Rows : {expeditedReports.length}
        </div>
      </div>
    </div>
  );
}

export default DashboardDeadline;
