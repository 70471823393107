import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getCompany, getCompanyDefaultValues, putCompany } from 'api/companies';
import { postTrackers, putTrackerData } from 'api/tracker';

import { Company } from 'types/company';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import CompanyForm from './CompanyForm';

function EditCompany() {
  const { t } = useTranslation();
  const { companyId } = useParams();

  const [tracker, setTracker] = useState<any>(null);
  const [logoFile, setLogoFile] = useState<File>();
  const [logoFileURL, setLogoFileURL] = useState<string>();

  const {
    form,
    errors,
    register,
    setValue,
    setError,
    clearError,
    handleSubmit,
  } = useForm({
    defaultValues: getCompanyDefaultValues(),
  });

  const { refetch } = useQuery(
    ['getCompany'],
    () => getCompany(companyId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Company) {
        setValue([
          { id: data.id },
          { companyName: data.companyName },
          { type: data.type },
          { enableTracker: data.enableTracker },
          { enableWhodd: data.enableWhodd },
          { whoDrugLicense: data.whoDrugLicense },
          { startDate: data.startDate },
          { endDate: data.endDate },
          { logo: data.logo },
          { deleted: data.deleted },
        ]);
        setTracker(data.tracker);
      },
    },
  );

  const { mutate: saveTrakerData } = useMutation(putTrackerData, {
    onSuccess() {
      refetch();
    },
  });

  const { mutate: newTrakerData } = useMutation(postTrackers, {
    onSuccess({ data: { trackerId } }) {
      saveTrakerData(trackerId);
      refetch();
    },
  });

  const { mutate: submit } = useMutation(putCompany);

  const handleChange = (key: string, value: any) => {
    if (key === 'logoFile') {
      if (value && value.length) {
        const file: File = value[0];
        const fileReader = new FileReader();

        if (file.size > 1024 * 128) {
          setError(key, t('error:bigFile'));
          return;
        }

        fileReader.onload = () => {
          const { result } = fileReader;
          if (!result) return;
          if (result instanceof ArrayBuffer) {
            const blob = new Blob([result]);
            const url = window.URL.createObjectURL(blob);
            setLogoFileURL(url);
          } else {
            setLogoFileURL(result);
          }
        };

        fileReader.readAsDataURL(file);

        setLogoFile(file);
      }
    } else {
      setValue(key, value);
    }
  };

  const createTrackerData = () => {
    if (tracker) {
      saveTrakerData(tracker.id);
    } else {
      newTrakerData({ companyId });
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      const formData = new FormData();
      if (logoFile) {
        formData.append('logoFile', logoFile);
      }
      formData.append('company', JSON.stringify(data));
      submit({ companyId, data: formData });
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyName' }, { required: t('error:required') });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'enableTracker' }, { required: t('error:required') });
    register(
      { name: 'enableWhodd' },
      {
        required: t('error:required'),
        validate: (enableWhodd: any) => {
          if (!enableWhodd) {
            clearError('whoDrugLicense');
          }
          return true;
        },
      },
    );
    register(
      { name: 'whoDrugLicense' },
      {
        validate: (whoDrugLicense: any, originForm: any) => {
          const { enableWhodd } = originForm;
          if (enableWhodd && !whoDrugLicense?.licenseValidDate) {
            setError('whoDrugLicense', 'disabled', t('error:required'));
            return false;
          }
          return true;
        },
      },
    );
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' }, { required: t('error:required') });
    register({ name: 'logo' });
  }, [clearError, register, setError, t]);

  return (
    <FormWrapper
      title="Edit Company"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <CompanyForm
        submitLabel="Save"
        form={form}
        tracker={tracker}
        logoFileURL={logoFileURL}
        errors={errors}
        createTrackerData={createTrackerData}
        onChange={handleChange}
        onSubmit={onSubmit}
      />
    </FormWrapper>
  );
}

export default EditCompany;
