import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getHealthCheck } from 'api/general';

import { ReactComponent as ServerDownImage } from 'assets/images/server_down.svg';

import Button from './common/Button';

function ServerDown() {
  const navigate = useNavigate();
  useQuery(['healthcheck'], getHealthCheck, {
    onSuccess() {
      navigate('/login', { replace: true });
    },
    onError() {},
  });

  return (
    <div className="h-[var(--mainHeight)] w-screen bg-slate-100">
      <div className="mx-auto flex h-full max-w-[45rem] flex-col items-center justify-center">
        <h1 className="mb-16 w-[25rem] justify-center text-3xl font-bold md:w-[30rem] md:text-4xl">
          <b className="block font-black">Sorry,</b> We are having some
          temporary server issues.
        </h1>
        <ServerDownImage className="h-auto w-[12rem] md:w-[20rem]" />
        <div className="text-color-slate-800 my-4 whitespace-pre-wrap text-center text-xl md:my-12 md:text-2xl">
          <a
            href="mailto:support@cnrres.com"
            className="text-blue-500 hover:text-blue-600 hover:underline"
          >
            support@cnrres.com
          </a>
          로 문의 부탁드립니다.
        </div>
        <div>
          <Button
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            RELOAD
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ServerDown;
