import React, { useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IoClose } from 'react-icons/io5';
import { TbBell, TbClock } from 'react-icons/tb';
import clsx from 'clsx';
import moment from 'moment';
import { useRecoilState } from 'recoil';

import { getNotifications } from 'api/notifications';

import { UserType } from 'types/user';
import { Notification } from 'types/notification';

import { ReactComponent as SearchImage } from 'assets/images/file_search.svg';

import { userState } from 'state';

import Switch from 'components/common/Switch';
import Notice from 'components/Notice';

function SidebarNotifications() {
  const match = useMatch('/:company/*');

  const [user] = useRecoilState(userState);

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);

  const { data: notifications = [] } = useQuery(
    ['visibleNotifications'],
    () =>
      getNotifications({
        query: {
          perPage: 0,
          q: {
            deleted: ['false'],
            visible: ['true'],
            // createdTime: [moment().subtract(30, 'days')],
          },
        },
      }).then(({ data }) => data.content),
    {
      retry: 3,
      staleTime: 5 * 60 * 1000,
    },
  );

  if (user?.userType !== UserType.user) return null;

  return (
    <>
      <div className="flex flex-col overflow-hidden px-4 pt-2">
        <button
          className="relative mb-1 flex h-9 min-w-[2.25rem] flex-shrink-0 items-center overflow-hidden rounded text-slate-500 hover:text-brand-500"
          onClick={toggle}
        >
          <TbBell className="h-9 w-9 flex-shrink-0 p-2" />
          {notifications.length ? (
            <span className="absolute top-1.5 left-1.5 flex h-1.5 w-1.5">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-red-500" />
            </span>
          ) : null}
          <span className="ml-2 inline-block flex-1 text-left">
            What&#39;s New
          </span>
        </button>
      </div>
      <div
        className={clsx(
          'absolute top-[calc(100vh-var(--mainHeight))] left-[var(--sidebarWidth)] h-[var(--mainHeight)] overflow-hidden transition-all',
          open ? 'w-80' : 'w-0',
        )}
      >
        <div
          className={clsx(
            'flex h-full w-80 flex-col overflow-hidden border-x bg-slate-50 transition-transform',
            'dark:border-slate-700 dark:bg-slate-900 dark:text-slate-300',
            open ? 'translate-x-0' : '-translate-x-80',
          )}
        >
          <div className="flex h-16 shrink-0 items-center justify-between border-b-2 p-4">
            <h3>What&#39;s New</h3>
            <IoClose
              className="cursor-pointer opacity-50 hover:opacity-100"
              fontSize="1.5rem"
              onClick={toggle}
            />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto px-4">
            <div className="flex items-center justify-between py-2 text-sm text-slate-400">
              LATEST
              {/* <div className="flex items-center">
                Only show unread <Switch className="ml-1" size="sm" />
              </div> */}
            </div>
            <ul className="flex-1 space-y-4 py-2">
              {notifications?.map(
                ({ id, subject, createdTime }: Notification) => (
                  <NavLink
                    to={`/${match?.params.company}/notifications`}
                    state={{ selected: id }}
                    key={id}
                    className={clsx(
                      'flex w-full cursor-pointer p-2 hover:bg-slate-200',
                      'dark:hover:bg-slate-800',
                    )}
                  >
                    <div className="mr-2 h-9 w-9 flex-shrink-0 rounded-full bg-slate-600 text-center leading-9 text-slate-100 ">
                      S
                    </div>
                    <div className="flex-1 space-y-1 text-sm">
                      <div className="overflow-ellipsis whitespace-pre-wrap break-words font-semibold line-clamp-2">
                        {subject}
                      </div>
                      <div>
                        <TbClock className="inline-block" />
                        {moment(createdTime).fromNow()}
                      </div>
                    </div>
                  </NavLink>
                ),
              )}
            </ul>
            <div className="flex flex-col items-center justify-center p-2">
              <div className="flex items-center">
                <SearchImage className="h-20 w-full" />
              </div>
              <NavLink
                to={`/${match?.params.company}/notifications`}
                className="w-56 whitespace-normal text-center text-sm"
              >
                Please, click for more notifications
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Notice data={notifications} />
    </>
  );
}

export default SidebarNotifications;
