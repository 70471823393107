import React from 'react';
import loadable, { LoadableComponent } from '@loadable/component';
import { IconBaseProps } from 'react-icons/lib';

interface typesPropsIcon extends IconBaseProps {
  className?: string;
  lib: string;
  name: string;
}

function Icon({ lib, name, ...rest }: typesPropsIcon): JSX.Element {
  const ElementIcon: LoadableComponent<IconBaseProps> = loadable(
    () => import(`react-icons/${lib}/index.js`),
    {
      resolveComponent: (el: JSX.Element) => {
        return el[name as keyof JSX.Element] || (() => null);
      },
    },
  );

  return <ElementIcon {...rest} />;
}

export default Icon;
