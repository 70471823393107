import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ColorType, SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

type RadioType = TypeModify<
  InputHTMLAttributes<HTMLInputElement>,
  {
    label?: string | React.ReactNode;
    size?: SizeType;
    color?: ColorType;
    enableClickReset?: boolean;
    valueLabel?: boolean;
    onChange?: (v?: string) => void;
  }
>;

const sizeStyles: { [key in SizeType]: string } = {
  sm: 'radio-sm',
  md: 'radio-md',
  lg: 'radio-lg',
};

const colorStyles: { [key in ColorType]: string } = {
  blue: 'text-blue-600 focus:ring-blue-500 focus:ring-offset-blue-500',
  brand: 'text-brand-600 focus:ring-brand-500 focus:ring-offset-brand-500',
  green: 'text-green-600 focus:ring-green-500 focus:ring-offset-green-500',
  red: 'text-red-600 focus:ring-red-500 focus:ring-offset-red-500',
  orange: 'text-orange-600 focus:ring-orange-500 focus:ring-offset-orange-500',
  slate: 'text-slate-600 focus:ring-slate-500 focus:ring-offset-slate-500',
  gray: 'text-gray-600 focus:ring-gray-500 focus:ring-offset-gray-500',
  yellow: 'text-yellow-600 focus:ring-yellow-500 focus:ring-offset-yellow-500',
  cyan: 'text-cyan-600 focus:ring-cyan-500 focus:ring-offset-cyan-500',
  indigo: 'text-indigo-600 focus:ring-indigo-500 focus:ring-offset-indigo-500',
};

function Radio({
  className,
  id,
  name,
  value,
  label,
  size = 'md',
  color = 'brand',
  checked = false,
  enableClickReset,
  valueLabel,
  disabled,
  onChange = () => null,
}: RadioType) {
  const handleClick = () => checked && enableClickReset && onChange(undefined);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);
  return (
    <div className={twMerge(clsx(`flex items-center`, className))}>
      <input
        id={id}
        className={clsx(
          sizeStyles[size],
          colorStyles[color],
          'mr-2 cursor-pointer select-none appearance-none rounded-full border border-gray-300 bg-white/10',
          'checked:border-transparent checked:bg-current checked:bg-radio checked:bg-[length:100%_100%] checked:bg-no-repeat',
          'focus:outline focus:outline-2 focus:outline-offset-2 focus:ring-offset-2',
          'dark:border-gray-600',
        )}
        type="radio"
        name={name}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      {label && (
        <label
          htmlFor={id}
          className="inline-block cursor-pointer text-slate-700 dark:text-slate-300"
        >
          {valueLabel ? `${value}:${label}` : label}
        </label>
      )}
    </div>
  );
}

export default Radio;
