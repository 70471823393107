import React from 'react';
import moment from 'moment';

interface Props {
  handleMonthClick: (e: React.MouseEvent) => void;
}
function Months({ handleMonthClick }: Props) {
  return (
    <div className="grid w-64 grid-cols-4">
      {moment.monthsShort().map((month, idx) => (
        <button
          key={idx}
          data-month={idx}
          className="block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
          onClick={handleMonthClick}
        >
          {month}
        </button>
      ))}
    </div>
  );
}

export default Months;
