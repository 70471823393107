/* eslint-disable no-shadow */
import isEqualWith from 'lodash.isequalwith';
import isObject from 'lodash.isobject';

function checkValue(value: any): boolean {
  return value === undefined || value === null || value === '';
}
function customEqual(objValue: any, othValue: any): boolean | undefined {
  if (checkValue(objValue) && checkValue(othValue)) return true;
  return undefined;
}

export function compareObject(newObj: any, origObj: any) {
  let result = false;
  if (!newObj || !origObj) return result;
  Object.entries<any>(newObj).forEach(([key, value]) => {
    if (!isEqualWith(value, origObj[key], customEqual)) {
      result =
        isObject(value) && isObject(origObj[key])
          ? compareObject(value, origObj[key])
          : true;
    }
  });
  return result;
}
