import getQueryString from 'utils/getQueryString';
import client from './client';

export const getAudits = ({
  caseId,
  elementId,
  translate,
  query,
}: {
  caseId?: number | string;
  elementId?: number | string;
  translate?: boolean;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  const auditTrailTragetURL = translate
    ? 'translated-value-audit-trails'
    : 'audit-trails';
  return client.get(
    `/api/v1/icsrs/${caseId}/data-elements/${elementId}/${auditTrailTragetURL}?${queryString}`,
  );
};
