import { atom } from 'recoil';

import { Language, Region, RegionItem } from 'types/core';
import storage from 'utils/storage';

const coreState = atom<{
  language: Language;
  region: Region;
  regionList: RegionItem[];
}>({
  key: 'core',
  default: {
    language: storage.getItem('csafer_language') || 'en',
    region: storage.getItem('csafer_region') || 'MFDS',
    regionList: [
      { region: 'MFDS', regionalImplementationGuide: 'KR' },
      { region: 'EMA', regionalImplementationGuide: 'EU' },
    ],
  },
});

export default coreState;
