import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Input from 'components/common/Input';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';
import Button from 'components/common/Button';
import { Notification } from 'types/notification';

function NotificationForm({
  submitLabel,
  form,
  onChange,
  onSubmit,
}: {
  submitLabel: string;
  form: Notification;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}) {
  const { subject, visible, contents } = form;
  return (
    <form className="max-w-6xl" onSubmit={onSubmit}>
      <div className="flex flex-col space-y-2 pt-1">
        <div>
          <em className="mb-2 block font-medium">Subject</em>
          <Input
            className="w-full"
            value={subject}
            maxLength={255}
            onChange={(e) => onChange('subject', e.target.value)}
          />
        </div>
        <div className="flex">
          <em className="mr-5 font-medium">Visible</em>
          <RadioGroup
            className="flex space-x-4"
            name="visible"
            value={visible?.toString()}
            onChange={(value) =>
              onChange('visible', value && JSON.parse(value))
            }
          >
            <Radio size="md" value="true" label="ON" />
            <Radio size="md" value="false" label="OFF" />
          </RadioGroup>
        </div>
        <div>
          <em className="mb-2 block font-medium">Content</em>
          <ReactQuill
            theme="snow"
            className="[&_.ql-editor]:h-[50vh] [&_.ql-editor]:max-h-[30rem] [&_.ql-editor]:min-h-[15rem]"
            value={contents}
            onChange={(value) => onChange('contents', value)}
          />
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit" disabled={!subject}>
          {submitLabel}
        </Button>
      </div>
    </form>
  );
}

export default NotificationForm;
