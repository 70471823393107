import React, { useState } from 'react';
import { TbFileExport, TbSend, TbTrash, TbWritingSign } from 'react-icons/tb';
import moment from 'moment-timezone';
import { useRecoilState } from 'recoil';

import { CaseValidationType } from 'types/case';
import { companyState, memberState } from 'state';

import modal from 'utils/modal';
import { buttonStyles } from 'components/common/Modal/Confirm';

import useCaseActions from './hooks/useCaseActions';

import ExportPopup from './popup/ExportPopup';
import SubmissionPopup from './popup/SubmissionPopup';

const svgStyles =
  'border h-[1.875rem] w-[1.875rem] rounded-sm bg-slate-200 p-1.5 text-slate-400 hover:bg-slate-100';

function CaseListActions({
  icsrIds,
  checkValidate,
  handleDelete,
  exportCallback,
  approvalCallback,
  submissionCallback,
}: {
  icsrIds: number[];
  checkValidate: (type: keyof typeof CaseValidationType) => boolean;
  handleDelete: () => Promise<void>;
  exportCallback?: () => void;
  approvalCallback?: () => void;
  submissionCallback?: () => void;
}) {
  const [member] = useRecoilState(memberState);
  const [{ selected: company }] = useRecoilState(companyState);

  const { apporveCases } = useCaseActions();

  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [submissionPopupOpen, setSubmissionPopupOpen] = useState(false);

  const exportPopupToggle = () => setExportPopupOpen((prev) => !prev);
  const submissionPopupToggle = () => setSubmissionPopupOpen((prev) => !prev);

  const handleExport = () => {
    if (!checkValidate(CaseValidationType.EXPORT)) return;
    exportPopupToggle();
  };

  const handleSubmission = () => {
    if (!checkValidate(CaseValidationType.SEND)) return;
    submissionPopupToggle();
  };

  const handleApprove = async () => {
    if (!checkValidate(CaseValidationType.APPROVE)) return;
    await modal.custom({
      title: 'Case(s) approve',
      message: (
        <input
          id="cases-approve-message"
          className="control control-md w-full"
          placeholder="Enter message"
        />
      ),
      actions: [
        ['Cancel', () => {}, true],
        [
          'Approve',
          () => {
            const comment = (
              document.getElementById(
                'cases-approve-message',
              ) as HTMLInputElement
            ).value;
            apporveCases({
              companyId: company?.id,
              subject: comment || moment().format(),
              icsrIds,
            }).then(approvalCallback);
          },
          true,
          buttonStyles.info,
        ],
      ],
    });
    // approvalPopupToggle();
  };

  return member?.privileges?.CASE_EDIT ? (
    <div className="flex cursor-pointer items-center space-x-2 pl-4">
      <button onClick={handleExport} disabled={!icsrIds.length}>
        <TbFileExport className={svgStyles} title="export" />
      </button>
      <button onClick={handleApprove} disabled={!icsrIds.length}>
        <TbWritingSign title="approval" className={svgStyles} />
      </button>
      <button onClick={handleSubmission} disabled={!icsrIds.length}>
        <TbSend title="submission" className={svgStyles} />
      </button>
      <button onClick={handleDelete} disabled={!icsrIds.length}>
        <TbTrash className={svgStyles} title="delete" />
      </button>
      <ExportPopup
        isOpen={exportPopupOpen}
        icsrIds={icsrIds}
        toggle={exportPopupToggle}
        callback={exportCallback}
      />
      <SubmissionPopup
        isOpen={submissionPopupOpen}
        icsrIds={icsrIds}
        toggle={submissionPopupToggle}
        callback={submissionCallback}
      />
    </div>
  ) : null;
}

export default CaseListActions;
