import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';
import clsx from 'clsx';

import { postChangePassword, postConfirmPassword } from 'api/profile';

import useForm from 'hooks/useForm';

import { userState } from 'state';

import Button from '../common/Button';
import Input from '../common/Input';

function ChangePassword() {
  const { t } = useTranslation();

  const [user] = useRecoilState(userState);

  const { form, errors, register, setValue, setError, handleSubmit } = useForm({
    defaultValues: {
      userId: user?.id,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const { mutate: changePassword } = useMutation(postChangePassword);
  const { mutate: confirmPassword } = useMutation(postConfirmPassword, {
    onSuccess() {
      const { userId, newPassword: userPw } = form;
      changePassword({ userId, userPw });
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.name, e.target.value);

  const onSubmit = handleSubmit(async (data) => {
    const { userId, currentPassword: userPw } = data;
    confirmPassword({ userId, userPw });
  });

  useEffect(() => {
    register(
      { name: 'newPassword' },
      {
        required: t('error:required'),
        pattern: {
          value:
            /^.*(?=^.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/,
          message: t('error:irregularPassword'),
        },
        validate: (value: any, { confirmNewPassword }: any) => {
          if (confirmNewPassword && value !== confirmNewPassword) {
            setError(
              'confirmNewPassword',
              'disabled',
              t('error:passwordNotMatch'),
            );
            return false;
          }
          return true;
        },
      },
    );
    register(
      { name: 'confirmNewPassword' },
      {
        validate: (value: any, { newPassword }: any) =>
          value === newPassword || t('error:passwordNotMatch'),
      },
    );
  }, [register, setError, t]);

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-1">
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Current Password</div>
          <div className="w-1/2">
            <Input
              type="password"
              className="w-full"
              name="currentPassword"
              value={form.currentPassword}
              onChange={handleChange}
            />
            <span
              className={clsx(
                errors.currentPassword ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.currentPassword?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">New Password</div>
          <div className="w-1/2">
            <Input
              type="password"
              className="w-full"
              name="newPassword"
              value={form.newPassword}
              onChange={handleChange}
            />
            <span
              className={clsx(
                errors.newPassword ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.newPassword?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Confirm New Password</div>
          <div className="w-1/2">
            <Input
              type="password"
              className="w-full"
              name="confirmNewPassword"
              value={form.confirmNewPassword}
              onChange={handleChange}
            />
            <span
              className={clsx(
                errors.confirmNewPassword ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.confirmNewPassword?.message}
            </span>
          </div>
        </div>
      </div>
      <div className="my-4 space-x-1 text-right">
        <Button type="submit" color="slate">
          Change
        </Button>
      </div>
    </form>
  );
}

export default ChangePassword;
