import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { postDataset } from 'api/dataset';

import { DefaultDatasetForm, EDCSetupType } from 'types/dataset';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import Button from 'components/common/Button';
import FileUpload from 'components/common/FileUpload';
import FormWrapper from 'components/common/FormWrapper';
import Input from 'components/common/Input';
import Select from 'components/common/Select/Select';

const EDCSetupTypeList = Object.entries(EDCSetupType).map(([, value]) => value);

function CreateDefaultEDCSetup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { form, errors, register, setValue, handleSubmit } =
    useForm<DefaultDatasetForm>({
      defaultValues: { settingName: '' },
    });

  const { mutate } = useMutation(postDataset, {
    onSuccess({ data: importSettingId }) {
      navigate(`../${importSettingId}`);
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      const { files, ...rest } = data;
      const formData = new FormData();
      [].forEach.call(files, (file) => {
        formData.append('specFile', file);
      });
      mutate({
        ...rest,
        formData,
      });
    }
  });

  useEffect(() => {
    register({ name: 'settingName' }, { required: t('error:required') });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'regulatory' }, { required: t('error:required') });
    register({ name: 'files' }, { required: t('error:required') });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Dataset"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <form className="max-w-4xl" onSubmit={onSubmit}>
        <div className="space-y-1 pt-1">
          <div className="flex py-4 px-2">
            <div className="required w-1/2">Rule Name</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={form.settingName}
                onChange={(e) => setValue('settingName', e.target.value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.settingName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="required w-1/2">Type</div>
            <div className="flex w-1/2 flex-wrap">
              <Select
                className="w-full"
                value={form.type}
                data={EDCSetupTypeList}
                onChange={(type: any) => setValue('type', type)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.type?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="required w-1/2">DB Spec.</div>
            <div className="flex w-1/2 flex-wrap">
              <FileUpload
                className="w-full"
                value={form.files}
                onChange={(value) => setValue('files', value)}
                accept=".xlsx"
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.files?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="required w-1/2">Regulatory</div>
            <div className="flex w-1/2 flex-wrap">
              <Select
                className="w-full"
                value={form.regulatory}
                data={['MFDS', 'EMA']}
                onChange={(value) => setValue('regulatory', value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.regulatory?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="my-4 space-x-1 text-right">
          <Button type="submit">Create</Button>
        </div>
      </form>
    </FormWrapper>
  );
}

export default CreateDefaultEDCSetup;
