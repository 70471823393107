import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { ColorType, SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

const colorStyles: { [key in ColorType]: string } = {
  blue: 'border-blue-600 bg-blue-600 text-white hover:bg-blue-500',
  brand: 'border-brand-600 bg-brand-600 text-white hover:bg-brand-500',
  green: 'border-green-600 bg-green-600 text-white hover:bg-green-500',
  red: 'border-red-600 bg-red-600 text-white hover:bg-red-500',
  orange: 'border-orange-600 bg-orange-600 text-white hover:bg-orange-500',
  slate: 'border-slate-600 bg-slate-600 text-white hover:bg-slate-500',
  gray: 'border-gray-600 bg-gray-600 text-white hover:bg-gray-500',
  yellow: 'border-yellow-600 bg-yellow-600 text-white hover:bg-yellow-500',
  cyan: 'border-cyan-600 bg-cyan-600 text-white hover:bg-cyan-500',
  indigo: 'border-indigo-600 bg-indigo-600 text-white hover:bg-indigo-500',
};

const outlineColorStyles: { [key in ColorType]: string } = {
  blue: 'bg-transparent text-blue-700 border-blue-300 hover:bg-blue-50',
  brand: 'bg-transparent text-brand-700 border-brand-300 hover:bg-brand-50',
  green: 'bg-transparent text-green-700 border-green-300 hover:bg-green-50',
  red: 'bg-transparent text-red-700 border-red-300 hover:bg-red-50',
  orange: 'bg-transparent text-orange-700 border-orange-300 hover:bg-orange-50',
  slate: 'bg-transparent text-slate-700 border-slate-300 hover:bg-slate-50',
  gray: 'bg-transparent text-gray-700 border-gray-300 hover:bg-gray-50',
  yellow: 'bg-transparent text-yellow-700 border-yellow-300 hover:bg-yellow-50',
  cyan: 'bg-transparent text-cyan-700 border-cyan-300 hover:bg-cyan-50',
  indigo: 'bg-transparent text-indigo-700 border-indigo-300 hover:bg-indigo-50',
};

const sizeStyles = {
  sm: 'py-1 px-3 text-sm',
  md: 'py-1.5 px-3 text-base',
  lg: 'py-2 px-3 text-lg',
};

type ButtonType = TypeModify<
  ButtonHTMLAttributes<HTMLButtonElement>,
  {
    color?: ColorType;
    size?: SizeType;
    pill?: boolean;
    outline?: boolean;
  }
>;

function Button(
  {
    type = 'button',
    className,
    color = 'brand',
    size = 'md',
    pill,
    outline,
    disabled,
    children,
    ...rest
  }: PropsWithChildren<ButtonType>,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <button
      ref={ref}
      type={type}
      className={twMerge(
        clsx(
          'h-fit border font-semibold shadow-sm',
          sizeStyles[size],
          outline ? outlineColorStyles[color] : colorStyles[color],
          pill ? 'rounded-full' : 'rounded',
          className,
        ),
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

export default React.forwardRef(Button);
