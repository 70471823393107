import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import { postResetPassword } from 'api/auth';
import useForm from 'hooks/useForm';

import Input from 'components/common/Input';
import Button from 'components/common/Button';

function ForgotPasswordPage() {
  const navigate = useNavigate();

  const { form, register, setValue, errors, handleSubmit } = useForm({
    defaultValues: { email: '' },
  });

  const { mutate } = useMutation(postResetPassword, {
    onSuccess() {
      navigate('/login');
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onSubmit = handleSubmit(async (data) => mutate(data));

  useEffect(() => {
    register(
      { name: 'email' },
      {
        required: 'Required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: 'Invalid email address',
        },
      },
    );
  }, [register]);

  return (
    <div className="flex h-[var(--mainHeight)] min-h-[600px] w-screen flex-col items-center justify-center bg-slate-200 dark:bg-slate-900">
      <div className="flex max-w-min flex-row rounded-lg bg-white p-10 shadow-lg dark:bg-slate-800">
        <form className="w-[380px]" onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center">
            <div className="mb-4 h-16 w-16 bg-icon bg-cover" />
            <h1 className="text-center text-2xl font-bold dark:text-white">
              Forgot your password?
            </h1>
            <span className="mb-4 text-base text-slate-400">
              Enter your e-mail address and we&lsquo;ll send you
              <br />a password reset link.
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="email"
              className="w-full"
              placeholder="Enter your e-mail"
              value={form.email}
              onChange={handleChange}
            />
            <span
              className={clsx(
                errors.email ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.email?.message}
            </span>
          </div>
          <div className="text-right">
            <Link
              to="/login"
              className="mr-4 font-semibold text-red-400 hover:text-red-500"
            >
              Back
            </Link>
            <Button type="submit">Send</Button>
          </div>
        </form>
      </div>
      <div className="mt-4 text-slate-500 opacity-80">
        Copyright &copy; C&R Research
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
