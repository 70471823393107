import qs from 'qs';
import moment, { Moment } from 'moment';

import { OrderType } from 'utils/commonType';

interface QueryFilterType {
  [key: string]: string | string[] | (Moment | undefined)[] | undefined;
}
interface QueryType {
  page?: number;
  perPage?: number;
  order?: OrderType;
  orderBy?: string;
  q?: QueryFilterType;
}

export const filterToString = <T>(q: T) =>
  q &&
  Object.entries(q)
    .reduce<string[]>((acc, [key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          if (!value.length) return acc;
          if (moment.isMoment(value[0]) && moment.isMoment(value[1])) {
            acc.push(
              `${key}[${value[0].toISOString()}~${value[1].toISOString()}]`,
            );
          } else if (moment.isMoment(value[0])) {
            acc.push(`${key}>=${value[0].toISOString()}`);
          } else if (moment.isMoment(value[1])) {
            acc.push(`${key}<=${value[1].toISOString()}`);
          } else {
            acc.push(
              (value as string[])
                .filter((v) => v)
                .map((v) => `${key}=${v}`)
                .toString(),
            );
          }
        } else {
          acc.push(`${key}:${value.toString()}`);
        }
      }
      return acc;
    }, [])
    .toString();

const getQueryString = ({ page, perPage, order, orderBy, q }: QueryType) => {
  return qs.stringify(
    {
      page: page && page - 1,
      size: perPage,
      sort: (orderBy || order) && [orderBy, order],
      q: filterToString<typeof q>(q),
    },
    {
      skipNulls: true,
      arrayFormat: 'comma',
    },
  );
};

export default getQueryString;
