import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  getCompanyDefaultValues,
  checkCompany,
  postCompany,
} from 'api/companies';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import CompanyForm from './CompanyForm';

function CreateCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [logoFile, setLogoFile] = useState<File>();
  const [logoFileURL, setLogoFileURL] = useState<string>();
  const { form, errors, register, setValue, setError, handleSubmit } = useForm({
    defaultValues: getCompanyDefaultValues(),
  });

  const { mutate } = useMutation(checkCompany, {
    onSuccess() {
      setCheck(true);
    },
    onError(err: AxiosError<any>) {
      setError(
        'companyName',
        'disabled',
        err.response?.data?.message || err.message,
      );
    },
    onMutate() {},
    onSettled() {},
  });

  const { mutate: submit } = useMutation(postCompany, {
    onSuccess() {
      navigate('..', { replace: true });
    },
  });

  const handleChange = (key: string, value: any) => {
    if (key === 'logoFile') {
      if (value && value.length) {
        const file: File = value[0];
        const fileReader = new FileReader();

        if (file.size > 1024 * 128) {
          setError(key, 'bigFile');
          return;
        }

        fileReader.onload = () => {
          const { result } = fileReader;
          if (!result) return;
          if (result instanceof ArrayBuffer) {
            const blob = new Blob([result]);
            const url = window.URL.createObjectURL(blob);
            setLogoFileURL(url);
          } else {
            setLogoFileURL(result);
          }
        };

        fileReader.readAsDataURL(file);

        setLogoFile(file);
      }
    } else {
      setValue(key, value);
    }
  };

  const checkAvailability = () => mutate(form.companyName);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      const formData = new FormData();
      if (logoFile) {
        formData.append('logoFile', logoFile);
      }
      formData.append('company', JSON.stringify(data));
      submit(formData);
    }
  });

  useEffect(() => {
    register({ name: 'companyName' }, { required: t('error:required') });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'enableTracker' }, { required: t('error:required') });
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' }, { required: t('error:required') });
    register({ name: 'logo' });
  }, [register, t]);

  useEffect(() => {
    setCheck(false);
  }, [form.companyName]);

  return (
    <FormWrapper
      title="Create Company"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <CompanyForm
        submitLabel="Create"
        check={check}
        form={form}
        logoFileURL={logoFileURL}
        errors={errors}
        checkAvailability={checkAvailability}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default CreateCompany;
