import React, { useState } from 'react';
import { TbChevronDown, TbPlus, TbTrash } from 'react-icons/tb';
import { MdRestore } from 'react-icons/md';
import { useRecoilState } from 'recoil';

import { codeSetsState } from 'state';

import { Sender } from 'types/sender';

import Input from 'components/common/Input';
import Radio from 'components/common/Radio';
import Select from 'components/common/Select/Select';
import RadioGroup from 'components/common/RadioGroup';
import Button from 'components/common/Button';
import clsx from 'clsx';
import { Tab, TabPanel, Tabs } from 'components/common/Tab';

interface props {
  submitLabel: string;
  form: Sender;
  errors: any;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
  changeSender: (key: string, value: any) => void;
  changeAuthorGateway: (author: string, key: string, value: any) => void;
  changeSendingOfficer: (key: string, value: any, index: number) => void;
  addSendingOfficer: () => void;
  deleteSendingOfficer: (index: number) => void;
  restoreSendingOfficer?: (index: number) => void;
}

function SederForm({
  submitLabel,
  form,
  errors,
  onSubmit,
  changeSender,
  changeAuthorGateway,
  changeSendingOfficer,
  addSendingOfficer,
  deleteSendingOfficer,
  restoreSendingOfficer = () => null,
}: props) {
  const {
    type,
    healthProfessionalType,
    organizationName,
    authorityGateways,
    sendingOfficers,
  } = form;

  const [codeSets] = useRecoilState(codeSetsState);

  const [tabValue, setTabValue] = useState(0);
  const handleTabValue = (value: number) => setTabValue(value);
  return (
    <form className="max-w-6xl" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div className="space-y-1">
          <div className="flex py-4 px-2">
            <div className="w-1/2">Sender Type (C.3.1)</div>
            <div className="flex w-1/2 flex-wrap">
              <RadioGroup
                className="space-y-2"
                name="sender-type"
                value={type}
                onChange={(val) => changeSender('type', val)}
                enableClickReset
              >
                <Radio value="1" label="1:Pharmaceutical Company" />
                <Radio value="2" label="2:Regulatory Authority" />
                <Radio value="3" label="3:Health Professional" />
                <Radio value="4" label="4:Regional Pharmacovigilance Centre" />
                <Radio
                  value="5"
                  label="5:WHO Collaborating Centres for International Drug Monitoring"
                />
                <Radio
                  value="6"
                  label="6:Other (e.g. Distributor, Study Sponsor, Contract Research Organisation, or non-Commercial Organisation)"
                />
                <Radio value="7" label="7:Patient / Consumer" />
              </RadioGroup>
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.type?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Health Professional Type (C.3.1.KR.1)</div>
            <div className="flex w-1/2 flex-wrap">
              <RadioGroup
                className="space-y-2"
                name="health-professional-type"
                value={healthProfessionalType}
                onChange={(val) => changeSender('healthProfessionalType', val)}
                enableClickReset
                disabled={type !== '3'}
              >
                <Radio value="1" label="1:Clinic/Hospital" />
                <Radio value="2" label="2:Pharmacy" />
                <Radio value="3" label="3:Public Health Centre" />
                <Radio value="4" label="4:Other" />
              </RadioGroup>
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.healthProfessionalType?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">{`Sender's Organization (C.3.2)`}</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={organizationName}
                onChange={(e) =>
                  changeSender('organizationName', e.target.value)
                }
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.organizationName?.message}
              </span>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2">Regulatory Authority</h2>
          <Tabs className="px-1" value={tabValue} onChange={handleTabValue}>
            <Tab index={0}>MFDS</Tab>
            <Tab index={1}>EMA</Tab>
          </Tabs>
          <div className="bg-slate-100">
            <TabPanel className="px-2" value={tabValue} index={0}>
              <div className="flex py-4 px-2">
                <div className="w-1/2">MFDS Login ID</div>
                <div className="w-1/2">
                  <Input
                    className="w-full"
                    value={authorityGateways.MFDS?.userId}
                    maxLength={255}
                    onChange={(e) =>
                      changeAuthorGateway('MFDS', 'userId', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex py-4 px-2">
                <div className="w-1/2">Sender ID</div>
                <div className="w-1/2">
                  <Input
                    className="w-full"
                    value={authorityGateways.MFDS?.senderId}
                    maxLength={255}
                    onChange={(e) =>
                      changeAuthorGateway('MFDS', 'senderId', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex py-4 px-2">
                <div className="w-1/2">Auth Key</div>
                <div className="w-1/2">
                  <Input
                    className="w-full"
                    value={authorityGateways.MFDS?.authKey}
                    maxLength={255}
                    onChange={(e) =>
                      changeAuthorGateway('MFDS', 'authKey', e.target.value)
                    }
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel className="px-2" value={tabValue} index={1}>
              <div className="flex py-4 px-2">
                <div className="w-1/2">Sender ID</div>
                <div className="w-1/2">
                  <Input
                    className="w-full"
                    value={authorityGateways.EMA?.senderId}
                    maxLength={255}
                    onChange={(e) =>
                      changeAuthorGateway('EMA', 'senderId', e.target.value)
                    }
                  />
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
        <div>
          <h2 className="mb-2">Person Responsible for Sending The Report</h2>
          <div className="flex bg-slate-100">
            <table className="w-full">
              <thead className="border-b-4 border-white text-left [&_th]:font-medium">
                <tr className="[&>th]:p-2">
                  <th />
                  <th>{`Sender's Department (C.3.3.1)`}</th>
                  <th>{`Sender's Title (C.3.3.2)`}</th>
                  <th>{`Sender's Given Name (C.3.3.3)`}</th>
                  <th className="text-center">default</th>
                  <th>
                    <button
                      type="button"
                      className="mx-auto flex"
                      onClick={addSendingOfficer}
                    >
                      <TbPlus />
                    </button>
                  </th>
                </tr>
              </thead>
              {sendingOfficers.map(
                (
                  {
                    department,
                    title,
                    givenName,
                    middleName,
                    familyName,
                    street,
                    city,
                    province,
                    postCode,
                    countryCode,
                    telephone,
                    fax,
                    email,
                    selected,
                    deleted,
                  },
                  index,
                ) => (
                  <tbody
                    key={index}
                    className={clsx(
                      'group border-b-4 border-white',
                      deleted && '[&_td]:opacity-60',
                    )}
                  >
                    <tr className="bg-inherit [&>td]:p-2">
                      <td>
                        <button
                          type="button"
                          className="group/button mx-auto flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-blue-600 align-middle hover:bg-blue-600"
                          onClick={(e: React.MouseEvent) => {
                            if (e.target instanceof HTMLButtonElement) {
                              const tbody =
                                e.target.parentElement?.parentElement
                                  ?.parentElement;
                              if (!(tbody instanceof HTMLElement)) return;
                              if (tbody.ariaExpanded === 'true') {
                                tbody.ariaExpanded = 'false';
                              } else {
                                tbody.ariaExpanded = 'true';
                              }
                            }
                          }}
                        >
                          <TbChevronDown className="pointer-events-none translate-y-px text-blue-600 transition-transform group-hover/button:text-white group-aria-expanded:-translate-y-px group-aria-expanded:-rotate-180" />
                        </button>
                      </td>
                      <td>{department}</td>
                      <td>{title}</td>
                      <td>{givenName}</td>
                      <td>
                        <Radio
                          value="true"
                          checked={selected}
                          className="justify-center [&>input]:m-0"
                          color="blue"
                          onChange={(v) =>
                            v && changeSendingOfficer('selected', !!v, index)
                          }
                          enableClickReset
                          disabled={deleted}
                        />
                      </td>
                      <td className="!opacity-100">
                        <button
                          type="button"
                          className="mx-auto flex"
                          onClick={() =>
                            deleted
                              ? restoreSendingOfficer(index)
                              : deleteSendingOfficer(index)
                          }
                        >
                          {deleted ? (
                            <MdRestore className="text-slate-600 hover:text-slate-500" />
                          ) : (
                            <TbTrash className="text-red-600 hover:text-red-500" />
                          )}
                        </button>
                      </td>
                    </tr>
                    <tr className="border-white group-aria-expanded:border-t group-aria-expanded:[&_div]:visible group-aria-expanded:[&_div]:max-h-[40rem]">
                      <td />
                      <td colSpan={7}>
                        <div className="invisible -ml-2 flex max-h-0 flex-wrap overflow-hidden transition-[max-height] [&>div]:flex [&>div]:w-full [&>div]:p-4  [&>div]:md:w-1/2">
                          <div>
                            <div className="w-1/2">{`Sender's Department (C.3.3.1)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={department}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'department',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Title (C.3.3.2)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={title}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'title',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Given Name (C.3.3.3)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={givenName}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'givenName',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Middle Name (C.3.3.4)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={middleName}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'middleName',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Family Name (C.3.3.5)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={familyName}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'familyName',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Street Address (C.3.4.1)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={street}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'street',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's City (C.3.4.2)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={city}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'city',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's State or Province (C.3.4.3)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={province}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'province',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Postcode (C.3.4.4)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={postCode}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'postCode',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Country Code (C.3.4.5)`}</div>
                            <div className="w-1/2">
                              <Select
                                className="w-full"
                                data={
                                  codeSets[codeSets.oidMap.countries]?.codes ||
                                  []
                                }
                                valueField="key"
                                textField="value"
                                value={countryCode}
                                onChange={(val) =>
                                  changeSendingOfficer(
                                    'countryCode',
                                    val.key,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Telephone (C.3.4.6)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={telephone}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'telephone',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's Fax (C.3.4.7)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={fax}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'fax',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="w-1/2">{`Sender's E-mail Address (C.3.4.8)`}</div>
                            <div className="w-1/2">
                              <Input
                                className="w-full"
                                value={email}
                                onChange={(e) =>
                                  changeSendingOfficer(
                                    'email',
                                    e.target.value,
                                    index,
                                  )
                                }
                                disabled={deleted}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-1 bg-inherit" />
                  </tbody>
                ),
              )}
            </table>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default SederForm;
