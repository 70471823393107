import { DatasetRule } from 'types/dataset';
import { IcsrStructure } from './E2BR3';

export const MappingStatus = {
  true: 'Yes',
  false: 'No',
} as const;

export const RequiredElement: { [key: string]: boolean } = {
  'C.1.1': true,
  'C.1.8.1': true,
  'D.1.1.4': true,
  'E.i.1.2': true,
  'G.k.1': true,
  'G.k.2.2': true,
} as const;

const icsrStructure = Object.entries(IcsrStructure).map(([key, value]) => ({
  key,
  value,
}));

const mapping = (data: DatasetRule[]) => {
  const classifiedData = icsrStructure.reduce(
    (acc: { [key: string]: DatasetRule[] }, { key }) => {
      acc[key] = [];
      return acc;
    },
    {},
  );

  data.forEach((rule: DatasetRule) => {
    const { elementNumber } = rule;
    const { key } =
      icsrStructure.find(({ value }) => {
        const regexp = new RegExp(`^${value}`, 'i');
        return regexp.test(elementNumber);
      }) || {};

    if (key) classifiedData[key].push(rule);
  });
  return classifiedData;
};

export default mapping;
