import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import produce from 'immer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import {
  getCompany,
  getCompanyDefaultValues,
  putCompanyAdmin,
} from 'api/companies';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';
import { Company, WhoDrugLicenseVersion } from 'types/company';

import { companyState } from 'state';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Select from 'components/common/Select/Select';

const approvalLevelList = [
  { value: 0, label: 'none' },
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
];

function Setting() {
  const { t } = useTranslation();

  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getCompanyDefaultValues(),
  });

  useQuery(
    ['getCompany'],
    () => getCompany(company?.id).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Company) {
        setValue([
          { id: data.id },
          { timezone: data.timezone },
          { approvalLevel: data.approvalLevel },
          {
            daysBeforeReportingDeadlineAlert:
              data.daysBeforeReportingDeadlineAlert,
          },
          { alertIncludeNextDays: data.alertIncludeNextDays },
          { enableWhodd: data.enableWhodd },
          { whoDrugLicense: data.whoDrugLicense },
        ]);
      },
    },
  );

  const { mutate } = useMutation(putCompanyAdmin);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      const formData = new FormData();
      formData.append('timezone', data.timezone);
      formData.append('approvalLevel', data.approvalLevel.toString());
      formData.append(
        'daysBeforeReportingDeadlineAlert',
        data.daysBeforeReportingDeadlineAlert.toString(),
      );
      formData.append(
        'alertIncludeNextDays',
        data.alertIncludeNextDays.toString(),
      );
      if (data.whoDrugLicense) {
        formData.append(
          'whoddVersionInUse',
          data.whoDrugLicense.whoddVersionInUse,
        );
      }
      mutate({ companyId: company?.id, data: formData });
    }
  });

  const timezones = useMemo(() => moment.tz.names(), []);

  useEffect(() => {
    register({ name: 'timezone' }, { required: t('error:required') });
    register({ name: 'approvalLevel' }, { required: t('error:required') });
    register({ name: 'daysBeforeReportingDeadlineAlert' });
    register({ name: 'alertIncludeNextDays' });
    register({ name: 'enableWhodd' });
    register({ name: 'whoDrugLicense' });
  }, [register, t]);

  return (
    <form className="max-w-4xl" onSubmit={onSubmit}>
      <div className="space-y-1 pt-1">
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Timezone</div>
          <div className="flex w-1/2 flex-wrap">
            <Select
              className="w-full"
              value={form.timezone}
              data={timezones}
              onChange={(value) => setValue('timezone', value)}
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.timezone?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="required w-1/2">Approval Level</div>
          <div className="flex w-1/2 flex-wrap">
            <Select
              className="w-full"
              value={form.approvalLevel}
              data={approvalLevelList}
              valueField="value"
              textField="label"
              onChange={(obj) => setValue('approvalLevel', obj.value)}
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.approvalLevel?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">WHODrug Global Version</div>
          <div className="w-1/2">
            <Select
              className="w-full"
              value={form.whoDrugLicense?.whoddVersionInUse}
              data={form.whoDrugLicense?.versions || []}
              valueField="shortVersion"
              textField="version"
              onChange={({ shortVersion }: WhoDrugLicenseVersion) => {
                const newWhoDrugLicense = produce(
                  form.whoDrugLicense || {},
                  (draft: any) => {
                    draft.whoddVersionInUse = shortVersion;
                  },
                );
                setValue('whoDrugLicense', newWhoDrugLicense);
              }}
              disabled={!form.enableWhodd}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Alarm date Before deadline</div>
          <div className="w-1/2">
            <Input
              type="number"
              className="w-full"
              value={form.daysBeforeReportingDeadlineAlert}
              maxLength={5}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('daysBeforeReportingDeadlineAlert', e.target.value)
              }
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Alarm past due date</div>
          <div className="my-auto w-1/2">
            <Checkbox
              checked={form.alertIncludeNextDays}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue('alertIncludeNextDays', e.target.checked)
              }
            />
          </div>
        </div>
      </div>
      <div className="my-4 space-x-1 text-right">
        <Button type="submit" color="blue">
          Save
        </Button>
      </div>
    </form>
  );
}

export default Setting;
