import { TFunction } from 'i18next';

const literatureReference = (codeList: any, t: TFunction) => ({
  'C.4.r': {
    elementNumber: 'C.4.r',
    list: [
      {
        elementNumber: 'C.4.r.1',
        label: t('ICSR:elementName:C.4.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'C.4.r.2',
        label: t('ICSR:elementName:C.4.r.2'),
        component: 'file',
      },
    ],
  },
  'C.4.r.1': {
    elementNumber: 'C.4.r.1',
    label: t('ICSR:elementName:C.4.r.1'),
    component: 'textarea',
    maxLength: '500',
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'C.4.r.2': {
    elementNumber: 'C.4.r.2',
    label: t('ICSR:elementName:C.4.r.2'),
    component: 'file',
    accept:
      '.pdf,.jpg,.jpeg,.txt,.rtf,.tiff,.tif,.html,.doc,.docx,.xls,.xlsx,.dicom',
  },
});

export default literatureReference;
