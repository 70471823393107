import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import moment from 'moment-timezone';
import produce from 'immer';

import client from 'api/client';
import { getCodeSets } from 'api/cases';
import { getMeddraVersion } from 'api/meddra';

import modal from 'utils/modal';
import localCodeSets from 'utils/codeSets';

import { codeSetsState, coreState } from 'state';

import WS from './WS';

function Core() {
  const { t, i18n } = useTranslation();
  const setCodeSets = useSetRecoilState(codeSetsState);
  const [{ language, region }] = useRecoilState(coreState);

  useQuery(
    ['icsrCodeSets', language],
    () => getCodeSets({ language }).then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const codeSets = data.reduce((accum: any, item: any) => {
          accum[item.oid] = item;
          return accum;
        }, {});
        const newCodeSets = produce(codeSets, (draft: any) => {
          draft['1.0.3166.1.2.2'].codes.push({
            key: 'EU',
            value: 'European Union',
          });
          draft['1.0.3166.1.2.2'].codes.push({
            key: 'XI',
            value: 'Northern Ireland',
          });
          const codeSet = draft['2.16.840.1.113883.3.989.2.1.1.26'];
          if (codeSet) {
            const { codes } = draft['2.16.840.1.113883.3.989.2.1.1.26'];
            draft['2.16.840.1.113883.3.989.2.1.1.26_1'] = {
              ...draft['2.16.840.1.113883.3.989.2.1.1.26'],
              codes: codes.filter((code: any) =>
                ['a', 'mo', 'wk', 'd', 'h', '10.a'].some((value: any) => {
                  return code.key === value;
                }),
              ),
            };
            draft['2.16.840.1.113883.3.989.2.1.1.26_2'] = {
              ...draft['2.16.840.1.113883.3.989.2.1.1.26'],
              codes: codes.filter((code: any) =>
                ['mo', 'wk', 'd', '{trimester}'].some((value: any) => {
                  return code.key === value;
                }),
              ),
            };
            draft['2.16.840.1.113883.3.989.2.1.1.26_3'] = {
              ...draft['2.16.840.1.113883.3.989.2.1.1.26'],
              codes: codes.filter((code: any) =>
                ['a', '10.a'].some((value: any) => {
                  return code.key === value;
                }),
              ),
            };
          }
        });
        setCodeSets((prev) => ({
          ...prev,
          ...localCodeSets[language],
          ...newCodeSets,
        }));
      },
      onError() {
        modal.alert(t('modal:alert:error'), 'Failed to get ICSR codesets.');
      },
    },
  );

  useQuery(
    ['meddraVersions'],
    () => getMeddraVersion().then(({ data }) => data),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCodeSets((prev) => ({ ...prev, meddra_version: data }));
      },
      onError() {
        modal.alert(t('modal:alert:error'), 'Failed to get meddra version');
      },
    },
  );

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      client.getInstance().defaults.headers.common['Accept-Language'] =
        language;
      moment.locale(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    client.getInstance().defaults.headers.common['X-REGULATOR-AUTHORITY-TYPE'] =
      region;
  }, [region]);

  return (
    <>
      <Outlet />
      <WS />
    </>
  );
}

export default Core;
