import getQueryString from 'utils/getQueryString';
import { Company } from 'types/company';
import client from './client';

export const getCompanyDefaultValues = (): Company => ({
  companyName: '',
  type: '',
  timezone: 'Asia/Seoul',
  approvalLevel: 0,
  enableTracker: false,
  enableWhodd: false,
  daysBeforeReportingDeadlineAlert: 3,
  alertIncludeNextDays: false,
});

export const getCompanies = ({ query }: { query?: any }) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/general/companys?${queryString}`);
};

export const getCompany = (companyId?: number | string) =>
  client.get(`/api/v1/general/companys/${companyId}`);

export const postCompany = (data: any) =>
  client.post('/api/v1/general/companys', data);

export const putCompany = ({
  companyId,
  data,
}: {
  companyId?: number | string;
  data: any;
}) => client.put(`/api/v1/general/companys/${companyId}`, data);

export const putCompanyAdmin = ({
  companyId,
  data,
}: {
  companyId?: number | string;
  data: any;
}) => client.put(`/api/v1/general/companys/${companyId}/admin`, data);

export const deleteCompanies = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/general/companys?${queryString}`);
};

export const checkCompany = (companyName: string) =>
  client.get(`/api/v1/general/companys/name/${companyName}`);
