import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getUserInfo } from 'api/general';
import { logout } from 'api/auth';

import { UserType } from 'types/user';
import tokenTimer from 'utils/tokenTimer';

import { userState } from 'state';

import Loader from './Loader';

function SafetyUser() {
  const navigate = useNavigate();

  const [user, setUser] = useRecoilState(userState);

  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  useQuery(['userInfo'], () => getUserInfo().then(({ data }) => data), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setUser(data);
    },
    onError() {
      handleLogout();
    },
  });

  if (!user) return <Loader />;

  if (user.userType === UserType.manager) {
    return <Navigate replace to="safety-manage" />;
  }

  return <Outlet />;
}

export default SafetyUser;
