import clsx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

type InputType = TypeModify<
  InputHTMLAttributes<HTMLInputElement>,
  {
    size?: SizeType;
    error?: boolean;
  }
>;
const sizeStyles: { [key in SizeType]: string } = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};

function Input(
  { className, size = 'md', error, value = '', disabled, ...rest }: InputType,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <input
      ref={ref}
      className={twMerge(
        clsx(
          sizeStyles[size],
          'focus:control-focus',
          error && 'control-error',
          className,
        ),
      )}
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
}

export default React.forwardRef(Input);
