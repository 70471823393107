import client from './client';

export const postExportFile = ({
  companyId,
  icsrIds,
  exportType,
  senderId,
  receiverId,
  translated,
  individual,
}: {
  companyId?: number;
  icsrIds?: number[];
  exportType?: string;
  senderId?: number;
  receiverId?: number;
  translated: boolean;
  individual: boolean;
}) =>
  client.post(
    `/api/v1/transform/export?companyId=${companyId}&icsrId=${icsrIds}&exportType=${exportType}&senderId=${senderId}&receiverId=${receiverId}&translated=${translated}&individual=${individual}`,
  );

export const getCIOMSForm = ({ companyId }: { companyId: number | string }) =>
  client.get(`/api/v1/transform/export/cioms/form/${companyId}`);

export const postCIOMSForm = ({
  companyId,
  formData,
}: {
  companyId: number | string;
  formData: FormData;
}) => client.post(`/api/v1/transform/export/cioms/form/${companyId}`, formData);
