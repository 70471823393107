import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RecoilRoot } from 'recoil';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import queryClient from 'config/queryClient';
import Modal from 'components/common/Modal';
import Request from 'components/Request';

import 'i18n';
import 'moment/locale/ko';

import './index.css';

import reportWebVitals from './reportWebVitals';

import App from './App';
import router from './Router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}

    <RecoilRoot>
      <DndProvider backend={HTML5Backend}>
        <App>
          <RouterProvider router={router} />
          <Modal />
          <ToastContainer
            autoClose={3000}
            pauseOnHover={false}
            theme="colored"
          />
          <Request />
        </App>
      </DndProvider>
    </RecoilRoot>
  </QueryClientProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
