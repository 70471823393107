import React from 'react';
import { TbPlus, TbTrash } from 'react-icons/tb';
import { MdRestore } from 'react-icons/md';

import { Product } from 'types/product';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Textarea from 'components/common/Textarea';

interface props {
  submitLabel: string;
  form: Product;
  errors: any;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
  changeProduct: (key: string, value: any) => void;
  addSubstance: () => void;
  deleteSubstance: (index: number) => void;
  restoreSubstance?: (index: number) => void;
  changeSubstance: (key: string, value: any, index: number) => void;
  addAECodeList: () => void;
  deleteAECodeList: (index: number) => void;
  restoreAECodeList?: (index: number) => void;
  changeAECodeList: (key: string, value: any, index: number) => void;
}

function ProductForm({
  submitLabel,
  form,
  errors,
  onSubmit,
  changeProduct,
  addSubstance,
  deleteSubstance,
  restoreSubstance = () => null,
  changeSubstance,
  addAECodeList,
  deleteAECodeList,
  restoreAECodeList = () => null,
  changeAECodeList,
}: props) {
  const { productName, brandName, description, substances, aeCodeList } = form;
  return (
    <form className="max-w-7xl" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div className="max-w-3xl space-y-1">
          <div className="flex py-4 px-2">
            <div className="w-1/2">IP Name</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={productName}
                maxLength={255}
                onChange={(e) => changeProduct('productName', e.target.value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.productName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Brand Name</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={brandName}
                maxLength={255}
                onChange={(e) => changeProduct('brandName', e.target.value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.brandName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Description</div>
            <div className="w-1/2">
              <Textarea
                className="w-full"
                minRows={3}
                maxRows={10}
                maxLength={255}
                value={description}
                onChange={(e) => changeProduct('description', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2">Substances</h2>
          <div className="flex bg-slate-100">
            <table className="w-full table-fixed">
              <thead className="border-b-4 border-white text-left [&_th]:font-medium">
                <tr className="text-center [&>th]:p-2">
                  <th>Substance / Specified Substance Name (G.k.2.3.r.1)</th>
                  <th>
                    Substance/Specified Substance TermID Version Date/Number
                    (G.k.2.3.r.2a)
                  </th>
                  <th>Substance/Specified Substance TermID (G.k.2.3.r.2b)</th>
                  <th>Strength (number) (G.k.2.3.r.3a)</th>
                  <th>Strength (unit) (G.k.2.3.r.3b)</th>
                  <th className="w-14">
                    <button
                      type="button"
                      className="mx-auto flex"
                      onClick={addSubstance}
                    >
                      <TbPlus />
                    </button>
                  </th>
                </tr>
              </thead>
              {substances.map(
                (
                  {
                    name,
                    strengthNumber,
                    strengthUnit,
                    termId,
                    termIdVersion,
                    deleted,
                  },
                  index,
                ) => (
                  <tbody key={index} className="group border-b-4 border-white">
                    <tr className="bg-inherit [&>td]:p-2">
                      <td>
                        <Input
                          className="w-full"
                          value={name}
                          onChange={(e) =>
                            changeSubstance('name', e.target.value, index)
                          }
                          disabled={deleted}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={termIdVersion}
                          onChange={(e) =>
                            changeSubstance(
                              'termIdVersion',
                              e.target.value,
                              index,
                            )
                          }
                          disabled={deleted}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={termId}
                          onChange={(e) =>
                            changeSubstance('termId', e.target.value, index)
                          }
                          disabled={deleted}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={strengthNumber}
                          onChange={(e) =>
                            changeSubstance(
                              'strengthNumber',
                              e.target.value,
                              index,
                            )
                          }
                          disabled={deleted}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={strengthUnit}
                          onChange={(e) =>
                            changeSubstance(
                              'strengthUnit',
                              e.target.value,
                              index,
                            )
                          }
                          disabled={deleted}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="mx-auto flex"
                          onClick={() =>
                            deleted
                              ? restoreSubstance(index)
                              : deleteSubstance(index)
                          }
                        >
                          {deleted ? (
                            <MdRestore className="text-slate-600 hover:text-slate-500" />
                          ) : (
                            <TbTrash className="text-red-600 hover:text-red-500" />
                          )}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ),
              )}
            </table>
          </div>
        </div>
        <div>
          <h2 className="mb-2">Interested AE</h2>
          <div className="flex bg-slate-100">
            <table className="w-full table-fixed">
              <thead className="border-b-4 border-white text-left [&_th]:font-medium">
                <tr className="text-center [&>th]:p-2">
                  <th>LLT Name</th>
                  <th>LLT Code</th>
                  <th className="w-14">
                    <button
                      type="button"
                      className="mx-auto flex"
                      onClick={addAECodeList}
                    >
                      <TbPlus />
                    </button>
                  </th>
                </tr>
              </thead>
              {aeCodeList.map(({ lltName, lltCode, deleted }, index) => (
                <tbody key={index} className="group border-b-4 border-white">
                  <tr className="bg-inherit [&>td]:p-2">
                    <td>
                      <Input
                        className="w-full"
                        value={lltName}
                        onChange={(e) =>
                          changeAECodeList('lltName', e.target.value, index)
                        }
                        disabled={deleted}
                      />
                    </td>
                    <td>
                      <Input
                        className="w-full"
                        value={lltCode}
                        onChange={(e) =>
                          changeAECodeList('lltCode', e.target.value, index)
                        }
                        disabled={deleted}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="mx-auto flex"
                        onClick={() =>
                          deleted
                            ? restoreAECodeList(index)
                            : deleteAECodeList(index)
                        }
                      >
                        {deleted ? (
                          <MdRestore className="text-slate-600 hover:text-slate-500" />
                        ) : (
                          <TbTrash className="text-red-600 hover:text-red-500" />
                        )}
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default ProductForm;
