import React from 'react';
import { TbPlus, TbTrash } from 'react-icons/tb';
import produce from 'immer';

import { DatasetRuleType } from 'types/dataset';

import Input from 'components/common/Input';

interface Props {
  rule: DatasetRuleType;
  setRule: (rule: DatasetRuleType) => void;
}

function CodeMappingTable({ rule, setRule }: Props) {
  return (
    <table className="w-full table-fixed border-collapse border-slate-800">
      <thead className="text-center">
        <tr className="[&>th]:border [&>th]:p-1">
          <th className="w-12">No.</th>
          <th>EDC Value</th>
          <th>E2B Value</th>
          <th className="w-8">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setRule(
                  produce(rule, (draft) => {
                    draft.codeConversions.push({});
                  }),
                );
              }}
            >
              <TbPlus />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {rule?.codeConversions.map(({ sourceValue, targetValue }, idx) => (
          <tr key={idx} className="text-center [&>td]:border [&>td]:p-1">
            <td>{idx + 1}</td>
            <td>
              <Input
                size="sm"
                className="w-full"
                value={sourceValue || ''}
                onChange={(e) =>
                  setRule(
                    produce(rule, (draft) => {
                      draft.codeConversions[idx].sourceValue = e.target.value;
                    }),
                  )
                }
              />
            </td>
            <td>
              <Input
                size="sm"
                className="w-full"
                value={targetValue || ''}
                onChange={(e) =>
                  setRule(
                    produce(rule, (draft) => {
                      draft.codeConversions[idx].targetValue = e.target.value;
                    }),
                  )
                }
              />
            </td>
            <td>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setRule(
                    produce(rule, (draft) => {
                      draft.codeConversions.splice(idx, 1);
                    }),
                  );
                }}
              >
                <TbTrash />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CodeMappingTable;
