import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/common/Dropdown';
import { TbArrowBarToDown } from 'react-icons/tb';
import clsx from 'clsx';

function LoadData({
  data = [],
  textField,
  disabled,
  onLoad = () => null,
}: {
  data?: any[];
  textField: string | ((item: any) => string);
  disabled?: boolean;
  onLoad?: (item: any, selectedIdx?: number) => void;
}) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <Dropdown
      className={clsx('ml-2 mt-1', disabled && 'disabled')}
      isOpen={open}
      toggle={toggle}
    >
      <DropdownToggle>
        <TbArrowBarToDown />
      </DropdownToggle>
      <DropdownMenu
        className={clsx(
          '!w-fit rounded border border-slate-200 bg-white py-0.5 text-slate-600 shadow',
          'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
        )}
      >
        {data.map((item, idx) => (
          <DropdownItem
            key={idx}
            className={clsx(
              'my-1 cursor-pointer px-3 py-1 hover:bg-slate-100',
              'dark:hover:bg-slate-900',
            )}
          >
            <button onClick={() => onLoad(item)}>
              {typeof textField === 'string'
                ? item[textField]
                : textField(item)}
            </button>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default LoadData;
