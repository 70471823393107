import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import produce from 'immer';

import { ICSRElement, ICSRFormElement, ICSRFormRootElement } from 'types/case';

import { Tab, TabPanel, Tabs } from 'components/common/Tab';

import { useCaseState } from './context/CaseContext';

import CaseFormElement from './CaseFormElement';
import RootElement from './RootElement';
import RootRepeatElement from './RootRepeatElement';
import SubTitle from './SubTitle';

export function CaseTabPanel({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tabId,
  children,
}: {
  tabId: number;
  children?: React.ReactNode;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

const getMessageCount = (
  data: any,
  messages: any,
  elementNumber: string,
): number => {
  if (!data) return 0;
  if (!messages[elementNumber]) return 0;
  if (Array.isArray(data.elements[elementNumber])) {
    const elementsErrorMessageCount = data.elements[elementNumber].reduce(
      (acc: number, cur: any) => {
        return acc + (messages[elementNumber][cur.id]?.count || 0);
      },
      0,
    );
    const errorMessageCountFromParent = messages[elementNumber].messages.filter(
      (message: any) => message.parent === data.id,
    ).length;
    return elementsErrorMessageCount + errorMessageCountFromParent;
  }
  return messages[elementNumber].count || 0;
};

function CaseTab({
  version,
  element,
  defaultTabList,
  onChange,
  onRootChange,
  disabled,
  children,
}: {
  version?: number;
  element?: ICSRFormRootElement;
  defaultTabList: {
    label: string;
    validationCount: number;
    memoCount: number;
  }[];
  onChange?: (n: string, v: ICSRElement) => void;
  onRootChange?: (
    n: string,
    v: ICSRFormRootElement | ICSRFormRootElement[],
  ) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}) {
  const { validationMessages, memoMessages } = useCaseState();

  const [tabValue, setTabValue] = useState(0);
  const tabList = useMemo(() => {
    const list = produce(defaultTabList, (draft) => {
      React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          draft[item.props.tabId].validationCount = 0;
          draft[item.props.tabId].memoCount = 0;
          (Array.isArray(item.props.children)
            ? item.props.children
            : [item.props.children]
          ).forEach((ele: React.ReactElement<React.PropsWithChildren<any>>) => {
            if (
              ele.type === CaseFormElement ||
              ele.type === RootElement ||
              ele.type === RootRepeatElement
            ) {
              draft[item.props.tabId].validationCount += getMessageCount(
                element,
                validationMessages,
                ele.props.number,
              );
              draft[item.props.tabId].memoCount += getMessageCount(
                element,
                memoMessages,
                ele.props.number,
              );
            }
          });
        }
      });
    });
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTabList, validationMessages, memoMessages]);

  return (
    <>
      <Tabs
        className={clsx('-mb-px flex')}
        onChange={(value) => {
          setTabValue(value);
        }}
      >
        {tabList.map(({ label, validationCount, memoCount }, index) => (
          <Tab
            key={label}
            className={clsx(
              'relative cursor-pointer items-center space-x-2 rounded-tl rounded-tr border border-b-0 border-slate-300 px-4 py-2 text-center font-semibold opacity-40 transition-opacity',
              tabValue === index && 'bg-white opacity-100',
            )}
            index={index}
          >
            {label}
            <div className="absolute -top-3 -left-2 space-x-px">
              {validationCount ? (
                <div className="inline-block rounded-full bg-red-500 px-2 text-xs text-white">
                  {validationCount}
                </div>
              ) : null}
              {memoCount ? (
                <div className="inline-block rounded-full bg-yellow-500 px-2 text-xs text-white">
                  {memoCount}
                </div>
              ) : null}
            </div>
          </Tab>
        ))}
      </Tabs>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) ? (
          <TabPanel
            className="flex flex-wrap border border-slate-300 px-2 pt-8"
            value={tabValue}
            index={child.props.tabId}
          >
            {(() => {
              if (child.props.children) {
                return (
                  Array.isArray(child.props.children)
                    ? child.props.children
                    : [child.props.children]
                ).map(
                  (ele: React.ReactElement<React.PropsWithChildren<any>>) => {
                    if (ele.type === CaseFormElement) {
                      return React.cloneElement(ele, {
                        key: ele.props.number,
                        parentId: element?.id,
                        element: element?.elements?.[
                          ele.props.number
                        ] as ICSRFormElement,
                        disabled:
                          disabled ||
                          (ele.props.available &&
                            !ele.props.available(element)),
                        onChange,
                      });
                    }
                    if (ele.type === RootElement) {
                      return React.cloneElement(ele, {
                        key: ele.props.number,
                        version,
                        element: element?.elements?.[
                          ele.props.number
                        ] as ICSRFormRootElement,
                        disabled,
                        onChange: onRootChange,
                      });
                    }
                    if (ele.type === RootRepeatElement) {
                      return React.cloneElement(ele, {
                        key: ele.props.number,
                        version,
                        element: element?.elements?.[
                          ele.props.number
                        ] as ICSRFormRootElement[],
                        disabled,
                        onChange: onRootChange,
                      });
                    }
                    if (ele.type === SubTitle) {
                      return React.cloneElement(ele, {
                        key: `${ele.props.number}-title`,
                        parentId: element?.id,
                      });
                    }
                    return ele;
                  },
                );
              }
              return child;
            })()}
          </TabPanel>
        ) : (
          child
        ),
      )}
    </>
  );
}

export default CaseTab;
