import { TFunction } from 'i18next';

const testResult = (codeList: any, t: TFunction) => ({
  'F.r': {
    elementNumber: 'F.r',
    list: [
      {
        elementNumber: 'F.r.1',
        label: t('ICSR:elementName:F.r.1'),
        component: 'date',
      },
      {
        elementNumber: 'F.r.2.1',
        label: t('ICSR:elementName:F.r.2.1'),
        component: 'input',
      },
      {
        elementNumber: 'F.r.2.2a',
        label: t('ICSR:elementName:F.r.2.2a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'F.r.2.2b',
        label: t('ICSR:elementName:F.r.2.2b'),
        component: 'MedDRASearch',
      },
    ],
  },
  'F.r.1': {
    elementNumber: 'F.r.1',
    label: t('ICSR:elementName:F.r.1'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['UNK'],
  },
  'F.r.2.1': {
    elementNumber: 'F.r.2.1',
    label: t('ICSR:elementName:F.r.2.1'),
    component: 'textarea',
    maxLength: '250',
  },
  'F.r.2.2a': {
    elementNumber: 'F.r.2.2a',
    label: t('ICSR:elementName:F.r.2.2a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'F.r.2.2b': {
    elementNumber: 'F.r.2.2b',
    label: t('ICSR:elementName:F.r.2.2b'),
    component: 'MedDRASearch',
  },
  'F.r.3.1': {
    elementNumber: 'F.r.3.1',
    label: t('ICSR:elementName:F.r.3.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.12']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'F.r.3.2': {
    elementNumber: 'F.r.3.2',
    label: t('ICSR:elementName:F.r.3.2'),
    component: 'input',
    maxLength: '50',
    nullFlavorList: ['NINF', 'PINF'],
  },
  'F.r.3.3': {
    elementNumber: 'F.r.3.3',
    label: t('ICSR:elementName:F.r.3.3'),
    component: 'select',
    data: codeList['2.16.840.1.113883.6.8']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'F.r.3.4': {
    elementNumber: 'F.r.3.4',
    label: t('ICSR:elementName:F.r.3.4'),
    component: 'textarea',
    maxLength: '2000',
  },
  'F.r.4': {
    elementNumber: 'F.r.4',
    label: t('ICSR:elementName:F.r.4'),
    component: 'input',
    maxLength: '50',
  },
  'F.r.5': {
    elementNumber: 'F.r.5',
    label: t('ICSR:elementName:F.r.5'),
    component: 'input',
    maxLength: '50',
  },
  'F.r.6': {
    elementNumber: 'F.r.6',
    label: t('ICSR:elementName:F.r.6'),
    component: 'textarea',
    maxLength: '2000',
  },
  'F.r.7': {
    elementNumber: 'F.r.7',
    label: t('ICSR:elementName:F.r.7'),
    component: 'radio',
    options: codeList.CNR_BOOLEAN?.codes,
    valueField: 'key',
    textField: 'value',
  },
});

export default testResult;
