import React from 'react';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';
import DashboardApproval from './DashboardApproval';
import DashboardReportResult from './DashboardReportResult';
import DashboardDeadline from './DashboardDeadline';

function Home() {
  return (
    <MainTemplate>
      <Header title="Home" />
      <Content className="dropdown-container flex flex-1 flex-col flex-wrap overflow-auto bg-slate-100 px-2 py-2">
        <div className="flex w-full min-w-[1024px] max-w-[1920px] flex-wrap">
          <DashboardApproval />
          <DashboardReportResult />
          <DashboardDeadline />
        </div>
      </Content>
    </MainTemplate>
  );
}

export default Home;
