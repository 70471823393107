/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useReducer, useEffect, useCallback, useState } from 'react';
import clsx from 'clsx';

import { ActionType, EV } from 'utils/modal';

import Alert from './Alert';
import Confirm from './Confirm';
import Custom from './Custom';

interface ModalState {
  type?: 'alert' | 'confirm' | 'custom';
  isOpen: boolean;
  title: string;
  message: string;
  backToggle?: boolean;
  confirm?: ActionType;
  custom?: {
    actions: ActionType[];
    icon?: JSX.Element | React.ReactNode;
  };
  close: () => void;
}

const initialModal: ModalState = {
  isOpen: false,
  title: '',
  message: '',
  close: () => {},
};

const modalReducer = (
  state: ModalState,
  action: { type: 'open' | 'close' | 'reset'; data?: ModalState },
) => {
  const { type, data } = action;
  switch (type) {
    case 'open':
      return { ...initialModal, ...data, isOpen: true };
    case 'close':
      return { ...state, isOpen: false };
    case 'reset':
      return initialModal;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

function ModalContainer() {
  const [open, setOpen] = useState<boolean>(false);
  const [state, dispatch] = useReducer(modalReducer, initialModal);
  const { type, isOpen, title, message, backToggle, confirm, custom, close } =
    state;

  const toggle = useCallback(() => {
    close();
    dispatch({ type: 'close' });
  }, [close]);

  useEffect(() => {
    EV.on(dispatch);
    return () => {};
  }, []);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    } else if (!isOpen) {
      setOpen(false);
    }
  }, [isOpen]);

  const component = (() => {
    switch (type) {
      case 'alert':
        return <Alert title={title} message={message} toggle={toggle} />;
      case 'confirm':
        return (
          confirm && (
            <Confirm
              title={title}
              message={message}
              confirm={confirm}
              toggle={toggle}
            />
          )
        );
      case 'custom':
        return (
          custom && (
            <Custom
              title={title}
              message={message}
              custom={custom}
              toggle={toggle}
            />
          )
        );
      default:
        return null;
    }
  })();

  return (
    <div
      className={clsx(open ? 'block' : 'hidden', 'relative z-dropdown')}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="dropdown-container fixed inset-0 bg-gray-500/75 transition-opacity"
        onClick={backToggle ? toggle : undefined}
      />
      {component}
    </div>
  );
}

export default ModalContainer;
