export const ApprovalCaseStatus = {
  request: 'APPROVAL_IN_PROGRESS',
  rejected: 'WRITING_IN_PROGRESS',
  approved: 'APPROVED',
} as const;

export const ApprovalStatus = {
  request: 'APPROVAL_IN_PROGRESS',
  rejected: 'REJECTED',
  approved: 'APPROVED',
} as const;

export const ApprovalLineStatus = {
  WAIT: 'WAIT',
  PROGRESS: 'PROGRESS',
  COMPLETE: 'COMPLETE',
} as const;

export const ApprovalAction = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
} as const;

export interface ApprovalLine {
  id: number;
  sequence: number;
  approverName: null | string;
  status: typeof ApprovalLineStatus[keyof typeof ApprovalLineStatus];
  action: null | string;
  comment: null | string;
  actionDate: string;
}

export interface Icsr {
  id: number;
  dateOfMostRecentInformationForThisReport: string;
  sendersSafetyReportUniqueIdentifier: string;
  productNames: string;
}

export interface Approval {
  id: number;
  subject: string;
  status: string;
  icsrs: Icsr[];
  approvalLines: ApprovalLine[];
  lastModifiedDate: string;
}
