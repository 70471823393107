import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { getRequestApprovals, putApproval } from 'api/approvals';

import {
  Approval,
  ApprovalAction,
  ApprovalLineStatus,
  ApprovalLine,
} from 'types/approval';
import { ListHead } from 'types/list';
import modal from 'utils/modal';
import useList from 'hooks/useList';

import { companyState } from 'state';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import ExpandedComponent from './ExpandedComponent';

const listHeads: ListHead[] = [
  {
    name: 'active',
    style: { width: '2.5rem' },
  },
  {
    name: 'subject',
    label: 'Subject',
  },
  {
    name: 'lastModifiedDate',
    label: 'Date of Request',
  },
  {
    name: 'requester',
    label: 'Requester',
  },
];

const filterList = [
  // {
  //   type: 'searchList',
  //   name: 'searchList',
  //   list: [{ name: 'subject', label: 'Subject' }],
  //   filter: true,
  //   width: '20rem',
  // },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function ApprovalList() {
  const { t } = useTranslation();

  const [{ selected: company }] = useRecoilState(companyState);
  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    queryKey: 'request',
    queryFn: (q) => getRequestApprovals({ companyId: company?.id, query: q }),
  });

  const { mutate } = useMutation(putApproval, {
    onSuccess() {
      refetch();
    },
  });

  const handleReject = (approvalId: number, approvalLines: ApprovalLine[]) => {
    const approvalLineId = approvalLines.find(
      ({ status }: any) => status === ApprovalLineStatus.PROGRESS,
    )?.id;
    modal.custom({
      title: t('modal:custom:caseApproveRejectTitle'),
      message: (
        <input
          id="approval-page-reject-message"
          className="control control-md w-full"
        />
      ),
      actions: [
        [
          'Ok',
          () => {
            const comment = (
              document.getElementById(
                'approval-page-reject-message',
              ) as HTMLInputElement
            ).value;
            mutate({
              approvalId,
              approvalLineId,
              data: { action: ApprovalAction.REJECT, comment },
            });
          },
          true,
        ],
      ],
    });
  };

  const handleApprove = async (
    approvalId: number,
    approvalLines: ApprovalLine[],
  ) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:approve'))
    ) {
      const approvalLineId = approvalLines.find(
        ({ status }: any) => status === ApprovalLineStatus.PROGRESS,
      )?.id;
      mutate({
        approvalId,
        approvalLineId,
        data: { action: ApprovalAction.APPROVE },
      });
    }
  };

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
            disableCheckbox
          >
            {data?.map((approval: Approval) => (
              <LTR
                key={approval.id}
                type="body"
                expandedComponent={
                  <ExpandedComponent
                    {...approval}
                    handleApprove={handleApprove}
                    handleReject={handleReject}
                  />
                }
                colSpan={4}
              >
                <LTD>{approval.subject}</LTD>
                <LTD>{approval.lastModifiedDate}</LTD>
                <LTD>{approval.approvalLines?.[0].approverName}</LTD>
              </LTR>
            ))}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
    </>
  );
}

export default ApprovalList;
