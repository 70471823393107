import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { getMembers } from 'api/members';

import { companyState } from 'state';
import useList from 'hooks/useList';
import { Member } from 'types/member';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

const listHeads = [
  {
    name: 'userName',
    label: 'Name',
    sort: true,
  },
  {
    name: 'email',
    label: 'Email',
    sort: true,
  },
  {
    name: 'roleName',
    label: 'Role',
    sort: true,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'End Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'edit',
    style: { width: '5rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [
      { name: 'email', label: 'Email' },
      { name: 'userName', label: 'Name' },
    ],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function Members() {
  const location = useLocation();
  const [{ selected: company }] = useRecoilState(companyState);

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    queryKey: 'members',
    queryFn: (q) => getMembers({ companyId: company?.id, query: q }),
  });

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
        <div className="flex items-center pl-4 ">
          <NavLink to="./new" state={{ prevUrl: location.pathname }}>
            <Button color="blue" size="sm">
              Create
            </Button>
          </NavLink>
        </div>
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
            disableCheckbox
          >
            {data?.map(
              ({
                id,
                userName,
                email,
                roleName,
                startDate,
                endDate,
              }: Member) => (
                <LTR key={id} type="body">
                  <LTD>{userName}</LTD>
                  <LTD>{email}</LTD>
                  <LTD>{roleName}</LTD>
                  <LTD>{startDate}</LTD>
                  <LTD>{endDate}</LTD>
                  <LTD>
                    <NavLink
                      to={`./${id}`}
                      state={{ prevUrl: location.pathname }}
                      className="flex items-center hover:opacity-60"
                      color="green"
                    >
                      <TbEdit className="mr-1" /> Edit
                    </NavLink>
                  </LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
    </>
  );
}

export default Members;
