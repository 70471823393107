import React from 'react';

import FormWrapper from 'components/common/FormWrapper';

import UploadForm from './UploadForm';

function Upload() {
  return (
    <FormWrapper title="Upload">
      <UploadForm />
    </FormWrapper>
  );
}

export default Upload;
