/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import {
  Tooltip as ReactTooltip,
  PlacesType,
  ChildrenType,
} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import uniqueId from 'lodash.uniqueid';

function Tooltip({
  isOpen,
  openOnClick,
  place,
  content,
  className,
  classNameArrow,
  style,
  disabled,
  children,
  noArrow,
  opacity,
  setIsOpen,
  render,
}: {
  isOpen?: boolean;
  openOnClick?: boolean;
  place?: PlacesType;
  content?: string;
  className?: string;
  classNameArrow?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children?: React.ReactNode;
  noArrow?: boolean;
  opacity?: number;
  setIsOpen?: (value: boolean) => void;
  render?: (r: {
    content: string | null;
    activeAnchor: HTMLElement | null;
  }) => ChildrenType;
}) {
  const id = useRef(uniqueId('tootip-'));
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement;
          return React.cloneElement(item, {
            id: id.current,
          });
        }
        return child;
      })}
      {!disabled && (
        <ReactTooltip
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          openOnClick={openOnClick}
          clickable
          closeOnEsc
          anchorSelect={`#${id.current}`}
          place={place}
          content={content}
          noArrow={noArrow}
          style={style}
          className={className}
          classNameArrow={classNameArrow}
          render={render}
          opacity={opacity}
        />
      )}
    </>
  );
}

export default Tooltip;
