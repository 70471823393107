import React, { useMemo, useState } from 'react';
import {
  TbCheck,
  TbFileExport,
  TbHistory,
  TbReload,
  TbSend,
  TbTrash,
  TbWritingSign,
} from 'react-icons/tb';
import {
  CaseStatus,
  CaseValidationResult,
  CaseValidationType,
} from 'types/case';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useRecoilState } from 'recoil';

import modal from 'utils/modal';
import { companyState, memberState } from 'state';

import { buttonStyles } from 'components/common/Modal/Confirm';

import { useCaseAction, useCaseState } from './context/CaseContext';

import ExportPopup from './popup/ExportPopup';
import SubmissionPopup from './popup/SubmissionPopup';
import useCaseActions from './hooks/useCaseActions';
import useCaseValidate from './hooks/useCaseValidate';
import CaseApprovalLine from './CaseApprovalLine';
import SubmissionHistory from './SubmissionHistory';

const svgStyles =
  'border h-[1.875rem] w-[1.875rem] rounded-sm bg-slate-200 p-1.5 text-slate-400 hover:bg-slate-100';

function CaseEidtActions() {
  const { t } = useTranslation();

  const [{ selected: company }] = useRecoilState(companyState);
  const [member] = useRecoilState(memberState);

  const { icsr } = useCaseState();
  const { refetchCase } = useCaseAction();
  const {
    id: icsrId,
    status,
    nonReporting,
    validationResult,
    deleted,
  } = icsr || {};
  const icsrIds = icsrId ? [icsrId] : [];

  const { checkCaseValidate } = useCaseValidate();
  const { deleteCase, resetCase, completeCase, restoreCase, apporveCases } =
    useCaseActions();

  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [submissionPopupOpen, setSubmissionPopupOpen] = useState(false);

  const exportPopupToggle = () => setExportPopupOpen((prev) => !prev);
  const submissionPopupToggle = () => setSubmissionPopupOpen((prev) => !prev);

  const checkValidate = (type: keyof typeof CaseValidationType) => {
    const result = checkCaseValidate(type, icsr);
    if (result === CaseValidationResult.SUCCESS) {
      return true;
    }
    modal.alert(t('modal:alert:title'), result);
    return false;
  };

  const {
    isPossibleComplete,
    isApproving,
    isApproved,
    isSubmitted,
    isProcessingCompleted,
    isPossibleUnlock,
  } = useMemo(
    () => ({
      isPossibleComplete:
        validationResult?.succeeded &&
        status === CaseStatus.WRITING_IN_PROGRESS,
      isApproving: status === CaseStatus.APPROVAL_IN_PROGRESS,
      isApproved:
        status === CaseStatus.COMPLETED ||
        status === CaseStatus.APPROVED ||
        status === CaseStatus.SUBMITTED ||
        status === CaseStatus.SUBMIT_FAILED ||
        status === CaseStatus.PROCESSING_FAILED ||
        status === CaseStatus.PROCESSING_COMPLETED,
      isSubmitted: status === CaseStatus.SUBMITTED,
      isProcessingCompleted: status === CaseStatus.PROCESSING_COMPLETED,
      isPossibleUnlock:
        status === CaseStatus.COMPLETED ||
        status === CaseStatus.APPROVED ||
        status === CaseStatus.SUBMIT_FAILED ||
        status === CaseStatus.PROCESSING_FAILED,
    }),
    [status, validationResult],
  );

  const handleExport = () => {
    if (!checkValidate(CaseValidationType.EXPORT)) return;
    exportPopupToggle();
  };

  const handleSubmission = () => {
    if (!checkValidate(CaseValidationType.SEND)) return;
    submissionPopupToggle();
  };

  const handleComplete = async () => {
    if (!checkValidate(CaseValidationType.COMPLETE)) return;
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:complete'))
    ) {
      completeCase(icsrId).then(() => refetchCase());
    }
  };

  const handleApprove = async () => {
    if (!checkValidate(CaseValidationType.APPROVE)) return;
    if (isPossibleComplete) {
      await modal.custom({
        title: 'Case(s) approve',
        message: (
          <input
            id="case-approve-message"
            className="control control-md w-full"
            placeholder="Enter message"
          />
        ),
        actions: [
          ['Cancel', () => {}, true],
          [
            'Approve',
            () => {
              const comment = (
                document.getElementById(
                  'case-approve-message',
                ) as HTMLInputElement
              ).value;
              apporveCases({
                companyId: company?.id,
                subject: comment || moment().format(),
                icsrIds,
              }).then(() => refetchCase());
            },
            true,
            buttonStyles.info,
          ],
        ],
      });
    }
  };

  const handleDelete = async () => {
    if (
      await modal.confirm(
        t('modal:confirm:title'),
        t('modal:confirm:delete'),
        'warn',
      )
    ) {
      deleteCase(icsrId).then(() => refetchCase());
    }
  };

  const handleReset = async () => {
    if (!checkValidate(CaseValidationType.RESET)) return;
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:reset'))
    ) {
      resetCase(icsrId).then(() => refetchCase());
    }
  };

  const handleRestore = async () => {
    if (!checkValidate(CaseValidationType.RESTORE)) return;
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:restore'))
    ) {
      restoreCase(icsrId).then(() => refetchCase());
    }
  };

  return (
    <div className="flex items-center pl-4">
      {member?.privileges?.CASE_EDIT && (
        <div className="relative">
          <div className="flex space-x-2">
            <button onClick={handleExport}>
              <TbFileExport className={svgStyles} title="export" />
            </button>
            {nonReporting ? (
              <button
                onClick={isPossibleUnlock ? handleReset : handleComplete}
                disabled={!isPossibleComplete && !isPossibleUnlock}
              >
                <TbCheck
                  title="complete"
                  className={twMerge(
                    clsx(
                      svgStyles,
                      isApproved &&
                        'pointer-events-none bg-brand-500 stroke-white',
                    ),
                  )}
                />
              </button>
            ) : (
              <button
                onClick={isPossibleUnlock ? handleReset : handleApprove}
                disabled={
                  !isApproving && !isPossibleComplete && !isPossibleUnlock
                }
              >
                <TbWritingSign
                  title="approval"
                  className={twMerge(
                    clsx(
                      svgStyles,
                      isApproving && 'border-brand-500 stroke-brand-500',
                      isApproved &&
                        'bg-brand-500 stroke-white hover:bg-brand-400',
                    ),
                  )}
                />
              </button>
            )}
            <button
              onClick={handleSubmission}
              disabled={!isApproved || isSubmitted || isProcessingCompleted}
            >
              <TbSend
                title="submission"
                className={twMerge(
                  clsx(
                    svgStyles,
                    isSubmitted && 'border-brand-500 stroke-brand-500',
                    isProcessingCompleted &&
                      'bg-brand-500 stroke-white hover:bg-brand-400',
                  ),
                )}
              />
            </button>
            <SubmissionHistory>
              <TbHistory title="history" className={svgStyles} />
            </SubmissionHistory>
            {deleted ? (
              <button onClick={handleRestore}>
                <TbReload className={svgStyles} title="restore" />
              </button>
            ) : (
              <button onClick={handleDelete}>
                <TbTrash className={svgStyles} title="delete" />
              </button>
            )}
          </div>
          <CaseApprovalLine />
        </div>
      )}
      <ExportPopup
        isOpen={exportPopupOpen}
        icsrIds={icsrIds}
        toggle={exportPopupToggle}
      />
      <SubmissionPopup
        isOpen={submissionPopupOpen}
        icsrIds={icsrIds}
        toggle={submissionPopupToggle}
        callback={refetchCase}
      />
    </div>
  );
}

export default CaseEidtActions;
