import React from 'react';
import { IoClose } from 'react-icons/io5';
import { TbDownload, TbLink } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';

import { getHistoriesDetails } from 'api/history';

import useListQuery from 'hooks/useListQuery';
import { HistoryDetail } from 'types/history';

import Popup from 'components/common/Popup';
import Pagination from 'components/common/Pagination';
import Download from 'components/common/Donwload';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

function HistoryPopup({
  isOpen,
  type,
  historyId,
  toggle,
}: {
  isOpen: boolean;
  type: 'import' | 'export';
  historyId?: number;
  toggle: () => void;
}) {
  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    refetch,
    changePage,
  } = useListQuery({
    queryKey: 'historyDetails',
    queryFn: (q) => getHistoriesDetails({ historyId, type, query: q }),
    options: {
      enabled: isOpen,
    },
  });

  return (
    <Popup isOpen={isOpen} className="w-[70rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        History Detail
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-2 overflow-auto bg-inherit py-2">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              <LTH>Case No.</LTH>
              <LTH>Type</LTH>
              <LTH>Study</LTH>
              <LTH>Product</LTH>
              <LTH>Type of Report</LTH>
              <LTH>Status</LTH>
              {type === 'import' && (
                <LTH className="w-32 text-center">Error Report</LTH>
              )}
              <LTH className="w-20 text-center">Link</LTH>
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={type === 'import' ? 8 : 7}
            disableCheckbox
          >
            {data?.map(
              ({
                id,
                caseNumber,
                importType,
                sponsorStudyNumber,
                productName,
                reportType,
                status,
                errorReportUrl,
                caseId,
              }: HistoryDetail) => (
                <LTR key={id} type="body">
                  <LTD>{caseNumber}</LTD>
                  <LTD>{importType}</LTD>
                  <LTD>{sponsorStudyNumber}</LTD>
                  <LTD>{productName}</LTD>
                  <LTD>{reportType}</LTD>
                  <LTD>{status}</LTD>
                  {type === 'import' && (
                    <LTD>
                      <Download
                        src={errorReportUrl}
                        className="hover:opacity-50"
                      >
                        <TbDownload className="mx-auto" />
                      </Download>
                    </LTD>
                  )}
                  <LTD className="text-center">
                    <NavLink to={`./../../cases/${caseId}`}>
                      <TbLink className="mx-auto hover:opacity-60" />
                    </NavLink>
                  </LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Pagination
          page={query.page}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          refresh={refetch}
          disabledChangeSize
        />
      </div>
    </Popup>
  );
}

export default HistoryPopup;
