import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import clsx from 'clsx';

import getCoords from 'utils/getCoords';

import DropdownToggle from './DropdownToggle';
import DropdownMenu from './DropdownMenu';

type DropdownLocationType = 'left' | 'right' | 'up' | 'down';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  toggle?: (e?: React.MouseEvent) => void;
  location?: DropdownLocationType;
  disableDropMenuToggle?: boolean;
  children?: React.ReactNode;
}

function Dropdown(
  {
    className,
    isOpen,
    location = 'down',
    children,
    toggle = () => null,
    onFocus = () => null,
    onBlur = () => null,
    onKeyDown = () => null,
    ...rest
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [focused, setFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    setFocused(true);
    onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (
      !containerRef.current?.contains(e.relatedTarget) &&
      !menuRef.current?.contains(e.relatedTarget)
    ) {
      if (isOpen) toggle();
      setFocused(false);
      onBlur(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const menuItems = Array.from(
      menuRef.current?.querySelectorAll<HTMLElement>(`[role="menuitem"]`) || [],
    ).filter((el: HTMLElement & { disabled?: boolean }) => !el.disabled);
    const selectedItemIdx = Array.prototype.findIndex.call(
      menuItems,
      (menuItem: HTMLElement) => menuItem.dataset.focused === 'true',
    );
    const selectedItem =
      selectedItemIdx !== -1 ? menuItems[selectedItemIdx] : null;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) {
          toggle();
          break;
        }
        if (menuItems.length === 0) break;
        if (
          selectedItemIdx === -1 ||
          selectedItemIdx === menuItems.length - 1
        ) {
          menuItems[0].scrollIntoView({ block: 'nearest', inline: 'end' });
          menuItems[0].dataset.focused = 'true';
          if (selectedItem) selectedItem.dataset.focused = 'false';
        } else if (
          menuItems?.[
            selectedItemIdx + 1 < menuItems.length
              ? selectedItemIdx + 1
              : menuItems.length - 1
          ]
        ) {
          menuItems[
            selectedItemIdx + 1 < menuItems.length
              ? selectedItemIdx + 1
              : menuItems.length - 1
          ].scrollIntoView({ block: 'nearest', inline: 'end' });
          menuItems[
            selectedItemIdx + 1 < menuItems.length
              ? selectedItemIdx + 1
              : menuItems.length - 1
          ].dataset.focused = 'true';
          if (selectedItem) selectedItem.dataset.focused = 'false';
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (menuItems.length === 0) break;
        if (selectedItemIdx === -1 || selectedItemIdx === 0) {
          menuItems[menuItems.length - 1].scrollIntoView({
            block: 'nearest',
            inline: 'end',
          });
          menuItems[menuItems.length - 1].dataset.focused = 'true';
          if (selectedItem) selectedItem.dataset.focused = 'false';
        } else if (
          menuItems?.[selectedItemIdx - 1 > 0 ? selectedItemIdx - 1 : 0]
        ) {
          menuItems[
            selectedItemIdx - 1 > 0 ? selectedItemIdx - 1 : 0
          ].scrollIntoView({ block: 'nearest', inline: 'end' });
          menuItems[
            selectedItemIdx - 1 > 0 ? selectedItemIdx - 1 : 0
          ].dataset.focused = 'true';
          if (selectedItem) selectedItem.dataset.focused = 'false';
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (isOpen) toggle();
        break;
      case 'Enter':
        e.preventDefault();
        if (menuItems?.[selectedItemIdx]) menuItems[selectedItemIdx].click();
        if (selectedItem) selectedItem.dataset.focused = 'false';
        break;
      default:
    }

    onKeyDown(e);
  };

  const repaintDropdownMenu = useCallback(
    (ele: HTMLElement, mEle: HTMLElement) => {
      const { top, bottom, left, right } = getCoords(ele);
      const minWidth = right - left;
      const { width } = mEle.style;
      if (location === 'up') {
        mEle.style.transform = `translate3d(${left}px, ${
          top - mEle.clientHeight - 2
        }px, 0px)`;
      }
      if (location === 'down') {
        mEle.style.transform = `translate3d(${left}px, ${bottom + 2}px, 0px)`;
      }
      if (location === 'left') {
        mEle.style.transform = `translate3d(${right - mEle.clientWidth}px, ${
          bottom + 2
        }px, 0px)`;
      }

      mEle.style.width = `${width || minWidth}px`;
    },
    [location],
  );

  useResizeObserver(containerRef, ({ target }) => {
    if (menuRef.current && target instanceof HTMLElement) {
      repaintDropdownMenu(target, menuRef.current);
    }
  });

  useEffect(() => {
    if (isOpen) {
      const ele = containerRef.current;
      const mEle = menuRef.current;
      if (ele && mEle) {
        repaintDropdownMenu(ele, mEle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      tabIndex={-1}
      role="button"
      ref={(_ref) => {
        containerRef.current = _ref;
        if (ref) {
          (ref as React.MutableRefObject<HTMLDivElement | null>).current = _ref;
        }
      }}
      className={clsx(`group relative`, className, focused && 'focused')}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          if (item.type === DropdownToggle) {
            return React.cloneElement(item, {
              onClick: toggle,
            });
          }
          if (item.type === DropdownMenu) {
            return React.cloneElement(item, {
              isOpen,
              containerRef,
              menuRef,
            });
          }
        }
        return child;
      })}
    </div>
  );
}

export default React.forwardRef(Dropdown);
