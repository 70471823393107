/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import { ICSRForm, ICSRFormRootElement, ICSRFormType } from 'types/case';
import { companyState } from 'state';

import Title from 'components/case/Title';
import RootElement from 'components/case/RootElement';
import RootRepeatElement from 'components/case/RootRepeatElement';
import CaseFormElement from 'components/case/CaseFormElement';
import SubTitle from 'components/case/SubTitle';
import Space from 'components/case/Space';
import CaseTab, { CaseTabPanel } from 'components/case/CaseTab';
import usePatientMedicalRecord from 'components/case/hooks/usePatientRecord';

function PatientCharacteristics({
  version,
  form,
  primaryReporterCountry,
  sponsorStudyNumber,
  receiverType,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;

  primaryReporterCountry?: string;
  sponsorStudyNumber?: string;
  receiverType?: string;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const { t } = useTranslation();
  const [{ selected: company }] = useRecoilState(companyState);
  const { whoDrugLicense } = company || {};

  const { getRecord, patientMedicalRecords } = usePatientMedicalRecord(
    company?.id,
    sponsorStudyNumber,
  );

  const handleChange = useCallback(
    (number: string, value: ICSRFormType) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const handleSelectRecordNumber = useCallback(
    async (number: string) => {
      const data = await getRecord(number);
      if (data) {
        const [patientCharacteristics, drug] = data;
        setForm(
          produce((draft: any) => {
            draft['D'] = patientCharacteristics;
            draft['G.k'] = drug;
          }),
        );
        handleVersion();
      }
    },
    [getRecord, handleVersion, setForm],
  );

  const defaultTabList = useMemo(
    () => [
      { label: t('ICSR:elementName:D_'), validationCount: 0, memoCount: 0 },
      { label: t('ICSR:elementName:D.7_'), validationCount: 0, memoCount: 0 },
      { label: t('ICSR:elementName:D.8.r_'), validationCount: 0, memoCount: 0 },
      { label: t('ICSR:elementName:D.9_'), validationCount: 0, memoCount: 0 },
      { label: t('ICSR:elementName:D.10_'), validationCount: 0, memoCount: 0 },
    ],
    [t],
  );

  return (
    <>
      <Title number="D" />

      <RootElement
        number="D"
        version={version}
        element={form.D as ICSRFormRootElement}
        disabled={disabled}
        onChange={handleChange}
      >
        <CaseTab defaultTabList={defaultTabList}>
          <CaseTabPanel tabId={0}>
            <CaseFormElement number="D.1" blank />
            <CaseFormElement number="D.1.1.1" />
            <CaseFormElement number="D.1.1.2" />
            <CaseFormElement number="D.1.1.3" />
            <CaseFormElement
              number="D.1.1.4"
              data={patientMedicalRecords}
              onSelect={handleSelectRecordNumber}
            />

            <Space />

            <SubTitle number="D.2" />
            <RootElement number="D.2">
              <CaseFormElement number="D.2.1" blank />
              <CaseFormElement number="D.2.2a" />
              <CaseFormElement number="D.2.2b" />
              <CaseFormElement number="D.2.2.1a" />
              <CaseFormElement number="D.2.2.1b" />
              <CaseFormElement number="D.2.3" />
            </RootElement>

            <Space />

            <CaseFormElement number="D.3" />
            <CaseFormElement number="D.4" />
            <CaseFormElement number="D.5" />
            <CaseFormElement number="D.6" />
          </CaseTabPanel>

          <CaseTabPanel tabId={1}>
            <SubTitle number="D.7.1.r" />
            <RootRepeatElement number="D.7.1.r">
              <CaseFormElement number="D.7.1.r.1a" />
              <CaseFormElement
                number="D.7.1.r.1b"
                valueToVersion="D.7.1.r.1a"
              />
              <CaseFormElement number="D.7.1.r.2" />
              <CaseFormElement number="D.7.1.r.3" />
              <CaseFormElement number="D.7.1.r.4" />
              <CaseFormElement number="D.7.1.r.5" />
              <CaseFormElement number="D.7.1.r.6" />
            </RootRepeatElement>

            <CaseFormElement number="D.7.2" />
            <CaseFormElement number="D.7.3" />
          </CaseTabPanel>

          <CaseTabPanel tabId={2}>
            <SubTitle number="D.8.r" />
            <RootRepeatElement number="D.8.r">
              <CaseFormElement number="D.8.r.1" blank />
              <CaseFormElement number="D.8.r.1.KR.1a" />
              <CaseFormElement
                number="D.8.r.1.KR.1b"
                targetElementNumber="D.8.r.1.KR.1a"
                version={whoDrugLicense?.whoddVersionInUse}
                country={primaryReporterCountry}
                component={
                  receiverType === 'CF' || receiverType === 'FR'
                    ? whoDrugLicense?.whoddVersionInUse
                      ? 'WHODrugMedicalProductSearch'
                      : 'input'
                    : 'MedicalProductSearch'
                }
              />
              <CaseFormElement number="D.8.r.2a" />
              <CaseFormElement number="D.8.r.2b" />
              <CaseFormElement number="D.8.r.3a" />
              <CaseFormElement number="D.8.r.3b" />
              <CaseFormElement number="D.8.r.4" />
              <CaseFormElement number="D.8.r.5" />
              <CaseFormElement number="D.8.r.6a" />
              <CaseFormElement number="D.8.r.6b" valueToVersion="D.8.r.6a" />
              <CaseFormElement number="D.8.r.7a" />
              <CaseFormElement number="D.8.r.7b" valueToVersion="D.8.r.7a" />
            </RootRepeatElement>
          </CaseTabPanel>

          <CaseTabPanel tabId={3}>
            <CaseFormElement number="D.9.1" />
            <CaseFormElement number="D.9.3" />

            <Space />

            <SubTitle number="D.9.2.r" />
            <RootRepeatElement number="D.9.2.r">
              <CaseFormElement number="D.9.2.r.1a" />
              <CaseFormElement
                number="D.9.2.r.1b"
                valueToVersion="D.9.2.r.1a"
              />
              <CaseFormElement number="D.9.2.r.2" />
            </RootRepeatElement>

            <SubTitle number="D.9.4.r" />
            <RootRepeatElement number="D.9.4.r">
              <CaseFormElement number="D.9.4.r.1a" />
              <CaseFormElement
                number="D.9.4.r.1b"
                valueToVersion="D.9.4.r.1a"
              />
              <CaseFormElement number="D.9.4.r.2" />
            </RootRepeatElement>
          </CaseTabPanel>

          <CaseTabPanel tabId={4}>
            <RootElement number="D.10">
              <CaseFormElement number="D.10.1" />
              <CaseFormElement number="D.10.2.1" />
              <CaseFormElement number="D.10.2.2a" />
              <CaseFormElement number="D.10.2.2b" />
              <CaseFormElement number="D.10.3" />
              <CaseFormElement number="D.10.4" />
              <CaseFormElement number="D.10.5" />
              <CaseFormElement number="D.10.6" />
            </RootElement>

            <RootElement number="D.10.7">
              <SubTitle number="D.10.7.1.r" />
              <RootRepeatElement number="D.10.7.1.r">
                <CaseFormElement number="D.10.7.1.r.1a" />
                <CaseFormElement
                  number="D.10.7.1.r.1b"
                  valueToVersion="D.10.7.1.r.1a"
                />
                <CaseFormElement number="D.10.7.1.r.2" />
                <CaseFormElement number="D.10.7.1.r.3" />
                <CaseFormElement number="D.10.7.1.r.4" />
                <CaseFormElement number="D.10.7.1.r.5" />
              </RootRepeatElement>

              <CaseFormElement number="D.10.7.2" full />
            </RootElement>

            <SubTitle number="D.10.8.r" />
            <RootRepeatElement number="D.10.8.r">
              <CaseFormElement number="D.10.8.r.1" blank />
              <CaseFormElement number="D.10.8.r.1.KR.1a" />
              <CaseFormElement
                number="D.10.8.r.1.KR.1b"
                targetElementNumber="D.10.8.r.1.KR.1a"
                version={whoDrugLicense?.whoddVersionInUse}
                country={primaryReporterCountry}
                component={
                  receiverType === 'CF' || receiverType === 'FR'
                    ? whoDrugLicense?.whoddVersionInUse
                      ? 'WHODrugMedicalProductSearch'
                      : 'input'
                    : 'MedicalProductSearch'
                }
              />
              <CaseFormElement number="D.10.8.r.2a" />
              <CaseFormElement number="D.10.8.r.2b" />
              <CaseFormElement number="D.10.8.r.3a" />
              <CaseFormElement number="D.10.8.r.3b" />
              <CaseFormElement number="D.10.8.r.4" />
              <CaseFormElement number="D.10.8.r.5" />
              <CaseFormElement number="D.10.8.r.6a" />
              <CaseFormElement
                number="D.10.8.r.6b"
                valueToVersion="D.10.8.r.6a"
              />
              <CaseFormElement number="D.10.8.r.7a" />
              <CaseFormElement
                number="D.10.8.r.7b"
                valueToVersion="D.10.8.r.7a"
              />
            </RootRepeatElement>
          </CaseTabPanel>
        </CaseTab>
      </RootElement>
    </>
  );
}

export default PatientCharacteristics;
