export const UploadTypes = {
  ICH_E2B_R2_KR: 'E2B_R2_KR',
  ICH_E2B_R3: 'E2B_R3',
  ICH_E2B_R3_KR: 'E2B_R3_KR',
  ICH_E2B_R3_EU: 'E2B_R3_EU',
  EXCEL: 'EXCEL',
  CUBE: 'CUBE',
} as const;
export const UploadFilenameExtension = {
  ICH_E2B_R2_KR: 'application/xml',
  ICH_E2B_R3: 'application/xml',
  ICH_E2B_R3_KR: 'application/xml',
  ICH_E2B_R3_EU: 'application/xml',
  EXCEL: '.xlsx',
  CUBE: '.xlsx',
} as const;
export const UploadStatus = {
  READY: 'READY',
  SUCCESS: 'SUCCESS',
  PROCESSING: 'PROCESSING',
  FAIL: 'FAIL',
} as const;
export const UploadStatusList = Object.entries(UploadStatus).map(
  ([key, value]: [string, string]) => ({
    value: key,
    label: value,
  }),
);
export interface Upload {
  id?: number;
  type?: keyof typeof UploadTypes;
  importSettingId?: number;
  receiverType?: string;
  studyId?: number;
  productIds: number[];
  creationDate?: string;
  recentDate?: string;
  files?: FileList;
}
export interface UploadFile {
  [key: string]: any;
  id: number;
  fileName?: string;
  fileType?: string;
  UploadTime?: string;
  Status?: string;
  User?: string;
  dataFileId?: string;
  importFileId?: string;
}
