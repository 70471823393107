/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { IoCalendarClearOutline, IoCloseCircle } from 'react-icons/io5';

import { SizeType } from 'utils/commonType';

import DatePickerDialog from './DatePickerDialog';
import { DateType } from './datePickerUtils';

interface DatePickerProps {
  className?: string;
  size?: SizeType;
  value?: DateType;
  format?: string;
  allowedFormat?: string[];
  placeholder?: string;
  menuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
  error?: boolean;
  disabled?: boolean;
  onChange?: (d?: Moment) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

export const controlStyles = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};

function DatePicker({
  className,
  size = 'md',
  value,
  format = 'YYYY-MM-DD',
  allowedFormat,
  placeholder = 'Select date',
  menuIsOpen = false,
  closeMenuOnSelect = true,
  error,
  disabled,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onKeyDown = () => null,
}: DatePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [focusedDate, setFocusedDate] = useState<Moment>(moment());

  const debounced = useRef(
    debounce((val: string) => {
      const date = moment(val, allowedFormat || format, true);
      if (date.isValid()) {
        setSelectedDate(moment(date));
        setFocusedDate(moment(date));
      }
    }, 500),
  );

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const focusDate = (direction: 'prev' | 'next' | 'up' | 'down') => {
    if (!focusedDate) {
      setFocusedDate(moment());
      return;
    }
    switch (direction) {
      case 'prev':
        setFocusedDate(moment(focusedDate.subtract(1, 'days')));
        break;
      case 'next':
        setFocusedDate(moment(focusedDate.add(1, 'days')));
        break;
      case 'up':
        setFocusedDate(moment(focusedDate.subtract(1, 'weeks')));
        break;
      case 'down':
        setFocusedDate(moment(focusedDate.add(1, 'weeks')));
        break;
      default:
        break;
    }
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    setFocusedDate(moment(selectedDate) || moment());
  };

  const handleClearMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedDate(undefined);
    onChange(undefined);
  };

  const handleControlMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      openMenu();
    }
  };

  const handleMenuMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    focusInput();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debounced.current(e.target.value);
    if (!isMenuOpen) {
      openMenu();
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    setIsFocused(true);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    const date = moment(e.target.value, allowedFormat || format, true);
    if (date.isValid()) {
      setSelectedDate(date);
      onChange(date);
    }
    setIsFocused(false);
    setIsMenuOpen(false);
    setInputValue('');
  };

  const handleSelect = (date: Moment) => {
    const formatedDate = moment(date.format(format), allowedFormat || format);
    onChange(formatedDate);
    setSelectedDate(formatedDate);
    setInputValue('');
    focusInput();
    if (closeMenuOnSelect) {
      setIsMenuOpen(false);
    }
  };

  const handleDateClick = (e: React.MouseEvent) => {
    const { target } = e;
    if (!(target instanceof HTMLButtonElement)) return;
    const date = moment(Number(target.dataset.date));
    handleSelect(date);
  };

  const handleControlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e);
    switch (e.key) {
      case 'ArrowLeft':
        focusDate('prev');
        break;
      case 'ArrowRight':
        focusDate('next');
        break;
      case 'Tab':
        if (isMenuOpen) {
          setIsMenuOpen(false);
          break;
        }
        return;
      case 'Enter':
        if (e.keyCode === 229) {
          break;
        }
        handleSelect(focusedDate);
        break;
      case 'Escape':
        setIsMenuOpen(false);
        setInputValue('');
        break;
      case 'ArrowUp':
        if (menuIsOpen || isMenuOpen) {
          focusDate('up');
        } else {
          openMenu();
        }
        break;
      case 'ArrowDown':
        if (menuIsOpen || isMenuOpen) {
          focusDate('down');
        } else {
          openMenu();
        }
        break;
      default:
        return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (!value) return;

    const date = moment(value, allowedFormat || format, true);
    setFocusedDate(date.isValid() ? moment(date) : moment());
    setSelectedDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={twMerge(
        clsx('group relative h-fit w-full', className, disabled && 'disabled'),
      )}
    >
      <div
        className={twMerge(
          clsx(
            controlStyles[size],
            'relative flex w-full flex-wrap items-center justify-between pr-0 transition-all',
            isFocused && 'control-focus',
            error && 'control-error',
          ),
        )}
        onMouseDown={handleControlMouseDown}
        onKeyDown={handleControlKeyDown}
      >
        <div className="grid flex-1 overflow-hidden">
          {!inputValue && (
            <div className="csafer-selet-value col-start-1 col-end-3 row-start-1 row-end-2 truncate">
              {selectedDate ? (
                (selectedDate.creationData().input as string)
              ) : (
                <span className="text-slate-300">{placeholder}</span>
              )}
            </div>
          )}
          <div
            className={clsx(
              'col-start-1 col-end-3 row-start-1 row-end-2 inline-grid grid-cols-[0px_min-content]',
              'after:invisible after:col-start-2 after:col-end-auto after:row-start-1	after:row-end-auto after:whitespace-pre after:content-[attr(data-value)]',
            )}
            data-value={inputValue}
          >
            <input
              ref={inputRef}
              className="col-start-2 col-end-auto row-start-1 row-end-auto w-full min-w-[2px] bg-inherit text-inherit opacity-100 "
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={disabled}
              // readOnly={readOnly}
            />
          </div>
        </div>
        <div className="casfer-select-action flex flex-shrink-0 items-center px-2">
          <button
            tabIndex={-1}
            className={clsx(
              'casfer-select-indicator',
              selectedDate && 'group-hover:hidden',
            )}
          >
            <IoCalendarClearOutline
              className={clsx(
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
          <button
            tabIndex={-1}
            className={clsx(
              'casfer-select-reset hidden',
              selectedDate && 'group-hover:block',
            )}
            onMouseDown={handleClearMouseDown}
          >
            <IoCloseCircle
              className={clsx(
                'hover:text-slate-500',
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
        </div>
      </div>
      {(menuIsOpen || isMenuOpen) && (
        <div
          ref={menuRef}
          className="csafer-select-list absolute z-dropdown pt-1"
          onMouseDown={handleMenuMouseDown}
        >
          <div className="min-h-fit min-w-fit overflow-auto rounded border border-slate-300 bg-white py-1 pt-1 shadow">
            <DatePickerDialog
              selectedDate={selectedDate}
              focusedDate={focusedDate}
              handleDateClick={handleDateClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(DatePicker);
