import React from 'react';
import { useRecoilState } from 'recoil';

import { codeSetsState } from 'state';

import { Reporter } from 'types/reporter';

import Input from 'components/common/Input';
import Select from 'components/common/Select/Select';
import Button from 'components/common/Button';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';

interface props {
  submitLabel: string;
  form: Reporter;
  errors: any;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}

function ReporterForm({
  submitLabel,
  form,
  errors,
  onChange,
  onSubmit,
}: props) {
  const {
    organizationName,
    department,
    title,
    givenName,
    middleName,
    familyName,
    countryCode,
    postCode,
    province,
    city,
    street,
    telephone,
    qualification,
  } = form;

  const [codeSets] = useRecoilState(codeSetsState);

  return (
    <form className="max-w-4xl" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div>
          <h2 className="mb-2">{`Reporter's Name`}</h2>
          <div className="space-y-1">
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Title (C.2.r.1.1)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={title}
                  onChange={(e) => onChange('title', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Given Name (C.2.r.1.2)`}</div>
              <div className="flex w-1/2 flex-wrap">
                <Input
                  className="w-full"
                  value={givenName}
                  onChange={(e) => onChange('givenName', e.target.value)}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.givenName?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Middle Name (C.2.r.1.3)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={middleName}
                  onChange={(e) => onChange('middleName', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Family Name (C.2.r.1.4)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={familyName}
                  onChange={(e) => onChange('familyName', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2">{`Reporter's Address and Telephone`}</h2>
          <div className="space-y-1">
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Organization (C.2.r.2.1)`}</div>
              <div className="flex w-1/2 flex-wrap">
                <Input
                  className="w-full"
                  value={organizationName}
                  onChange={(e) => onChange('organizationName', e.target.value)}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.organizationName?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Department (C.2.r.2.2)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={department}
                  onChange={(e) => onChange('department', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Street (C.2.r.2.3)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={street}
                  onChange={(e) => onChange('street', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's City (C.2.r.2.4)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={city}
                  onChange={(e) => onChange('city', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's State or Province (C.2.r.2.5)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={province}
                  onChange={(e) => onChange('province', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Postcode (C.2.r.2.6)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={postCode}
                  onChange={(e) => onChange('postCode', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Telephone (C.2.r.2.7)`}</div>
              <div className="w-1/2">
                <Input
                  className="w-full"
                  value={telephone}
                  onChange={(e) => onChange('telephone', e.target.value)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">{`Reporter's Country Code (C.2.r.3)`}</div>
              <div className="w-1/2">
                <Select
                  className="w-full"
                  value={countryCode}
                  data={codeSets[codeSets.oidMap.countries]?.codes || []}
                  valueField="key"
                  textField="value"
                  onChange={(val) => onChange('countryCode', val.key)}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">Qualification (C.2.r.4)</div>
              <div className="flex w-1/2 flex-wrap">
                <RadioGroup
                  className="space-y-2"
                  name="sender-type"
                  value={qualification}
                  onChange={(val) => onChange('qualification', val)}
                  enableClickReset
                >
                  <Radio value="1" label="1:Physician" />
                  <Radio value="2" label="2:Pharmacist" />
                  <Radio value="3" label="3:Other Health Professional " />
                  <Radio value="4" label="4:Lawyer" />
                  <Radio
                    value="5"
                    label="5:Consumer or other Non-Health Professional"
                  />
                </RadioGroup>
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.qualification?.message}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default ReporterForm;
