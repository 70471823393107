import React, { useState } from 'react';

import { ICSRFormElement } from 'types/case';
import { MedicalSubstanceCode } from 'types/codes';
import SearchInput from 'components/common/SearchInput';
import clsx from 'clsx';
import MedicalSubstance from 'components/popup/MedicalSubstance';

function MedicalSubstanceComponent({
  className,
  number,
  value,
  disabled,
  onChange = () => null,
}: {
  className?: string;
  number: string;
  value?: string | null;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(number, {
      value: e.target.value,
      nullFlavor: undefined,
    });
  };

  return (
    <div className={clsx('space-y-1', className)}>
      <SearchInput
        className="w-full"
        value={value}
        onClick={toggle}
        onChange={handleChange}
        disabled={disabled}
        readOnly
      />
      <MedicalSubstance
        isOpen={open}
        toggle={toggle}
        callback={({ code }: MedicalSubstanceCode) => {
          if (disabled) return;
          onChange(number, {
            value: String(code),
            nullFlavor: undefined,
          });
        }}
      />
    </div>
  );
}

export default MedicalSubstanceComponent;
