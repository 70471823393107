import { TFunction } from 'i18next';

const narrative = (codeList: any, t: TFunction) => ({
  'H.1': {
    elementNumber: 'H.1',
    label: t('ICSR:elementName:H.1'),
    component: 'textarea',
    maxLength: '100000',
    required: true,
  },
  'H.2': {
    elementNumber: 'H.2',
    label: t('ICSR:elementName:H.2'),
    component: 'textarea',
    maxLength: '20000',
  },
  'H.3.r': {
    elementNumber: 'H.3.r',
    list: [
      {
        elementNumber: 'H.3.r.1a',
        label: t('ICSR:elementName:H.3.r.1a'),
        component: 'select',
        data: codeList.meddra_version,
        valueField: 'version',
        textField: 'version',
      },
      {
        elementNumber: 'H.3.r.1b',
        label: t('ICSR:elementName:H.3.r.1b'),
        component: 'MedDRASearch',
      },
    ],
  },
  'H.3.r.1a': {
    elementNumber: 'H.3.r.1a',
    label: t('ICSR:elementName:H.3.r.1a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'H.3.r.1b': {
    elementNumber: 'H.3.r.1b',
    label: t('ICSR:elementName:H.3.r.1b'),
    component: 'MedDRASearch',
  },
  'H.4': {
    elementNumber: 'H.4',
    label: t('ICSR:elementName:H.4'),
    component: 'textarea',
    maxLength: '20000',
  },
  'H.5.r': {
    elementNumber: 'H.5.r',
    list: [
      {
        elementNumber: 'H.5.r.1a',
        label: t('ICSR:elementName:H.5.r.1a'),
        component: 'input',
      },
      {
        elementNumber: 'H.5.r.1b',
        label: t('ICSR:elementName:H.5.r.1b'),
        component: 'select',
        data: codeList['1.0.639.2']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
  },
  'H.5.r.1a': {
    elementNumber: 'H.5.r.1a',
    label: t('ICSR:elementName:H.5.r.1a'),
    component: 'textarea',
    maxLength: '100000',
  },
  'H.5.r.1b': {
    elementNumber: 'H.5.r.1b',
    label: t('ICSR:elementName:H.5.r.1b'),
    component: 'select',
    data: codeList['1.0.639.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
});

export default narrative;
