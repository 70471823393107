import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { TbEdit } from 'react-icons/tb';
import clsx from 'clsx';

import { getDatasetSummaries } from 'api/dataset';

import mapping, { MappingStatus, RequiredElement } from 'utils/mappingRules';
import {
  ICSRElementCondition,
  ICSRElementConditionMap,
  IcsrStructure,
} from 'utils/E2BR3';
import { DatasetRule } from 'types/dataset';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';
import { Tab, Tabs, TabPanel } from 'components/common/Tab';
import FilterBox from 'components/common/Filter/FilterBox';

import MappingRulePopup from './MappingRulePopup';

const tabList = Object.entries(IcsrStructure).map(([key]) => ({
  label: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
  name: key,
}));
const elementConditionList = Object.entries(ICSRElementCondition).map(
  ([key, value]) => ({ key, value }),
);
const mappingStatusList = Object.entries(MappingStatus).map(([key, value]) => ({
  value: JSON.parse(key),
  label: value,
}));
const breadcrumbs = [{ label: 'List', to: '..' }];

const filterList = [
  {
    type: 'search',
    name: 'search',
    label: 'Search',
    width: '15rem',
  },
  {
    type: 'select',
    name: 'validation',
    label: 'Validation',
    data: elementConditionList,
    valueField: 'value',
    textField: 'value',
    width: '14rem',
  },
  {
    type: 'select',
    name: 'status',
    label: 'Mapping Status',
    data: mappingStatusList,
    valueField: 'value',
    textField: 'label',
  },
];

function EditEDCSetup() {
  const { datasetId } = useParams();
  const [tabValue, setTabValue] = useState<number>(0);
  const [filter, setFilter] = useState<{
    [key: string]: any;
    search?: string;
    validation?: Array<
      typeof ICSRElementCondition[keyof typeof ICSRElementCondition]
    >;
    status?: Array<boolean>;
  }>({});
  const [open, setOpen] = useState(false);
  const [mappingData, setMappingData] = useState<{
    importSettingId?: number;
    elementNumber?: string;
    elementLabel?: string;
    repeatable?: boolean;
  }>({});

  const { data = [], refetch } = useQuery(
    ['getDatasetSummaries'],
    () => getDatasetSummaries(datasetId).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const classifiedData = useMemo(
    () =>
      data
        ? mapping(data)
        : tabList.reduce((acc: { [key: string]: DatasetRule[] }, { name }) => {
            acc[name] = [];
            return acc;
          }, {}),
    [data],
  );

  const filterdData = useMemo(() => {
    const { name } = tabList[tabValue];
    return classifiedData[name].filter(
      ({ elementNumber, elementLabel, matched }) => {
        const { search, validation, status } = filter;
        if (search) {
          const regexp = new RegExp(search, 'i');
          if (!regexp.test(elementNumber) && !regexp.test(elementLabel)) {
            return false;
          }
        }
        if (
          validation?.length &&
          validation.findIndex(
            (item) => item === ICSRElementConditionMap[elementNumber],
          ) === -1
        ) {
          return false;
        }
        if (
          status?.length &&
          status.findIndex((item) => item === matched) === -1
        ) {
          return false;
        }

        return true;
      },
    );
  }, [tabValue, classifiedData, filter]);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <MainTemplate>
      <Header title="Edit Dataset" breadcrumbs={breadcrumbs} />
      <Content className="flex flex-col overflow-hidden px-0">
        <div className="flex h-full flex-col">
          <div className="dropdown-container z-0 flex h-full w-full ">
            <Tabs
              className="flex w-48 flex-col space-x-0 overflow-y-auto border-r py-2 px-4 [&>button]:py-2"
              onChange={(value) => {
                setTabValue(value);
              }}
            >
              {tabList.map(({ label }, index) => (
                <Tab
                  key={label}
                  className={clsx(
                    tabValue === index && 'font-semibold text-brand-600',
                  )}
                  index={index}
                >
                  {label}
                </Tab>
              ))}
            </Tabs>
            <div className="flex w-full flex-col px-2">
              <div className="py-2">
                <FilterBox
                  filter={filter}
                  filterList={filterList}
                  setFilter={setFilter}
                />
              </div>
              <div className="-mt-2 flex-1 overflow-y-scroll">
                {tabList.map(({ name }, index) => (
                  <TabPanel key={name} value={tabValue} index={index}>
                    <table className="relative w-full border-b border-slate-200">
                      <thead className="drop sticky top-0 bg-white drop-shadow">
                        <tr className="[&>th]:py-1 [&>th]:px-2">
                          <th className="w-32 text-left">Element No.</th>
                          <th className="text-left">Element Item Name</th>
                          <th className="w-32 text-center">Validation</th>
                          <th className="w-28 text-center">
                            Mapping
                            <br />
                            Status
                          </th>
                          <th className="w-24 text-center">
                            Mapping
                            <br />
                            Rule
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterdData.map(
                          ({
                            importSettingId,
                            elementNumber,
                            elementLabel,
                            matched,
                            repeatable,
                          }) => (
                            <tr
                              key={elementNumber}
                              className="[&>td]:py-1 [&>td]:px-2 [&:nth-child(2n+1)]:bg-slate-100"
                            >
                              <td>{elementNumber}</td>
                              <td>
                                {RequiredElement[elementNumber] ? (
                                  <span className="font-semibold text-brand-600">
                                    *{elementLabel}
                                  </span>
                                ) : (
                                  elementLabel
                                )}
                              </td>
                              <td className="text-center">
                                {ICSRElementConditionMap[elementNumber]}
                              </td>
                              <td
                                className={clsx(
                                  'text-center',
                                  matched ? 'text-green-600' : 'text-red-600',
                                )}
                              >
                                {matched
                                  ? MappingStatus.true
                                  : MappingStatus.false}
                              </td>
                              <td className="mt-1 text-center hover:opacity-60">
                                <button
                                  onClick={() => {
                                    setMappingData({
                                      importSettingId,
                                      elementNumber,
                                      elementLabel,
                                      repeatable,
                                    });
                                    setOpen(true);
                                  }}
                                >
                                  <TbEdit />
                                </button>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>
                  </TabPanel>
                ))}
              </div>
            </div>
          </div>
        </div>
        <MappingRulePopup
          isOpen={open}
          toggle={toggle}
          callback={refetch}
          {...mappingData}
        />
      </Content>
    </MainTemplate>
  );
}

export default EditEDCSetup;
