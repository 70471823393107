import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getRegistrationDefaultValues,
  getStudy,
  getStudyDefaultValues,
  putStudy,
} from 'api/studies';
import { getProducts } from 'api/products';

import { Study } from 'types/study';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import StudyForm from './StudyForm';

function EditStudy() {
  const { t } = useTranslation();
  const { studyId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getStudyDefaultValues(company?.id),
  });

  const { refetch } = useQuery(
    ['getStudy'],
    () => getStudy(studyId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Study) {
        setValue([
          { id: data.id },
          { companyId: data.companyId },
          { productIdList: data.productIdList },
          { studyName: data.studyName },
          { sponsorStudyNumber: data.sponsorStudyNumber },
          { studyType: data.studyType },
          { registrations: data.registrations },
          { version: data.version },
        ]);
      },
    },
  );

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(putStudy, {
    onSuccess() {
      refetch();
    },
  });

  const changeStudy = (key: string, value: any) => {
    setValue(key, value);
  };

  const addRegistration = () => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) => {
        draft.push(getRegistrationDefaultValues());
      }),
    );
  };

  const deleteRegistration = (index: number) => {
    if (form.registrations[index].id) {
      setValue(
        'registrations',
        produce(form.registrations, (draft) => {
          const target = draft.find((_officer, idx) => index === idx);
          if (target) target.deleted = true;
        }),
      );
    } else {
      setValue(
        'registrations',
        produce(form.registrations, (draft) =>
          draft.filter((_officer, idx) => index !== idx),
        ),
      );
    }
  };

  const restoreRegistration = (index: number) => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) => {
        const target = draft.find((_officer, idx) => index === idx);
        if (target) target.deleted = false;
      }),
    );
  };

  const changeRegistration = (key: string, value: any, index: number) => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' });
    register({ name: 'productIdList' }, { required: t('error:required') });
    register({ name: 'studyName' }, { required: t('error:required') });
    register({ name: 'sponsorStudyNumber' }, { required: t('error:required') });
    register({ name: 'studyType' });
    register({ name: 'registrations' });
    register({ name: 'version' });
  }, [register, t]);

  return (
    <FormWrapper title="Edit Study" breadcrumbs={[{ label: 'List', to: '..' }]}>
      <StudyForm
        submitLabel="Save"
        form={form}
        errors={errors}
        products={products}
        onSubmit={onSubmit}
        changeStudy={changeStudy}
        addRegistration={addRegistration}
        deleteRegistration={deleteRegistration}
        restoreRegistration={restoreRegistration}
        changeRegistration={changeRegistration}
      />
    </FormWrapper>
  );
}

export default EditStudy;
