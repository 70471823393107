import React from 'react';
import { Outlet } from 'react-router-dom';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

const tabList = [
  { label: 'Request', to: 'request' },
  { label: 'Rejected', to: 'rejected' },
  { label: 'Approved', to: 'approved' },
];

function Approval() {
  return (
    <MainTemplate>
      <Header title="Approval" tabList={tabList} />
      <Content className="flex flex-col overflow-hidden pt-1">
        <Outlet />
      </Content>
    </MainTemplate>
  );
}

export default Approval;
