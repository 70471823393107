import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import router from 'Router';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  headers: { 'Cache-Control': 'no-cache' },
});

// API server down interceptor
instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (!error.response || error.response.status === 500) {
      router.navigate('/server-down');
    }
    return Promise.reject(error);
  },
);

export default {
  getInstance: () => instance,
  get: (url: string, config?: AxiosRequestConfig) => instance.get(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) =>
    instance.post(url, data, config),
  patch: (url: string, data?: any, config?: AxiosRequestConfig) =>
    instance.patch(url, data, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    instance.put(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) =>
    instance.delete(url, config),
};

const dictionaryInstance = axios.create({
  baseURL: process.env.REACT_APP_MEDDRA_URL,
  withCredentials: false,
  headers: { 'Cache-Control': 'no-cache' },
});

export const dictionaryClient = {
  getInstance: () => dictionaryInstance,
  get: (url: string, config?: AxiosRequestConfig) =>
    dictionaryInstance.get(url, config),
  post: (url: string, data?: any, config?: AxiosRequestConfig) =>
    dictionaryInstance.post(url, data, config),
  patch: (url: string, data?: any, config?: AxiosRequestConfig) =>
    dictionaryInstance.patch(url, data, config),
  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    dictionaryInstance.put(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) =>
    dictionaryInstance.delete(url, config),
};

const apiGatewayInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  withCredentials: false,
  headers: { 'Cache-Control': 'no-cache' },
});

export const apiGatewayClient = {
  getInstance: () => apiGatewayInstance,
  get: (url: string, config: AxiosRequestConfig | undefined = undefined) =>
    apiGatewayInstance.get(url, config),
  post: (
    url: string,
    data: any = undefined,
    config: AxiosRequestConfig | undefined = undefined,
  ) => apiGatewayInstance.post(url, data, config),
  patch: (
    url: string,
    data: any = undefined,
    config: AxiosRequestConfig | undefined = undefined,
  ) => apiGatewayInstance.patch(url, data, config),
  put: (
    url: string,
    data: any = undefined,
    config: AxiosRequestConfig | undefined = undefined,
  ) => apiGatewayInstance.put(url, data, config),
  delete: (url: string, config: AxiosRequestConfig | undefined = undefined) =>
    apiGatewayInstance.delete(url, config),
};
