import React from 'react';
import { TailSpin } from 'react-loader-spinner';

function Loader() {
  return (
    <div className="flex h-[var(--mainHeight)] w-screen flex-col items-center justify-center">
      <h1 className="-mt-40 mb-16 text-3xl italic text-brand-500">
        Loading...
      </h1>
      <TailSpin
        height="120"
        width="120"
        ariaLabel="tail-spin-loading"
        color="#6C63FF"
        radius="1"
        visible
      />
    </div>
  );
}

export default Loader;
