import React from 'react';

import Search from './SearchFilter';
import SearchList from './SearchListFilter';
import Select from './SelectFilter';
import Date from './DateFilter';

export interface FilterType {
  name: string;
  label?: string;
  type?: string;
  target?: string;
  flex?: boolean;
  data?: any[];
  list?: { name: string; label: string }[];
  valueField?: string;
  textField?: string;
  width?: string;
}

function Filter({
  item,
  value,
  onChange,
  hideFilter,
}: {
  item: FilterType;
  value: any;
  onChange: (k: string, v?: any) => void;
  hideFilter?: () => void;
}) {
  const {
    type,
    name,
    target,
    list,
    label,
    data,
    valueField,
    textField,
    width,
  } = item;

  if (type === 'search') {
    return (
      <Search
        size="sm"
        width={width}
        value={value}
        label={label}
        onChange={(val) => onChange(name, val)}
      />
    );
  }
  if (type === 'searchList') {
    return (
      <SearchList
        size="sm"
        width={width}
        value={value}
        target={target}
        list={list}
        onChange={onChange}
      />
    );
  }
  if (type === 'date') {
    return (
      <Date
        value={value || []}
        label={label}
        onChange={(dates) => {
          onChange(name, dates);
        }}
        hideFilter={hideFilter}
      />
    );
  }
  return (
    <Select
      width={width}
      label={label}
      value={value}
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={(v) => onChange(name, v)}
      hideFilter={hideFilter}
    />
  );
}

export default React.memo(Filter);
