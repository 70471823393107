import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';

import { getDatasetRules, putDatasetRules } from 'api/dataset';

import { DatasetRuleType } from 'types/dataset';
import modal from 'utils/modal';

import Popup from 'components/common/Popup';
import Button from 'components/common/Button';

import ElementRuleTable from './ElementRuleTable';
import EDCItemTable from './EDCItemTable';
import ElementListTable from './ElementListTable';
import CodeMappingTable from './CodeMappingTable';

function MappingRulePopup({
  isOpen,
  importSettingId,
  elementNumber,
  elementLabel,
  repeatable,
  toggle,
  callback = () => null,
}: {
  isOpen: boolean;
  importSettingId?: number;
  elementNumber?: string;
  elementLabel?: string;
  repeatable?: boolean;
  toggle: () => void;
  callback?: () => void;
}) {
  const { t } = useTranslation();
  const [rules, setRules] = useState<DatasetRuleType[]>([]);
  const [selectedRuleIdx, setSelectedRuleIdx] = useState(-1);

  useQuery(
    ['getRules', elementNumber],
    () =>
      getDatasetRules({ importSettingId, elementNumber }).then(
        ({ data }) => data,
      ),
    {
      enabled: !!importSettingId,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setSelectedRuleIdx(data.length ? 0 : -1);
        setRules(data);
      },
    },
  );

  const { mutate } = useMutation(putDatasetRules, {
    onSuccess() {
      callback();
    },
  });

  const handleSubmit = async () => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate({ importSettingId, rules });
    }
  };

  useEffect(() => {
    setRules([]);
    setSelectedRuleIdx(-1);
  }, [elementNumber]);

  return (
    <Popup isOpen={isOpen} className="w-[70rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        <p>Mapping Rule</p>
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-2 space-y-4 py-2 text-base [&_th]:font-medium">
        <div>
          <p>Element List</p>
          <ElementListTable
            rules={rules}
            selectedRuleIdx={selectedRuleIdx}
            repeatable={repeatable}
            elementLabel={elementLabel}
            elementNumber={elementNumber}
            setRules={setRules}
            setSelectedRuleIdx={setSelectedRuleIdx}
          />
        </div>
        <div>
          <p>E2B R3 Element</p>
          <ElementRuleTable
            rule={rules[selectedRuleIdx]}
            sequence={selectedRuleIdx}
            setRule={(rule: DatasetRuleType) =>
              setRules(
                produce((draft) => {
                  draft[selectedRuleIdx] = rule;
                }),
              )
            }
          />
        </div>
        <div>
          <p>EDC Item</p>
          <EDCItemTable
            importSettingId={importSettingId}
            rule={rules[selectedRuleIdx]}
            setRule={(rule: DatasetRuleType) =>
              setRules(
                produce((draft) => {
                  draft[selectedRuleIdx] = rule;
                }),
              )
            }
          />
        </div>
        <div>
          <p>Code Mapping</p>
          <CodeMappingTable
            rule={rules[selectedRuleIdx]}
            setRule={(rule: DatasetRuleType) =>
              setRules(
                produce((draft) => {
                  draft[selectedRuleIdx] = rule;
                }),
              )
            }
          />
        </div>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="blue">
          Save
        </Button>
      </div>
    </Popup>
  );
}

export default MappingRulePopup;
