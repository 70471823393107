import React, { ThHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

function LTH({
  className,
  style,
  children,
}: ThHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      className={twMerge(clsx('h-11 px-2 py-1 font-medium', className))}
      style={style}
    >
      {children}
    </th>
  );
}

export default LTH;
