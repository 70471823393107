import React, { useState } from 'react';
import { MdGTranslate, MdSwapVert } from 'react-icons/md';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { AiOutlineAudit } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { AxiosError } from 'axios';

import { translate } from 'api/translate';
import { ICSRFormElement } from 'types/case';
import modal from 'utils/modal';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/common/Dropdown';
import { requestEnd, requestStart } from 'components/Request';

import AuditTrailPopup from './popup/AuditTrailPopup';

const iconStyles = 'text-lg text-slate-500 hover:text-brand-500';
const dropItemStyles =
  'cursor-pointer px-2 py-1 hover:bg-slate-100 dark:hover:bg-slate-900';

function Popover({
  number,
  elementId,
  value,
  translatedValue,
  disabled,
  onChange,
}: {
  number: string;
  elementId?: number;
  value?: string;
  translatedValue?: string;
  disabled?: boolean;
  onChange: (n: string, v: ICSRFormElement) => void;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openAuditTrail, setOpenAuditTrail] = useState(false);

  const { mutate } = useMutation(translate, {
    onMutate() {
      requestStart();
    },
    onSuccess({ data }) {
      const { translatedText } = data;
      onChange(number, { translatedValue: translatedText });
    },
    onSettled(data, error) {
      requestEnd();
      if (error instanceof AxiosError) {
        modal.alert(
          t('modal:alert:error'),
          error?.response?.data?.message || error?.message,
        );
      }
    },
  });

  const handleTranslate = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      const { language: targetLanguageCode } = e.target.dataset;
      mutate({ targetLanguageCode, text: value });
    }
  };

  const swap = () => {
    onChange(number, { value: translatedValue, translatedValue: value });
  };

  const remove = async () => {
    const check = await modal.confirm(
      t('modal:confirm:title'),
      t('modal:confirm:delete'),
    );
    if (check) onChange(number, { translatedValue: undefined });
  };

  return (
    <div className="flex w-[104px] space-x-1 p-1">
      <Dropdown
        location="left"
        isOpen={open}
        toggle={() => setOpen((prev) => !prev)}
      >
        <DropdownToggle>
          <MdGTranslate className={iconStyles} />
        </DropdownToggle>
        <DropdownMenu
          className={clsx(
            '!w-fit rounded border border-slate-200 bg-white py-0.5 text-slate-600 shadow',
            'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
          )}
        >
          <DropdownItem className={dropItemStyles}>
            <button
              data-language="en"
              onClick={handleTranslate}
              disabled={disabled}
            >
              en: English
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button
              data-language="ko"
              onClick={handleTranslate}
              disabled={disabled}
            >
              ko: Korean
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button
              data-language="zh"
              onClick={handleTranslate}
              disabled={disabled}
            >
              zh: Chinease
            </button>
          </DropdownItem>
          <DropdownItem className={dropItemStyles}>
            <button
              data-language="ja"
              onClick={handleTranslate}
              disabled={disabled}
            >
              ja: japanese
            </button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <button onClick={swap}>
        <MdSwapVert className={iconStyles} />
      </button>
      <button onClick={() => setOpenAuditTrail(true)}>
        <AiOutlineAudit className={iconStyles} />
      </button>
      <button onClick={remove}>
        <IoMdRemoveCircleOutline
          className={twMerge(clsx(iconStyles, 'hover:text-red-500'))}
        />
      </button>

      <AuditTrailPopup
        isOpen={openAuditTrail}
        elementId={elementId}
        elementNumber={number}
        translate
        toggle={() => setOpenAuditTrail((prev) => !prev)}
      />
    </div>
  );
}

export default Popover;
