import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TbTrash } from 'react-icons/tb';
import { IoAdd, IoSave } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import { deleteRole, getRoles, postRole, putRoles } from 'api/roles';

import { Role, RoleType } from 'types/role';
import modal from 'utils/modal';

import { companyState } from 'state';

import Checkbox from 'components/common/Checkbox';

import CreatePopup from './CreatePopup';

function RolePrivilege() {
  const { t } = useTranslation();

  const [{ selected: company }] = useRecoilState(companyState);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<{ name?: string; reference?: Role }>({});
  const [roles, setRoles] = useState<Role[]>([]);

  const { refetch } = useQuery(
    ['getRoles'],
    () =>
      getRoles({
        companyId: company?.id,
        query: { orderBy: 'id', perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setRoles(data);
      },
    },
  );

  const { mutate: createMutate } = useMutation(postRole, {
    onSuccess() {
      refetch();
    },
  });
  const { mutate: updateMutate } = useMutation(putRoles, {
    onSuccess() {
      refetch();
    },
  });
  const { mutate: deleteMutate } = useMutation(deleteRole, {
    onSuccess() {
      refetch();
    },
  });

  const toggle = () => setOpen((prev) => !prev);

  const handleChangePrivileges = (
    idx: number,
    key: string,
    checked: boolean,
  ) => {
    setRoles(
      produce((draft) => {
        draft[idx].privileges[key] = checked;
      }),
    );
  };

  const handleCreate = () => {
    createMutate({
      companyId: company?.id,
      type: RoleType.COMMON,
      name: form.name,
      privileges: form.reference?.privileges,
    });
    setOpen(false);
  };

  const handleUpdate = async () => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      updateMutate(roles);
    }
  };

  const handleDelete = async (id: number) => {
    if (
      await modal.confirm(
        t('modal:confirm:title'),
        t('modal:confirm:delete'),
        'warn',
      )
    ) {
      deleteMutate(id);
    }
  };

  return (
    <>
      <div className="relative mt-3 flex overflow-auto">
        <table className="w-full table-fixed">
          <thead>
            <tr className="sticky top-0 z-20">
              <th className="sticky left-0 z-30 min-w-[15rem] bg-slate-300 py-4">
                권한
              </th>
              {roles.map(({ id, name }) => (
                <th key={name} className="min-w-[10rem] bg-slate-300 px-1">
                  {name}
                  {name !== 'Company Admin' && name !== 'System Manager' && (
                    <button onClick={() => handleDelete(id)}>
                      <TbTrash className="-mt-[3px] ml-1 inline cursor-pointer text-red-600 hover:opacity-50" />
                    </button>
                  )}
                </th>
              ))}
              <th className="sticky right-16 w-1 min-w-[0.25rem] bg-white" />
              <th className="sticky right-8 top-0 w-[2rem] min-w-[2rem] bg-slate-300">
                <button
                  className="pt-2 hover:opacity-50"
                  onClick={() => {
                    setForm({});
                    toggle();
                  }}
                >
                  <IoAdd title="create" />
                </button>
              </th>
              <th className="sticky right-0 top-0 w-[2rem] min-w-[2rem] bg-slate-300">
                <button
                  className="pt-2 hover:opacity-50"
                  onClick={handleUpdate}
                >
                  <IoSave title="update" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {roles[0]?.privileges
              ? Object.entries(roles[0].privileges).map(([key]) => (
                  <tr key={key} className="text-center font-semibold">
                    <td className="sticky left-0 z-10 bg-slate-100 py-4">
                      {key}
                    </td>
                    {roles.map(({ name, privileges }, idx) => (
                      <td key={name}>
                        <Checkbox
                          className="justify-center"
                          checked={privileges[key]}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            e.stopPropagation();
                            handleChangePrivileges(idx, key, e.target.checked);
                          }}
                          disabled={
                            name === 'Company Admin' ||
                            name === 'System Manager'
                          }
                        />
                      </td>
                    ))}
                    <td className="sticky right-16 bg-white" />
                    <td className="sticky right-8 bg-white" />
                    <td className="sticky right-0 bg-white" />
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      <CreatePopup
        isOpen={open}
        form={form}
        roles={roles}
        toggle={toggle}
        setForm={setForm}
        handleCreate={handleCreate}
      />
    </>
  );
}

export default RolePrivilege;
