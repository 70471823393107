import React from 'react';
import { ICSRFormElement } from 'types/case';

import Select from 'components/common/Select/Select';

function SelectComponent({
  className,
  value,
  number,
  data = [],
  valueField,
  textField,
  valueLabel,
  disabled,
  onChange = () => null,
}: {
  className?: string;
  value?: string | null;
  number: string;
  data: any[];
  valueField: string;
  textField: string;
  valueLabel?: boolean;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  return (
    <Select
      className={className}
      value={value}
      data={data}
      valueField={valueField}
      textField={
        valueLabel
          ? (item) => `${item[valueField]}: ${item[textField]}`
          : textField
      }
      onChange={(obj) => {
        onChange(number, {
          value: obj?.[valueField],
          description: obj?.[textField],
          nullFlavor: undefined,
        });
      }}
      disabled={disabled}
    />
  );
}

export default SelectComponent;
