import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import produce from 'immer';

import { codeSetsState } from 'state';

import { ICSRForm, ICSRFormElement, ICSRFormRootElement } from 'types/case';
import { Reporter } from 'types/reporter';

import { useCaseState } from '../../context/CaseContext';

import Title from '../../Title';
import RootRepeatElement from '../../RootRepeatElement';
import SubTitle from '../../SubTitle';
import CaseFormElement from '../../CaseFormElement';
import Space from '../../Space';
import LoadData from '../../LoadData';

function PrimaryInformation({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const [codeSets] = useRecoilState(codeSetsState);
  const { reporters } = useCaseState();

  const handleLoad = useCallback(
    (reporter: Reporter) => {
      const qualificationOid = codeSets.oidMap.qualification;
      const qualificationCode = codeSets[qualificationOid]?.codes.find(
        (code: any) => code.key === reporter.qualification,
      );
      const countryOid = codeSets.oidMap.countries;
      const countryCode = codeSets[countryOid]?.codes.find(
        (code: any) => code.key === reporter.countryCode,
      );

      setForm(
        produce((draft) => {
          (draft['C.2.r'] as ICSRFormRootElement[]).push({
            number: 'C.2.r',
            elements: {
              'C.2.r.1.1': { value: reporter?.title },
              'C.2.r.1.2': { value: reporter?.givenName },
              'C.2.r.1.3': { value: reporter?.middleName },
              'C.2.r.1.4': { value: reporter?.familyName },
              'C.2.r.2.1': { value: reporter?.organizationName },
              'C.2.r.2.2': { value: reporter?.department },
              'C.2.r.2.3': { value: reporter?.street },
              'C.2.r.2.4': { value: reporter?.city },
              'C.2.r.2.5': { value: reporter?.province },
              'C.2.r.2.6': { value: reporter?.postCode },
              'C.2.r.2.7': { value: reporter?.telephone },
              'C.2.r.3': {
                value: reporter?.countryCode,
                description: countryCode?.value,
              },
              'C.2.r.4': {
                value: reporter?.qualification,
                description: qualificationCode?.value,
              },
              'C.2.r.5': { value: reporter?.primarySource },
            },
          });
        }),
      );
      handleVersion();
    },
    [codeSets, handleVersion, setForm],
  );

  const handleChange = useCallback(
    (
      number: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  return (
    <>
      <Title number="C.2.r">
        <LoadData
          textField="givenName"
          data={reporters}
          disabled={disabled}
          onLoad={handleLoad}
        />
      </Title>

      <RootRepeatElement
        number="C.2.r"
        version={version}
        element={form['C.2.r'] as ICSRFormRootElement[]}
        disabled={disabled}
        onChange={handleChange}
      >
        <SubTitle number="C.2.r.1" />
        <CaseFormElement number="C.2.r.1.1" />
        <CaseFormElement number="C.2.r.1.2" />
        <CaseFormElement number="C.2.r.1.3" />
        <CaseFormElement number="C.2.r.1.4" />

        <Space />

        <SubTitle number="C.2.r.2" />
        <CaseFormElement number="C.2.r.2.1" />
        <CaseFormElement number="C.2.r.2.2" />
        <CaseFormElement number="C.2.r.2.3" full />
        <CaseFormElement number="C.2.r.2.4" />
        <CaseFormElement number="C.2.r.2.5" />
        <CaseFormElement number="C.2.r.2.6" />
        <CaseFormElement number="C.2.r.2.7" />

        <Space />

        <CaseFormElement number="C.2.r.3" />
        <CaseFormElement number="C.2.r.5" />
        <CaseFormElement number="C.2.r.4" />
      </RootRepeatElement>
    </>
  );
}

export default React.memo(PrimaryInformation);
