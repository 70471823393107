import React from 'react';
import { TbPlus, TbTrash } from 'react-icons/tb';
import produce from 'immer';

import { getDefaultDatasetRule } from 'api/dataset';

import { DatasetRuleType } from 'types/dataset';
import clsx from 'clsx';

interface Props {
  repeatable?: boolean;
  elementNumber?: string;
  elementLabel?: string;
  rules: DatasetRuleType[];
  selectedRuleIdx: number;
  setRules: React.Dispatch<React.SetStateAction<DatasetRuleType[]>>;
  setSelectedRuleIdx: React.Dispatch<React.SetStateAction<number>>;
}

function ElementListTable({
  rules,
  elementNumber,
  elementLabel,
  repeatable,
  selectedRuleIdx,
  setRules,
  setSelectedRuleIdx,
}: Props) {
  return (
    <table className="w-full border-collapse border-slate-800">
      <thead className="text-center">
        <tr className="[&>th]:border [&>th]:p-1">
          <th className="w-12">No.</th>
          <th className="w-32">Element No.</th>
          <th>Element Name</th>
          {repeatable && (
            <th className="w-8">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setRules(
                    produce((draft) => {
                      draft.push(getDefaultDatasetRule(draft[0]));
                    }),
                  );
                }}
              >
                <TbPlus />
              </button>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {rules?.map((item: DatasetRuleType, index: number) => (
          <tr
            key={index}
            role="button"
            className={clsx(
              'text-center [&>td]:border [&>td]:p-1',
              selectedRuleIdx === index && 'bg-brand-300',
            )}
            onClick={() => setSelectedRuleIdx(index)}
          >
            <td>{index + 1}</td>
            <td>{elementNumber}</td>
            <td>{elementLabel}</td>
            {repeatable && (
              <td className="w-8">
                {index !== 0 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setRules(
                        produce((draft) => {
                          draft.splice(index, 1);
                        }),
                      );
                      setSelectedRuleIdx(-1);
                    }}
                  >
                    <TbTrash />
                  </button>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ElementListTable;
