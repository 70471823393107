import React from 'react';
import { useRecoilState } from 'recoil';

import { codeSetsState } from 'state';
import { Receiver } from 'types/receiver';

import Input from 'components/common/Input';
import Select from 'components/common/Select/Select';
import Button from 'components/common/Button';

interface props {
  submitLabel: string;
  form: Receiver;
  errors: any;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}

function ReceiverForm({
  submitLabel,
  form,
  errors,
  onSubmit,
  onChange,
}: props) {
  const {
    type,
    name,
    receiverId,
    nsaeSolicited,
    nsaeNonSolicited,
    saeSolicited,
    saeNonSolicited,
  } = form;

  const [codeSets] = useRecoilState(codeSetsState);

  return (
    <form className="max-w-2xl" onSubmit={onSubmit}>
      <div className="space-y-1 pt-1">
        <div className="flex py-4 px-2">
          <div className="w-1/2">Receiver Type</div>
          <div className="flex w-1/2 flex-wrap">
            <Select
              className="w-full"
              data={codeSets?.receiverType || []}
              valueField="value"
              textField="label"
              value={type}
              onChange={(val) => onChange('type', val.value)}
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.type?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Receiver name</div>
          <div className="flex w-1/2 flex-wrap">
            <Input
              className="w-full"
              value={name}
              onChange={(e) => onChange('name', e.target.value)}
            />
            <span className="inline-block h-0 w-full text-sm text-red-600">
              {errors.name?.message}
            </span>
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Receiver ID</div>
          <div className="w-1/2">
            <Input
              className="w-full"
              value={receiverId}
              onChange={(e) => onChange('receiverId', e.target.value)}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Solicited NSAE</div>
          <div className="w-1/2">
            <Input
              type="number"
              className="w-full"
              value={nsaeSolicited}
              onChange={(e) => onChange('nsaeSolicited', e.target.value)}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Unsolicited NSAE</div>
          <div className="w-1/2">
            <Input
              type="number"
              className="w-full"
              value={nsaeNonSolicited}
              onChange={(e) => onChange('nsaeNonSolicited', e.target.value)}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Solicited SAE</div>
          <div className="w-1/2">
            <Input
              type="number"
              className="w-full"
              value={saeSolicited}
              onChange={(e) => onChange('saeSolicited', e.target.value)}
            />
          </div>
        </div>
        <div className="flex py-4 px-2">
          <div className="w-1/2">Unsolicited SAE</div>
          <div className="w-1/2">
            <Input
              type="number"
              className="w-full"
              value={saeNonSolicited}
              onChange={(e) => onChange('saeNonSolicited', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default ReceiverForm;
