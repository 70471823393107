import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import {
  getReporter,
  getReporterDefaultValues,
  putReporter,
} from 'api/reporters';

import { Reporter } from 'types/reporter';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import ReporterForm from './ReporterForm';

function EditReporter() {
  const { t } = useTranslation();
  const { reporterId } = useParams();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getReporterDefaultValues(company?.id),
  });

  const { refetch } = useQuery(
    ['getReporter'],
    () => getReporter(reporterId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data: Reporter) {
        setValue([
          { id: data.id },
          { companyId: data.companyId },
          { organizationName: data.organizationName },
          { department: data.department },
          { title: data.title },
          { givenName: data.givenName },
          { middleName: data.middleName },
          { familyName: data.familyName },
          { street: data.street },
          { city: data.city },
          { province: data.province },
          { postCode: data.postCode },
          { countryCode: data.countryCode },
          { telephone: data.telephone },
          { fax: data.fax },
          { email: data.email },
          { qualification: data.qualification },
          { version: data.version },
        ]);
      },
    },
  );

  const { mutate } = useMutation(putReporter, {
    onSuccess() {
      refetch();
    },
  });

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'id' });
    register({ name: 'companyId' });
    register({ name: 'organizationName' }, { required: t('error:required') });
    register({ name: 'title' });
    register({ name: 'department' });
    register({ name: 'givenName' }, { required: t('error:required') });
    register({ name: 'middleName' });
    register({ name: 'familyName' });
    register({ name: 'street' });
    register({ name: 'city' });
    register({ name: 'province' });
    register({ name: 'postCode' });
    register({ name: 'countryCode' });
    register({ name: 'telephone' });
    register({ name: 'fax' });
    register({ name: 'email' });
    register({ name: 'qualification' }, { required: t('error:required') });
    register({ name: 'version' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Edit Reporter"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <ReporterForm
        submitLabel="Save"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default EditReporter;
