import React from 'react';
import { TbChevronRight } from 'react-icons/tb';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Tab, Tabs } from './common/Tab';
import { NavItemType } from './nav/NavItems';
import Timer from './Timer';

export interface HeaderProps {
  className?: string;
  title?: string;
  breadcrumbs?: NavItemType[];
  tabList?: NavItemType[];
}

function Header({ className, title, tabList, breadcrumbs }: HeaderProps) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <header
      className={clsx(
        'border-b border-slate-200 bg-white px-4 pt-2 text-slate-900 dark:bg-slate-900/80 dark:text-slate-100',
        className,
      )}
    >
      <div className="flex h-10 items-center justify-between">
        {breadcrumbs ? (
          <nav className="flex items-center space-x-1 text-xl font-semibold">
            {breadcrumbs.map(({ label, to }) => (
              // <NavLink
              //   key={label}
              //   to={to}
              //   className="text-slate-500 hover:underline"
              // >
              //   {label}
              // </NavLink>
              <button
                key={label}
                type="button"
                className="text-slate-500 hover:underline"
                onClick={() => {
                  if (to === '..' && location.state?.prevUrl) navigate(-1);
                  else navigate(to);
                }}
              >
                {label}
              </button>
            ))}
            <TbChevronRight className="text-slate-500" />
            <NavLink to="#" className="pointer-events-none">
              {title}
            </NavLink>
          </nav>
        ) : (
          <h1 className="text-2xl">{title}</h1>
        )}
        <Timer />
      </div>
      <div className="pt-2">
        {tabList && (
          <Tabs value={location.pathname.split('/').at(-1)}>
            {tabList.map(({ to, label }) => (
              <Tab key={to} index={to}>
                <NavLink to={to}>{label}</NavLink>
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
    </header>
  );
}

export default React.memo(Header);
