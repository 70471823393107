import React from 'react';

import Sidebar from 'components/nav/Sidebar';

export interface MainTemplateProps {
  children?: React.ReactNode;
}

function MainTemplate({ children }: MainTemplateProps) {
  return (
    <div className="flex h-[var(--mainHeight)] w-screen bg-white dark:bg-slate-800">
      <Sidebar />
      <main className="flex h-full w-[calc(100%-var(--sidebarWidth))] flex-col bg-inherit">
        {children}
      </main>
    </div>
  );
}

export default MainTemplate;
