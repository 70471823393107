import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorField from './ErrorField';

function SubTitle({
  parentId,
  number,
  children,
}: {
  parentId?: number;
  number: string;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <div className="mb-2 inline-flex w-full flex-wrap items-center font-semibold">
      {t(`ICSR:elementName:${number}`)}
      {children}
      <ErrorField parentId={parentId} elementNumber={number} />
    </div>
  );
}

export default SubTitle;
