import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getNotification,
  getNotificationDefaultValues,
  putNotification,
} from 'api/notifications';

import { Notification } from 'types/notification';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import NotificationForm from './NotificationForm';

function EditNotification() {
  const { t } = useTranslation();
  const { notificationId } = useParams();
  const { form, setValue, handleSubmit } = useForm({
    defaultValues: getNotificationDefaultValues(),
  });

  useQuery(
    ['getNotification'],
    () => getNotification(notificationId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess({ id, subject, visible, contents, version }: Notification) {
        setValue([{ id }, { subject }, { visible }, { contents }, { version }]);
      },
    },
  );

  const { mutate: submit } = useMutation(putNotification);

  const handleChange = (key: string, value: any) => setValue(key, value);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:update'))
    ) {
      submit({ notificationId, data });
    }
  });

  return (
    <FormWrapper
      title="Edit Notification"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <NotificationForm
        submitLabel="Save"
        form={form}
        onChange={handleChange}
        onSubmit={onSubmit}
      />
    </FormWrapper>
  );
}

export default EditNotification;
