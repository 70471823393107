import { atom } from 'recoil';

import { Member } from 'types/member';

const memberState = atom<Member | undefined>({
  key: 'member',
  default: undefined,
});

export default memberState;
