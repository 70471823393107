import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';

import { SubmissionStatus } from 'types/submission';

import { getCases } from 'api/cases';
import { companyState } from 'state';

import Select from 'components/common/Select/Select';
import { Tab, TabPanel, Tabs } from 'components/common/Tab';
import { NavLink } from 'react-router-dom';

const columnStyles = 'relative flex-1 pr-2 last:pr-0';
const rowStyles =
  'relative shadow hover:bg-slate-100 flex w-full flex-wrap pl-2 py-2 text-left';

const SEARCH_PERIOD = [
  { id: 1, label: '1 Week', amount: 7, unit: 'days' } as const,
  { id: 2, label: '1 Month', amount: 1, unit: 'months' } as const,
  { id: 3, label: '3 Months', amount: 1, unit: 'years' } as const,
];

function DashboardReportResult() {
  const [{ selected: company }] = useRecoilState(companyState);
  const [tabValue, setTabValue] = useState<number>(0);
  const [period, setPeriod] = useState(SEARCH_PERIOD[0]);
  const { amount, unit } = period;

  const { data: getAsyncSubmittedCases = [] } = useQuery(
    ['getAsyncSubmittedCases', company?.id, period],
    () =>
      getCases({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 500,
          q: {
            status: SubmissionStatus.submitted,
            lastSubmittedDate: [moment().subtract(amount, unit).startOf('day')],
          },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: getAsyncSubmitFailedCases = [] } = useQuery(
    ['getAsyncSubmitFailedCases', company?.id, period],
    () =>
      getCases({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 500,
          q: {
            status: SubmissionStatus.submitFailed,
            lastSubmittedDate: [moment().subtract(amount, unit).startOf('day')],
          },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: getAsyncAcceptCases = [] } = useQuery(
    ['getAsyncAcceptCases', company?.id, period],
    () =>
      getCases({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 500,
          q: {
            status: SubmissionStatus.processingCompeleted,
            lastSubmittedDate: [moment().subtract(amount, unit).startOf('day')],
          },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: getAsyncErrorCases = [] } = useQuery(
    ['getAsyncErrorCases', company?.id, period],
    () =>
      getCases({
        companyId: company?.id,
        query: {
          page: 1,
          perPage: 500,
          q: {
            status: SubmissionStatus.processingFailed,
            lastSubmittedDate: [moment().subtract(amount, unit).startOf('day')],
          },
        },
      }).then(({ data }) => data.content),
    {
      refetchOnWindowFocus: false,
    },
  );

  const data = [
    getAsyncSubmittedCases,
    getAsyncSubmitFailedCases,
    getAsyncAcceptCases,
    getAsyncErrorCases,
  ];

  return (
    <div className="w-full p-2 xl:w-1/2">
      <div className="space-x-4 rounded border bg-white px-2 py-4 shadow xl:w-full xl:flex-shrink-0 xl:flex-grow-0 xl:basis-full xl:space-x-0 xl:space-y-4">
        <div className="px-2">
          <div className="m-0 flex justify-between text-xl font-bold text-gray-700">
            Regulatory Authority Treated Case
            <Select
              size="sm"
              className="w-36 text-base font-normal"
              value={period.id}
              onChange={(val) => setPeriod(val)}
              data={SEARCH_PERIOD}
              valueField="id"
              textField="label"
              isClearable={false}
            />
          </div>
          <div className="border-b border-gray-500 leading-10 text-gray-600 [&_.tab-navigator]:mt-0">
            <Tabs value={tabValue} onChange={(value) => setTabValue(value)}>
              <Tab
                index={0}
              >{`SUBMITTED(${getAsyncSubmittedCases.length})`}</Tab>
              <Tab
                index={1}
              >{`SUBMIT FAILED(${getAsyncSubmitFailedCases.length})`}</Tab>
              <Tab index={2}>{`ACK AA(${getAsyncAcceptCases.length})`}</Tab>
              <Tab index={3}>{`ACK AE(${getAsyncErrorCases.length})`}</Tab>
            </Tabs>
          </div>
        </div>
        <div className="overflow-auto pl-4 pr-2">
          <div className="sticky top-0 z-10 -mx-2 flex flex-wrap bg-white p-2">
            <div className={clsx('flex-[1.5]', columnStyles)}>Case No.</div>
            <div className={columnStyles}>Recent Information Date</div>
            <div className={columnStyles}>Submitted Date</div>
          </div>
          <div>
            {data.map((cases, index) => (
              <TabPanel key={index} index={index} value={tabValue}>
                <div className="relative mt-0 -ml-2 h-56 flex-col space-y-2 pt-2">
                  {cases.length ? (
                    cases.map((icsr: any) => (
                      <NavLink
                        key={icsr.id}
                        to={`../cases/${icsr.id}`}
                        className={rowStyles}
                      >
                        <div className={clsx('flex-[1.5]', columnStyles)}>
                          {icsr.sendersSafetyReportUniqueIdentifier}
                        </div>
                        <div className={columnStyles}>
                          {icsr.dateOfMostRecentInformationForThisReport}
                        </div>
                        <div className={columnStyles}>
                          {icsr.lastSubmittedDate &&
                            moment(icsr.lastSubmittedDate).format(
                              'YYYY-MM-DD HH:mm:ss Z',
                            )}
                        </div>
                      </NavLink>
                    ))
                  ) : (
                    <div className="flex h-full items-center justify-center text-2xl text-gray-400">
                      No Data
                    </div>
                  )}
                </div>
              </TabPanel>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardReportResult;
