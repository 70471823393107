import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';

function App({ children }: { children?: React.ReactNode }) {
  const [visibleVersionChange, setVisibleVersionChange] = useState(true);

  useEffect(() => {
    if (visibleVersionChange) {
      document.documentElement.style.setProperty(
        '--mainHeight',
        'calc(100vh - 2rem)',
      );
    } else {
      document.documentElement.style.setProperty('--mainHeight', '100vh');
    }
  }, [visibleVersionChange]);

  return (
    <>
      {visibleVersionChange && (
        <div className="relative flex items-center justify-center bg-brand-500 text-slate-100">
          <a
            href={`https://${
              process.env.REACT_APP_ENVIRONMENT === 'prod'
                ? 'www'
                : process.env.REACT_APP_ENVIRONMENT
            }.csafer.co.kr/login`}
            className="h-8 hover:opacity-80"
          >
            <b className="mr-1 text-lg">Important:</b>
            <span>This UI version is new. For old version, click on me</span>
          </a>
          <button
            type="button"
            className="ml-1 hover:opacity-80"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();
              setVisibleVersionChange(false);
            }}
          >
            <IoClose fontSize="1.25rem" className="mt-0.5" />
          </button>
        </div>
      )}
      {children}
    </>
  );
}

export default App;
