import clsx from 'clsx';
import React from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { twMerge } from 'tailwind-merge';

import { SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

type TextareaType = TypeModify<
  TextareaAutosizeProps,
  {
    size?: SizeType;
    error?: boolean;
  }
>;

const sizeStyles: { [key in SizeType]: string } = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};

function Textarea(
  { className, size = 'md', error, disabled, ...rest }: TextareaType,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  return (
    <TextareaAutosize
      ref={ref}
      className={twMerge(
        clsx(
          sizeStyles[size],
          'focus:control-focus',
          error && 'control-error',
          disabled && 'disabled',
          className,
        ),
      )}
      disabled={disabled}
      {...rest}
    />
  );
}

export default React.forwardRef(Textarea);
