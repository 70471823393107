/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';
import { twMerge } from 'tailwind-merge';
import { IoCalendarClearOutline, IoCloseCircle } from 'react-icons/io5';

import DatePickerDialog from './DatePickerDialog';
import { controlStyles } from './DatePicker';
import { DateType } from './datePickerUtils';

type FocusingTarget = 'start' | 'end';

interface RangePickerProps {
  className?: string;
  size?: SizeType;
  value?: DateType[];
  format?: string;
  allowedFormat?: string[];
  placeholder?: string[];
  menuIsOpen?: boolean;
  closeMenuOnSelect?: boolean;
  error?: boolean;
  disabled?: boolean;
  startDateRef?: React.MutableRefObject<HTMLInputElement | null>;
  endDateRef?: React.MutableRefObject<HTMLInputElement | null>;
  onChange?: (ds?: (Moment | undefined)[]) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

function RangePicker({
  className,
  size = 'md',
  value,
  format = 'YYYY-MM-DD',
  allowedFormat,
  placeholder = ['Start Date', 'End Date'],
  menuIsOpen = false,
  closeMenuOnSelect = true,
  error,
  disabled,
  startDateRef,
  endDateRef,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onKeyDown = () => null,
}: RangePickerProps) {
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [startInputValue, setStartInputValue] = useState('');
  const [endInputValue, setEndInputValue] = useState('');
  const [focusingTarget, setFocusingTarget] = useState<FocusingTarget>();
  const [selectedDates, setSelectedDates] = useState<(Moment | undefined)[]>();
  const [focusedDate, setFocusedDate] = useState<Moment>(moment());
  const [hoverDate, setHoverDate] = useState<Moment>();

  const debounced = useRef(
    debounce((val: string, target?: FocusingTarget) => {
      const date = moment(val, allowedFormat || format, true);
      if (date.isValid()) {
        if (target === 'start')
          setSelectedDates((prev) => [moment(date), prev?.[1]]);
        if (target === 'end')
          setSelectedDates((prev) => [prev?.[0], moment(date)]);
      }
    }, 500),
  );

  const focusInput = (target: FocusingTarget = 'start') => {
    if (target === 'start' && startInputRef.current) {
      startInputRef.current.focus();
    }
    if (target === 'end' && endInputRef.current) {
      endInputRef.current.focus();
    }
  };

  const focusDate = (direction: 'prev' | 'next' | 'up' | 'down') => {
    if (!focusedDate) {
      setFocusedDate(moment());
      return;
    }
    switch (direction) {
      case 'prev':
        setFocusedDate(moment(focusedDate).subtract(1, 'days'));
        setHoverDate(moment(focusedDate).subtract(1, 'days'));
        break;
      case 'next':
        setFocusedDate(moment(focusedDate).add(1, 'days'));
        setHoverDate(moment(focusedDate).add(1, 'days'));
        break;
      case 'up':
        setFocusedDate(moment(focusedDate).subtract(1, 'weeks'));
        setHoverDate(moment(focusedDate).subtract(1, 'weeks'));
        break;
      case 'down':
        setFocusedDate(moment(focusedDate).add(1, 'weeks'));
        setHoverDate(moment(focusedDate).add(1, 'weeks'));
        break;
      default:
        break;
    }
  };

  const openMenu = () => {
    setIsMenuOpen(true);
    setFocusedDate(moment(selectedDates?.[0] || selectedDates?.[1]));
  };

  const handleClearMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setFocusedDate(moment());
    setSelectedDates(undefined);
    onChange(undefined);
  };

  const handleControlMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let target: FocusingTarget | undefined;
    if (e.target instanceof HTMLDivElement) {
      target = e.target.dataset.target as FocusingTarget;
    }
    focusInput(target || focusingTarget);
    if (
      isMenuOpen &&
      !containerRef?.current?.contains(e.target as HTMLDivElement)
    ) {
      setIsMenuOpen(false);
    } else {
      openMenu();
    }
  };

  const handleMenuMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (focusingTarget === 'start') setStartInputValue(e.target.value);
    if (focusingTarget === 'end') setEndInputValue(e.target.value);
    debounced.current(e.target.value, focusingTarget);
    if (!isMenuOpen) {
      openMenu();
    }
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus(e);
    if (e.target instanceof HTMLInputElement) {
      const { target } = e.target.dataset;
      setFocusingTarget(target as FocusingTarget);
    }
    setIsFocused(true);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    if (!containerRef.current?.contains(e.relatedTarget)) {
      setIsFocused(false);
      setIsMenuOpen(false);
    }
    setStartInputValue('');
    setEndInputValue('');
  };

  const handleSelect = (date: Moment) => {
    const formatedDate = moment(date.format(format), allowedFormat || format);
    let newDates: (Moment | undefined)[] | undefined;

    if (focusingTarget === 'start') {
      formatedDate.startOf('day');
      if (selectedDates?.[1]?.isValid()) {
        if (formatedDate.valueOf() >= selectedDates[1].valueOf()) {
          newDates = [formatedDate, undefined];
        } else {
          newDates = [formatedDate, selectedDates[1]];
        }
      } else {
        newDates = [formatedDate, undefined];
      }
      focusInput('end');
    }
    if (focusingTarget === 'end') {
      formatedDate.endOf('day');
      if (selectedDates?.[0]?.isValid()) {
        if (formatedDate.valueOf() <= selectedDates[0].valueOf()) {
          newDates = [undefined, formatedDate];
        } else {
          newDates = [selectedDates[0], formatedDate];
        }
      } else {
        newDates = [undefined, formatedDate];
      }
      if (newDates[0]) {
        focusInput('end');
        if (closeMenuOnSelect) {
          setIsMenuOpen(false);
        }
      } else {
        focusInput('start');
      }
    }

    onChange(newDates);
    setSelectedDates(newDates);
    setFocusedDate(date);
    setStartInputValue('');
    setEndInputValue('');
  };

  const handleDateClick = (e: React.MouseEvent) => {
    const { target } = e;
    if (!(target instanceof HTMLButtonElement)) return;
    const date = moment(Number(target.dataset.date));
    handleSelect(date);
  };

  const handleDateHover = ({ target }: React.MouseEvent) => {
    if (!(target instanceof HTMLButtonElement)) return;
    const date = moment(Number(target.dataset.date));
    setHoverDate(date);
  };

  const handleControlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e);
    switch (e.key) {
      case 'ArrowLeft':
        focusDate('prev');
        break;
      case 'ArrowRight':
        focusDate('next');
        break;
      case 'Tab':
        if (isMenuOpen && focusingTarget === 'end') {
          setIsMenuOpen(false);
        }
        onChange(selectedDates);
        return;
      case 'Enter':
        handleSelect(focusedDate);
        break;
      case 'Escape':
        setIsMenuOpen(false);
        setStartInputValue('');
        setEndInputValue('');
        break;
      case 'ArrowUp':
        if (menuIsOpen || isMenuOpen) {
          focusDate('up');
        } else {
          openMenu();
        }
        break;
      case 'ArrowDown':
        if (menuIsOpen || isMenuOpen) {
          focusDate('down');
        } else {
          openMenu();
        }
        break;
      default:
        return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (!value) return;

    const startDate = moment(value[0], allowedFormat || format, true);
    const endDate = moment(value[1], allowedFormat || format, true);

    setSelectedDates([startDate, endDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      ref={containerRef}
      className={twMerge(
        clsx('group relative h-fit w-full', className, disabled && 'disabled'),
      )}
    >
      <div
        className={twMerge(
          clsx(
            controlStyles[size],
            'relative flex w-full flex-wrap items-center justify-between pr-0 transition-all',
            isFocused && 'control-focus',
            error && 'control-error',
          ),
        )}
        onMouseDown={handleControlMouseDown}
        onKeyDown={handleControlKeyDown}
      >
        <div className="grid flex-1 overflow-hidden">
          {!startInputValue && (
            <div className="csafer-selet-value col-start-1 col-end-3 row-start-1 row-end-2 truncate">
              {selectedDates?.[0] ? (
                (selectedDates[0].creationData().input as string)
              ) : (
                <span className="text-slate-300">{placeholder[0]}</span>
              )}
            </div>
          )}
          <div
            className={clsx(
              'col-start-1 col-end-3 row-start-1 row-end-2 inline-grid grid-cols-[0px_min-content]',
              'after:invisible after:col-start-2 after:col-end-auto after:row-start-1	after:row-end-auto after:whitespace-pre after:content-[attr(data-value)]',
            )}
            data-value={startInputValue}
            data-target="start"
          >
            <input
              ref={(_ref) => {
                (
                  startInputRef as React.MutableRefObject<HTMLInputElement | null>
                ).current = _ref;
                if (startDateRef) {
                  startDateRef.current = _ref;
                }
              }}
              className="col-start-2 col-end-auto row-start-1 row-end-auto w-full min-w-[2px] bg-inherit text-inherit opacity-100 "
              value={startInputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={disabled}
              data-target="start"
              // readOnly={readOnly}
            />
          </div>
        </div>
        <div className="min-w-[1.5rem] max-w-[1.5rem] text-center">~</div>
        <div className="grid flex-1 overflow-hidden">
          {!endInputValue && (
            <div className="csafer-selet-value col-start-1 col-end-3 row-start-1 row-end-2 truncate">
              {selectedDates?.[1] ? (
                (selectedDates[1].creationData().input as string)
              ) : (
                <span className="text-slate-300">{placeholder[1]}</span>
              )}
            </div>
          )}
          <div
            className={clsx(
              'col-start-1 col-end-3 row-start-1 row-end-2 inline-grid grid-cols-[0px_min-content]',
              'after:invisible after:col-start-2 after:col-end-auto after:row-start-1	after:row-end-auto after:whitespace-pre after:content-[attr(data-value)]',
            )}
            data-value={endInputValue}
            data-target="end"
          >
            <input
              ref={(_ref) => {
                (
                  endInputRef as React.MutableRefObject<HTMLInputElement | null>
                ).current = _ref;
                if (endDateRef) {
                  endDateRef.current = _ref;
                }
              }}
              className="col-start-2 col-end-auto row-start-1 row-end-auto w-full min-w-[2px] bg-inherit text-inherit opacity-100 "
              value={endInputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={disabled}
              data-target="end"
              // readOnly={readOnly}
            />
          </div>
        </div>
        <div className="casfer-select-action flex flex-shrink-0 items-center px-2">
          <button
            tabIndex={-1}
            className={clsx(
              'casfer-select-indicator',
              selectedDates && 'group-hover:hidden',
            )}
          >
            <IoCalendarClearOutline
              className={clsx(
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
          <button
            tabIndex={-1}
            className={clsx(
              'casfer-select-reset hidden',
              selectedDates && 'group-hover:block',
            )}
            onMouseDown={handleClearMouseDown}
          >
            <IoCloseCircle
              className={clsx(
                'hover:text-slate-500',
                isFocused
                  ? 'text-slate-700 dark:text-slate-200'
                  : `text-slate-400 dark:text-slate-500`,
              )}
            />
          </button>
        </div>
      </div>
      {(menuIsOpen || isMenuOpen) && (
        <div
          ref={menuRef}
          className="csafer-select-list absolute z-dropdown pt-1"
          onMouseDown={handleMenuMouseDown}
        >
          <div className="min-h-fit min-w-fit overflow-auto rounded border border-slate-300 bg-white py-1 pt-1 shadow">
            <DatePickerDialog
              selectedDates={selectedDates}
              focusedDate={focusedDate}
              hoverDate={hoverDate}
              handleDateClick={handleDateClick}
              handleDateHover={handleDateHover}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(RangePicker);
