import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

function DropdownToggle({ className, children, ...rest }: Props) {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

export default DropdownToggle;
