import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorField from './ErrorField';

function Title({
  number,
  children,
}: {
  number: string;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <div className="mb-8 inline-flex w-full flex-wrap items-center text-xl font-bold">
      {t(`ICSR:elementName:${number}`)}
      {children}
      <ErrorField elementNumber={number} />
    </div>
  );
}

export default Title;
