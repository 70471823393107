import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ICSRFormElement } from 'types/case';

import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';

function RadioComponent({
  className,
  value,
  number,
  options,
  valueField,
  textField,
  valueLabel,
  disabled,
  onChange = () => null,
}: {
  className?: string;
  value?: string | null;
  number: string;
  options: any[];
  valueField: string;
  textField: string;
  valueLabel?: boolean;
  disabled?: boolean;
  onChange?: (n: string, v: ICSRFormElement) => void;
}) {
  return (
    <RadioGroup
      className={twMerge(clsx('space-y-2', className))}
      name={number}
      value={value || ''}
      onChange={(val: any) => {
        const target = options.find((item: any) => item[valueField] === val);
        const description = target && target[textField];
        onChange(number, {
          number,
          value: val,
          description,
          nullFlavor: undefined,
        });
      }}
      enableClickReset
      disabled={disabled}
    >
      {options.map(({ key, value: label }) => (
        <Radio
          key={key}
          size="md"
          value={key}
          label={label}
          valueLabel={valueLabel}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
}

export default RadioComponent;
