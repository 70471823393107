import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { codeSetsState, coreState } from 'state';
import { CaseStatus } from 'types/case';
import { ListHead } from 'types/list';

function useCaseList() {
  const [{ region }] = useRecoilState(coreState);
  const [codeSets] = useRecoilState(codeSetsState);

  const filterDataSets: {
    [key in
      | 'whoUmcResultOfAssessment'
      | 'krctResultOfAssessment'
      | 'typeOfReport'
      | 'status']: { key: string; value: string }[];
  } = useMemo(
    () => ({
      whoUmcResultOfAssessment: (
        codeSets['2.16.840.1.113883.3.989.5.1.10.1.5'] || []
      ).codes.map(({ key, value }: any) => ({
        key,
        value,
      })),
      krctResultOfAssessment: (
        codeSets['2.16.840.1.113883.3.989.5.1.10.1.6'] || []
      ).codes.map(({ key, value }: any) => ({
        key,
        value,
      })),
      typeOfReport: (
        codeSets['2.16.840.1.113883.3.989.2.1.1.2'] || []
      ).codes.map(({ key, value }: any) => ({
        key,
        value,
      })),
      status: Object.entries(CaseStatus).map(([key, value]) => ({
        key,
        value,
      })),
    }),
    [codeSets],
  );

  const listHeads: ListHead[] = useMemo(
    () => [
      {
        type: 'select',
        name: 'validated',
        label: 'Validation',
        filter: true,
        data: [
          { value: 'false', label: 'FAIL' },
          { value: 'true', label: 'SUCCESS' },
        ],
        valueField: 'value',
        textField: 'label',
        width: '14rem',
        style: { width: '6rem' },
      },
      {
        name: 'sendersSafetyReportUniqueIdentifier',
        label: 'Case No.',
        style: { width: '20rem' },
      },
      {
        type: 'select',
        label: 'Type',
        name: 'reportClassification',
        sort: true,
        filter: true,
        data: [
          { value: 'INITIAL', label: 'INITIAL' },
          { value: 'FOLLOW_UP', label: 'FOLLOW_UP' },
        ],
        valueField: 'value',
        textField: 'label',
        width: '10rem',
        style: { width: '7rem' },
      },
      {
        name: 'productName',
        label: 'Product',
        style: { width: '10rem' },
      },
      {
        name: 'reactionEventTerm',
        label: 'AE Term (LLT)',
        style: { width: '20rem' },
      },
      {
        type: 'select',
        label: 'SAE',
        name: 'reactionEventsSeriousness',
        sort: true,
        filter: true,
        data: [
          { value: 'SAE', label: 'SERIOUS' },
          { value: 'NSAE', label: 'NON-SERIOUS' },
        ],
        valueField: 'value',
        textField: 'label',
        width: '10rem',
        style: { width: '4rem' },
      },
      {
        label: 'Causality',
        name: 'causality',
        style: { width: '12rem' },
      },
      {
        type: 'date',
        name: 'dateOfCreation',
        label: 'Date of Creation',
        sort: true,
        filter: true,
        flex: true,
        width: '14rem',
        style: { width: '10rem' },
      },
      {
        type: 'date',
        name: 'dateOfMostRecentInformationForThisReport',
        label: 'Recent information date',
        sort: true,
        filter: true,
        flex: true,
        width: '14rem',
        style: { width: '13rem' },
      },
      {
        type: 'select',
        name: 'typeOfReport',
        label: 'Source',
        sort: true,
        filter: true,
        data: filterDataSets.typeOfReport,
        valueField: 'key',
        textField: 'value',
        width: '17em',
        style: { width: '10rem' },
      },
      {
        name: 'sponsorStudyNumber',
        label: 'Study',
        style: { width: '8rem' },
      },
      {
        type: 'select',
        name: 'status',
        label: 'Status',
        sort: true,
        filter: true,
        data: filterDataSets.status,
        valueField: 'key',
        textField: 'value',
        width: '17rem',
        style: { width: '12rem' },
      },
      {
        type: 'date',
        name: 'regulatoryAuthorityReportingDeadline',
        label: 'RA timeline',
        sort: true,
        filter: true,
        flex: true,
        width: '14rem',
        style: { width: '8rem' },
      },
      {
        type: 'date',
        name: 'lastSubmittedDate',
        label: 'Submitted Date',
        sort: true,
        filter: true,
        flex: true,
        width: '14rem',
        style: { width: '10rem' },
      },
    ],
    [filterDataSets],
  );
  const filterList = useMemo(
    () => [
      {
        type: 'searchList',
        name: 'searchList',
        list: [
          { name: 'sendersSafetyReportUniqueIdentifier', label: 'Case Number' },
          { name: 'productName', label: 'Product Name' },
          { name: 'reactionEventTerm', label: 'AE Name' },
        ],
        filter: true,
        width: '20rem',
      },
      ...(region === 'MFDS'
        ? [
            {
              type: 'select',
              label: 'WHO-UMC',
              name: 'whoUmcResultOfAssessment',
              sort: true,
              filter: true,
              flex: true,
              data: filterDataSets.whoUmcResultOfAssessment,
              valueField: 'key',
              textField: 'value',
              width: '16.5rem',
            },
            {
              type: 'select',
              label: 'KRCT',
              name: 'krctResultOfAssessment',
              sort: true,
              filter: true,
              flex: true,
              data: filterDataSets.krctResultOfAssessment,
              valueField: 'key',
              textField: 'value',
              width: '10rem',
            },
          ]
        : []),
      ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
    ],
    [filterDataSets, listHeads, region],
  );

  return {
    listHeads,
    filterList,
    filterDataSets,
  };
}

export default useCaseList;
