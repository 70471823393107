import getQueryString from 'utils/getQueryString';
import { dictionaryClient } from './client';

export const getMedicalProducts = ({
  query,
  keyword,
}: {
  query?: any;
  keyword: string;
}) => {
  const queryString = getQueryString(query);
  return dictionaryClient.get(
    `/api/v1/mfds-medical-products?keyword=${keyword}&${queryString}`,
  );
};

export const getSubstances = ({
  query,
  keyword,
}: {
  query?: any;
  keyword: string;
}) => {
  const queryString = getQueryString(query);
  return dictionaryClient.get(
    `/api/v1/mfds-substances?keyword=${keyword}&${queryString}`,
  );
};
