import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { logout } from 'api/auth';
import client from 'api/client';

import { coreState, companyState, userState } from 'state';

import { UserType } from 'types/user';
import { Company } from 'types/company';
import { Language, Region } from 'types/core';

import storage from 'utils/storage';
import tokenTimer from 'utils/tokenTimer';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'components/common/Dropdown';

const languageList: {
  code: Language;
  name: string;
}[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
];

const dropItemStyles = clsx(
  'group cursor-pointer my-1 px-3 py-1 hover:bg-slate-100',
  'dark:hover:bg-slate-900',
);

const regionStyles: { [key in Region]: string } = {
  MFDS: 'bg-orange-500 text-xxs',
  EMA: 'bg-cyan-500 text-xs',
};

function SidebarUser() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const [user] = useRecoilState(userState);
  const [{ selected: company, list: companyList }, setCompany] =
    useRecoilState(companyState);
  const [{ language, region, regionList }, setCore] = useRecoilState(coreState);

  const [open, setOpen] = useState(false);

  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  const toggle = () => setOpen((prev) => !prev);

  const handleCompany = (compy: Company) => {
    setCompany((prev) => ({ ...prev, selected: compy }));
    navigate(`/${compy.companyName}/home`);
  };

  const handleLanguage = (lang: Language) => {
    client.getInstance().defaults.headers.common['Accept-Language'] = lang;
    i18n.changeLanguage(lang);
    setCore((prev) => ({
      ...prev,
      language: lang,
    }));
    storage.setItem('csafer_language', lang);
    moment.locale(lang);
  };

  const handleRegion = (reg: Region) => {
    client.getInstance().defaults.headers.common['X-REGULATOR-AUTHORITY-TYPE'] =
      reg;
    setCore((prev) => ({
      ...prev,
      region: reg,
    }));
    storage.setItem('csafer_region', reg);
  };

  return (
    <div className="px-4 pt-3 pb-2">
      <Dropdown isOpen={open} toggle={toggle}>
        <DropdownToggle className="flex h-9 w-full min-w-[2.25rem] items-center overflow-hidden">
          <div
            className={clsx(
              'h-9 w-9 flex-shrink-0 rounded text-center font-bold leading-9 text-white',
              user?.userType === UserType.manager
                ? 'bg-brand-500 text-base'
                : regionStyles[region],
            )}
          >
            {user?.userType === UserType.manager ? 'S' : region}
          </div>
          <div className="ml-2 flex h-9 flex-col overflow-hidden text-left leading-4">
            <div
              className={clsx(
                'flex h-1/2 items-center text-slate-800',
                'dark:text-slate-200',
              )}
            >
              <span className="block h-full truncate">
                {user?.userType === UserType.manager
                  ? 'SAFETY'
                  : company?.companyName}
              </span>
              <IoChevronDown
                className={clsx(
                  'ml-1 mt-[0.25rem] text-slate-800',
                  'dark:text-slate-200',
                )}
                fontSize="0.75rem"
              />
            </div>
            <div className="h-1/2 truncate text-slate-400">
              {user?.userName}
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="mt-1 !w-[16rem]">
          <ul
            className={clsx(
              'min-w-none rounded border border-slate-200 bg-white py-2 text-slate-600 shadow',
              'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
            )}
          >
            {user?.userType === UserType.user && (
              <>
                <DropdownItem
                  className={clsx(dropItemStyles, 'pointer-events-none m-0')}
                >
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        'my-1 h-9 w-9 flex-shrink-0 rounded text-center font-bold leading-9 text-white',
                        regionStyles[region],
                      )}
                    >
                      {region}
                    </div>
                    <div
                      className={clsx(
                        'ml-2 flex-1 items-center truncate text-slate-800',
                        'dark:text-slate-200',
                      )}
                    >
                      {company?.companyName}
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem className={dropItemStyles}>
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        'flex-1 items-center text-slate-800',
                        'dark:text-slate-200',
                      )}
                    >
                      Switch Company
                    </div>
                    <IoChevronForward fontSize="0.875rem" />
                    <div className="min-w-none absolute top-12 -right-48 hidden w-48 -translate-x-1 p-1 pl-2 group-hover:block">
                      <ul
                        className={clsx(
                          'rounded border border-slate-200 bg-white py-2 shadow',
                          'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
                        )}
                      >
                        {companyList?.map((compy) => (
                          <li
                            key={compy.id}
                            className={clsx(
                              'cursor-pointer px-2 py-1 hover:bg-slate-100',
                              'dark:hover:bg-slate-900',
                              compy.id === company?.id &&
                                'bg-slate-100 dark:bg-slate-900',
                            )}
                          >
                            <button
                              className="w-full text-left"
                              onClick={() => handleCompany(compy)}
                            >
                              {compy.companyName}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem className={dropItemStyles}>
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        'flex-1 items-center text-slate-800',
                        'dark:text-slate-200',
                      )}
                    >
                      Change Region
                    </div>
                    <IoChevronForward fontSize="0.875rem" />
                    <div className="min-w-none absolute top-[5.25rem] -right-48 hidden w-48 -translate-x-1 p-1 pl-2 group-hover:block">
                      <ul
                        className={clsx(
                          'rounded border border-slate-200 bg-white py-2 shadow',
                          'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
                        )}
                      >
                        {regionList.map(({ region: reg }) => (
                          <li
                            key={reg}
                            className={clsx(
                              'cursor-pointer px-2 py-1 hover:bg-slate-100',
                              'dark:hover:bg-slate-900',
                              region === reg &&
                                'bg-slate-100 dark:bg-slate-900',
                            )}
                          >
                            <button
                              className="w-full text-left"
                              onClick={() => handleRegion(reg)}
                            >
                              {reg}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </DropdownItem>
                <div className="my-2 h-px w-full bg-slate-200" />
                <DropdownItem className={dropItemStyles}>
                  <NavLink to="/profile">
                    <div className="flex items-center">
                      <div className="h-9 w-9 flex-shrink-0 rounded-full bg-blue-700 text-center font-bold leading-9 text-white">
                        {user?.userName
                          .split(' ')
                          .slice(0, 2)
                          .map((str) => str.at(0))}
                      </div>
                      <div
                        className={clsx(
                          'ml-2 flex-1 items-center text-slate-800',
                          'dark:text-slate-200',
                        )}
                      >
                        {user?.userName}
                      </div>
                    </div>
                  </NavLink>
                </DropdownItem>
                <DropdownItem className={dropItemStyles}>
                  <div className="flex items-center">
                    <div
                      className={clsx(
                        'flex-1 items-center text-slate-800',
                        'dark:text-slate-200',
                      )}
                    >
                      Language
                    </div>
                    <IoChevronForward fontSize="0.875rem" />
                    <div className="min-w-none absolute top-[11.275rem] -right-48 hidden w-48 -translate-x-1 p-1 pl-2 group-hover:block">
                      <ul
                        className={clsx(
                          'rounded border border-slate-200 bg-white py-2 shadow',
                          'dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300',
                        )}
                      >
                        {languageList.map(({ code, name }) => (
                          <li
                            key={code}
                            className={clsx(
                              'cursor-pointer px-2 py-1 hover:bg-slate-100',
                              'dark:hover:bg-slate-900',
                              language === code &&
                                'bg-slate-100 dark:bg-slate-900',
                            )}
                          >
                            <button
                              className="w-full text-left"
                              onClick={() => handleLanguage(code)}
                            >
                              {name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </DropdownItem>
              </>
            )}
            <DropdownItem className={dropItemStyles}>
              <button
                className="w-full text-left"
                onClick={() => handleLogout()}
              >
                Logout
              </button>
            </DropdownItem>
          </ul>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default SidebarUser;
