import getQueryString from 'utils/getQueryString';
import client from './client';

export const getCodeSets = ({
  oid,
  version,
  language,
}: {
  oid?: string;
  version?: string;
  language?: string;
}) => {
  const query =
    (oid ? `oid=${oid}` : '') +
    (version ? `version=${version}` : '') +
    (language ? `language=${language}` : '');
  return client.get(`/api/v1/icsr-code-sets?${query}`);
};

export const getCases = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/icsr-summaries?companyId=${companyId}&${queryString}`,
  );
};

export const getCase = (id?: number | string) =>
  client.get(`/api/v1/icsrs/${id}`);

export const postCase = (data: any) => client.post('/api/v1/icsrs', data);

export const putCase = ({ id, data }: { id?: number | string; data: any }) =>
  client.put(`/api/v1/icsrs/${id}`, data);

export const deleteCase = (id?: number | string) =>
  client.delete(`/api/v1/icsrs/${id}`);

export const deleteCases = (ids: Array<any>) =>
  client.delete(`/api/v1/icsrs?ids=${ids.toString()}`);

export const getFollowUps = ({
  companyId,
  caseId,
}: {
  companyId?: number | string;
  caseId?: number | string;
}) =>
  client.get(
    `/api/v1/icsr-summaries/${caseId}/follow-ups?companyId=${companyId}`,
  );

export const postFollowUp = (id?: number | string) =>
  client.post(`/api/v1/icsrs/${id}/follow-ups`);

export const putCasesAction = (data: any) =>
  client.put('/api/v1/icsrs/actions', data);

export const putCaseActions = ({
  caseId,
  data,
}: {
  caseId?: number | string;
  data: any;
}) => client.put(`/api/v1/icsrs/${caseId}/actions`, data);

export const getReceivers = (id: number | string) =>
  client.get(`/api/v1/icsrs/${id}/icsr-receivers`);

export const putReceivers = (id: number | string, data: any) =>
  client.put(`/api/v1/icsrs/${id}/icsr-receivers`, data);

export const getCaseSubmissionHistories = (id?: number | string) =>
  client.get(`/api/v1/icsrs/${id}/submissions`);

export const getCaseVersion = (id: number | string) =>
  client.get(`/api/v1/icsrs/${id}/version`);

export const fileUpload = (formData: FormData) =>
  client.post('/api/v1/files', formData);
