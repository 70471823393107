import React from 'react';
import { IoClose } from 'react-icons/io5';
import { TbAlertTriangle, TbInfoCircle } from 'react-icons/tb';

import { ActionType } from 'utils/modal';

const icon: { [key: string]: JSX.Element } = {
  info: <TbInfoCircle className="text-blue-600" fontSize="1.5rem" />,
  warn: <TbAlertTriangle className="text-red-600" fontSize="1.5rem" />,
};
export const buttonStyles: { [key: string]: string } = {
  info: 'inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto',
  warn: 'inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto',
};

function Confirm({
  title,
  message,
  confirm,
  toggle,
}: {
  title: string;
  message: string;
  confirm: ActionType;
  toggle: () => void;
}) {
  const [label, action, close, type = 'info'] = confirm;
  return (
    <div className="fixed top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            {icon[type]}
          </div>
          <div className="mt-3 flex-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex justify-between">
              <h3
                className=" text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {title}
              </h3>
              <button onClick={toggle}>
                <IoClose
                  className="text-slate-500 hover:text-inherit"
                  fontSize="1.5rem"
                />
              </button>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {message.split('\n').map((str: string) => (
                  <span className="inline-block" key={str}>
                    {str}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="button"
          className={buttonStyles[type]}
          onClick={() => {
            action();
            if (close) toggle();
          }}
        >
          {label}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={toggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default Confirm;
