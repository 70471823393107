import getQueryString from 'utils/getQueryString';
import client from './client';

export const getHistories = ({
  type,
  companyId,
  query,
}: {
  type: string;
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/transform/${type}/histories?companyId=${companyId}&${queryString}`,
  );
};

export const getTrackerHistories = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/tracker-reports?companyId=${companyId}&${queryString}`,
  );
};

export const getHistoriesDetails = ({
  type,
  historyId,
  query,
}: {
  type: string;
  historyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/transform/${type}/histories/${historyId}/details?${queryString}`,
  );
};
