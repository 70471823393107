import React from 'react';
import clsx from 'clsx';

import { useCaseState } from './context/CaseContext';

const styles = 'w-full bg-red-700 px-2 font-semibold text-base text-white';
function ErrorField({
  className,
  parentId,
  elementId,
  elementNumber,
}: {
  className?: string;
  parentId?: number;
  elementId?: number;
  elementNumber: string;
}) {
  const { validationMessages } = useCaseState();

  if (!elementNumber) return null;
  if (!validationMessages[elementNumber]) return null;

  if (!elementId) {
    return (
      validationMessages[elementNumber]?.messages.map(
        ({ message, parent }: any, idx: number) =>
          parentId === parent && (
            <div
              key={idx}
              className={clsx(className, styles)}
            >{`*${message}`}</div>
          ),
      ) || null
    );
  }

  return (
    validationMessages[elementNumber][elementId]?.messages.map(
      ({ message }: any, idx: number) => (
        <div key={idx} className={clsx(className, styles)}>{`*${message}`}</div>
      ),
    ) || null
  );
}

export default ErrorField;
