export interface TrackerColumn {
  id: number;
  columnSpec: string;
  name: string;
  order: number;
  visible?: boolean;
}

export const TrackerStatus = [
  { value: 'READY', label: 'READY' },
  { value: 'SUCCESS', label: 'SUCCESS' },
  { value: 'PROCESSING', label: 'PROCESSING' },
  { value: 'FAIL', label: 'FAIL' },
];

export const TrackerDataStatus = {
  CREATED: 'CREATED',
  PROGRESS: 'DATA_UPDATE_IN_PROGRESS',
  COMPLETE: 'DATA_UPDATE_COMPLETE',
} as const;
