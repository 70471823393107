import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getDatasets, postDataset } from 'api/dataset';
import { getReporters } from 'api/reporters';
import { getSenders } from 'api/senders';
import { getStudies } from 'api/studies';

import { DatasetForm, EDCSetupType } from 'types/dataset';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import Button from 'components/common/Button';
import FileUpload from 'components/common/FileUpload';
import FormWrapper from 'components/common/FormWrapper';
import Input from 'components/common/Input';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';

const EDCSetupTypeList = Object.entries(EDCSetupType).map(([, value]) => value);

function CreateEDCSetup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } =
    useForm<DatasetForm>({
      defaultValues: { settingName: '', reporterIds: [] },
    });

  const { data: defaultsDataset = [] } = useQuery(['getDefaultsDataset'], () =>
    getDatasets({}).then(({ data }) => data.content),
  );

  const { data: studies = [] } = useQuery(['getStudies'], () =>
    getStudies({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { data: senders = [] } = useQuery(['getSenders'], () =>
    getSenders({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { data: reporters = [] } = useQuery(['getReporters'], () =>
    getReporters({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(postDataset, {
    onSuccess({ data: imoprtSettingId }) {
      navigate(`../${imoprtSettingId}`);
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      const { files, ...rest } = data;
      const formData = new FormData();
      [].forEach.call(files, (file) => {
        formData.append('specFile', file);
      });
      mutate({
        companyId: company?.id,
        ...rest,
        regulatory: 'MFDS',
        formData,
      });
    }
  });

  useEffect(() => {
    register({ name: 'settingName' }, { required: t('error:required') });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'studyId' });
    register({ name: 'senderId' });
    register({ name: 'reporterIds' });
    register({ name: 'files' }, { required: t('error:required') });
    register({ name: 'defaultSettingId' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Dataset"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <form className="max-w-4xl" onSubmit={onSubmit}>
        <div className="space-y-1 pt-1">
          <div className="flex py-4 px-2">
            <div className="required w-1/2">Rule Name</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                value={form.settingName}
                onChange={(e) => setValue('settingName', e.target.value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.settingName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="required w-1/2">Type</div>
            <div className="flex w-1/2 flex-wrap">
              <Select
                className="w-full"
                value={form.type}
                data={EDCSetupTypeList}
                onChange={(type: any) => setValue('type', type)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.type?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">DB Spec.</div>
            <div className="flex w-1/2 flex-wrap">
              <FileUpload
                className="w-full"
                value={form.files}
                onChange={(value) => setValue('files', value)}
                accept=".xlsx"
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.files?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Study</div>
            <div className="w-1/2">
              <Select
                className="w-full"
                value={form.studyId}
                data={studies}
                valueField="id"
                textField="sponsorStudyNumber"
                onChange={(obj) => setValue('studyId', obj.id)}
              />
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Sender</div>
            <div className="w-1/2">
              <Select
                className="w-full"
                value={form.senderId}
                data={senders}
                valueField="id"
                textField="organizationName"
                onChange={(obj) => setValue('senderId', obj.id)}
              />
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Reporter</div>
            <div className="w-1/2">
              <MultiSelect
                className="w-full"
                value={form.reporterIds}
                data={reporters}
                valueField="id"
                textField="givenName"
                onChange={(obj) =>
                  setValue(
                    'reporterIds',
                    obj.map((el: any) => el.id),
                  )
                }
              />
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Default Setting</div>
            <div className="w-1/2">
              <Select
                className="w-full"
                value={form.defaultSettingId}
                data={defaultsDataset}
                valueField="id"
                textField="name"
                onChange={(obj) => setValue('defaultSettingId', obj.id)}
              />
            </div>
          </div>
        </div>
        <div className="my-4 space-x-1 text-right">
          <Button type="submit">Create</Button>
        </div>
      </form>
    </FormWrapper>
  );
}

export default CreateEDCSetup;
