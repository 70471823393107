import React from 'react';
import { TbLink } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';

import { Icsr } from 'types/approval';

function ICSRListTable({ icsrs }: { icsrs: Icsr[] }) {
  return (
    <table className="relative -mt-0.5 w-full">
      <thead>
        <tr className="border-b border-slate-500 text-left [&>th]:!h-auto [&>th]:!p-1">
          <th>Case No.</th>
          <th>Recent Information Date</th>
          <th>Product</th>
          <th className="w-20 text-center">Link</th>
        </tr>
      </thead>
      <tbody>
        {icsrs?.map(
          (
            {
              id,
              sendersSafetyReportUniqueIdentifier,
              dateOfMostRecentInformationForThisReport,
              productNames,
            },
            index,
          ) => (
            <tr
              key={index}
              className="border-b border-slate-500 [&>td]:!h-auto [&>td]:!p-1"
            >
              <td>{sendersSafetyReportUniqueIdentifier}</td>
              <td>{dateOfMostRecentInformationForThisReport}</td>
              <td>{productNames}</td>
              <td className="text-center" width="80px">
                <NavLink to={`../../cases/${id}`}>
                  <TbLink className="mx-auto hover:opacity-60" />
                </NavLink>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
}

export default ICSRListTable;
