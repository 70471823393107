import React from 'react';
import { TbPlus, TbTrash } from 'react-icons/tb';
import { MdRestore } from 'react-icons/md';
import { useRecoilState } from 'recoil';

import { codeSetsState } from 'state';

import { Study } from 'types/study';
import { Product } from 'types/product';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Textarea from 'components/common/Textarea';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import RadioGroup from 'components/common/RadioGroup';
import Radio from 'components/common/Radio';

interface props {
  submitLabel: string;
  form: Study;
  errors: any;
  products?: Product[];
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
  changeStudy: (key: string, value: any) => void;
  addRegistration: () => void;
  deleteRegistration: (index: number) => void;
  restoreRegistration?: (index: number) => void;
  changeRegistration: (key: string, value: any, index: number) => void;
}

function StudyForm({
  submitLabel,
  form,
  errors,
  products,
  onSubmit,
  changeStudy,
  addRegistration,
  deleteRegistration,
  restoreRegistration = () => null,
  changeRegistration,
}: props) {
  const {
    productIdList,
    registrations,
    sponsorStudyNumber,
    studyName,
    studyType,
  } = form;

  const [codeSets] = useRecoilState(codeSetsState);

  return (
    <form className="max-w-6xl" onSubmit={onSubmit}>
      <div className="space-y-8 pt-1">
        <div className="space-y-1">
          <div className="flex py-4 px-2">
            <div className="w-1/2">Product</div>
            <div className="flex w-1/2 flex-wrap">
              <MultiSelect
                className="w-full"
                value={productIdList}
                data={products || []}
                valueField="id"
                textField={(dataItem: any) =>
                  dataItem?.brandName || dataItem?.productName || dataItem
                }
                onChange={(obj) =>
                  changeStudy(
                    'productIdList',
                    obj.map(({ id }: Product) => id),
                  )
                }
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.productIdList?.message}
              </span>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2">Registrations</h2>
          <div className="flex bg-slate-100">
            <table className="w-full table-fixed">
              <thead className="border-b-4 border-white text-left [&_th]:font-medium">
                <tr className="text-center [&>th]:p-2">
                  <th>Study Registration Number (C.5.1.r.1)</th>
                  <th>Study Registration Country (C.5.1.r.2)</th>
                  <th className="w-14">
                    <button
                      type="button"
                      className="mx-auto flex"
                      onClick={addRegistration}
                    >
                      <TbPlus />
                    </button>
                  </th>
                </tr>
              </thead>
              {registrations.map(({ number, country, deleted }, index) => (
                <tbody key={index} className="group border-b-4 border-white">
                  <tr className="bg-inherit [&>td]:p-2">
                    <td>
                      <Input
                        className="w-full"
                        value={number}
                        onChange={(e) =>
                          changeRegistration('number', e.target.value, index)
                        }
                      />
                    </td>
                    <td>
                      <Select
                        className="w-full"
                        value={country}
                        data={codeSets[codeSets.oidMap.countries]?.codes || []}
                        valueField="key"
                        textField="value"
                        onChange={(val) =>
                          changeRegistration('country', val.key, index)
                        }
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="mx-auto flex"
                        onClick={() =>
                          deleted
                            ? restoreRegistration(index)
                            : deleteRegistration(index)
                        }
                      >
                        {deleted ? (
                          <MdRestore className="text-slate-600 hover:text-slate-500" />
                        ) : (
                          <TbTrash className="text-red-600 hover:text-red-500" />
                        )}
                      </button>
                    </td>
                  </tr>
                  <tr className="h-1 bg-inherit" />
                </tbody>
              ))}
            </table>
          </div>
        </div>
        <div className="space-y-1">
          <div className="flex py-4 px-2">
            <div className="w-1/2">Study Name (C.5.2)</div>
            <div className="flex w-1/2 flex-wrap">
              <Textarea
                className="w-full"
                value={studyName}
                minRows={3}
                maxRows={10}
                maxLength={255}
                onChange={(e) => changeStudy('studyName', e.target.value)}
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.studyName?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">Sponsor Study Number (C.5.3)</div>
            <div className="flex w-1/2 flex-wrap">
              <Input
                className="w-full"
                maxLength={255}
                value={sponsorStudyNumber}
                onChange={(e) =>
                  changeStudy('sponsorStudyNumber', e.target.value)
                }
              />
              <span className="inline-block h-0 w-full text-sm text-red-600">
                {errors.sponsorStudyNumber?.message}
              </span>
            </div>
          </div>
          <div className="flex py-4 px-2">
            <div className="w-1/2">
              Study Type Where Reaction(s) / Event(s) Were Observed (C.5.4)
            </div>
            <div className="w-1/2">
              <RadioGroup
                className="space-y-2"
                name="study-type"
                value={studyType}
                onChange={(val) => changeStudy('studyType', val)}
                enableClickReset
              >
                <Radio value="1" label="1:Pharmaceutical Company" />
                <Radio value="2" label="2:Regulatory Authority" />
                <Radio value="3" label="3:Health Professional" />
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="submit">{submitLabel}</Button>
      </div>
    </form>
  );
}

export default StudyForm;
