import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TbChevronRight } from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { userState } from 'state';

import useGoBack from 'hooks/useGoBack';

import { Tab, TabPanel, Tabs } from '../common/Tab';
import AccountDetails from './AccountDetails';
import ChangePassword from './ChangePassword';

function Profile() {
  const goBack = useGoBack();

  const [user] = useRecoilState(userState);

  const [tabValue, setTabValue] = useState(0);

  return (
    <div className="flex h-[var(--mainHeight)] w-screen flex-col">
      <div className="border-b bg-cyan-400 p-4 drop-shadow">
        <div className="relative mx-auto h-32 max-w-4xl">
          <div className="absolute -bottom-16 left-8 flex h-32 w-32 items-center justify-center rounded-full border-2 border-white bg-blue-700 text-6xl text-white shadow">
            {user?.userName
              .split(' ')
              .slice(0, 2)
              .map((str) => str.at(0))}
          </div>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-4xl flex-1 divide-x pt-16 pb-8">
        <div className="w-56 px-8">
          <nav className="flex items-center space-x-1 pb-1 text-lg font-semibold">
            <button
              className="text-slate-500 hover:underline"
              onClick={() => goBack()}
            >
              Back
            </button>
            <TbChevronRight className="text-slate-500" />
            <NavLink to="#" className="pointer-events-none">
              Profile
            </NavLink>
          </nav>
          <Tabs
            className="flex flex-col space-x-0 py-2"
            value={tabValue}
            onChange={(value) => {
              setTabValue(value);
            }}
          >
            {['Account Details', 'Change Password'].map((label, index) => (
              <Tab
                key={index}
                index={index}
                className="py-1 text-left [&>div]:hidden"
              >
                {label}
              </Tab>
            ))}
          </Tabs>
        </div>
        <div className="flex-1 px-8">
          <TabPanel value={tabValue} index={0}>
            <AccountDetails />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ChangePassword />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default Profile;
