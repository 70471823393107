import React from 'react';

import { Member } from 'types/member';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { DatePicker } from 'components/common/DatePicker';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import { Product } from 'types/product';
import { Study } from 'types/study';
import { Role } from 'types/role';

interface props {
  submitLabel: string;
  check?: boolean;
  form: Member;
  roles: Role[];
  products: Product[];
  studies: Study[];
  errors: any;
  checkAvailability?: () => void;
  resendEmail?: () => void;
  onChange: (key: string, value: any) => void;
  onSubmit: (e: React.SyntheticEvent<Element, Event>) => Promise<void>;
}

function MemberForm({
  submitLabel,
  check,
  form,
  roles,
  products,
  studies,
  errors,
  checkAvailability = () => null,
  resendEmail = () => null,
  onChange,
  onSubmit,
}: props) {
  const {
    userName,
    email,
    phone,
    startDate,
    endDate,
    roleId,
    productIdList,
    studyIdList,
  } = form;

  return (
    <div className="max-w-4xl">
      <div className="space-y-8 pt-1">
        <div>
          <h2 className="mb-2">MEMBER INFO</h2>
          <div className="space-y-1">
            <div className="flex py-4 px-2">
              <div className="required w-1/2">Email</div>
              <div className="flex w-1/2 flex-wrap">
                <Input
                  className="w-full flex-1"
                  value={email}
                  onChange={(e) => onChange('email', e.target.value)}
                />
                {submitLabel === 'Create' ? (
                  <Button
                    className="ml-1"
                    color="slate"
                    onClick={checkAvailability}
                    disabled={!email || !!errors.email}
                    outline
                  >
                    Check
                  </Button>
                ) : (
                  <Button
                    className="ml-1"
                    color="slate"
                    onClick={resendEmail}
                    outline
                  >
                    Resend
                  </Button>
                )}
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.email?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">Name</div>
              <div className="flex w-1/2 flex-wrap">
                <Input
                  className="w-full"
                  value={userName}
                  onChange={(e) => onChange('userName', e.target.value)}
                  disabled={!check && submitLabel === 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.userName?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">Phone</div>
              <div className="flex w-1/2 flex-wrap">
                <Input
                  className="w-full"
                  value={phone}
                  onChange={(e) => onChange('phone', e.target.value)}
                  disabled={!check && submitLabel === 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.phone?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">Start Date</div>
              <div className="flex w-1/2 flex-wrap">
                <DatePicker
                  className="w-full"
                  value={startDate}
                  format="YYYY-MM-DD"
                  onChange={(date) =>
                    onChange(
                      'startDate',
                      date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                    )
                  }
                  disabled={!check && submitLabel === 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.startDate?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">End Date</div>
              <div className="flex w-1/2 flex-wrap">
                <DatePicker
                  className="w-full"
                  value={endDate}
                  format="YYYY-MM-DD"
                  onChange={(date) =>
                    onChange(
                      'endDate',
                      date?.isValid() ? date.format('YYYY-MM-DD') : undefined,
                    )
                  }
                  disabled={!check && submitLabel === 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.endDate?.message}
                </span>
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="required w-1/2">Role</div>
              <div className="flex w-1/2 flex-wrap">
                <Select
                  className="w-full"
                  value={roleId}
                  data={roles}
                  valueField="id"
                  textField="name"
                  onChange={(obj: any) => onChange('roleId', obj.id)}
                  disabled={!check && submitLabel === 'Create'}
                />
                <span className="inline-block h-0 w-full text-sm text-red-600">
                  {errors.roleId?.message}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2">MEMBER AUTHORITY</h2>
          <div className="space-y-1">
            <div className="flex py-4 px-2">
              <div className="w-1/2">Product(s)</div>
              <div className="w-1/2">
                <MultiSelect
                  className="w-full"
                  value={productIdList}
                  data={products}
                  valueField="id"
                  textField={(item) =>
                    item.brandName || item.productName || item
                  }
                  onChange={(obj: any) =>
                    onChange(
                      'productIdList',
                      obj.map((el: any) => el.id),
                    )
                  }
                  disabled={!check && submitLabel === 'Create'}
                />
              </div>
            </div>
            <div className="flex py-4 px-2">
              <div className="w-1/2">Study(ies)</div>
              <div className="w-1/2">
                <MultiSelect
                  className="w-full"
                  value={studyIdList}
                  data={studies}
                  valueField="id"
                  textField="sponsorStudyNumber"
                  onChange={(obj: any) =>
                    onChange(
                      'studyIdList',
                      obj.map((el: any) => el.id),
                    )
                  }
                  disabled={!check && submitLabel === 'Create'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 text-right">
        <Button type="button" onClick={onSubmit}>
          {submitLabel}
        </Button>
      </div>
    </div>
  );
}

export default MemberForm;
