import React from 'react';

import { ICSRForm } from 'types/case';

import { TabPanel } from 'components/common/Tab';

import Identification from './Identification';
import PrimaryInformation from './PrimaryInformation';
import Sender from './Sender';
import LiteratureReference from './LiteratureReference';
import Study from './Study';
import PatientCharacteristics from './PatientCharacteristics';
import ReactionEvent from './ReactionEvent';
import TestResult from './TestResult';
import Drug from './Drug';
import Narrative from './Narrative';

function EMA({
  tabValue,
  version,
  form,
  sponsorStudyNumber,
  linkedStudy,
  disabled,
  setForm,
  handleVersion,
}: {
  tabValue: number;
  version: number;
  form: ICSRForm;
  sponsorStudyNumber?: string;
  linkedStudy?: string;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  return (
    <>
      <TabPanel value={tabValue} index={0}>
        <Identification
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PrimaryInformation
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Sender
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <LiteratureReference
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Study
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <PatientCharacteristics
          version={version}
          form={form}
          sponsorStudyNumber={sponsorStudyNumber}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <ReactionEvent
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <TestResult
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <Drug
          version={version}
          form={form}
          reactionEvents={form['E.i']}
          linkedStudy={linkedStudy}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={9}>
        <Narrative
          version={version}
          form={form}
          disabled={disabled}
          setForm={setForm}
          handleVersion={handleVersion}
        />
      </TabPanel>
    </>
  );
}

export default EMA;
