import client from './client';

export const getProfile = (id?: number | string) =>
  client.get(`/api/v1/my/${id}`);

export const putProfile = (data: any) =>
  client.put(`/api/v1/my/${data.id}`, data);

export const postChangePassword = (data: {
  userId?: number | string;
  userPw?: string;
}) => client.post(`/api/v1/my/${data.userId}/password`, data);

export const postConfirmPassword = (data: {
  userId?: number | string;
  userPw?: string;
}) => client.post(`/api/v1/my/${data.userId}/verify`, data);
