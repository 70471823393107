import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';

import EventManager from 'utils/eventManager';

const EV = new EventManager();

export const requestStart = () => EV.emit(true);
export const requestEnd = () => EV.emit(false);

function Request() {
  const [isOpen, dispatch] = useState(false);

  useEffect(() => {
    EV.on(dispatch);
    return () => {};
  }, []);

  return isOpen ? (
    <div className="fixed top-0 left-0 z-dropdown flex h-screen w-screen items-center justify-center bg-gray-500/75">
      <ThreeDots
        height="120"
        width="120"
        ariaLabel="tail-spin-loading"
        color="#6C63FF"
        radius="1"
        visible
      />
    </div>
  ) : null;
}

export default Request;
