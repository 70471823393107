import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getAEListDefaultValues,
  getProductDefaultValues,
  getSubstanceDefaultValues,
  postProduct,
} from 'api/products';

import useForm from 'hooks/useForm';
import modal from 'utils/modal';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import ProductForm from './ProductForm';

function CreateProduct() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const {
    form,
    errors,
    register,
    clearError,
    setError,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: getProductDefaultValues(company?.id),
  });

  const { mutate } = useMutation(postProduct, {
    onSuccess() {
      navigate('../products', { replace: true });
    },
  });

  const changeProduct = (key: string, value: any) => {
    setValue(key, value);
  };

  const addSubstance = () => {
    setValue(
      'substances',
      produce(form.substances, (draft) => {
        draft.push(getSubstanceDefaultValues());
      }),
    );
  };

  const deleteSubstance = (index: number) => {
    setValue(
      'substances',
      produce(form.substances, (draft) =>
        draft.filter((_officer, idx) => index !== idx),
      ),
    );
  };

  const changeSubstance = (key: string, value: any, index: number) => {
    setValue(
      'substances',
      produce(form.substances, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const addAECodeList = () => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) => {
        draft.push(getAEListDefaultValues());
      }),
    );
  };

  const deleteAECodeList = (index: number) => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) =>
        draft.filter((_officer, idx) => index !== idx),
      ),
    );
  };

  const changeAECodeList = (key: string, value: any, index: number) => {
    setValue(
      'aeCodeList',
      produce(form.aeCodeList, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'companyId' });
    register(
      { name: 'productName' },
      {
        validate: (value: any, originForm: any) => {
          if (value || originForm.brandName) {
            clearError('brandName');
            return true;
          }
          setError('brandName', 'disabled', t('error:requiredProductName'));
          return t('error:requiredProductName');
        },
      },
    );
    register(
      { name: 'brandName' },
      {
        validate: (value: any, originForm: any) => {
          if (value || originForm.productName) {
            clearError('productName');
            return true;
          }
          setError('productName', 'disabled', t('error:requiredProductName'));
          return t('error:requiredProductName');
        },
      },
    );
    register({ name: 'description' });
    register({ name: 'substances' });
    register({ name: 'aeCodeList' });
  }, [clearError, register, setError, t]);

  return (
    <FormWrapper
      title="Create Product"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <ProductForm
        submitLabel="Create"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        changeProduct={changeProduct}
        addSubstance={addSubstance}
        deleteSubstance={deleteSubstance}
        changeSubstance={changeSubstance}
        addAECodeList={addAECodeList}
        deleteAECodeList={deleteAECodeList}
        changeAECodeList={changeAECodeList}
      />
    </FormWrapper>
  );
}

export default CreateProduct;
