import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';

import { getUsers } from 'api/users';

import useList from 'hooks/useList';
import { User, UserType } from 'types/user';
import { ListHead } from 'types/list';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

const listHeads: ListHead[] = [
  {
    name: 'email',
    label: 'Email',
    sort: true,
  },
  {
    name: 'userName',
    label: 'Name',
    sort: true,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'End Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'userType',
    label: 'User Type',
    sort: true,
    filter: true,
    type: 'select',
    data: Object.entries(UserType).map(([key, value]) => ({ key, value })),
    valueField: 'value',
    textField: 'value',
    width: '12rem',
  },
  {
    name: 'companyName',
    label: 'Company Admin',
    sort: true,
  },
  {
    name: 'edit',
    style: { width: '5rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [
      { name: 'email', label: 'Email' },
      { name: 'userName', label: 'Name' },
    ],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function UserList() {
  const location = useLocation();

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
  } = useList({
    queryKey: 'users',
    queryFn: (q) => getUsers({ query: q }),
  });

  return (
    <MainTemplate>
      <Header title="User List" />
      <Content className="flex flex-col overflow-hidden pt-1">
        <div className="-ml-1 flex justify-between py-2">
          <FilterBox
            filter={query.q}
            filterList={filterList}
            setFilter={setFilter}
          />
          <div className="flex items-center pl-4 ">
            <NavLink to="./new" state={{ prevUrl: location.pathname }}>
              <Button color="blue" size="sm">
                Create
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="overflow-auto bg-inherit">
          <LTable className="table-fixed">
            <LTHead>
              <LTR type="head">
                {listHeads.map(({ name, label, sort, style }) => (
                  <LTH key={name} style={style}>
                    {sort ? (
                      <SortColumn
                        name={name}
                        order={query.order}
                        orderBy={query.orderBy}
                        onChange={changeSort}
                      >
                        {label}
                      </SortColumn>
                    ) : (
                      label
                    )}
                  </LTH>
                ))}
              </LTR>
            </LTHead>
            <LTBody
              isFetching={isFetching}
              rows={data?.length || query.perPage}
              cols={listHeads.length}
              disableCheckbox
            >
              {data?.map(
                ({
                  id,
                  userName,
                  email,
                  startDate,
                  endDate,
                  userType,
                  companyName,
                }: User) => (
                  <LTR key={id} type="body">
                    <LTD>{email}</LTD>
                    <LTD>{userName}</LTD>
                    <LTD>{startDate}</LTD>
                    <LTD>{endDate}</LTD>
                    <LTD>{userType}</LTD>
                    <LTD>{companyName}</LTD>
                    <LTD>
                      <NavLink
                        to={`./${id}`}
                        state={{ prevUrl: location.pathname }}
                        className="flex items-center hover:opacity-60"
                        color="green"
                      >
                        <TbEdit className="mr-1" /> Edit
                      </NavLink>
                    </LTD>
                  </LTR>
                ),
              )}
            </LTBody>
          </LTable>
        </div>
        <Pagination
          page={query.page}
          perPage={query.perPage}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          onChangePerPage={changePerPage}
          refresh={refetch}
        />
      </Content>
    </MainTemplate>
  );
}

export default UserList;
