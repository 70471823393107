import { Sender, SendingOfficer } from 'types/sender';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getSendingOfficerDeafultValues = (
  selected?: boolean,
): SendingOfficer => ({
  department: '',
  title: '',
  givenName: '',
  middleName: '',
  familyName: '',
  street: '',
  city: '',
  province: '',
  postCode: '',
  countryCode: '',
  telephone: '',
  fax: '',
  email: '',
  selected: !!selected,
  deleted: false,
});

export const getSenderDefaultValues = (companyId = -1): Sender => ({
  companyId,
  authorityGateways: {
    MFDS: {
      senderId: '',
      userId: '',
      authKey: '',
    },
    EMA: {
      senderId: '',
    },
  },
  type: '',
  healthProfessionalType: '',
  organizationName: '',
  sendingOfficers: [getSendingOfficerDeafultValues(true)],
  deleted: false,
  version: 0,
});

export const getSenders = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/senders?companyId=${companyId}&${queryString}`);
};

export const getSender = (senderId?: number | string) =>
  client.get(`/api/v1/senders/${senderId}`);

export const postSender = (data: Sender) =>
  client.post('/api/v1/senders', data);

export const putSender = (data: Sender) =>
  client.put(`/api/v1/senders/${data.id}`, data);

export const deleteSenders = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/senders?${queryString}`);
};
