export interface Case {
  id?: number;
  reactionEventTerms?: string;
  reactionEventsSeriousness?: string;
  sendersSafetyReportUniqueIdentifier?: string;
  typeOfReport?: string;
  dateOfCreation?: string;
  dateOfMostRecentInformationForThisReport?: string;
  sponsorStudyNumber?: string;
  status?: string;
  nonReporting?: boolean;
  productNames?: string;
  whoUmcResultsOfAssessment?: string;
  krctResultsOfAssessment?: string;
  regulatoryAuthorityReportingDeadline?: string;
  reportClassification?: string;
  lastSubmittedDate?: string;
  validated?: string;
  deleted: boolean;
}

export const CaseStatus = {
  WRITING_IN_PROGRESS: 'WRITING_IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  APPROVAL_IN_PROGRESS: 'APPROVAL_IN_PROGRESS',
  APPROVED: 'APPROVED',
  SUBMITTED: 'SUBMITTED',
  SUBMIT_FAILED: 'SUBMIT_FAILED',
  PROCESSING_FAILED: 'PROCESSING_FAILED',
  PROCESSING_COMPLETED: 'PROCESSING_COMPLETED',
} as const;

export const CaseAction = {
  SUBMIT: 'SUBMIT',
  VALIDATION: 'VALIDATION',
  WRITING_COMPLETE: 'WRITING_COMPLETE',
  BACK_TO_WRITING: 'BACK_TO_WRITING',
  RESTORE: 'RESTORE',
} as const;

export const CaseValidationType = {
  COMPLETE: 'COMPLETE',
  APPROVE: 'APPROVE',
  DELETE: 'DELETE',
  RESTORE: 'RESTORE',
  SEND: 'SEND',
  EXPORT: 'EXPORT',
  RESET: 'RESET',
} as const;

export const CaseValidationResult = {
  REQUIRED_PERMISSION: 'REQUIRED_PERMISSION',
  EMPTY: 'EMPTY',
  SUCCESS: 'SUCCESS',
  WRONG_STATUS: 'WRONG_STATUS',
  DELETED: 'DELETED',
  ALREADY_COMPLETED: 'ALREADY_COMPLETED',
  NUMBER_LIMIT: 'NUMBER_LIMIT',
  ONLY_VALIDATION_SUCCESS_CASE: 'ONLY_VALIDATION_SUCCESS_CASE',
  ONLY_REPORT_CASE: 'ONLY_REPORT_CASE',
} as const;

export type ValidationMessage = {
  messageNumber: string;
  message: string;
};

export type NullFlavor = 'NI' | 'MSK' | 'ASKU' | 'NASK' | 'UNK' | 'NA';

export interface ICSRElement {
  id?: number;
  number?: string;
  value?: string;
  nullFlavor?: NullFlavor;
  description?: string;
  translatedValue?: string;
  validationMessages?: ValidationMessage[];
  deleted?: boolean;
}

export interface ICSRRootElement {
  id?: number;
  number: string;
  elements: (ICSRRootElement | ICSRElement)[];
  validationMessages?: ValidationMessage[];
  numberOfValidationErrors?: number;
  deleted?: boolean;
}

export interface ICSRFormElement {
  id?: number;
  number?: string;
  value?: string;
  nullFlavor?: NullFlavor;
  description?: string;
  translatedValue?: string;
  deleted?: boolean;
}
export interface ICSRFormRootElement {
  id?: number;
  number?: string;
  elements: {
    [key in string]:
      | ICSRFormRootElement[]
      | ICSRFormRootElement
      | ICSRFormElement;
  };
  validationMessages?: ValidationMessage[];
  numberOfValidationErrors?: number;
  deleted?: boolean;
}

export interface ApprovalLine {
  id: number;
  sequence: number;
  approverName: string;
  status: string;
  action: string;
  comment: string;
  actionDate: string;
}

export interface FollowUp {
  id: number;
  dateOfMostRecentInformationForThisReport: string;
  deleted: boolean;
}

export interface LatestApproval {
  id: number;
  icsrIds: number[];
  approvalLines: {
    id: number;
    sequence: number;
    approverName: string;
    status: string;
    action: string;
    comment: null | string;
    actionDate: string;
  }[];
}
export interface ICSR {
  id: number;
  regionalImplementationGuide: string;
  studyId: null | number;
  productIds: number[];
  nonReporting: boolean;
  status: keyof typeof CaseStatus;
  elements: ICSRRootElement[];
  validationMessages: ValidationMessage[];
  latestApproval: null | LatestApproval;
  deleted: boolean;
  validationResult: { succeeded: boolean };
  version: number;
}

export type ICSRFormType =
  | ICSRFormElement
  | ICSRFormRootElement
  | ICSRFormRootElement[]
  | undefined;

export interface ICSRForm {
  [x: string]: ICSRFormType;
}

export interface CaseSubmissionHistory {
  id: number;
  submissionNo: string;
  submissionDate: string;
  status: keyof typeof CaseStatus;
}
