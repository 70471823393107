import React from 'react';
import Content from 'components/Content';
import Header from 'components/Header';
import { NavItemType } from 'components/nav/NavItems';
import MainTemplate from 'components/template/MainTemplate';

interface props {
  title: string;
  breadcrumbs?: NavItemType[];
  children?: React.ReactNode;
}

function FormWrapper({ title, breadcrumbs, children }: props) {
  return (
    <MainTemplate>
      <Header title={title} breadcrumbs={breadcrumbs} />
      <Content className="flex flex-col overflow-hidden">
        <div className="flex h-full flex-col">
          <div className="dropdown-container z-0 -mr-4 flex h-full flex-col overflow-auto pr-4">
            {children}
          </div>
        </div>
      </Content>
    </MainTemplate>
  );
}

export default FormWrapper;
