import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import produce from 'immer';

import { coreState, companyState } from 'state';

import { getCaseForm } from 'utils/caseForm';
import { convertICSR } from 'utils/convertICSRForm';

import { postCase } from 'api/cases';
import { getStudies } from 'api/studies';
import { getProducts } from 'api/products';

import { Study } from 'types/study';
import { Product } from 'types/product';

import Popup from 'components/common/Popup';
import Button from 'components/common/Button';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import Input from 'components/common/Input';
import { DatePicker } from 'components/common/DatePicker';
import Checkbox from 'components/common/Checkbox';

function CreateCasePopup() {
  const navigate = useNavigate();

  const [{ selected: company }] = useRecoilState(companyState);
  const [{ region, regionList }] = useRecoilState(coreState);

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prev) => !prev);

  const [
    { productIds, studyId, caseNumber, recentInformationDate, nonReporting },
    setState,
  ] = useState<{
    productIds: number[];
    studyId?: number;
    caseNumber: string;
    recentInformationDate?: string;
    nonReporting?: boolean;
  }>({
    productIds: [],
    caseNumber: '',
    nonReporting: false,
  });

  const { data: products = [] } = useQuery(
    ['getProducts'],
    () =>
      getProducts({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: studies = [] } = useQuery(
    ['getStudies'],
    () =>
      getStudies({
        companyId: company?.id,
        query: { perPage: 0, q: { deleted: ['false'] } },
      }).then(({ data }) => data.content),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate } = useMutation(postCase, {
    onSuccess({ data: icsrId }) {
      navigate(`./${icsrId}`);
    },
  });

  const filteredStudies = useMemo(() => {
    return studies.filter((study: any) =>
      productIds.every((productId: number) =>
        study.productIdList.includes(productId),
      ),
    );
  }, [productIds, studies]);

  const handleSubmit = () => {
    const data = convertICSR({
      companyId: company?.id,
      form: produce(getCaseForm(), (draft: any) => {
        draft['C.1'].elements['C.1.1'] = { value: caseNumber };
        draft['C.1'].elements['C.1.8.1'] = { value: caseNumber };
        draft['C.1'].elements['C.1.5'] = { value: recentInformationDate };
      }),
      nonReporting,
      productIds,
      studyId,
      regionalImplementationGuide: regionList.find((r) => r.region === region)
        ?.regionalImplementationGuide,
    });
    mutate(data);
  };

  return (
    <>
      <Button color="blue" size="sm" onClick={toggle}>
        Create
      </Button>
      <Popup isOpen={open} className="w-[40rem]">
        <div className="border-b border-brand-600 py-2 font-semibold">
          Create Case
        </div>
        <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
          <div>
            <div className="w-1/2">
              <em>Product(s)</em>
            </div>
            <div className="flex-1">
              <MultiSelect
                value={productIds}
                data={products}
                valueField="id"
                textField={(dataItem: any) =>
                  dataItem?.brandName || dataItem?.productName || dataItem
                }
                onChange={(value: Product[]) => {
                  setState((prev) => ({
                    ...prev,
                    productIds: value.map((p) => p.id as number),
                    studyId: undefined,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="w-1/2">
              <em>Study</em>
            </div>
            <div className="flex-1">
              <Select
                value={studyId}
                data={filteredStudies}
                valueField="id"
                textField="sponsorStudyNumber"
                onChange={(value: Study) => {
                  setState((prev) => ({
                    ...prev,
                    studyId: value.id,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="w-1/2">
              <em>{`Sender's (case) Safety Report Unique Identifier (C.1.1)`}</em>
            </div>
            <div className="flex-1">
              <Input
                className="w-full"
                value={caseNumber}
                maxLength={100}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState((prev) => ({
                    ...prev,
                    caseNumber: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="w-1/2">
              <em>Date of Most Recent Information for This Report (C.1.5)</em>
            </div>
            <div className="flex-1">
              <DatePicker
                value={recentInformationDate}
                format="YYYYMMDD"
                allowedFormat={[
                  'YYYYMMDDHHmmssZZ',
                  'YYYYMMDDHHmmss',
                  'YYYYMMDDHHmm',
                  'YYYYMMDDHH',
                  'YYYYMMDD',
                ]}
                onChange={(date) => {
                  if (date?.isValid()) {
                    setState((prev) => ({
                      ...prev,
                      recentInformationDate: date.format('YYYYMMDD'),
                    }));
                  } else {
                    setState((prev) => ({
                      ...prev,
                      recentInformationDate: undefined,
                    }));
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className="w-1/2">
              <em>Non Reporting</em>
            </div>
            <div className="flex flex-1 items-center">
              <Checkbox
                checked={nonReporting}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState((prev) => ({
                    ...prev,
                    nonReporting: e.target.checked,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="space-x-2 py-2 text-right">
          <Button onClick={toggle} color="gray" outline>
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={handleSubmit}
            disabled={
              !productIds.length || !caseNumber || !recentInformationDate
            }
          >
            Create
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default CreateCasePopup;
