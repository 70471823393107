import { Reporter } from 'types/reporter';
import getQueryString from 'utils/getQueryString';
import client from './client';

export const getReporterDefaultValues = (companyId = -1): Reporter => ({
  companyId,
  organizationName: '',
  department: '',
  title: '',
  givenName: '',
  middleName: '',
  familyName: '',
  countryCode: '',
  postCode: '',
  province: '',
  city: '',
  street: '',
  email: '',
  telephone: '',
  fax: '',
  qualification: '',
  primarySource: '',
  deleted: false,
  version: 0,
});

export const getReporters = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(`/api/v1/reporters?companyId=${companyId}&${queryString}`);
};

export const getReporter = (reporterId?: number | string) =>
  client.get(`/api/v1/reporters/${reporterId}`);

export const postReporter = (data: any) =>
  client.post('/api/v1/reporters', data);

export const putReporter = (data: any) =>
  client.put(`/api/v1/reporters/${data.id}`, data);

export const deleteReporters = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(`/api/v1/reporters?${queryString}`);
};
