import EventManager from 'utils/eventManager';

export type ActionType =
  | [string, () => void, boolean, string]
  | [string, () => void, boolean];

export const EV = new EventManager();

const modal = () => {};

modal.alert = (title: string, message: string) =>
  new Promise((resolve: any) => {
    EV.emit({
      type: 'open',
      data: {
        type: 'alert',
        isOpen: true,
        title,
        message,
        backToggle: true,
        close: () => resolve(null),
      },
    });
  });

modal.confirm = (title: string, message: string, type?: 'info' | 'warn') =>
  new Promise((resolve: any) => {
    EV.emit({
      type: 'open',
      data: {
        type: 'confirm',
        isOpen: true,
        title,
        message,
        confirm: ['Ok', () => resolve(true), true, type],
        close: () => resolve(null),
      },
    });
  });

modal.custom = ({
  actions,
  icon,
  ...rest
}: {
  title: string;
  message: string | JSX.Element | React.ReactNode;
  actions: ActionType[];
  icon?: JSX.Element | React.ReactNode;
}) =>
  new Promise((resolve: any) => {
    EV.emit({
      type: 'open',
      data: {
        type: 'custom',
        isOpen: true,
        custom: {
          actions,
          icon,
        },
        ...rest,
        close: () => resolve(null),
      },
    });
  });

modal.reset = () =>
  EV.emit({
    type: 'reset',
  });

export default modal;
