import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import modal from 'utils/modal';

import Checkbox from 'components/common/Checkbox';
import Select from 'components/common/Select/Select';
import Button from 'components/common/Button';

import { useCaseAction, useCaseState } from './context/CaseContext';
import CaseConfigPopup from './popup/CaseConfigPopup';
import { CaseConfig } from './EditCase';
import CaseEditActions from './CaseEditActions';

function CaseBase({
  config,
  setConfig,
}: {
  config: CaseConfig;
  setConfig: React.Dispatch<React.SetStateAction<CaseConfig>>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { caseId, followUpList } = useCaseState();
  const { createFollowUpCase } = useCaseAction();

  const handleConfig = (name: string, value: any) => {
    setConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateFollowUpCase = async () => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:followUp'))
    ) {
      createFollowUpCase();
    }
  };

  const followUpTextField = useCallback(
    (
      { dateOfMostRecentInformationForThisReport, deleted }: any,
      index: number,
    ) => (
      <div
        className={clsx(
          deleted &&
            "relative opacity-60 before:absolute before:top-1/2 before:left-0 before:h-px before:w-full before:bg-red-600 before:content-['']",
        )}
      >{`#${index + 1} ${dateOfMostRecentInformationForThisReport}`}</div>
    ),
    [],
  );

  return (
    <div className="z-10 my-2 flex justify-between bg-white px-2">
      <div className="-ml-1 flex min-w-0 flex-1 flex-wrap items-center py-2 [&>*]:m-1 [&>*]:max-w-full">
        <div className="flex items-center">
          <Select
            size="sm"
            className="w-40 [&>.control]:rounded-tr-none [&>.control]:rounded-br-none [&>.control]:border-r-0"
            value={caseId}
            data={followUpList}
            valueField="id"
            textField={followUpTextField}
            onChange={({ id }) => navigate(`../${id}/follow-up`)}
            readOnly
          />
          <Button
            className="rounded-tl-none rounded-bl-none border-l-0"
            size="sm"
            onClick={handleCreateFollowUpCase}
          >
            F/R
          </Button>
        </div>
        <button
          className="control-sm inline-flex cursor-pointer items-center rounded border border-slate-300"
          onClick={() => handleConfig('nonReporting', !config.nonReporting)}
        >
          <Checkbox
            checked={config.nonReporting}
            className="pointer-events-none mr-2 inline-flex [&_label]:text-sm"
            label="Non Report"
            onChange={() => {}}
          />
        </button>
        {/* <button className="control-sm inline-flex items-center rounded border border-slate-300">
          <Checkbox
            defaultChecked
            className="pointer-events-none mr-2 inline-flex"
          />
          <span className="text-sm">Alarm</span>
        </button> */}
        <CaseConfigPopup config={config} handleConfig={handleConfig} />
      </div>
      <CaseEditActions />
    </div>
  );
}

export default CaseBase;
