import qs from 'qs';
import client from './client';

const Authorization = process.env.REACT_APP_API_AUTHORIZATION;

export const login = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) =>
  client.post(
    '/api/v1/oauth/token',
    qs.stringify({
      grant_type: 'password',
      username,
      password,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${Authorization}`,
      },
    },
  );

export const updateToken = () =>
  client.put(
    '/api/v1/oauth/token',
    qs.stringify({
      grant_type: 'refresh_token',
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${Authorization}`,
      },
    },
  );

export const logout = () =>
  client.delete('/api/v1/oauth/token', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const getAccount = ({ email }: { email: string }) =>
  client.get(`/api/v1/accounts/${email}`);

export const getAccountByToken = ({ token }: { token: string | null }) =>
  client.get(`/api/v1/accounts/tokens/${token}`);

export const putAccount = ({
  token,
  email,
  ...rest
}: {
  token: string | null;
  email: string;
}) =>
  client.put(
    `/api/v1/accounts/${email}`,
    { email, ...rest },
    {
      headers: {
        Authorization: token,
      },
    },
  );

export const postResetPassword = ({ email }: { email: string }) =>
  client.post(`/api/v1/accounts/${email}/password/reset`);

export const putResetPassword = ({
  token,
  email,
  password,
}: {
  token: string | null;
  email: string;
  password: string;
}) =>
  client.put(
    `/api/v1/accounts/${email}/password/reset`,
    {
      password,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );
