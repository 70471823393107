import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { checkEmail, getMemberDefaultValues, postMember } from 'api/members';
import { getProducts } from 'api/products';
import { getStudies } from 'api/studies';
import { getRoles } from 'api/roles';

import { RoleType } from 'types/role';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import MemberForm from './MemberForm';

function CreateMember() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const [check, setCheck] = useState(false);
  const { form, errors, register, setValue, setError, handleSubmit } = useForm({
    defaultValues: getMemberDefaultValues(company?.id),
  });

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );
  const { data: studies } = useQuery(['getStudies'], () =>
    getStudies({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );
  const { data: roles } = useQuery(['getRoles'], () =>
    getRoles({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(checkEmail, {
    onSuccess({ data }) {
      setValue('userName', data.userName);
      setValue('phone', data.phone);
      setCheck(true);
    },
    onError(err: AxiosError<any>) {
      if (err.response?.status === 404) {
        setCheck(true);
      } else {
        setError(
          'email',
          'disabled',
          err.response?.data?.message || err.message,
        );
      }
    },
    onMutate() {},
    onSettled() {},
  });

  const { mutate: submit } = useMutation(postMember, {
    onSuccess() {
      navigate('../members', { replace: true });
    },
  });

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const checkAvailability = () =>
    mutate({ companyId: company?.id, email: form.email });

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      submit(data);
    }
  });

  const filterdRoles = useMemo(
    () => roles?.filter((role: any) => role.type !== RoleType.ADMIN),
    [roles],
  );

  useEffect(() => {
    setCheck(false);
  }, [form.email]);

  useEffect(() => {
    register({ name: 'companyId' }, { required: t('error:required') });
    register(
      { name: 'email' },
      {
        required: t('error:required'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: t('error:invalidEmail'),
        },
      },
    );
    register({ name: 'userName' }, { required: t('error:required') });
    register({ name: 'phone' }, { required: t('error:required') });
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' }, { required: t('error:required') });
    register({ name: 'roleId' }, { required: t('error:required') });
    register({ name: 'productIdList' });
    register({ name: 'studyIdList' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Member"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <MemberForm
        submitLabel="Create"
        check={check}
        form={form}
        errors={errors}
        roles={filterdRoles || []}
        products={products || []}
        studies={studies || []}
        checkAvailability={checkAvailability}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default CreateMember;
