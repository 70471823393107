/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import moment from 'moment';

import { getCaseSubmissionHistories } from 'api/cases';
import { CaseStatus, CaseSubmissionHistory } from 'types/case';

import Tooltip from 'components/common/Tooltip';
import Button from 'components/common/Button';

import { useCaseState } from './context/CaseContext';

const iconStyles: { [key in keyof typeof CaseStatus]: string } = {
  SUBMITTED: 'bg-green-500',
  SUBMIT_FAILED: 'bg-red-500',
  PROCESSING_FAILED: 'bg-red-500',
  PROCESSING_COMPLETED: 'bg-green-500',
  WRITING_IN_PROGRESS: 'bg-slate-500',
  COMPLETED: 'bg-slate-500',
  APPROVAL_IN_PROGRESS: 'bg-slate-500',
  APPROVED: 'bg-slate-500',
};

function History({
  data = [],
  close,
}: {
  data: CaseSubmissionHistory[];
  close: () => void;
}) {
  return (
    <div className="text-slate-600">
      <h3 className="mb-3 text-xs font-bold">SUBMISSION HISTORY</h3>
      <div className="flow-root">
        <ul>
          {data.map(({ id, status, submissionDate }) => (
            <li key={id}>
              <div className="relative pb-4">
                <span className="absolute left-2 top-[18px] -ml-px h-[12px] w-0.5 bg-slate-200" />
                <div className="relative flex items-center justify-center space-x-2">
                  <div
                    className={clsx(
                      'flex h-4 w-4 items-center justify-center rounded-full',
                      iconStyles[status] || 'bg-slate-500',
                    )}
                  >
                    <div className="h-2 w-2 rounded-full bg-white" />
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-2 text-xs font-semibold">
                    <span>{status}</span>
                    <span>
                      {moment(submissionDate.split('[')[0]).format(
                        'YYYY-MM-DD HH:mm:ss Z',
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-3 border-t pt-2 text-right">
        <Button size="sm" color="red" onClick={close} outline>
          Close
        </Button>
      </div>
    </div>
  );
}

function SubmissionHistory({ children }: { children?: React.ReactNode }) {
  const { caseId } = useCaseState();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  const { data } = useQuery(
    ['getSubmissionHistory'],
    () => getCaseSubmissionHistories(caseId).then(({ data }) => data),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Tooltip
      isOpen={isOpen}
      className="z-dropdown -translate-x-4 -translate-y-1.5 border shadow"
      style={{ background: 'white' }}
      opacity={1}
      render={() => <History data={data} close={toggle} />}
      noArrow
    >
      <button onClick={toggle}>{children}</button>
    </Tooltip>
  );
}

export default SubmissionHistory;
