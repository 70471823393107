import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import { getAccountByToken, putAccount } from 'api/auth';
import useForm from 'hooks/useForm';
import Input from 'components/common/Input';
import Button from 'components/common/Button';

function RegisterPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const {
    form,
    register,
    setValue,
    errors,
    setError,
    clearError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      token,
      email: '',
      username: '',
      phone: '',
      password: '',
      passwordRep: '',
      userType: '',
      managerFlag: 0,
    },
  });

  useQuery(['register'], () => getAccountByToken({ token }), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess({ email }: any) {
      setValue('email', email);
    },
  });

  const { mutate } = useMutation(putAccount, {
    onSuccess() {
      navigate('/login');
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onSubmit = handleSubmit(async (data) => mutate(data));

  useEffect(() => {
    register({ name: 'id' }, { required: t('error:required') });
    register(
      { name: 'email' },
      {
        required: t('error:required'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: t('error:invalidEmail'),
        },
      },
    );
    register({ name: 'userName' }, { required: t('error:required') });
    register({ name: 'phone' }, { required: t('error:required') });
    register(
      { name: 'password' },
      {
        required: t('error:required'),
        pattern: {
          value:
            /^.*(?=^.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/,
          message: t('error:irregularPassword') as string,
        },
        validate: (value: any, _form: any) => {
          if (_form.passwordRep && value !== _form.passwordRep) {
            setError('passwordRep', 'disabled', t('error:passwordNotMatch'));
          } else {
            clearError('passwordRep');
          }
          return true;
        },
      },
    );
    register(
      { name: 'passwordRep' },
      {
        validate: (value: any, _form: any) =>
          value === _form.password || (t('error:passwordNotMatch') as string),
      },
    );
    register({ name: 'userType' });
    register({ name: 'managerFlag' });
  }, [clearError, register, setError, t]);

  return (
    <div className="flex h-[var(--mainHeight)] min-h-[600px] w-screen flex-col items-center justify-center bg-slate-200 dark:bg-slate-900">
      <div className="flex max-w-min flex-row rounded-lg bg-white p-10 shadow-lg dark:bg-slate-800">
        <form className="w-[380px]" onSubmit={onSubmit}>
          <div className="flex flex-col">
            <h1 className="tex-left text-4xl font-bold dark:text-white">
              Welcome,
            </h1>
            <span className="mb-4 text-left text-base text-slate-400">
              It only takes a{' '}
              <span className="text-green-400">few seconds</span>
              &nbsp;to create your account.
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              className="w-full"
              placeholder="Email"
              value={form.email}
              onChange={handleChange}
              error={errors.email}
            />
            <span
              className={clsx(
                errors.email ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.email?.message}
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              className="w-full"
              placeholder="Name"
              value={form.username}
              onChange={handleChange}
              error={errors.username}
            />
            <span
              className={clsx(
                errors.username ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.username?.message}
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              className="w-full"
              placeholder="Phone"
              value={form.phone}
              onChange={handleChange}
              error={errors.phone}
            />
            <span
              className={clsx(
                errors.phone ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.phone?.message}
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="password"
              className="w-full"
              placeholder="Password"
              value={form.password}
              onChange={handleChange}
              error={errors.password}
            />
            <span
              className={clsx(
                errors.password ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.password?.message}
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="password"
              className="w-full"
              placeholder="Repeat Password"
              value={form.passwordRep}
              onChange={handleChange}
              error={errors.passwordRep}
            />
            <span
              className={clsx(
                errors.passwordRep ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.passwordRep?.message}
            </span>
          </div>
          <div className="mb-4 py-2 text-left dark:text-white ">
            Already have an account?
            <Link
              to="/login"
              className="ml-2 font-semibold text-brand-400 hover:text-brand-500"
            >
              Login
            </Link>
          </div>
          <div>
            <Button className="w-full" type="submit">
              Sign up
            </Button>
          </div>
        </form>
      </div>
      <div className="mt-4 text-slate-500 opacity-80">
        Copyright &copy; C&R Research
      </div>
    </div>
  );
}

export default RegisterPage;
