import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { TbPlus, TbTrash } from 'react-icons/tb';
import { MdOutlineRestore } from 'react-icons/md';

import Checkbox from 'components/common/Checkbox';

import { useCaseState } from './context/CaseContext';

function Repeat({
  className,
  selected,
  list = [],
  data = [],
  handleSelect,
  handleAdd,
  handleDelete,
  disabled,
  children,
}: {
  className?: string;
  selected: number;
  list: any[];
  data?: any[];
  handleSelect: (i: number) => void;
  handleAdd: () => void;
  handleDelete: (i: number) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}) {
  const { validationMessages, memoMessages } = useCaseState();

  const contentRef = useRef<HTMLInputElement>(null);
  const [showDeletedItem, setShowDeletedItem] = useState(false);

  const handleRowClick = useCallback(
    (idx: number) => {
      if (contentRef.current) contentRef.current.focus();
      handleSelect(idx);
    },
    [handleSelect],
  );

  const handleDeletedOption = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setShowDeletedItem(e.target.checked),
    [],
  );

  const filteredData = useMemo(
    () => data.filter(({ deleted }) => showDeletedItem || !deleted),
    [data, showDeletedItem],
  );

  return (
    <div className={clsx('mb-16 flex w-full flex-col', className)}>
      <Checkbox
        className="justify-end [&_label]:flex-none"
        label="Show deleted items"
        checked={showDeletedItem}
        onChange={handleDeletedOption}
      />
      <div className="-ml-4 pl-4">
        <table className="w-full table-fixed border-collapse border border-l-0 text-gray-500">
          <thead className="bg-white [&_th]:font-semibold [&_th:not(:first-child)]:border [&_th:not(:first-child)]:p-2">
            <tr>
              <th className="w-0 p-0" />
              {list.map(({ label }) => (
                <th key={label}>{label}</th>
              ))}
              <th className="w-10 !p-0">
                <button
                  className="p-3"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    handleAdd();
                  }}
                  disabled={disabled}
                >
                  <TbPlus />
                </button>
              </th>
            </tr>
          </thead>
          <tbody className="min-h-[10rem] [&_td]:truncate [&_td]:py-2 [&_td:not(:first-child)]:border [&_td:not(:first-child)]:px-2">
            {filteredData.length ? (
              filteredData.map((ele, idx) => {
                const validationCount =
                  validationMessages[ele.number]?.[ele.id]?.count || 0;
                const memoCount =
                  memoMessages[ele.number]?.[ele.id]?.count || 0;

                return (
                  <tr
                    key={idx}
                    className={clsx(
                      'relative cursor-pointer hover:bg-brand-300',
                      idx === selected && 'bg-brand-300',
                      ele.deleted &&
                        'before:absolute before:left-0 before:top-1/2 before:h-px before:w-full before:bg-red-600 before:px-0',
                    )}
                    onClick={() => handleRowClick(idx)}
                  >
                    <td className="absolute -top-4 -left-2.5 p-0">
                      <div className="space-x-px">
                        {validationCount ? (
                          <div className="inline-block rounded-full bg-red-500 px-2 text-xs text-white">
                            {validationCount}
                          </div>
                        ) : null}
                        {memoCount ? (
                          <div className="inline-block rounded-full bg-yellow-500 px-2 text-xs text-white">
                            {memoCount}
                          </div>
                        ) : null}
                      </div>
                    </td>
                    {list.map(
                      ({
                        elementNumber,
                        component,
                        data: datasets,
                        valueField,
                        textField,
                      }) => {
                        if (
                          component === 'select' ||
                          component === 'combobox' ||
                          component === 'radio'
                        ) {
                          const result = datasets?.find((dataset: any) => {
                            return (
                              dataset[valueField] ===
                              ele.elements[elementNumber]?.value
                            );
                          });
                          return (
                            <td key={elementNumber}>
                              {result && result[textField]}
                            </td>
                          );
                        }
                        if (component === 'file') {
                          return (
                            <td key={elementNumber}>
                              {ele.elements[elementNumber]?.description}
                            </td>
                          );
                        }
                        if (component === 'MedDRASearch') {
                          const code = ele.elements[elementNumber]?.value;
                          const term = ele.elements[elementNumber]?.description;
                          return (
                            <td key={elementNumber}>
                              {term ? `${code}(${term})` : code}
                            </td>
                          );
                        }
                        return (
                          <td key={elementNumber}>
                            {ele.elements[elementNumber]?.value}
                          </td>
                        );
                      },
                    )}
                    <td className="!p-0 text-center">
                      <button
                        className="p-3"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          handleDelete(data.findIndex((item) => item === ele));
                        }}
                        disabled={disabled}
                      >
                        {ele.deleted ? <MdOutlineRestore /> : <TbTrash />}
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td />
                <td colSpan={list.length + 1}>
                  <div className="flex h-20 w-full items-center justify-center">
                    No Items
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="repeat-content dropdown-container mt-3 flex w-full flex-shrink-0 flex-grow-0 flex-wrap border p-2">
        <div ref={contentRef} className="absolute opacity-0" />
        {children}
        {selected === -1 && (
          <div className="absolute top-0 left-0 z-10 h-full w-full bg-white opacity-50" />
        )}
      </div>
    </div>
  );
}

export default Repeat;
