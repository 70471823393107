import React from 'react';
import { IoClose } from 'react-icons/io5';
import { TbDownload, TbLink } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getSubmissionsDetails } from 'api/submission';

import { companyState } from 'state';
import useList from 'hooks/useList';
import { ICSRMessageACKCode, SubmissionDetail } from 'types/submission';

import Popup from 'components/common/Popup';
import Pagination from 'components/common/Pagination';
import Download from 'components/common/Donwload';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

function SubmissionPopup({
  isOpen,
  submissionId,
  toggle,
}: {
  isOpen: boolean;
  submissionId?: number;
  toggle: () => void;
}) {
  const [{ selected: company }] = useRecoilState(companyState);
  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    refetch,
    changePage,
  } = useList({
    queryKey: 'submissionDetails',
    queryFn: (q) =>
      getSubmissionsDetails({ submissionId, companyId: company?.id, query: q }),
    options: {
      enabled: isOpen,
    },
  });

  return (
    <Popup isOpen={isOpen} className="w-[70rem]">
      <div className="flex items-center justify-between border-b border-brand-600 py-2 font-semibold">
        Submission Detail
        <button className="hover:opacity-50" onClick={toggle}>
          <IoClose />
        </button>
      </div>
      <div className="mb-2 overflow-auto bg-inherit py-2">
        <LTable className="table-fixed">
          <LTHead>
            <LTR type="head">
              <LTH>Receiver</LTH>
              <LTH>Case No.</LTH>
              <LTH>Product</LTH>
              <LTH>Status</LTH>
              <LTH className="w-20 text-center">Error Report</LTH>
              <LTH className="w-20 text-center">Link</LTH>
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={6}
            disableCheckbox
          >
            {data?.map(
              ({
                id,
                sendersSafetyReportUniqueIdentifier,
                icsrMessageACKSender,
                status,
                productNames,
                referenceICSRId,
              }: SubmissionDetail) => (
                <LTR key={id} type="body">
                  <LTD>{icsrMessageACKSender}</LTD>
                  <LTD>{sendersSafetyReportUniqueIdentifier}</LTD>
                  <LTD>{productNames}</LTD>
                  <LTD>{status}</LTD>
                  <LTD className="text-center">
                    {status === ICSRMessageACKCode.reject && (
                      <Download
                        src={`${process.env.REACT_APP_API_HOST}/api/v1/submissions/${submissionId}/icsr-message-acknowledgement/${id}/error-report?companyId=${company?.id}`}
                        className="hover:opacity-50"
                      >
                        <TbDownload className="mx-auto" />
                      </Download>
                    )}
                  </LTD>
                  <LTD className="text-center">
                    <NavLink to={`./../cases/${referenceICSRId}`}>
                      <TbLink className="mx-auto hover:opacity-60" />
                    </NavLink>
                  </LTD>
                </LTR>
              ),
            )}
          </LTBody>
        </LTable>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Pagination
          page={query.page}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          refresh={refetch}
          disabledChangeSize
        />
      </div>
    </Popup>
  );
}

export default SubmissionPopup;
