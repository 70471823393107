import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import {
  getNotificationDefaultValues,
  postNotification,
} from 'api/notifications';

import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import NotificationForm from './NotificationForm';

function CreateNotification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { form, setValue, handleSubmit } = useForm({
    defaultValues: getNotificationDefaultValues(),
  });

  const { mutate: submit } = useMutation(postNotification, {
    onSuccess() {
      navigate('..', { replace: true });
    },
  });

  const handleChange = (key: string, value: any) => setValue(key, value);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      submit(data);
    }
  });

  return (
    <FormWrapper
      title="Create Notification"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <NotificationForm
        submitLabel="Create"
        form={form}
        onChange={handleChange}
        onSubmit={onSubmit}
      />
    </FormWrapper>
  );
}

export default CreateNotification;
