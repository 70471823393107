export default {
  check: [{ value: 'true', label: '예' }],
  boolean: [
    { value: 'true', label: '예' },
    { value: 'false', label: '아니오' },
  ],
  primarySource: [{ value: '1', label: '원보고자' }],
  userType: [
    { value: 'SAFETY_MANAGER', label: 'System Admin' },
    { value: 'MEMBER_USER', label: 'User' },
  ],
  receiverType: [
    { value: 'REGULATORY_AUTHORITY', label: '규제당국' },
    { value: 'OTHER', label: '기타' },
  ],
  receiverId_MFDS: [
    { value: 'CT', label: '임상시험국내' },
    { value: 'CF', label: '임상시험국외' },
    { value: 'CU', label: '치료목적' },
    { value: 'KR', label: '시판후국내' },
    { value: 'FR', label: '시판후국외' },
  ],
  expectedness: [
    { value: '1', label: '예상되는' },
    { value: '2', label: '예상하지 못한' },
  ],
};
