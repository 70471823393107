import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import clsx from 'clsx';

import { deleteCases, getCases } from 'api/cases';

import useList from 'hooks/useList';
import modal from 'utils/modal';
import { Case, CaseValidationResult, CaseValidationType } from 'types/case';

import { coreState, companyState } from 'state';

import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import SortColumn from 'components/common/SortColumn';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';
import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';
import ArrayString from 'components/common/ArrayString';

import useCaseList from './hooks/useCaseList';
import useCaseValidate from './hooks/useCaseValidate';
import CaseListActions from './CaseListActions';
import CreateCasePopup from './popup/CreateCasePopup';

function CaseList() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [{ region }] = useRecoilState(coreState);
  const [{ selected: company }] = useRecoilState(companyState);
  const { listHeads, filterList, filterDataSets } = useCaseList();

  const { checkCasesValidate } = useCaseValidate();

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    selected,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    handleDelete,
  } = useList({
    queryKey: 'cases',
    refreshKey: region,
    queryFn: (q) => getCases({ companyId: company?.id, query: q }),
    deleteFn: (s) => deleteCases(s),
  });

  const checkValidate = (type: keyof typeof CaseValidationType) => {
    const cases = data.filter(({ id }: Case) => id && selected.includes(id));
    const result = checkCasesValidate(type, cases);
    if (result === CaseValidationResult.SUCCESS) {
      return true;
    }
    modal.alert(t('modal:alert:title'), t(`modal:alert:${result}`));
    return false;
  };

  const exportCallback = async () => {
    if (
      await modal.confirm(
        t('modal:confirm:title'),
        t('modal:confirm:goExportPage'),
      )
    ) {
      navigate('./../history/exports');
    } else {
      refetch();
    }
  };

  const approvalCallback = () => {
    reset();
    refetch();
  };

  const submissionCallback = () => {
    refetch();
  };

  return (
    <MainTemplate>
      <Header title="Case" />
      <Content className="flex flex-col overflow-hidden pt-1">
        <div className="-ml-1 flex justify-between py-2">
          <FilterBox
            filter={query.q}
            filterList={filterList}
            setFilter={setFilter}
          />
          <div className="flex items-center space-x-2">
            <CaseListActions
              icsrIds={selected}
              checkValidate={checkValidate}
              handleDelete={handleDelete}
              exportCallback={exportCallback}
              approvalCallback={approvalCallback}
              submissionCallback={submissionCallback}
            />
            <CreateCasePopup />
          </div>
        </div>
        <div className="overflow-auto bg-inherit">
          <LTable className="table-fixed">
            <LTHead>
              <LTR
                type="head"
                selected={data?.length === selected.length}
                select={(checked) => (checked ? selectAll() : reset())}
              >
                {listHeads.map(({ name, label, sort, style }) => (
                  <LTH key={name} style={style}>
                    {sort ? (
                      <SortColumn
                        name={name}
                        order={query.order}
                        orderBy={query.orderBy}
                        onChange={changeSort}
                      >
                        {label}
                      </SortColumn>
                    ) : (
                      label
                    )}
                  </LTH>
                ))}
              </LTR>
            </LTHead>
            <LTBody
              isFetching={isFetching}
              rows={data?.length || query.perPage}
              cols={listHeads.length}
            >
              {data?.map(
                ({
                  id,
                  reactionEventTerms,
                  reactionEventsSeriousness,
                  sendersSafetyReportUniqueIdentifier,
                  typeOfReport,
                  dateOfCreation,
                  dateOfMostRecentInformationForThisReport,
                  sponsorStudyNumber,
                  status,
                  productNames,
                  whoUmcResultsOfAssessment,
                  krctResultsOfAssessment,
                  regulatoryAuthorityReportingDeadline,
                  reportClassification,
                  lastSubmittedDate,
                  validated,
                  deleted,
                }: Case) => (
                  <LTR
                    key={id}
                    type="body"
                    selected={selected.findIndex((item) => item === id) !== -1}
                    select={(checked) => select(id, checked)}
                    deleted={deleted}
                  >
                    <LTD>
                      {validated ? (
                        <span className="inline-block w-16 rounded bg-green-500 py-1 text-center text-xs font-semibold text-white">
                          PASS
                        </span>
                      ) : (
                        <span className="inline-block w-16 rounded bg-red-500 py-1 text-center text-xs font-semibold text-white">
                          FAIL
                        </span>
                      )}
                    </LTD>
                    <LTD>
                      <NavLink
                        to={`./${id}`}
                        state={{ prevUrl: location.pathname }}
                        className="text-blue-700 hover:underline dark:text-blue-500"
                      >
                        {sendersSafetyReportUniqueIdentifier}
                      </NavLink>
                    </LTD>
                    <LTD>{reportClassification}</LTD>
                    <LTD>
                      <ArrayString>{productNames}</ArrayString>
                    </LTD>
                    <LTD>
                      <ArrayString>{reactionEventTerms}</ArrayString>
                    </LTD>
                    <LTD>
                      <span
                        className={clsx(
                          reactionEventsSeriousness === 'SAE' &&
                            'font-semibold text-red-500',
                        )}
                      >
                        {reactionEventsSeriousness}
                      </span>
                    </LTD>
                    <LTD>
                      {krctResultsOfAssessment
                        ?.replace(/(\s*)/g, '')
                        .split(',')
                        .map((str: string, idx: number) => {
                          const res =
                            filterDataSets.krctResultOfAssessment.find(
                              (item: any) => item.key === str,
                            );
                          return <div key={idx}>{res?.value}</div>;
                        })}
                      {whoUmcResultsOfAssessment
                        ?.replace(/(\s*)/g, '')
                        .split(',')
                        .map((str: string, idx: number) => {
                          const res =
                            filterDataSets.whoUmcResultOfAssessment.find(
                              (item: any) => item.key === str,
                            );
                          return <div key={idx}>{res?.value}</div>;
                        })}
                    </LTD>
                    <LTD>{dateOfCreation}</LTD>
                    <LTD>{dateOfMostRecentInformationForThisReport}</LTD>
                    <LTD>
                      {
                        filterDataSets.typeOfReport.find(
                          ({ key }) => typeOfReport?.toString() === key,
                        )?.value
                      }
                    </LTD>
                    <LTD>{sponsorStudyNumber}</LTD>
                    <LTD>{status}</LTD>
                    <LTD>{regulatoryAuthorityReportingDeadline}</LTD>
                    <LTD>{lastSubmittedDate}</LTD>
                  </LTR>
                ),
              )}
            </LTBody>
          </LTable>
        </div>
        <Pagination
          page={query.page}
          perPage={query.perPage}
          showDeletedItem={query.q?.deleted?.[0] as 'false'}
          lastPage={lastPage}
          totalElements={totalElements}
          onChangePage={changePage}
          onChangePerPage={changePerPage}
          refresh={refetch}
          setFilter={setFilter}
        />
      </Content>
    </MainTemplate>
  );
}

export default CaseList;
