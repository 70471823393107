import React from 'react';
import { Outlet } from 'react-router-dom';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

const tabList = [
  { label: 'Members', to: 'members' },
  { label: 'Role & Privileges', to: 'role-privileges' },
  { label: 'Setting', to: 'setting' },
];

function Admin() {
  return (
    <MainTemplate>
      <Header title="Admin" tabList={tabList} />
      <Content className="flex flex-col overflow-hidden pt-1">
        <Outlet />
      </Content>
    </MainTemplate>
  );
}

export default Admin;
