import React from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getMyCompany } from 'api/general';
import { logout } from 'api/auth';
import { getMemberByUserId } from 'api/members';

import { Company } from 'types/company';

import tokenTimer from 'utils/tokenTimer';

import { userState, companyState, memberState } from 'state';

import Loader from './Loader';

function CheckCompany() {
  const match = useMatch('/:company/*');
  const navigate = useNavigate();

  const [{ selected: selectedCompany }, setCompany] =
    useRecoilState(companyState);
  const [user] = useRecoilState(userState);
  const [, setMember] = useRecoilState(memberState);

  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  useQuery(
    ['myCompany'],
    () =>
      getMyCompany().then(({ data: { my_company: myCompany } }) => myCompany),
    {
      enabled: !selectedCompany,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const selected = data.find(
          (company: Company) => company.companyName === match?.params.company,
        );
        if (selected) {
          setCompany({ selected, list: data });
        } else if (match?.params.company !== 'select-company') {
          setCompany((prev) => ({ ...prev, list: data }));
          navigate('/select-company', { replace: true });
        }
      },
      onError() {
        handleLogout();
      },
    },
  );

  useQuery(
    ['getMember'],
    () =>
      getMemberByUserId({
        companyId: selectedCompany?.id,
        userId: user?.id,
      }).then(({ data }) => data),
    {
      enabled: !!selectedCompany,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setMember(data);
      },
      onError() {
        handleLogout();
      },
    },
  );

  if (!selectedCompany) return <Loader />;

  return <Outlet />;
}

export default CheckCompany;
