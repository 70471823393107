import { atom } from 'recoil';

import { User } from 'types/user';

const userState = atom<User | undefined>({
  key: 'user',
  default: undefined,
});

export default userState;
