import React from 'react';
import { Outlet } from 'react-router-dom';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

const tabList = [
  { label: 'Imports', to: 'imports' },
  { label: 'Exports', to: 'exports' },
  { label: 'Trackers', to: 'trackers' },
];

function History() {
  return (
    <MainTemplate>
      <Header title="History" tabList={tabList} />
      <Content className="flex flex-col overflow-hidden pt-1">
        <Outlet />
      </Content>
    </MainTemplate>
  );
}

export default History;
