import React from 'react';

import { Role } from 'types/role';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Popup from 'components/common/Popup';
import Select from 'components/common/Select/Select';

function CreatePopup({
  isOpen,
  form,
  roles,
  setForm,
  toggle,
  handleCreate,
}: {
  isOpen: boolean;
  form: any;
  roles: Role[];
  setForm: React.Dispatch<any>;
  toggle: () => void;
  handleCreate: () => void;
}) {
  return (
    <Popup isOpen={isOpen} className="w-[28rem]">
      <div className="border-b border-brand-600 py-2 font-semibold">
        Create Role
      </div>
      <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
        <div>
          <div className="w-36">
            <em>Role Name</em>
          </div>
          <div className="flex-1">
            <Input
              className="w-full"
              value={form.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setForm((prev: any) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
        </div>
        <div>
          <div className="w-36">
            <em>Reference</em>
          </div>
          <div className="flex-1">
            <Select
              value={form.reference?.id}
              onChange={(obj: any) => {
                setForm((prev: any) => ({
                  ...prev,
                  reference: obj,
                }));
              }}
              data={roles}
              valueField="id"
              textField="name"
            />
          </div>
        </div>
      </div>
      <div className="space-x-2 py-2 text-right">
        <Button onClick={toggle} color="gray" outline>
          Cancel
        </Button>
        <Button onClick={handleCreate} disabled={!form.name} color="blue">
          Create
        </Button>
      </div>
    </Popup>
  );
}

export default CreatePopup;
