import getQueryString from 'utils/getQueryString';
import { DatasetRuleType } from 'types/dataset';
import client from './client';

export const getDefaultDatasetRule = ({
  importSettingId,
  elementNumber,
  codes,
}: DatasetRuleType): DatasetRuleType => ({
  importSettingId,
  elementNumber,
  codes,
  items: [],
  value: '',
  codeConversions: [],
  deleted: false,
});

export const getDataset = (importSettingId: number | string) =>
  client.get(`/api/v1/transform/import/dataset/setting/${importSettingId}`);

export const getDatasets = ({
  companyId,
  query,
}: {
  companyId?: number | string;
  query?: any;
}) => {
  if (companyId) {
    const queryString = getQueryString(query);
    return client.get(
      `/api/v1/transform/import/dataset/setting?companyId=${companyId}&${queryString}`,
    );
  }
  return client.get('/api/v1/transform/import/dataset/setting/defaults');
};

export const getDatasetSummaries = (importSettingId?: number | string) =>
  client.get(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/rules/summaries`,
  );

export const postDataset = ({
  companyId,
  type,
  settingName,
  regulatory,
  studyId,
  senderId,
  reporterIds,
  defaultSettingId,
  formData,
}: any) => {
  const pQuery = `
    ${companyId == null ? '' : `&companyId=${companyId}`}
    ${studyId == null ? '' : `&studyId=${studyId}`}
    ${senderId == null ? '' : `&senderId=${senderId}`}
    ${defaultSettingId == null ? '' : `&defaultSettingId=${defaultSettingId}`}
    ${!reporterIds?.length ? '' : `&reporterIds=${reporterIds}`}
  `;
  return client.post(
    `/api/v1/transform/import/dataset/setting?settingName=${settingName}&type=${type}&regulatory=${regulatory}${pQuery}`,
    formData,
  );
};

export const deleteDatasets = (ids: number[]) => {
  const queryString = `ids=${ids.toString()}`;
  return client.delete(
    `/api/v1/transform/import/dataset/setting?${queryString}`,
  );
};

export const getDatasetRules = ({
  importSettingId,
  elementNumber,
}: {
  importSettingId?: number;
  elementNumber?: string;
}) =>
  client.get(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/rules?elementNumber=${elementNumber}`,
  );

export const putDatasetRules = ({
  importSettingId,
  rules,
}: {
  importSettingId?: number;
  rules: any;
}) =>
  client.put(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/rules`,
    rules,
  );

export const getDatasetRule = ({
  importSettingId,
  ruleId,
}: {
  importSettingId?: number;
  ruleId?: number;
}) =>
  client.get(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/rules/${ruleId}`,
  );

export const getDatasetDomains = (importSettingId?: number) =>
  client.get(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/domains`,
  );

export const getDatasetDomainItems = ({
  importSettingId,
  domainName,
}: {
  importSettingId?: number;
  domainName?: string;
}) =>
  client.get(
    `/api/v1/transform/import/dataset/setting/${importSettingId}/domains/${domainName}/items`,
  );
