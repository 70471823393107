import { TFunction } from 'i18next';

const drug = (codeList: any, t: TFunction) => ({
  'G.k': {
    elementNumber: 'G.k',
    list: [
      {
        elementNumber: 'G.k.1',
        label: t('ICSR:elementName:G.k.1'),
        component: 'radio',
        data: codeList['2.16.840.1.113883.3.989.2.1.1.13']?.codes,
        valueField: 'key',
        textField: 'value',
      },
      {
        elementNumber: 'G.k.2.2',
        label: t('ICSR:elementName:G.k.2.2'),
        component: 'input',
      },
    ],
  },
  'G.k.1': {
    elementNumber: 'G.k.1',
    label: t('ICSR:elementName:G.k.1'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.13']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
    required: true,
  },
  'G.k.2.1.1a': {
    elementNumber: 'G.k.2.1.1a',
    label: t('ICSR:elementName:G.k.2.1.1a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.2.1.1b': {
    elementNumber: 'G.k.2.1.1b',
    label: t('ICSR:elementName:G.k.2.1.1b'),
    component: 'textarea',
    maxLength: '1000',
  },
  'G.k.2.1.2a': {
    elementNumber: 'G.k.2.1.2a',
    label: t('ICSR:elementName:G.k.2.1.2a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.2.1.2b': {
    elementNumber: 'G.k.2.1.2b',
    label: t('ICSR:elementName:G.k.2.1.2b'),
    component: 'input',
    maxLength: '250',
  },
  'G.k.2.2': {
    elementNumber: 'G.k.2.2',
    label: t('ICSR:elementName:G.k.2.2'),
    component: 'textarea',
    maxLength: '250',
    required: true,
  },
  'G.k.2.3.r': {
    elementNumber: 'G.k.2.3.r',
    list: [
      {
        elementNumber: 'G.k.2.3.r.1',
        label: t('ICSR:elementName:G.k.2.3.r.1'),
        component: 'input',
      },
    ],
  },
  'G.k.2.3.r.1': {
    elementNumber: 'G.k.2.3.r.1',
    label: t('ICSR:elementName:G.k.2.3.r.1'),
    component: 'input',
    maxLength: '250',
  },
  'G.k.2.3.r.2a': {
    elementNumber: 'G.k.2.3.r.2a',
    label: t('ICSR:elementName:G.k.2.3.r.2a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.2.3.r.2b': {
    elementNumber: 'G.k.2.3.r.2b',
    label: t('ICSR:elementName:G.k.2.3.r.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.2.3.r.3a': {
    elementNumber: 'G.k.2.3.r.3a',
    label: t('ICSR:elementName:G.k.2.3.r.3a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '10',
  },
  'G.k.2.3.r.3b': {
    elementNumber: 'G.k.2.3.r.3b',
    label: t('ICSR:elementName:G.k.2.3.r.3b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.25']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.2.4': {
    elementNumber: 'G.k.2.4',
    label: t('ICSR:elementName:G.k.2.4'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.2.5': {
    elementNumber: 'G.k.2.5',
    label: t('ICSR:elementName:G.k.2.5'),
    component: 'radio',
    options: codeList.CNR_CHECK?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.3.1': {
    elementNumber: 'G.k.3.1',
    label: t('ICSR:elementName:G.k.3.1'),
    component: 'input',
    maxLength: '35',
  },
  'G.k.3.2': {
    elementNumber: 'G.k.3.2',
    label: t('ICSR:elementName:G.k.3.2'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.3.3': {
    elementNumber: 'G.k.3.3',
    label: t('ICSR:elementName:G.k.3.3'),
    component: 'input',
    maxLength: '60',
  },
  'G.k.4.r': {
    elementNumber: 'G.k.4.r',
    list: [
      {
        elementNumber: 'G.k.4.r.4',
        label: t('ICSR:elementName:G.k.4.r.4'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.4.r.5',
        label: t('ICSR:elementName:G.k.4.r.5'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.4.r.9.1',
        label: t('ICSR:elementName:G.k.4.r.9.1'),
        component: 'input',
      },
      {
        elementNumber: 'G.k.4.r.10.1',
        label: t('ICSR:elementName:G.k.4.r.10.1'),
        component: 'input',
      },
    ],
  },
  'G.k.4.r.1a': {
    elementNumber: 'G.k.4.r.1a',
    label: t('ICSR:elementName:G.k.4.r.1a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '8',
  },
  'G.k.4.r.1b': {
    elementNumber: 'G.k.4.r.1b',
    label: t('ICSR:elementName:G.k.4.r.1b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.25']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.4.r.2': {
    elementNumber: 'G.k.4.r.2',
    label: t('ICSR:elementName:G.k.4.r.2'),
    component: 'input',
    maxLength: '4',
  },
  'G.k.4.r.3': {
    elementNumber: 'G.k.4.r.3',
    label: t('ICSR:elementName:G.k.4.r.3'),
    component: 'select',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.4.r.4': {
    elementNumber: 'G.k.4.r.4',
    label: t('ICSR:elementName:G.k.4.r.4'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'G.k.4.r.5': {
    elementNumber: 'G.k.4.r.5',
    label: t('ICSR:elementName:G.k.4.r.5'),
    component: 'date',
    format: 'YYYYMMDD',
    allowedFormat: [
      'YYYYMMDDHHmmssZZ',
      'YYYYMMDDHHmmss',
      'YYYYMMDDHHmm',
      'YYYYMMDDHH',
      'YYYYMMDD',
      'YYYYMM',
      'YYYY',
    ],
    nullFlavorList: ['MSK', 'ASKU', 'NASK'],
  },
  'G.k.4.r.6a': {
    elementNumber: 'G.k.4.r.6a',
    label: t('ICSR:elementName:G.k.4.r.6a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '5',
  },
  'G.k.4.r.6b': {
    elementNumber: 'G.k.4.r.6b',
    label: t('ICSR:elementName:G.k.4.r.6b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.4.r.7': {
    elementNumber: 'G.k.4.r.7',
    label: t('ICSR:elementName:G.k.4.r.7'),
    component: 'input',
    maxLength: '35',
  },
  'G.k.4.r.8': {
    elementNumber: 'G.k.4.r.8',
    label: t('ICSR:elementName:G.k.4.r.8'),
    component: 'textarea',
    maxLength: '2000',
  },
  'G.k.4.r.9.1': {
    elementNumber: 'G.k.4.r.9.1',
    label: t('ICSR:elementName:G.k.4.r.9.1'),
    component: 'textarea',
    maxLength: '60',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'G.k.4.r.9.2a': {
    elementNumber: 'G.k.4.r.9.2a',
    label: t('ICSR:elementName:G.k.4.r.9.2a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.4.r.9.2b': {
    elementNumber: 'G.k.4.r.9.2b',
    label: t('ICSR:elementName:G.k.4.r.9.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.4.r.10.1': {
    elementNumber: 'G.k.4.r.10.1',
    label: t('ICSR:elementName:G.k.4.r.10.1'),
    component: 'textarea',
    maxLength: '60',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'G.k.4.r.10.2a': {
    elementNumber: 'G.k.4.r.10.2a',
    label: t('ICSR:elementName:G.k.4.r.10.2a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.4.r.10.2b': {
    elementNumber: 'G.k.4.r.10.2b',
    label: t('ICSR:elementName:G.k.4.r.10.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.4.r.11.1': {
    elementNumber: 'G.k.4.r.11.1',
    label: t('ICSR:elementName:G.k.4.r.11.1'),
    component: 'textarea',
    maxLength: '60',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'G.k.4.r.11.2a': {
    elementNumber: 'G.k.4.r.11.2a',
    label: t('ICSR:elementName:G.k.4.r.11.2a'),
    component: 'input',
    maxLength: '10',
  },
  'G.k.4.r.11.2b': {
    elementNumber: 'G.k.4.r.11.2b',
    label: t('ICSR:elementName:G.k.4.r.11.2b'),
    component: 'input',
    maxLength: '100',
  },
  'G.k.5a': {
    elementNumber: 'G.k.5a',
    label: t('ICSR:elementName:G.k.5a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '10',
  },
  'G.k.5b': {
    elementNumber: 'G.k.5b',
    label: t('ICSR:elementName:G.k.5b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.25']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.6a': {
    elementNumber: 'G.k.6a',
    label: t('ICSR:elementName:G.k.6a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '3',
  },
  'G.k.6b': {
    elementNumber: 'G.k.6b',
    label: t('ICSR:elementName:G.k.6b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26_2']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.7.r': {
    elementNumber: 'G.k.7.r',
    list: [
      {
        elementNumber: 'G.k.7.r.1',
        label: t('ICSR:elementName:G.k.7.r.1'),
        component: 'input',
      },
    ],
  },
  'G.k.7.r.1': {
    elementNumber: 'G.k.7.r.1',
    label: t('ICSR:elementName:G.k.7.r.1'),
    component: 'textarea',
    maxLength: '250',
    nullFlavorList: ['UNK', 'ASKU', 'NASK'],
  },
  'G.k.7.r.2a': {
    elementNumber: 'G.k.7.r.2a',
    label: t('ICSR:elementName:G.k.7.r.2a'),
    component: 'select',
    data: codeList.meddra_version,
    valueField: 'version',
    textField: 'version',
  },
  'G.k.7.r.2b': {
    elementNumber: 'G.k.7.r.2b',
    label: t('ICSR:elementName:G.k.7.r.2b'),
    component: 'MedDRASearch',
  },
  'G.k.8': {
    elementNumber: 'G.k.8',
    label: t('ICSR:elementName:G.k.8'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.15']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.9.i': {
    elementNumber: 'G.k.9.i',
  },
  'G.k.9.i.1': {
    elementNumber: 'G.k.9.i.1',
    label: t('ICSR:elementName:G.k.9.i.1'),
    component: 'select',
    valueField: 'value',
    textField: 'label',
  },
  'G.k.9.i.2.r': {
    elementNumber: 'G.k.9.i.2.r',
    list: [
      {
        elementNumber: 'G.k.9.i.2.r.1',
        label: t('ICSR:elementName:G.k.9.i.2.r.1'),
        component: 'input',
      },
    ],
  },
  'G.k.9.i.2.r.1': {
    elementNumber: 'G.k.9.i.2.r.1',
    label: t('ICSR:elementName:G.k.9.i.2.r.1'),
    component: 'input',
    maxLength: '60',
  },
  'G.k.9.i.2.r.2': {
    elementNumber: 'G.k.9.i.2.r.2',
    label: t('ICSR:elementName:G.k.9.i.2.r.2'),
    component: 'input',
    maxLength: '60',
  },
  'G.k.9.i.2.r.3': {
    elementNumber: 'G.k.9.i.2.r.3',
    label: t('ICSR:elementName:G.k.9.i.2.r.3'),
    component: 'input',
    maxLength: '60',
  },
  'G.k.9.i.3.1a': {
    elementNumber: 'G.k.9.i.3.1a',
    label: t('ICSR:elementName:G.k.9.i.3.1a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '5',
  },
  'G.k.9.i.3.1b': {
    elementNumber: 'G.k.9.i.3.1b',
    label: t('ICSR:elementName:G.k.9.i.3.1b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.9.i.3.2a': {
    elementNumber: 'G.k.9.i.3.2a',
    label: t('ICSR:elementName:G.k.9.i.3.2a'),
    component: 'input',
    placeholder: 'NUMBER',
    type: 'number',
    maxLength: '5',
  },
  'G.k.9.i.3.2b': {
    elementNumber: 'G.k.9.i.3.2b',
    label: t('ICSR:elementName:G.k.9.i.3.2b'),
    component: 'select',
    placeholder: 'UNIT',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.26']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.9.i.4': {
    elementNumber: 'G.k.9.i.4',
    label: t('ICSR:elementName:G.k.9.i.4'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.16']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
  'G.k.10.r': {
    elementNumber: 'G.k.10.r',
    label: t('ICSR:elementName:G.k.10.r'),
    list: [
      {
        elementNumber: 'G.k.10.r',
        label: t('ICSR:elementName:G.k.10.r'),
        component: 'select',
        data: codeList['2.16.840.1.113883.3.989.2.1.1.17']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
    component: 'select',
    data: codeList['2.16.840.1.113883.3.989.2.1.1.17']?.codes,
    valueField: 'key',
    textField: 'value',
  },
  'G.k.11': {
    elementNumber: 'G.k.11',
    label: t('ICSR:elementName:G.k.11'),
    component: 'textarea',
    maxLength: '2000',
  },
});

export default drug;
