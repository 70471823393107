export const SubmissionStatus = {
  submitted: 'SUBMITTED',
  submitFailed: 'SUBMIT_FAILED',
  processingFailed: 'PROCESSING_FAILED',
  processingCompeleted: 'PROCESSING_COMPLETED',
} as const;
export const SubmissionDetailStatus = {
  submitted: 'SUBMITTED',
  submitFailed: 'SUBMIT_FAILED',
  processingFailed: 'PROCESSING_FAILED',
  processingCompeleted: 'PROCESSING_COMPLETED',
} as const;

export const ACKCode = {
  accept: 'AA',
  error: 'AE',
  reject: 'AR',
} as const;
export const ICSRMessageACKCode = {
  accept: 'CA',
  reject: 'CR',
};
export interface Submission {
  [key: string]: any;
  id: number;
  submissionNo: string;
  acknowledgementBatchNumber: string;
  submissionDate: string;
  submitterName: string;
  processingDate: string;
  status: typeof SubmissionStatus[keyof typeof SubmissionStatus];
  commitAcceptCount: number;
  commitRejectCount: number;
  transmissionAcknowledgementCode: typeof ACKCode[keyof typeof ACKCode];
  comment: string;
}
export interface SubmissionDetail {
  [key: string]: any;
  id: number;
  sendersSafetyReportUniqueIdentifier: string;
  icsrMessageACKSender: string;
  status: typeof ICSRMessageACKCode[keyof typeof ICSRMessageACKCode];
  studyName: string;
  productNames: string;
  referenceICSRId: number;
}
