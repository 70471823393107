import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoCloseCircle, IoSearch } from 'react-icons/io5';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';

interface SearchType {
  className?: string;
  width?: string;
  value?: string;
  label?: string;
  size?: SizeType;
  onChange?: (v: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const sizeStyles: { [key in SizeType]: string } = {
  sm: 'control control-sm',
  md: 'control control-md',
  lg: 'control control-lg',
};

function SearchFilter(
  {
    className,
    width,
    size = 'md',
    value = '',
    label,
    onChange = () => null,
    onKeyDown,
  }: SearchType,
  ref: React.Ref<HTMLInputElement>,
) {
  const [inputValue, setInputValue] = useState('');

  const debounced = useRef(debounce((cb) => cb(), 500));

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      debounced.current(() => onChange(e.target.value));
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    setInputValue('');
    debounced.current(() => onChange(''));
  }, [onChange]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={twMerge(
        clsx(
          sizeStyles[size],
          'focus-within:control-focus group flex items-center justify-between',
          className,
        ),
      )}
      style={{ width }}
    >
      <input
        ref={ref}
        className="min-w-0 flex-1 bg-inherit"
        placeholder={label || 'Search'}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
      <div className="ml-1 flex items-center justify-center">
        {value?.length ? (
          <IoCloseCircle
            className="cursor-pointer text-slate-400 hover:!text-slate-800 dark:text-slate-500 dark:hover:!text-slate-200"
            onClick={handleReset}
          />
        ) : (
          <IoSearch className="text-slate-400 dark:text-slate-500" />
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(SearchFilter);
