import React, { HTMLAttributes } from 'react';
import { useRecoilState } from 'recoil';

import { UserType } from 'types/user';

import { userState } from 'state';

import NavItem from './NavItem';
import { UserNav, ManagerNav } from './NavItems';

function Nav(props: HTMLAttributes<HTMLElement>) {
  const [user] = useRecoilState(userState);

  return (
    <nav
      className="z-30 flex flex-1 flex-col overflow-y-auto overflow-x-hidden px-4 py-2"
      {...props}
    >
      {Object.values(
        user?.userType === UserType.user ? UserNav : ManagerNav,
      ).map(({ id, ...rest }) => (
        <NavItem key={id} {...rest} />
      ))}
    </nav>
  );
}

export default Nav;
