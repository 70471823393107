import { Region } from './core';
import { Study } from './study';

export interface Dataset {
  id: number;
  importType: string;
  name: string;
  study: Study;
  createdBy: string;
  lastModifiedBy: string;
  dbSpecFileName: string;
  createdDate: string;
  lastModifiedDate: string;
}
export interface DatasetForm {
  settingName: string;
  type?: number;
  studyId?: number;
  senderId?: number;
  reporterIds: Array<number>;
  files?: FileList;
  defaultSettingId?: number;
}

export interface DefaultDatasetForm {
  settingName: string;
  type?: number;
  files?: FileList;
  regulatory?: Region;
}

export const EDCSetupType = {
  cube: 'CUBE',
} as const;

interface ElementCodeType {
  value: string;
  description: string;
}

interface ItemCodeType {
  value: string;
  description: string;
}

export interface DatasetDomainItemType {
  [key: string]: any;
  id?: number;
  domainName?: string;
  domainDescription?: string;
  itemName?: string;
  itemDescription?: string;
  dataType?: string;
  dataFormat?: string;
  codeList?: ItemCodeType[];
}

interface DatasetRuleCodeConversionType {
  id?: number;
  sourceValue?: string;
  targetValue?: string;
}

export interface DatasetRuleType {
  id?: number;
  importSettingId: number;
  elementNumber: string;
  codes: ElementCodeType[];
  items: DatasetDomainItemType[];
  value: string;
  codeConversions: DatasetRuleCodeConversionType[];
  deleted: boolean;
}

export interface DatasetRule {
  importSettingId: number;
  elementNumber: string;
  elementLabel: string;
  repeatable: boolean;
  matched: boolean;
}
