import { TFunction } from 'i18next';

import identification from './elements/identification';
import primaryinformation from './elements/primaryInformation';
import sender from './elements/sender';
import literatureReference from './elements/literatureReference';
import study from './elements/study';
import patientCharacteristics from './elements/patientCharacteristics';
import reactionEvent from './elements/reactionEvent';
import testResult from './elements/testResult';
import drug from './elements/drug';
import narrative from './elements/narrative';

const ICH = (codeList: any, t: TFunction) => ({
  ...identification(codeList, t),
  ...primaryinformation(codeList, t),
  ...sender(codeList, t),
  ...literatureReference(codeList, t),
  ...study(codeList, t),
  ...patientCharacteristics(codeList, t),
  ...reactionEvent(codeList, t),
  ...testResult(codeList, t),
  ...drug(codeList, t),
  ...narrative(codeList, t),
});

export default ICH;
