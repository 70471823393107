import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import {
  getRegistrationDefaultValues,
  getStudyDefaultValues,
  postStudy,
} from 'api/studies';
import { getProducts } from 'api/products';

import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import StudyForm from './StudyForm';

function CreateStudy() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getStudyDefaultValues(company?.id),
  });

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then(({ data }) => data.content),
  );

  const { mutate } = useMutation(postStudy, {
    onSuccess() {
      navigate('../studies', { replace: true });
    },
  });

  const changeStudy = (key: string, value: any) => {
    setValue(key, value);
  };

  const addRegistration = () => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) => {
        draft.push(getRegistrationDefaultValues());
      }),
    );
  };

  const deleteRegistration = (index: number) => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) =>
        draft.filter((_officer, idx) => index !== idx),
      ),
    );
  };

  const changeRegistration = (key: string, value: any, index: number) => {
    setValue(
      'registrations',
      produce(form.registrations, (draft) => {
        draft[index][key] = value;
      }),
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'companyId' });
    register({ name: 'productIdList' }, { required: t('error:required') });
    register({ name: 'studyName' }, { required: t('error:required') });
    register({ name: 'sponsorStudyNumber' }, { required: t('error:required') });
    register({ name: 'studyType' });
    register({ name: 'registrations' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Study"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <StudyForm
        submitLabel="Create"
        form={form}
        errors={errors}
        products={products}
        onSubmit={onSubmit}
        changeStudy={changeStudy}
        addRegistration={addRegistration}
        deleteRegistration={deleteRegistration}
        changeRegistration={changeRegistration}
      />
    </FormWrapper>
  );
}

export default CreateStudy;
