import React from 'react';
import clsx from 'clsx';
import { TbArrowNarrowUp, TbArrowNarrowDown } from 'react-icons/tb';

import { OrderType } from 'utils/commonType';

function SortColumn({
  name,
  order,
  orderBy,
  onChange = () => null,
  children,
}: {
  name: string;
  order?: OrderType;
  orderBy?: string;
  onChange?: (o: OrderType, n: string) => void;
  children?: React.ReactNode;
}) {
  const Arrow =
    orderBy === name && order === 'desc' ? TbArrowNarrowDown : TbArrowNarrowUp;

  return (
    <button
      className="group -ml-2 flex h-fit w-fit max-w-[200px] cursor-pointer items-center rounded-sm px-2 py-1 text-left transition-colors hover:bg-slate-100"
      onClick={() =>
        onChange(orderBy === name && order === 'asc' ? 'desc' : 'asc', name)
      }
    >
      <span className="block flex-1 overflow-hidden text-ellipsis">
        {children}
      </span>
      <Arrow
        className={clsx(
          'ml-1 mt-0.5 transition-colors group-hover:opacity-30',
          orderBy === name ? '!text-brand-600 !opacity-100' : 'opacity-0',
        )}
        fontSize="1rem"
      />
    </button>
  );
}

export default SortColumn;
