import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  TbTrash,
  TbPlayerStopFilled,
  TbDownload,
  TbAlertCircle,
} from 'react-icons/tb';
import { useRecoilState } from 'recoil';

import { deleteSignals, getSignals, postSignal, putSignal } from 'api/signal';
import { getProducts } from 'api/products';
import { getStudies } from 'api/studies';

import { Signal, SignalStatus } from 'types/signal';
import { ListHead } from 'types/list';
import modal from 'utils/modal';
import useList from 'hooks/useList';

import { companyState } from 'state';

import SortColumn from 'components/common/SortColumn';
import FilterBox from 'components/common/Filter/FilterBox';
import Pagination from 'components/common/Pagination';
import Tooltip from 'components/common/Tooltip';
import Download from 'components/common/Donwload';
import Button from 'components/common/Button';
import Popup from 'components/common/Popup';
import { DatePicker } from 'components/common/DatePicker';
import Select from 'components/common/Select/Select';
import MultiSelect from 'components/common/Select/MultiSelect';
import {
  LTBody,
  LTD,
  LTH,
  LTHead,
  LTR,
  LTable,
} from 'components/common/ListTable';

const listHeads: ListHead[] = [
  {
    name: 'productName',
    label: 'Product',
    sort: true,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'endDate',
    label: 'End Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'createdDate',
    label: 'Created Date',
    sort: true,
    filter: true,
    type: 'date',
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    type: 'select',
    data: [
      { key: 'WAIT', value: 'WAIT' },
      { key: 'PROCESSING', value: 'PROCESSING' },
      { key: 'CANCEL', value: 'CANCEL' },
      { key: 'ERROR', value: 'ERROR' },
      { key: 'COMPLETE', value: 'COMPLETE' },
    ],
    valueField: 'key',
    textField: 'value',
    width: '10.5rem',
    style: { width: '8rem' },
  },
  {
    name: 'signalCount',
    label: 'Signal Detections',
    style: { width: '6rem', textAlign: 'center' },
  },
  {
    name: 'download',
    style: { width: '4rem' },
  },
];

const filterList = [
  {
    type: 'searchList',
    name: 'searchList',
    list: [{ name: 'productName', label: 'Product Name' }],
    filter: true,
    width: '20rem',
  },
  ...listHeads.filter(({ filter }) => filter).map((heads) => heads),
];

function SignalList() {
  const { t } = useTranslation();
  const [{ selected: company }] = useRecoilState(companyState);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<{
    companyId?: number;
    productId: number | null;
    excludeStudyNumbers: string[];
    startDate?: string;
    endDate?: string;
  }>({
    companyId: company?.id,
    productId: null,
    excludeStudyNumbers: [],
  });

  const {
    data,
    lastPage,
    totalElements,
    isFetching,
    query,
    selected,
    setFilter,
    refetch,
    changeSort,
    changePage,
    changePerPage,
    select,
    selectAll,
    reset,
    handleDelete,
  } = useList({
    queryKey: 'signals',
    queryFn: (q) => getSignals({ companyId: company?.id, query: q }),
    deleteFn: (s) => deleteSignals(company?.id, s),
  });

  const { data: products } = useQuery(['getProducts'], () =>
    getProducts({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then((res) => res.data.content),
  );

  const { data: studies } = useQuery(['getStudies'], () =>
    getStudies({
      companyId: company?.id,
      query: { perPage: 0, q: { deleted: ['false'] } },
    }).then((res) => res.data.content),
  );

  const { mutate: create } = useMutation(postSignal, {
    onSuccess() {
      refetch();
    },
  });

  const { mutate: cancel } = useMutation(putSignal, {
    onSuccess() {
      refetch();
    },
  });

  const handleCreate = async () => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      create(form);
      setOpen(false);
    }
  };

  const handleCancel = async (signalId: number) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:cancel'))
    ) {
      cancel({ companyId: company?.id, signalId });
    }
  };

  const filteredProducts = useMemo(() => products || [], [products]);

  const filteredStudys = useMemo(() => {
    if (!studies) return [];
    return studies?.filter((study: any) =>
      study.productIdList.includes(form.productId),
    );
  }, [form.productId, studies]);

  return (
    <>
      <div className="-ml-1 flex justify-between py-2">
        <FilterBox
          filter={query.q}
          filterList={filterList}
          setFilter={setFilter}
        />
        <div
          className="flex cursor-pointer items-center space-x-2 pl-4 [&_svg]:h-[1.875rem] [&_svg]:w-[1.875rem] [&_svg]:rounded-sm [&_svg]:bg-gray-100 
              [&_svg]:p-1.5 [&_svg]:text-slate-500 dark:[&_svg]:border-slate-600 dark:[&_svg]:text-slate-500
              [&_svg:hover]:bg-slate-400 [&_svg:hover]:text-slate-200 dark:[&_svg:hover]:bg-slate-600"
        >
          <button onClick={handleDelete} disabled={!selected.length}>
            <TbTrash title="delete" />
          </button>
          <Button color="blue" size="sm" onClick={() => setOpen(true)}>
            Create
          </Button>
        </div>
      </div>
      <div className="overflow-auto bg-inherit">
        <LTable className="table-fixed">
          <LTHead>
            <LTR
              type="head"
              selected={data?.length === selected.length}
              select={(checked) => (checked ? selectAll() : reset())}
            >
              {listHeads.map(({ name, label, sort, style }) => (
                <LTH key={name} style={style}>
                  {sort ? (
                    <SortColumn
                      name={name}
                      order={query.order}
                      orderBy={query.orderBy}
                      onChange={changeSort}
                    >
                      {label}
                    </SortColumn>
                  ) : (
                    label
                  )}
                </LTH>
              ))}
            </LTR>
          </LTHead>
          <LTBody
            isFetching={isFetching}
            rows={data?.length || query.perPage}
            cols={listHeads.length}
          >
            {data?.map(
              ({
                id,
                productName,
                startDate,
                endDate,
                createdDate,
                status,
                signalCount,
                downloadUrl,
                errorMessage,
              }: Signal) => {
                const icon = (() => {
                  if (
                    status === SignalStatus.wait ||
                    status === SignalStatus.processing
                  ) {
                    return (
                      <TbPlayerStopFilled
                        className="hover:text-red-500"
                        onClick={() => handleCancel(id)}
                      />
                    );
                  }
                  if (status === SignalStatus.error) {
                    return (
                      <Tooltip content={errorMessage}>
                        <TbAlertCircle className="text-red-500" />
                      </Tooltip>
                    );
                  }
                  if (status === SignalStatus.complete) {
                    return (
                      downloadUrl && (
                        <Download
                          src={downloadUrl}
                          className="hover:opacity-50"
                        >
                          <TbDownload />
                        </Download>
                      )
                    );
                  }
                  return null;
                })();

                return (
                  <LTR
                    key={id}
                    type="body"
                    selected={selected.findIndex((item) => item === id) !== -1}
                    select={(checked) => select(id, checked)}
                  >
                    <LTD>{productName}</LTD>
                    <LTD>{startDate}</LTD>
                    <LTD>{endDate}</LTD>
                    <LTD>{createdDate}</LTD>
                    <LTD>
                      {
                        listHeads
                          .find(({ name }) => name === 'status')
                          ?.data?.find(({ key }) => key === status)?.value
                      }
                    </LTD>
                    <LTD className="text-center">{signalCount}</LTD>
                    <LTD className="[&_svg]:mx-auto">{icon}</LTD>
                  </LTR>
                );
              },
            )}
          </LTBody>
        </LTable>
      </div>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        lastPage={lastPage}
        totalElements={totalElements}
        onChangePage={changePage}
        onChangePerPage={changePerPage}
        refresh={refetch}
      />
      <Popup isOpen={open} className="w-[28rem]">
        <div className="border-b border-brand-600 py-2 font-semibold">
          Signal Detection
        </div>
        <div className="mb-2 py-2 [&>div]:mt-4 [&>div]:flex">
          <div>
            <div className="w-36">
              <em>Product</em>
            </div>
            <div className="flex-1">
              <Select
                value={form.productId}
                onChange={(obj: any) => {
                  setForm((prev) => ({
                    ...prev,
                    productId: obj?.id,
                    excludeStudyNumbers: [],
                  }));
                }}
                data={filteredProducts}
                valueField="id"
                textField={(dataItem: any) =>
                  dataItem?.brandName || dataItem?.productName || dataItem
                }
              />
            </div>
          </div>
          <div>
            <div className="w-36">
              <em>Studies to Exclude</em>
            </div>
            <div className="flex-1">
              <MultiSelect
                value={form.excludeStudyNumbers}
                onChange={(obj: any) =>
                  setForm((prev) => ({
                    ...prev,
                    excludeStudyNumbers: obj.map(
                      (el: any) => el.sponsorStudyNumber,
                    ),
                  }))
                }
                data={filteredStudys}
                valueField="sponsorStudyNumber"
                textField="sponsorStudyNumber"
                disabled={!form.productId}
              />
            </div>
          </div>
          <div>
            <div className="w-36">
              <em>Start Date</em>
            </div>
            <div className="flex-1">
              <DatePicker
                value={form.startDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  setForm((prev) => ({
                    ...prev,
                    startDate: date?.isValid()
                      ? date.format('YYYY-MM-DD')
                      : undefined,
                  }))
                }
              />
            </div>
          </div>
          <div>
            <div className="w-36">
              <em>End Date</em>
            </div>
            <div className="flex-1">
              <DatePicker
                value={form.endDate}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  setForm((prev) => ({
                    ...prev,
                    endDate: date?.isValid()
                      ? date.format('YYYY-MM-DD')
                      : undefined,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="space-x-2 py-2 text-right">
          <Button onClick={() => setOpen(false)} color="gray" outline>
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            disabled={!form.productId || !form.endDate || !form.startDate}
            color="blue"
          >
            Create
          </Button>
        </div>
      </Popup>
    </>
  );
}

export default SignalList;
