import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getAccountByToken, putResetPassword } from 'api/auth';
import useForm from 'hooks/useForm';

import Input from 'components/common/Input';
import Button from 'components/common/Button';
import clsx from 'clsx';

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const {
    form,
    register,
    setValue,
    errors,
    setError: setForError,
    clearError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      token,
      email: '',
      password: '',
      passwordRep: '',
    },
  });

  useQuery(['resetPassword'], () => getAccountByToken({ token }), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: ({ email }: any) => {
      setValue('email', email);
    },
  });

  const { mutate } = useMutation(putResetPassword, {
    onSuccess() {
      navigate('/login');
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onSubmit = handleSubmit(async (data) => mutate(data));

  useEffect(() => {
    register({ name: 'email' });
    register(
      { name: 'password' },
      {
        pattern: {
          value:
            /^.*(?=^.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+=]).*$/,
          message:
            'Password must be at least 8 characters and contain upper and lower case letters, numbers, and special characters',
        },
        validate: (value: any, _form: any) => {
          if (_form.passwordRep && value !== _form.passwordRep) {
            setForError('passwordRep', 'disabled', 'Passwords do not match');
          } else {
            clearError('passwordRep');
          }
          return true;
        },
      },
    );
    register(
      { name: 'passwordRep' },
      {
        validate: (value: any, _form: any) =>
          value === _form.password || 'Passwords do not match',
      },
    );
  }, [clearError, register, setForError]);

  return (
    <div className="flex h-[var(--mainHeight)] min-h-[600px] w-screen flex-col items-center justify-center bg-slate-200 dark:bg-slate-900">
      <div className="flex max-w-min flex-row rounded-lg bg-white p-10 shadow-lg dark:bg-slate-800">
        <form className="w-[380px]" onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center">
            <div className="mb-4 h-16 w-16 bg-icon bg-cover" />
            <h1 className="text-center text-2xl font-bold dark:text-white">
              Reset your password?
            </h1>
            <span className="mb-4 text-base text-slate-400">
              What would you like your new password to be?
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="password"
              name="password"
              className="w-full"
              placeholder="New password"
              value={form.password}
              onChange={handleChange}
              error={errors.password}
            />
            <span
              className={clsx(
                errors.password ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.password?.message}
            </span>
          </div>
          <div className="mb-4">
            <Input
              type="password"
              name="passwordRep"
              className="w-full"
              placeholder="Repeat password"
              value={form.passwordRep}
              onChange={handleChange}
              error={errors.passwordRep}
            />
            <span
              className={clsx(
                errors.passwordRep ? 'block' : 'hidden',
                'text-sm text-red-600',
              )}
            >
              {errors.passwordRep?.message}
            </span>
          </div>
          <div className="text-right">
            <Link
              to="/login"
              className="mr-4 font-semibold text-red-400 hover:text-red-500"
            >
              Back
            </Link>
            <Button type="submit">Reset</Button>
          </div>
        </form>
      </div>
      <div className="mt-4 text-slate-500 opacity-80">
        Copyright &copy; C&R Research
      </div>
    </div>
  );
}

export default ResetPasswordPage;
