import React, { InputHTMLAttributes } from 'react';
import { IoIosSearch } from 'react-icons/io';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import { SizeType } from 'utils/commonType';
import TypeModify from 'utils/typeModify';

import Input from './Input';
import Button from './Button';

type Type = TypeModify<
  InputHTMLAttributes<HTMLElement>,
  {
    value?: string | null;
    size?: SizeType;
    error?: boolean;
  }
>;

function SearchInput(
  {
    className,
    size = 'md',
    value,
    onClick,
    onChange,
    readOnly,
    disabled,
  }: Type,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <div className={twMerge(clsx('relative', className))}>
      <Input
        ref={ref}
        className="w-full pr-10"
        value={value || ''}
        size={size}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      <Button
        className="cursor-pointertext-center absolute top-0 right-0 h-full w-8 rounded-tl-none rounded-bl-none"
        size={size}
        color="indigo"
        onClick={onClick}
      >
        <IoIosSearch
          color="white"
          className={clsx(
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
            size === 'sm' && 'text-base',
            size === 'md' && 'text-xl',
            size === 'lg' && 'text-2xl',
          )}
        />
      </Button>
    </div>
  );
}

export default React.forwardRef(SearchInput);
