import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbChevronDown } from 'react-icons/tb';
import clsx from 'clsx';
import moment from 'moment';
import { useRecoilState } from 'recoil';

import { ApprovalAction, ApprovalLineStatus } from 'types/approval';
import { CaseStatus } from 'types/case';
import modal from 'utils/modal';

import { memberState } from 'state';

import { buttonStyles } from 'components/common/Modal/Confirm';

import { useCaseAction, useCaseState } from './context/CaseContext';
import useCaseActions from './hooks/useCaseActions';

const signStyles =
  'relative flex items-center justify-center h-14 w-14 border border-gray-500 text-gray-500 text-5xl pb-1 hover:opacity-80';
const signedStyles =
  'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[50px] h-[50px] text-[10px] leading-[12px] pt-[10px] text-center text-red-600 border border-red-600 rounded-full [&>span]:inline-block [&>span]:align-middle [&>span]:leading-normal';

function CaseApprovalLine({ className }: { className?: string }) {
  const { t } = useTranslation();

  const [member] = useRecoilState(memberState);

  const [open, setOpen] = useState(true);
  const toggle = () => setOpen((prev) => !prev);

  const { updateApproval } = useCaseActions();

  const { icsr } = useCaseState() || {};
  const { refetchCase } = useCaseAction();
  const { id: icsrId, latestApproval, status } = icsr || {};
  const {
    id: latestApprovalId,
    approvalLines,
    icsrIds,
  } = latestApproval || { approvalLines: [] };

  const reject = (approvalId: number | undefined, approvalLine: any) => {
    modal.custom({
      title: t('modal:custom:caseApproveRejectTitle'),
      message: (
        <input
          className="control control-md w-full"
          id="approval-popup-reject-message"
        />
      ),
      actions: [
        [
          'Ok',
          () => {
            const comment = (
              document.getElementById(
                'approval-popup-reject-message',
              ) as HTMLInputElement
            ).value;
            updateApproval({
              approvalId,
              approvalLineId: approvalLine.id,
              data: {
                action: ApprovalAction.REJECT,
                comment: comment || moment().format(),
              },
            }).then(() => refetchCase());
          },
          true,
        ],
      ],
    });
  };

  const approve = (approvalId: number | undefined, approvalLine: any) => {
    updateApproval({
      approvalId,
      approvalLineId: approvalLine.id,
      data: {
        action: ApprovalAction.APPROVE,
      },
    }).then(() => refetchCase());
  };

  const handleClick = (approvalId: number | undefined, approvalLine: any) => {
    if (icsrIds?.length !== 1 || icsrIds[0] !== icsrId) {
      modal.alert(t('modal:alert:title'), t('modal:alert:onlyBatchApprovals'));
      return;
    }
    if (
      approvalLines.length === 2 &&
      !member?.privileges?.CASE_APPROVE_TWO_EDIT
    ) {
      modal.alert(t('modal:alert:title'), t('modal:alert:requiredPermission'));
      return;
    }
    if (approvalLines.length === 3) {
      if (
        (approvalLine === approvalLines[1] &&
          !member?.privileges?.CASE_APPROVE_ONE_EDIT) ||
        (approvalLine === approvalLines[2] &&
          !member?.privileges?.CASE_APPROVE_TWO_EDIT)
      ) {
        modal.alert(
          t('modal:alert:title'),
          t('modal:alert:requiredPermission'),
        );
        return;
      }
    }
    modal.custom({
      title: t('modal:confirm:title'),
      message: t('modal:custom:caseApproveConfirmMssage'),
      actions: [
        ['Cancel', () => {}, true],
        [
          'Reject',
          () => {
            reject(approvalId, approvalLine);
          },
          false,
          buttonStyles.warn,
        ],
        [
          'Approve',
          () => {
            approve(approvalId, approvalLine);
          },
          true,
          buttonStyles.info,
        ],
      ],
    });
  };

  if (
    status === CaseStatus.WRITING_IN_PROGRESS ||
    status === CaseStatus.COMPLETED ||
    !approvalLines?.length
  ) {
    return null;
  }

  return (
    <div
      className={clsx(
        'absolute z-20 w-36 translate-y-1 translate-x-0 overflow-hidden rounded-bl-lg rounded-br-lg border bg-slate-100/80 transition-[height]',
        open ? 'h-20' : 'h-4',
      )}
    >
      {open ? (
        <div className={clsx('flex h-16 space-x-4 p-2', className)}>
          {approvalLines.length === 1 && (
            <button
              className={signStyles}
              onClick={() => handleClick(latestApprovalId, approvalLines[0])}
              disabled={approvalLines[0].status === ApprovalLineStatus.COMPLETE}
            >
              S
              {approvalLines[0].status === ApprovalLineStatus.COMPLETE && (
                <span className={signedStyles}>
                  {approvalLines[0]?.approverName}
                  <br />
                  {approvalLines[0]?.actionDate
                    .split('T')[0]
                    .replace(/-/gi, '.')}
                </span>
              )}
            </button>
          )}
          {approvalLines.length > 1 && (
            <button
              className={signStyles}
              onClick={() => handleClick(latestApprovalId, approvalLines[1])}
              disabled={approvalLines[1].status === ApprovalLineStatus.COMPLETE}
            >
              S
              {approvalLines[1].status === ApprovalLineStatus.COMPLETE && (
                <span className={signedStyles}>
                  {approvalLines[1]?.approverName}
                  <br />
                  {approvalLines[1]?.actionDate
                    .split('T')[0]
                    .replace(/-/gi, '.')}
                </span>
              )}
            </button>
          )}
          {approvalLines.length > 2 && (
            <button
              className={signStyles}
              onClick={() => handleClick(latestApprovalId, approvalLines[2])}
              disabled={
                approvalLines[1].status === ApprovalLineStatus.PROGRESS ||
                approvalLines[2].status === ApprovalLineStatus.COMPLETE
              }
            >
              S
              {approvalLines[2].status === ApprovalLineStatus.COMPLETE && (
                <span className={signedStyles}>
                  {approvalLines[2].approverName}
                  <br />
                  {approvalLines[2].actionDate
                    .split('T')[0]
                    .replace(/-/gi, '.')}
                </span>
              )}
            </button>
          )}
        </div>
      ) : null}
      <button
        className={clsx(
          'flex w-full items-center justify-center transition-transform hover:bg-slate-100 hover:opacity-60',
          open ? 'rotate-180' : 'rotate-0',
        )}
        onClick={toggle}
      >
        <TbChevronDown />
      </button>
    </div>
  );
}

export default CaseApprovalLine;
