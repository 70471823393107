import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import produce from 'immer';

import { codeSetsState } from 'state';
import { ICSRForm, ICSRFormRootElement, ICSRFormType } from 'types/case';
import { Study as StudyType } from 'types/study';

import { useCaseState } from '../../context/CaseContext';
import useCaseConvert from '../../hooks/useCaseConvert';

import Title from '../../Title';
import LoadData from '../../LoadData';
import RootElement from '../../RootElement';
import RootRepeatElement from '../../RootRepeatElement';
import CaseFormElement from '../../CaseFormElement';
import SubTitle from '../../SubTitle';

function Study({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const [codeSets] = useRecoilState(codeSetsState);
  const { studies } = useCaseState();

  const { convertStudy } = useCaseConvert(codeSets);

  const handleChange = useCallback(
    (number: string, value: ICSRFormType) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const handleLoad = useCallback(
    (study: StudyType) => {
      setForm(
        produce((draft) => {
          draft['C.5'] = convertStudy(
            study,
            draft['C.5'] as ICSRFormRootElement,
          );
        }),
      );
      handleVersion();
    },
    [convertStudy, handleVersion, setForm],
  );

  return (
    <>
      <Title number="C.5">
        <LoadData
          data={studies}
          textField="sponsorStudyNumber"
          onLoad={handleLoad}
          disabled={disabled}
        />
      </Title>

      <RootElement
        number="C.5"
        version={version}
        element={form['C.5'] as ICSRFormRootElement}
        disabled={disabled}
        onChange={handleChange}
      >
        <SubTitle number="C.5.1.r" />
        <RootRepeatElement number="C.5.1.r">
          <CaseFormElement number="C.5.1.r.1" />
          <CaseFormElement number="C.5.1.r.2" />
        </RootRepeatElement>

        <CaseFormElement number="C.5.2" />
        <CaseFormElement number="C.5.3" />
        <CaseFormElement number="C.5.4" />
      </RootElement>
    </>
  );
}

export default Study;
