import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';

import caseFormDeleted from 'utils/caseFormDeleted';
import { getRepeatForm } from 'utils/caseRepeatForm';
import { ICSRFormElement, ICSRFormRootElement } from 'types/case';

import { useCaseState } from './context/CaseContext';
import CaseFormElement from './CaseFormElement';
import Repeat from './Repeat';
import RootElement from './RootElement';
import CaseTab from './CaseTab';
import SubTitle from './SubTitle';

interface RootRepeatElementProps {
  className?: string;
  version?: number;
  number: string;
  element?: ICSRFormRootElement[];
  list?: any[];
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (
    n: string,
    v: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
  ) => void;
  onControl?: (n: string, d: any) => void;
  disableDelete?: (f: ICSRFormRootElement) => boolean;
}

function RootRepeatElement({
  className,
  version,
  number,
  element,
  list,
  disabled,
  children,
  onChange = () => null,
  onControl = () => null,
  disableDelete = () => false,
}: RootRepeatElementProps) {
  const { E2BR3Elements } = useCaseState();

  const [rootVersion, setRootVersion] = useState(version);
  const [root, setRoot] = useState<ICSRFormRootElement[] | undefined>(element);
  const [selected, setSelected] = useState(-1);

  const handleChange = useCallback(
    (
      cNumber: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      if (selected !== -1) {
        setRoot(
          produce((draft) => {
            if (draft) {
              draft[selected].elements[cNumber] = {
                ...draft[selected].elements[cNumber],
                ...value,
              };
              onControl(cNumber, draft[selected]);
            }
          }),
        );
      }
    },
    [onControl, selected],
  );

  const handleRootChange = useCallback(
    (
      cNumber: string,
      value: ICSRFormElement | ICSRFormRootElement | ICSRFormRootElement[],
    ) => {
      if (selected !== -1) {
        setRoot(
          produce((draft) => {
            if (draft) draft[selected].elements[cNumber] = value;
          }),
        );
      }
    },
    [selected],
  );

  const handleSelect = useCallback((idx: number) => setSelected(idx), []);

  const handleAdd = useCallback(() => {
    setRoot((prev) => {
      if (!prev) return [getRepeatForm(number) || { number, elements: {} }];
      return produce(prev, (draft) => {
        draft.push(getRepeatForm(number) || { number, elements: {} });
      });
    });
  }, [number]);

  const handleDelete = useCallback(
    (idx: number) => {
      setRoot((prev) => {
        if (!prev) return prev;
        if (disableDelete(prev[idx])) return prev;
        return produce(prev, (draft) => {
          if (draft[idx].id) {
            caseFormDeleted(draft[idx]);
          } else {
            draft.splice(idx, 1);
            setSelected(-1);
          }
        });
      });
    },
    [disableDelete],
  );

  useEffect(() => {
    if (root) {
      onChange(number, root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  useEffect(() => {
    setRoot(element);
    setRootVersion(version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const { list: defaultList } = E2BR3Elements[number];

  return (
    <Repeat
      className={className}
      selected={selected}
      list={list || defaultList}
      data={root}
      disabled={disabled}
      handleSelect={handleSelect}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const item = child as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          if (item.type === CaseFormElement) {
            return React.cloneElement(item, {
              key: selected,
              parentId: root?.[selected]?.id,
              version:
                item.props.version ||
                (item.props.valueToVersion &&
                  (
                    root?.[selected]?.elements[
                      item.props.valueToVersion
                    ] as ICSRFormElement
                  )?.value),
              element: root?.[selected]?.elements?.[item.props.number],
              disabled:
                disabled ||
                root?.[selected]?.deleted ||
                (item.props.available &&
                  !item.props.available(root?.[selected])),
              onChange: handleChange,
            });
          }
          if (item.type === RootElement) {
            return React.cloneElement(item, {
              key: selected,
              version: rootVersion,
              element: root?.[selected]?.elements?.[item.props.number],
              disabled: disabled || root?.[selected]?.deleted,
              onChange: handleRootChange,
            });
          }
          if ((item.type as any)?.type?.name === RootRepeatElement.name) {
            return React.cloneElement(item, {
              key: selected,
              version: rootVersion,
              parentId: root?.[selected]?.id,
              element: root?.[selected]?.elements?.[item.props.number],
              disabled: disabled || root?.[selected]?.deleted,
              onChange: handleRootChange,
            });
          }
          if (child.type === CaseTab) {
            return React.cloneElement(item, {
              key: selected,
              version: rootVersion,
              element: root?.[selected],
              disabled: disabled || root?.[selected]?.deleted,
              onChange: handleChange,
              onRootChange: handleRootChange,
            });
          }
          if (item.type === SubTitle) {
            return React.cloneElement(item, {
              parentId: root?.[selected]?.id,
            });
          }
        }
        return child;
      })}
    </Repeat>
  );
}

export default React.memo(RootRepeatElement);
