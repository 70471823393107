import React, { AnchorHTMLAttributes } from 'react';

import Modify from 'utils/typeModify';

type props = Modify<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  {
    src?: string;
    children?: React.ReactNode;
  }
>;
function Download({ src, children, ...rest }: props) {
  return (
    <a href={src} rel="noopener noreferrer" target="_blank" download {...rest}>
      {children}
    </a>
  );
}

export default Download;
