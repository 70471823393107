import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getReceiverDefaultValues, postReceiver } from 'api/receivers';

import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import { companyState } from 'state';

import FormWrapper from 'components/common/FormWrapper';

import ReceiverForm from './ReceiverForm';

function CreateReceiver() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ selected: company }] = useRecoilState(companyState);
  const { form, errors, register, setValue, handleSubmit } = useForm({
    defaultValues: getReceiverDefaultValues(company?.id),
  });

  const { mutate } = useMutation(postReceiver, {
    onSuccess() {
      navigate('../receivers', { replace: true });
    },
  });

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      mutate(data);
    }
  });

  useEffect(() => {
    register({ name: 'companyId' });
    register({ name: 'type' }, { required: t('error:required') });
    register({ name: 'name' }, { required: t('error:required') });
    register({ name: 'receiverId' });
    register({ name: 'nsaeSolicited' });
    register({ name: 'nsaeNonSolicited' });
    register({ name: 'saeSolicited' });
    register({ name: 'saeNonSolicited' });
  }, [register, t]);

  return (
    <FormWrapper
      title="Create Receiver"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <ReceiverForm
        submitLabel="Create"
        form={form}
        errors={errors}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default CreateReceiver;
