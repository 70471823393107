import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import { getMyCompany } from 'api/general';
import { logout } from 'api/auth';

import { Company } from 'types/company';
import tokenTimer from 'utils/tokenTimer';

import { companyState } from 'state';

import SearchFilter from './common/Filter/SearchFilter';

function SelectCompany() {
  const navigate = useNavigate();

  const [{ list }, setCompany] = useRecoilState(companyState);

  const [searchInput, setSearchInput] = useState<string>('');

  const { mutate: handleLogout } = useMutation(logout, {
    onMutate() {},
    onSettled() {
      tokenTimer.remove();
      navigate('/login');
    },
  });

  useQuery(
    ['myCompany'],
    () =>
      getMyCompany().then(({ data: { my_company: myCompany } }) => myCompany),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCompany((prev) => ({ ...prev, list: data }));
      },
      onError() {
        handleLogout();
      },
    },
  );

  const filteredList = useMemo(
    () =>
      list.filter((c) =>
        c.companyName.toLowerCase().includes(searchInput.toLowerCase()),
      ),
    [searchInput, list],
  );

  const handleSelect = (company: Company) => {
    setCompany((prev) => ({ ...prev, selected: company }));
    navigate(`/${company.companyName}/home`);
  };

  return (
    <div className="h-[var(--mainHeight)] w-screen overflow-auto">
      <div className="mx-auto mt-20 w-3/4 min-w-[48rem]">
        <div className="mb-4">
          <SearchFilter
            width="20rem"
            value={searchInput}
            onChange={(val) => setSearchInput(val)}
          />
        </div>
        <div>
          <table className="w-full">
            <thead className="border-b-2 border-slate-300 text-left">
              <tr className="[&>th]:p-2">
                <th className="w-[40%]">Name</th>
                <th>Type</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody className="border-b-2 border-slate-300 text-left">
              {filteredList.map((company) => (
                <tr key={company.companyName} className="[&>td]:p-2">
                  <td>
                    <button
                      type="button"
                      className="text-blue-700 hover:underline dark:text-blue-500"
                      onClick={() => handleSelect(company)}
                    >
                      {company.companyName}
                    </button>
                  </td>
                  <td>{company.type}</td>
                  <td>2022-10-21</td>
                  <td>2023-10-20</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SelectCompany;
