import React, { useCallback } from 'react';
import { IoSync } from 'react-icons/io5';
import produce from 'immer';

import { ICSRForm, ICSRFormRootElement, ICSRFormType } from 'types/case';

import Title from 'components/case/Title';
import RootElement from 'components/case/RootElement';
import RootRepeatElement from 'components/case/RootRepeatElement';
import CaseFormElement from 'components/case/CaseFormElement';
import SubTitle from 'components/case/SubTitle';
import Space from 'components/case/Space';

function Narrative({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const handleChange = useCallback(
    (number: string, value: ICSRFormType) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const handleLoad = useCallback(() => {
    setForm((prev) =>
      produce(prev, (draft) => {
        const diagnosis = (prev['E.i'] as ICSRFormRootElement[]).map(
          (reactionEvent: any) => {
            const value = reactionEvent.elements['E.i.2.1a']?.value;
            const lltCode = reactionEvent.elements['E.i.2.1b']?.value;
            const lltTerm = reactionEvent.elements['E.i.2.1b']?.description;
            return {
              number: 'H.3.r',
              elements: {
                'H.3.r.1a': { value },
                'H.3.r.1b': { value: lltCode, description: lltTerm },
              },
            };
          },
        );

        (draft.H as ICSRFormRootElement).elements['H.3.r'] = diagnosis;
      }),
    );
    handleVersion();
  }, [handleVersion, setForm]);

  return (
    <>
      <Title number="H" />

      <RootElement
        number="H"
        version={version}
        element={form.H as ICSRFormRootElement}
        disabled={disabled}
        onChange={handleChange}
      >
        <CaseFormElement number="H.1" full />
        <CaseFormElement number="H.2" full />

        <Space />

        <SubTitle number="H.3.r">
          <button title="Load Reaction(s)/Event(s)" disabled={disabled}>
            <IoSync
              className="mt-px ml-2"
              onClick={handleLoad}
              size="1.25rem"
              data-outline={false}
              title="Load Reaction(s)/Event(s)"
            />
          </button>
        </SubTitle>
        <RootRepeatElement number="H.3.r">
          <CaseFormElement number="H.3.r.1a" />
          <CaseFormElement number="H.3.r.1b" valueToVersion="H.3.r.1a" />
        </RootRepeatElement>

        <CaseFormElement number="H.4" full />

        <Space />

        <SubTitle number="H.5.r" />
        <RootRepeatElement number="H.5.r">
          <CaseFormElement number="H.5.r.1a" />
          <CaseFormElement number="H.5.r.1b" />
        </RootRepeatElement>
      </RootElement>
    </>
  );
}

export default Narrative;
