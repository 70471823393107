import client from './client';

export const postImportFile = ({
  companyId,
  type,
  importSettingId,
  receiverType,
  productIds,
  studyId,
  creationDate,
  recentDate,
  formData,
}: {
  companyId?: number;
  type?: string;
  importSettingId?: number;
  receiverType?: string;
  productIds: number[];
  studyId?: number;
  creationDate?: string;
  recentDate?: string;
  formData: FormData;
}) => {
  const pQuery = `${studyId == null ? '' : `&studyId=${studyId}`}${
    creationDate ? `&creationDate=${encodeURIComponent(creationDate)}` : ''
  }${recentDate ? `&recentDate=${encodeURIComponent(recentDate)}` : ''}${
    importSettingId == null ? '' : `&importSettingId=${importSettingId}`
  }${receiverType == null ? '' : `&receiverType=${receiverType}`}`;

  return client.post(
    `/api/v1/transform/import/file?companyId=${companyId}&type=${type}&productIds=${productIds}${pQuery}`,
    formData,
  );
};
