import { useMutation } from '@tanstack/react-query';
import { postApproval, putApproval } from 'api/approvals';
import { deleteCase, putCaseActions, putCasesAction } from 'api/cases';
import { postExportFile } from 'api/export';
import { CaseAction } from 'types/case';

function useCaseActions() {
  const { mutateAsync: mutateDelete } = useMutation(deleteCase);
  const { mutateAsync: mutateExport } = useMutation(postExportFile);
  const { mutateAsync: mutateApprove } = useMutation(postApproval);
  const { mutateAsync: mutateApprovalUpdate } = useMutation(putApproval);
  const { mutateAsync: mutateSubmit } = useMutation(putCasesAction);
  const { mutateAsync: mutateAction } = useMutation(putCaseActions);

  return {
    deleteCase: (data: any) => mutateDelete(data),
    exportCases: (data: any) => mutateExport(data),
    apporveCases: (data: any) => mutateApprove(data),
    updateApproval: (data: any) => mutateApprovalUpdate(data),
    submitCases: (data: any) => mutateSubmit(data),
    restoreCase: (caseId?: number) =>
      mutateAction({ caseId, data: { actionType: CaseAction.RESTORE } }),
    resetCase: (caseId?: number) =>
      mutateAction({
        caseId,
        data: { actionType: CaseAction.BACK_TO_WRITING },
      }),
    completeCase: (caseId?: number) =>
      mutateAction({
        caseId,
        data: { actionType: CaseAction.WRITING_COMPLETE },
      }),
  };
}

export default useCaseActions;
