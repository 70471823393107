import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { TbPlus } from 'react-icons/tb';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from '../Dropdown';
import { FilterType } from './Filter';

export interface FilterAddType extends FilterType {
  id: number;
  disabled?: boolean;
}

function AddFilter({
  list,
  showFilter,
}: {
  list: FilterAddType[];
  showFilter: (item: FilterAddType, idx: number) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (open) inputRef.current?.focus();
  }, [open]);

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle>
        <button className="control control-sm group-[.focused]:control-focus flex w-full items-center">
          <input tabIndex={-1} className="relative w-px opacity-0" readOnly />
          <div className="flex flex-1 cursor-pointer flex-wrap items-center">
            <TbPlus className="text-slate-400 group-[.focused]:text-slate-800" />
          </div>
        </button>
      </DropdownToggle>
      <DropdownMenu
        className="min-w-[10rem] rounded border border-slate-300 bg-white dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300"
        onClick={(e) => e.stopPropagation()}
      >
        <input ref={inputRef} className="absolute w-px" readOnly />
        <ul className="flex max-h-[10rem] flex-col overflow-y-auto p-1">
          {list.map((item, idx) => {
            const { disabled } = item;
            return (
              <DropdownItem
                key={idx}
                tag="button"
                className={clsx(
                  `mb-1 w-full rounded-sm px-2 py-1 text-left last:mb-0 hover:bg-slate-100 data-[focused=true]:bg-slate-300/40`,
                  'dark:data-[focused=true]:bg-slate-700',
                  disabled ? 'order-2' : 'order-1',
                )}
                onClick={(e) => {
                  (e.target as HTMLElement).blur();
                  showFilter(item, idx);
                }}
                disabled={disabled}
              >
                {item.label}
              </DropdownItem>
            );
          })}
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
}

export default AddFilter;
