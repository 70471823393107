import React, { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  tag?: keyof JSX.IntrinsicElements;
  disabled?: boolean;
  children?: React.ReactNode;
}

function DropdownItem({
  tag: Tag = 'div',
  className,
  children,
  ...rest
}: Props) {
  const handleMouseOver = (e: React.MouseEvent) =>
    (e.target as HTMLElement).focus();

  return (
    <Tag
      className={className}
      role="menuitem"
      tabIndex={0}
      onMouseOver={handleMouseOver}
      {...rest}
    >
      {children}
    </Tag>
  );
}

export default DropdownItem;
