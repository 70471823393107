import { TFunction } from 'i18next';

const study = (codeList: any, t: TFunction) => ({
  'C.5.1.r': {
    elementNumber: 'C.5.1.r',
    list: [
      {
        elementNumber: 'C.5.1.r.1',
        label: t('ICSR:elementName:C.5.1.r.1'),
        component: 'input',
      },
      {
        elementNumber: 'C.5.1.r.2',
        label: t('ICSR:elementName:C.5.1.r.2'),
        component: 'select',
        data: codeList['1.0.3166.1.2.2']?.codes,
        valueField: 'key',
        textField: 'value',
      },
    ],
  },
  'C.5.1.r.1': {
    elementNumber: 'C.5.1.r.1',
    label: t('ICSR:elementName:C.5.1.r.1'),
    component: 'input',
    maxLength: '50',
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'C.5.1.r.2': {
    elementNumber: 'C.5.1.r.2',
    label: t('ICSR:elementName:C.5.1.r.2'),
    component: 'select',
    data: codeList['1.0.3166.1.2.2']?.codes,
    valueField: 'key',
    textField: 'value',
    nullFlavorList: ['ASKU', 'NASK'],
    valueLabel: true,
  },
  'C.5.2': {
    elementNumber: 'C.5.2',
    label: t('ICSR:elementName:C.5.2'),
    component: 'textarea',
    maxLength: '2000',
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'C.5.3': {
    elementNumber: 'C.5.3',
    label: t('ICSR:elementName:C.5.3'),
    component: 'input',
    maxLength: '50',
    nullFlavorList: ['ASKU', 'NASK'],
  },
  'C.5.4': {
    elementNumber: 'C.5.4',
    label: t('ICSR:elementName:C.5.4'),
    component: 'radio',
    options: codeList['2.16.840.1.113883.3.989.2.1.1.8']?.codes,
    valueField: 'key',
    textField: 'value',
    valueLabel: true,
  },
});

export default study;
