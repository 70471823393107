import getQueryString from 'utils/getQueryString';
import client from './client';

const convertData = (res: any) => {
  if (res?.data?.content?.length) {
    res.data.content = res.data.content.map((icsr: any) =>
      icsr?.data?.reduce((acc: any, cur: any) => {
        const { columnId, value } = cur;
        acc[columnId] = value;
        return acc;
      }, {}),
    );
  }
  return res;
};

export const getTrackerColumnSpecs = () =>
  client.get('/api/v1/trackers/column-specs');

export const getTrackers = (companyId?: number) =>
  client.get(`/api/v1/trackers?companyId=${companyId}`);

export const postTrackers = (data: any) =>
  client.post('/api/v1/trackers', data);

export const putTrackers = ({
  trackerId,
  data,
}: {
  trackerId: number;
  data: any;
}) => client.put(`/api/v1/trackers/${trackerId}`, data);

export const getTrackersData = async ({
  id,
  companyId,
  query,
}: {
  id?: number;
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  const res = await client.get(
    `/api/v1/trackers/${id}/data-rows?companyId=${companyId}&${queryString}`,
  );
  return convertData(res);
};

export const putTrackerData = (id: number) => {
  return client.put(`/api/v1/trackers/${id}/data-rows`);
};

export const getTrackerReports = ({
  companyId,
  query,
}: {
  companyId?: number;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.get(
    `/api/v1/tracker-reports?companyId=${companyId}&${queryString}`,
  );
};

export const postTrackerReports = ({
  data,
  query,
}: {
  data: any;
  query?: any;
}) => {
  const queryString = getQueryString(query);
  return client.post(`/api/v1/tracker-reports?${queryString}`, data);
};

// export const postTrackerReports = (data: any) => {
//   const { query } = data;
//   const { q: newQuery } = convertQuery(query);
//   if (!newQuery) throw new Error();
//   const newData = { ...data, query: newQuery };
//   return client.post(`/api/v1/tracker-reports?${qs.stringify({ q: 'deleted=false' })}`, newData);
// };
