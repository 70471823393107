import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import produce from 'immer';

import { codeSetsState } from 'state';
import {
  ICSRForm,
  ICSRFormElement,
  ICSRFormRootElement,
  ICSRFormType,
} from 'types/case';
import { Sender as SenderType } from 'types/sender';

import { useCaseState } from '../../context/CaseContext';

import Title from '../../Title';
import LoadData from '../../LoadData';
import RootElement from '../../RootElement';
import CaseFormElement from '../../CaseFormElement';
import Space from '../../Space';
import SubTitle from '../../SubTitle';

function Sender({
  version,
  form,
  disabled,
  setForm,
  handleVersion,
}: {
  version?: number;
  form: ICSRForm;
  disabled?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICSRForm>>;
  handleVersion: () => void;
}) {
  const [codeSets] = useRecoilState(codeSetsState);
  const { senders } = useCaseState();

  const handleLoad = useCallback(
    (sender: SenderType) => {
      const typeOid = codeSets.oidMap.senderType;
      const typeCode = codeSets[typeOid]?.codes.find(
        (code: any) => code.key === sender.type,
      );

      const healthProfessionalTypeOid = codeSets.oidMap.healthProfessionalType;
      const healthProfessionalTypeCode = codeSets[
        healthProfessionalTypeOid
      ]?.codes.find((code: any) => code.key === sender.healthProfessionalType);

      const selectedSendigOfficer = sender.sendingOfficers.find(
        (sendingOfficer: any) => sendingOfficer.selected,
      );
      const countryOid = codeSets.oidMap.countries;
      const countryCode = codeSets[countryOid]?.codes.find(
        (code: any) => code.key === selectedSendigOfficer?.countryCode,
      );

      setForm(
        produce((draft) => {
          const target = draft['C.3'] as ICSRFormRootElement;
          target.elements['C.3.1'] = {
            ...target.elements['C.3.1'],
            value: sender?.type,
            description: typeCode?.value,
          };
          target.elements['C.3.1.KR.1'] = {
            ...target.elements['C.3.1.KR.1'],
            value: sender?.healthProfessionalType,
            description: healthProfessionalTypeCode?.value,
          };
          target.elements['C.3.2'] = {
            ...target.elements['C.3.2'],
            value: sender?.organizationName,
          };
          target.elements['C.3.3.1'] = {
            ...target.elements['C.3.3.1'],
            value: selectedSendigOfficer?.department,
          };
          target.elements['C.3.3.2'] = {
            ...target.elements['C.3.3.2'],
            value: selectedSendigOfficer?.title,
          };
          target.elements['C.3.3.3'] = {
            ...target.elements['C.3.3.3'],
            value: selectedSendigOfficer?.givenName,
          };
          target.elements['C.3.3.4'] = {
            ...target.elements['C.3.3.4'],
            value: selectedSendigOfficer?.middleName,
          };
          target.elements['C.3.3.5'] = {
            ...target.elements['C.3.3.5'],
            value: selectedSendigOfficer?.familyName,
          };
          target.elements['C.3.4.1'] = {
            ...target.elements['C.3.4.1'],
            value: selectedSendigOfficer?.street,
          };
          target.elements['C.3.4.2'] = {
            ...target.elements['C.3.4.2'],
            value: selectedSendigOfficer?.city,
          };
          target.elements['C.3.4.3'] = {
            ...target.elements['C.3.4.3'],
            value: selectedSendigOfficer?.province,
          };
          target.elements['C.3.4.4'] = {
            ...target.elements['C.3.4.3'],
            value: selectedSendigOfficer?.postCode,
          };
          target.elements['C.3.4.5'] = {
            ...target.elements['C.3.4.5'],
            value: selectedSendigOfficer?.countryCode,
            description: countryCode?.value,
          };
          target.elements['C.3.4.6'] = {
            ...target.elements['C.3.4.6'],
            value: selectedSendigOfficer?.telephone,
          };
          target.elements['C.3.4.7'] = {
            ...target.elements['C.3.4.7'],
            value: selectedSendigOfficer?.fax,
          };
          target.elements['C.3.4.8'] = {
            ...target.elements['C.3.4.8'],
            value: selectedSendigOfficer?.email,
          };
        }),
      );
      handleVersion();
    },
    [codeSets, handleVersion, setForm],
  );

  const handleChange = useCallback(
    (number: string, value: ICSRFormType) => {
      setForm(
        produce((draft) => {
          draft[number] = value;
        }),
      );
    },
    [setForm],
  );

  const handleControl = useCallback(
    (number: string, draft: ICSRFormRootElement) => {
      if (number === 'C.3.1') {
        const ele = draft.elements['C.3.1'] as ICSRFormElement;
        if (ele?.value !== '3') {
          draft.elements['C.3.1.KR.1'] = {
            ...draft.elements['C.3.1.KR.1'],
            value: undefined,
            description: undefined,
          };
        }
      }
    },
    [],
  );

  const available = useCallback(
    (f: ICSRFormRootElement) =>
      (f?.elements['C.3.1'] as ICSRFormElement)?.value === '3',
    [],
  );

  return (
    <>
      <Title number="C.3">
        <LoadData
          data={senders}
          textField="organizationName"
          disabled={disabled}
          onLoad={handleLoad}
        />
      </Title>

      <RootElement
        number="C.3"
        version={version}
        element={form['C.3'] as ICSRFormRootElement}
        disabled={disabled}
        onChange={handleChange}
        onControl={handleControl}
      >
        <CaseFormElement number="C.3.1" />
        <CaseFormElement number="C.3.1.KR.1" available={available} />
        <CaseFormElement number="C.3.2" />

        <Space />

        <SubTitle number="C.3.3" />
        <CaseFormElement number="C.3.3.1" />
        <CaseFormElement number="C.3.3.2" />
        <CaseFormElement number="C.3.3.3" />
        <CaseFormElement number="C.3.3.4" />
        <CaseFormElement number="C.3.3.5" />

        <Space />

        <SubTitle number="C.3.4" />
        <CaseFormElement number="C.3.4.1" full />
        <CaseFormElement number="C.3.4.2" />
        <CaseFormElement number="C.3.4.3" />
        <CaseFormElement number="C.3.4.4" />
        <CaseFormElement number="C.3.4.5" />
        <CaseFormElement number="C.3.4.6" />
        <CaseFormElement number="C.3.4.7" />
        <CaseFormElement number="C.3.4.8" />
      </RootElement>
    </>
  );
}

export default Sender;
