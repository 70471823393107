import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { checkEmail, getUserDefaultValues, postUser } from 'api/users';
import { getCompanies } from 'api/companies';

import { UserType } from 'types/user';
import modal from 'utils/modal';
import useForm from 'hooks/useForm';

import FormWrapper from 'components/common/FormWrapper';

import UserForm from './UserForm';

const userTypeList = Object.entries(UserType).map(([key, value]) => ({
  key,
  value,
}));

function CreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const { form, errors, register, setValue, setError, handleSubmit } = useForm({
    defaultValues: getUserDefaultValues(),
  });

  const { data: companies } = useQuery(['getCompanies'], () =>
    getCompanies({ query: { perPage: 0, q: { deleted: ['false'] } } }).then(
      ({ data }) => data.content,
    ),
  );

  const { mutate } = useMutation(checkEmail, {
    onSuccess() {
      setError('email', 'disabled', t('error:registeredEmail'));
    },
    onError(err: AxiosError<any>) {
      if (err.response?.status === 404) {
        setCheck(true);
      } else {
        setError(
          'email',
          'disabled',
          err.response?.data?.message || err.message,
        );
      }
    },
    onMutate() {},
    onSettled() {},
  });

  const { mutate: submit } = useMutation(postUser, {
    onSuccess() {
      navigate('..', { replace: true });
    },
  });

  const handleChange = (key: string, value: any) => {
    setValue(key, value);
  };

  const checkAvailability = () => mutate(form.email);

  const onSubmit = handleSubmit(async (data) => {
    if (
      await modal.confirm(t('modal:confirm:title'), t('modal:confirm:create'))
    ) {
      submit(data);
    }
  });

  useEffect(() => {
    setCheck(false);
  }, [form.email]);

  useEffect(() => {
    register(
      { name: 'email' },
      {
        required: t('error:required'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: t('error:invalidEmail'),
        },
      },
    );

    register({ name: 'userName' }, { required: t('error:required') });
    register({ name: 'phone' }, { required: t('error:required') });
    register({ name: 'startDate' }, { required: t('error:required') });
    register({ name: 'endDate' });
    register({ name: 'userType' }, { required: t('error:required') });
    register(
      { name: 'companyId' },
      {
        validate: (value: any, originForm: any) => {
          const { userType } = originForm;
          if (!userType || userType === UserType.manager) {
            return true;
          }
          if (userType === UserType.user && Number.isInteger(value)) {
            return true;
          }
          return t('error:required');
        },
      },
    );
    register({ name: 'managerFlag' });
  }, [t, register]);

  return (
    <FormWrapper
      title="Create User"
      breadcrumbs={[{ label: 'List', to: '..' }]}
    >
      <UserForm
        submitLabel="Create"
        check={check}
        form={form}
        companies={companies}
        errors={errors}
        userTypeList={userTypeList}
        checkAvailability={checkAvailability}
        onSubmit={onSubmit}
        onChange={handleChange}
      />
    </FormWrapper>
  );
}

export default CreateUser;
