import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoCaretDown, IoClose } from 'react-icons/io5';
import debounce from 'lodash.debounce';
import includes from 'lodash.includes';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import Checkbox from '../Checkbox';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from '../Dropdown';
import Search from './SearchFilter';

interface FilterItem {
  [key: string]: string;
}
interface SelectFilterProps {
  width?: string;
  value?: string[];
  label?: string;
  data?: any[];
  valueField?: string;
  textField?: string;
  onChange?: (v: string[]) => void;
  onSelect?: (v: string[]) => void;
  hideFilter?: () => void;
}

function SelectFilter({
  width,
  value = [],
  label,
  data = [],
  valueField = 'value',
  textField = 'label',
  onChange,
  onSelect,
  hideFilter,
}: SelectFilterProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [dropItems, setDropItems] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');

  const debounced = useRef(
    debounce(
      (items: any[], tFiled: string, sInput: string) =>
        setDropItems(
          items.filter((item) =>
            includes(item[tFiled].toLowerCase(), sInput.toLowerCase()),
          ),
        ),
      500,
    ),
  );

  const toggle = () => setOpen((prev) => !prev);

  const handleChange = (item: FilterItem) => {
    const newValue = [...value, item[valueField]];
    if (onChange) onChange(newValue);
    if (onSelect) onSelect(newValue);
  };

  const handleRemove = (item: FilterItem) => {
    const newValue = value.filter((v) => v !== item[valueField]);
    if (onChange) onChange(newValue);
    if (onSelect) onSelect(newValue);
  };

  const handleRemoveAll = () => {
    if (onChange) onChange([]);
    if (onSelect) onSelect([]);
    inputRef.current?.focus();
  };

  const handleSearchInput = useCallback(
    (val: string) => setSearchInput(val),
    [],
  );

  useEffect(() => {
    debounced.current(data, textField, searchInput);
  }, [data, searchInput, textField]);

  useEffect(() => {
    if (dropdownRef.current) {
      const ele = dropdownRef.current;
      ele.addEventListener('openfilter', () => {
        ele.focus();
        setOpen(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) inputRef.current?.focus();
  }, [open]);

  return (
    <Dropdown ref={dropdownRef} isOpen={open} toggle={toggle}>
      <DropdownToggle>
        <button
          className={twMerge(
            clsx(
              'control control-sm group-[.focused]:control-focus w-fit pr-7',
              value.length && '!bg-slate-800 !text-slate-200',
            ),
          )}
          style={{ maxWidth: width }}
        >
          <div className="flex flex-1">
            <div className="flex w-full flex-wrap items-center">
              {value.length ? (
                <span className="flex items-center">
                  <span>{label}</span>:&nbsp;
                  <span className="flex-1 overflow-hidden text-ellipsis break-all text-left line-clamp-1">
                    {
                      data.find((item) => item[valueField] === value[0])?.[
                        textField
                      ]
                    }
                  </span>
                  {value.length > 1 && (
                    <span className="ml-1 rounded-full bg-gray-400 px-1.5 text-xs text-slate-800">
                      +{value.length - 1}
                    </span>
                  )}
                </span>
              ) : (
                <span className="text-slate-400">{label}</span>
              )}
            </div>
          </div>
        </button>
        {hideFilter ? (
          <IoClose
            tabIndex={-1}
            className={twMerge(
              clsx(
                'absolute top-1/2 right-2 -translate-y-1/2 text-sm text-slate-400 group-[.focused]:text-slate-800 dark:text-slate-200 dark:group-[.focused]:text-slate-200',
                value.length && '!bg-slate-800 !text-slate-200',
              ),
            )}
            onClick={hideFilter}
          />
        ) : (
          <IoCaretDown
            tabIndex={-1}
            className={twMerge(
              clsx(
                'absolute top-1/2 right-2 -translate-y-1/2 text-sm text-slate-400 group-[.focused]:text-slate-800 dark:text-slate-200 dark:group-[.focused]:text-slate-200',
                value.length && '!bg-slate-800 !text-slate-200',
              ),
            )}
          />
        )}
      </DropdownToggle>
      <DropdownMenu
        className="rounded border border-slate-300 bg-white text-sm dark:border-slate-600 dark:bg-slate-800 dark:text-slate-300"
        width={width}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="border-b-2 border-inherit p-1">
          <Search
            ref={inputRef}
            className="control-focus w-full border-none"
            size="sm"
            value={searchInput}
            onChange={handleSearchInput}
            onKeyDown={(e) => {
              if (e.key === 'Tab') {
                e.preventDefault();
              }
            }}
          />
        </div>
        <ul className="max-h-[10rem] overflow-y-auto p-1">
          {dropItems.map((item, idx) => {
            const isSelected = value.includes(item[valueField]);
            return (
              <DropdownItem
                key={idx}
                tag="li"
                className="mb-1 flex cursor-default items-center break-all rounded-sm px-2 py-1 last:mb-0 hover:cursor-pointer hover:bg-slate-300/40 data-[focused=true]:bg-slate-300/40 dark:hover:bg-slate-700 dark:data-[focused=true]:bg-slate-700"
                onClick={() => {
                  if (isSelected) {
                    handleRemove(item);
                  } else {
                    handleChange(item);
                  }
                  inputRef.current?.focus();
                }}
                onMouseOver={(e) => e.preventDefault()}
                aria-selected={isSelected}
              >
                <Checkbox
                  tabIndex={-1}
                  className="pointer-events-none"
                  checked={isSelected}
                  label={item[textField]}
                  readOnly
                />
              </DropdownItem>
            );
          })}
        </ul>
        <div className="border-t-2 border-inherit py-2 px-3 text-slate-400 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-600">
          <button onClick={handleRemoveAll}>Clear Selection</button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

export default SelectFilter;
