import React from 'react';

import MainTemplate from 'components/template/MainTemplate';
import Header from 'components/Header';
import Content from 'components/Content';

import SubmissionList from './SubmissionList';

function Signal() {
  return (
    <MainTemplate>
      <Header title="Submission" />
      <Content className="flex flex-col overflow-hidden pt-1">
        <SubmissionList />
      </Content>
    </MainTemplate>
  );
}

export default Signal;
